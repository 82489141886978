import React, { useState, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { AppBar, Box, Grid, styled, useTheme } from "@mui/material";
import Home from "../Home";
import { httpclient } from "../../utils/httpClient";
import { PriorityHigh, Warning } from "@mui/icons-material";
import ModulesView from "../Online/ModulesView";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const AppBarTabs = styled(AppBar)(({ theme }) => ({
  background: "#fff",
  color: theme.palette.primary.dark,
  borderRadius: "4px",
  boxShadow: "none",
}));

const HomeMain = () => {
  const theme = useTheme();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  var loginData = localStorage.getItem("login");
  var loginValue = JSON.parse(loginData);
  //console.log("loginValue", loginValue);
  const [moduleList, setModuleList] = useState([]);

  useEffect(() => {
    getAllModules();
  }, []);

  const getAllModules = () => {
    httpclient
      .get("modules")
      .then(({ data }) => {
        if (data.success) {
          //setModuleList(data.data);
          const filteredModules = data.data.filter(
            (module) => module.is_active === 1
          );
          setModuleList(filteredModules);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.clear();
          window.location = "/login";
        }
      });
  };

  const tabValue = loginValue?.userModule?.some((module) => module === "Online")
    ? 1
    : 0;
  //console.log("tab", tabValue)
  const [value, setValue] = useState(0);

  return (
    <div>
      <AppBarTabs position="static">
        {!(
          loginValue?.UserLevel === 2 && loginValue?.userModule.length === 0
        ) && (
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="inherit"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            {moduleList
              .slice()
              // .reverse()
              .map((module, index) => (
                <Tab label={module.name} key={index} {...a11yProps(index)} />
              ))}
          </Tabs>
        )}
      </AppBarTabs>

      {moduleList
        .slice()
        // .reverse()
        .map((module, index) => (
          <TabPanel value={value} index={index} key={index}>
            {loginValue?.UserLevel === 2 ? (
              loginValue?.userModule.some(
                (userModule) => userModule === module.name
              ) ? (
                module.name === "NetSuite" ? (
                  <Home />
                ) : (
                  <ModulesView module={module.name} />
                )
              ) : loginValue?.userModule.length > 0 ? (
                <span style={{ fontFamily: "sans-serif" }}>
                  You don't have access to this module
                </span>
              ) : (
                <span
                  style={{
                    fontFamily: "sans-serif",
                    fontWeight: "bold",
                    color: "red",
                  }}
                >
                  <PriorityHigh sx={{ paddingTop: "10px" }} /> Please contact
                  your admin for further access
                </span>
              )
            ) : module.name === "NetSuite" ? (
              <Home />
            ) :(
              <ModulesView module={module.name} />
            )}
          </TabPanel>
        ))}
    </div>
  );
};

export default HomeMain;
