import { Box, Button, Grid, Portal, Snackbar, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { CloudUpload, FilterList } from "@mui/icons-material";
import useApiCall from "../../../../../../hooks/useApiCall";
import FilterForm from "../../../../../../Components/FilterForm/FilterForm";
import ExcelMenu from "../../../../../../Components/ExcelMenu/ExcelMenu";
import { colDataExtendAttributes } from "../../../../../../utils/DataGrid/colDataExtendAttributes";
import { httpclient } from "../../../../../../utils/httpClient";

const UploadGridTablePage = ({
  customIDKey,
  columns,
  filterColumn,
  headerTitle,
  uploadFeature,
  pageUrl,
  uploadFileLink,
  columnsVisibility,
  children,
  dataChanged,
  hideMenu,
  navigateState,
  forecastPassedUrl,
  tempTableOff,
}) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("Upload Successful.");
  const [toogleFilterForm, setToogleFilterForm] = useState(false);
  const [toogleChildren, setToogleChildren] = useState(false);
  const [passRowParams, setPassRowParams] = useState({});
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 20,
  });
  const [sortModel, setSortModel] = useState([
    {
      field: "",
      sort: "",
    },
  ]);
  const [filterData, setFilterData] = useState("");

  const [file, setFile] = useState("");
  const [uploadLoading, setUploadLoading] = useState(null);

  const { loading, error, data, pageInfo } = useApiCall(
    `${pageUrl}?pagination=${paginationModel.pageSize}&page=${
      paginationModel.page + 1
    }&sort_by=${sortModel[0]?.field || ""}&direction=${
      sortModel[0]?.sort || ""
    }&${filterData}`,
    "GET",
    [sortModel, paginationModel, filterData, dataChanged, file]
    //check this dependencies pagination working only after sorting model changed
  );

  const [rowCountState, setRowCountState] = React.useState(
    pageInfo?.total || 0
  );

  const handleFilterDataChange = (newFilterData) => {
    setFilterData(newFilterData);
  };

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;

    // Check if a file is selected
    if (!selectedFiles || selectedFiles.length === 0) {
      return;
    }

    // Set the selected file in the state (assuming you have a state variable named 'file')
    setFile(selectedFiles[0]);

    // Check if the selected file is a PDF
    const file = selectedFiles[0];
    if (!file.type.includes("pdf")) {
      setMessage("Please select only a PDF file.");
      setOpen(true);
      // Clear the input value to prevent invalid files from being submitted
      event.target.value = null;
      return;
    }

    const formData = new FormData();
    formData.append("file", file); // Use 'file' instead of 'selectedFiles'
    setUploadLoading(true);
    httpclient
      .post("/temple-webster/upload-shipping-label-file", formData)
      .then(({ data }) => {
        if (data.success === true) {
          setUploadLoading(false);
          setMessage(data.message);
          setFile("");
          setOpen(true);
        } else {
          setUploadLoading(false);
          // Handle error, e.g., show a message to the user
          setMessage(data.message);
          // : " + data.message);
          setOpen(true);
          setFile("");
        }
      })
      .catch((error) => {
        setUploadLoading(false);
        // Handle HTTP error
        setMessage("Upload Failed.");
        alert("An error occurred while uploading the file.");
      });
    console.log("FILE UPLOADED", file);
  };

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      pageInfo?.total !== undefined ? pageInfo?.total : prevRowCountState
    );
  }, [pageInfo?.total, setRowCountState]);

  const handleSortModelChange = (model) => {
    if (JSON.stringify(model) !== JSON.stringify(sortModel)) {
      setSortModel(model);
    }
  };
  const handleCellClick = (param, event) => {
    event.stopPropagation();
  };

  const customToolBar = () => {
    return (
      <>
        {uploadFeature && (
          <Portal container={() => document.getElementById("filter-panel")}>
            {!uploadFileLink && (
              <>
                <Button
                  component="label"
                  variant="contained"
                  startIcon={<CloudUpload />}
                >
                  {uploadLoading ? "Uploading ..." : "Upload PDF"}
                  <input
                    type="file"
                    accept=".pdf"
                    style={{
                      clip: "rect(0 0 0 0)",
                      clipPath: "inset(50%)",
                      height: 1,
                      overflow: "hidden",
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      whiteSpace: "nowrap",
                      width: 1,
                    }}
                    // multiple
                    onChange={handleFileChange} // Add the change event handler
                  />
                </Button>
              </>
            )}
          </Portal>
        )}
      </>
    );
  };

  return (
    <>
      {/* maxWidth={false}> */}
      {!error && (
        <>
          <Grid container spacing={2} sx={{ width: "100%" }}>
            <Grid item md={8} xs={12}>
              <h1>{headerTitle}</h1>
              <Typography fontWeight={"bold"}>
                {navigateState?.fileName
                  ? `File Name: ${navigateState?.fileName}`
                  : ""}
              </Typography>
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
            >
              {uploadFeature && (
                <Box id="filter-panel">
                  {uploadFileLink && (
                    <Button
                      color="primary"
                      variant="contained"
                      target="_blank"
                      disabled={{}}
                      href={{}}
                      // onClick={() => console.log(downloadForecastData)}
                    >
                      Upload PDF
                    </Button>
                  )}
                </Box>
              )}
              {!!filterColumn && (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => setToogleFilterForm((prev) => !prev)}
                  style={{ marginLeft: "5px" }}
                >
                  Filter
                  <FilterList fontSize="small" />
                </Button>
              )}
            </Grid>
            <Grid item xs={12}>
              {!!filterColumn && (
                <FilterForm
                  toogle={toogleFilterForm}
                  navigateState={navigateState}
                  filterColumn={filterColumn}
                  onFilterDataChange={handleFilterDataChange}
                />
              )}

              {navigateState?.startDate && (
                <ExcelMenu hideMenu={hideMenu} navigateState={navigateState} />
              )}
            </Grid>

            <Grid item xs={12}>
              {!tempTableOff && (
                <DataGrid
                  //this optional
                  slots={{
                    toolbar: customToolBar,
                  }}
                  //this changes and optional
                  columnVisibilityModel={columnsVisibility}
                  //this changes and mandatory
                  getRowId={
                    customIDKey ? (row) => row[customIDKey] : () => uuidv4()
                  }
                  rows={data}
                  onRowClick={
                    children
                      ? (params) => {
                          setToogleChildren((prev) => !prev);
                          setPassRowParams(params);
                          // console.log("ROW CLICKED", params.row);
                        }
                      : null
                  }
                  rowCount={rowCountState}
                  columns={colDataExtendAttributes(columns)}
                  loading={loading}
                  disableColumnMenu
                  autoHeight={true}
                  pageSizeOptions={[20, 50, 70, 100]}
                  paginationMode="server"
                  paginationModel={paginationModel}
                  onPaginationModelChange={setPaginationModel}
                  onCellClick={handleCellClick}
                  sortingMode="server"
                  sortModel={sortModel}
                  onSortModelChange={handleSortModelChange}
                />
              )}
            </Grid>

            {toogleChildren &&
              React.cloneElement(children, {
                setToogleChildren: setToogleChildren,
                parameter: passRowParams,
              })}
          </Grid>

          <Snackbar
            open={open}
            autoHideDuration={3000}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            onClose={(event, reason) => {
              if (reason === "clickaway") {
                return;
              }
              setOpen(false);
            }}
            message={message}
          />
        </>
      )}
      {error && console.log("error message", error)}
    </>
  );
};

export default UploadGridTablePage;
