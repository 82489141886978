import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { httpclient } from "../../../../utils/httpClient";

const StyledHeaderTitle = styled(DialogTitle)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: "#fff",
  position: "relative",
  "& button": {
    position: "absolute",
    right: "15px",
    top: "15px",
    color: "#fff",
  },
}));

// const StyledActions = styled(DialogActions)(({theme}) => ({
//   margin:"5px 10px"
// }))

const StatusDialog = (props) => {

  const [dialogDetails, setDialogDetails] = useState({
    open: true,
    success: false,
    fullfillment: "1",
  });
  const [cancelReason, setCancelReason] = React.useState('');
  const [reasonCode, setReasonCode] = React.useState('');
  //console.log('view details props', props.viewDetails);
  const [reasons, setReasons] = React.useState([]);
  // console.log('selectedddd', props.selectedChecked);

  useEffect(() => {
    if (props.status === "reject") {
      props.sendChangeOrder(dialogDetails, cancelReason, reasonCode);
    } else {
      props.sendChangeOrder(dialogDetails);
    }
  }, [props.status, dialogDetails, cancelReason, reasonCode]);

  useEffect(() => {
    if (props.status === "reject") {
    getReasonCode();
    }
  }, []);

  const getReasonCode = () => {

    httpclient(`new-system/netsuite/order/get-cancel-reasons/${props.selectedChecked?.length > 0 ? props.selectedChecked[0]?.company.erpAccountCustomerID : props.viewDetails.company.erpAccountCustomerID}`).then(({ data }) => {
      if (data) {
        setReasons(data.data);

      }
    })
  }



  const reasons12 = [
    {
      id: 1,
      reason: "Bad Address",
      reasonCode: "CSXB",

    },
    {
      id: 2,
      reason: "Cancelled at Retailer Request",
      reasonCode: "CXSC",

    },
    {
      id: 3,
      reason: "Invalid Ship Instructions",
      reasonCode: "CXSI",

    },
    {
      id: 4,
      reason: "Can't Ship on Time",
      reasonCode: "CXST",

    },
    {
      id: 5,
      reason: "Discontinued Item",
      reasonCode: "CXSD",

    },
    {
      id: 6,
      reason: "Not Enough Stock",
      reasonCode: "CXSN",

    },
    {
      id: 6,
      reason: "Other",
      reasonCode: "CXSO",

    },
    {
      id: 6,
      reason: "Out of Stock",
      reasonCode: "CXSS",

    },
  ]

  const handleChange = (event) => {
    setCancelReason(event.target.value);
    const selectedReason = reasons?.find((reason) => reason.label === event.target.value);
    setReasonCode(selectedReason ? selectedReason.code : '');
  };

  const handleClose = () => {
    setDialogDetails({
      ...dialogDetails,
      open: false,
    });
  };

  const handleYes = () => {
    setDialogDetails({
      ...dialogDetails,
      open: false,
      success: true,
    });
  };

  const handleRadioChange = (e) => {
    setDialogDetails({
      ...dialogDetails,
      fullfillment: e.target.value
    })
  }

  return (
    <div>
      <Dialog
        open={dialogDetails.open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <StyledHeaderTitle id="alert-dialog-title">
          {props.status === "accept" ? "Accept Order" : "Reject Order"}
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </StyledHeaderTitle>
        <DialogContent>
          {/* {props.selectedChecked.length === 0 ?
          props.status === "accept" && props.viewDetails.reportType !== "readyToProcessOrder" ?
          <Box pt={3}>
            
            <FormControl>
            <span > This order has exception, Please confirm you are ready to accept this order?</span>
            <RadioGroup
              row
              name="fullfillment"
              onChange={handleRadioChange}
            >
              <FormControlLabel value="1" control={<Radio />} label="Yes" />
              <FormControlLabel value="0" control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>

          </Box>
          :
          <Box pt={3} >Are you sure you want to {props.status} the order?</Box>

          :
          props.status === "accept" && props.selectedChecked[0].reportType !== "readyToProcessOrder" ?
          <Box pt={3}>
            
            <FormControl>
            <FormLabel > This order has exception, Please confirm you are ready to accept this order?</FormLabel>
            <RadioGroup
              row
              name="fullfillment"
              onChange={handleRadioChange}
            >
              <FormControlLabel value="1" control={<Radio />} label="Yes" />
              <FormControlLabel value="0" control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>

          </Box>
          :
          <Box pt={3} >Are you sure you want to {props.status} the order?</Box>
          }  */}

          <Box pt={3} >Are you sure you want to {props.status} the order?</Box>
          {props.status === "reject" && (
            <>
              <Box pt={3}>Please select the viable reason for rejection.</Box>
              <Box pt={2} sx={{ minWidth: 240 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">required*</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={cancelReason}
                    label="Reason"
                    onChange={handleChange}
                  >
                    <MenuItem value="">
                      <em>Select Reason</em>
                    </MenuItem>
                    {reasons &&
                      reasons.map((reason) => (
                        <MenuItem key={reason.id} value={reason.label}>
                          {reason.label}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>
            </>
          )}
        </DialogContent>
        <DialogActions styled={{ margin: "5px 10px" }}>
          <Button onClick={handleClose} color="primary" variant="outlined" autoFocus>
            No
          </Button>
          <Button onClick={handleYes} color="primary" variant="outlined" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default StatusDialog;
