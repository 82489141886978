import { RemoveRedEye } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Snackbar,
  styled,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { httpclient } from "../../../../utils/httpClient";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Header = styled("div")(({ theme }) => ({
  "& h1": {
    color: theme.palette.primary.dark,
  },
}));

const RootCard = styled(Card)(({ theme }) => ({
  padding: "40px",
  [theme.breakpoints.down("md")]: {
    padding: "20px",
  },
}));

const PasswordBox = styled(Box)(({ theme }) => ({
  position: "relative",
  "& svg": {
    cursor: "pointer",
    position: "absolute",
    right: "18px",
    top: "18px",
    color: "#999",
  },
}));

const AddUser = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [moduleList, setModuleList] = useState([]);
  // const mapModuleList = () => {
  //   return moduleList.map((module, index) => ({
  //     id: module.id, 
  //     name: module.name,
  //   }));
  // };
  const [userDetails, setUserDatails] = useState({
    userName: "",
    password: "",
    firstName: "",
    lastName: "",
    UserLevel: "",
    userModule: [],
    userActive: false,
  });

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [messageState, setMessageState] = useState("");

  const [viewPassword, setViewPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  var loginData = localStorage.getItem("login");
  var loginValue = JSON.parse(loginData);



  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  useEffect(() => {
    getAllModules();
    getSingleUser();

  }, []);

  const getAllModules = () => {
    httpclient.get("/user/active/modules").then(({ data }) => {
      if (data.success) {
        setModuleList(data.data);

      }
    }).catch(err => {
      if (err.response.status === 401) {
        localStorage.clear();
        window.location = "/login";
      }
    })
  };
  //console.log('modulelist', moduleList);
  //console.log('loc', location);
  // const handleChange1 = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   setUserModule(
  //     // On autofill we get a stringified value.
  //     typeof value === 'string' ? value.split(',') : value,
  //   );
  // };

  const getSingleUser = () => {
    id &&
      httpclient.get(`user/${id}`).then(({ data }) => {
        if (data.success) {
          //console.log("user-success", data);
          setUserDatails({
            userName: data.data.userName,
            password: location.state === "edit_profile" ? "" : data.data.password,
            firstName: data.data.firstName,
            lastName: data.data.lastName,
            UserLevel: data.data.UserLevel,
            userModule: data.data.userModule.map(user => user),
            userActive: data.data.userActive === 1 ? true : false,
          });
        }
      });
  };

  //console.log("user dteails", userDetails);

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setUserDatails({
  //     ...userDetails,
  //     [name]: value,
  //   });
  // };
  const handleChange = (e) => {
    //console.log("changed", e )
    const { name, value } = e.target;


    if (name === "userModule") {
      //console.log("name", name)
      const selectedModules = e.target.value;
      //console.log("selected-module", selectedModules)
      setUserDatails({
        ...userDetails,
        [name]: selectedModules,
      });

    } else {
      setUserDatails({
        ...userDetails,
        [name]: value,
      });
    }

  };

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setUserDatails({
      ...userDetails,
      [name]: checked,
    });
  };

  const handleBack = () => {
    navigate(-1);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    !id
      ? httpclient
        .post("user", {
          userName: userDetails.userName,
          password: userDetails.password,
          firstName: userDetails.firstName,
          lastName: userDetails.lastName,
          userActive: userDetails.userActive === true ? 1 : 0,
          UserLevel: userDetails.UserLevel,
          userModule: userDetails.userModule,
        })
        .then(({ data }) => {
          if (data.success) {
            setOpen(true);
            setMessageState("success");
            setMessage(data.message);
            setLoading(false);
            setTimeout(() => {
              navigate("/users");
            }, [1500]);
          } else {
            setLoading(false);
            setOpen(true);
            setMessageState("error");
            setMessage(typeof (data.error) === "string" ? data.error : data.error[0]);
          }
        })
      :
      location.state === "edit_profie" ?
        httpclient
          .post(`user/${id}`, {
            userName: userDetails.userName,
            password: userDetails.password,
            firstName: userDetails.firstName,
            lastName: userDetails.lastName,
            userActive: userDetails.userActive === true ? 1 : 0,
            UserLevel: userDetails.UserLevel,
            userModule: userDetails.userModule,
          })
          .then(({ data }) => {
            if (data.success) {
              setOpen(true);
              setMessageState("success");
              setMessage(data.message);
              setLoading(false);
              setTimeout(() => {
                navigate("/users");
              }, [1500]);
            } else {
              setLoading(false);
              setOpen(true);
              setMessageState("error");
              setMessage(typeof (data.error) === "string" ? data.error : data.error[0]);
            }
          })
        :
        httpclient
          .post(`user/${id}`, {
            userName: userDetails.userName,
            // password: userDetails.password,
            firstName: userDetails.firstName,
            lastName: userDetails.lastName,
            userActive: userDetails.userActive === true ? 1 : 0,
            UserLevel: userDetails.UserLevel,
            userModule: userDetails.userModule,
          })
          .then(({ data }) => {
            if (data.success) {
              setOpen(true);
              setMessageState("success");
              setMessage(data.message);
              setLoading(false);
              setTimeout(() => {
                navigate("/users");
              }, [1500]);
            } else {
              setLoading(false);
              setOpen(true);
              setMessageState("error");
              setMessage(typeof (data.error) === "string" ? data.error : data.error[0]);
            }
          });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  //console.log("user-details", userDetails);
  return (
    <div>
      <Header>
        <h1>{id ? location.state === "edit_profile" ? "Edit Profile" : "Edit User" : "Add User"}</h1>
      </Header>
      <RootCard>
        <form onSubmit={(e) => handleSubmit(e)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <TextField
                variant="outlined"
                name="firstName"
                type="text"
                label="firstName"
                value={userDetails.firstName}
                onChange={(e) => handleChange(e)}
                sx={{ width: "100%" }}
                required
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                variant="outlined"
                name="lastName"
                type="text"
                label="lastName"
                value={userDetails.lastName}
                onChange={(e) => handleChange(e)}
                sx={{ width: "100%" }}
                required
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                variant="outlined"
                name="userName"
                type="text"
                label="Username/Email"
                value={userDetails.userName}
                onChange={(e) => handleChange(e)}
                sx={{ width: "100%" }}
                required
              />
            </Grid>
            {!id &&
              // location.state === "edit_profile" &&
              <Grid item xs={12} md={12}>
                <PasswordBox>
                  <TextField
                    variant="outlined"
                    name="password"
                    type={viewPassword ? "text" : "password"}
                    label="Password"
                    value={userDetails.password}
                    onChange={(e) => handleChange(e)}
                    sx={{ width: "100%" }}
                    required
                  />
                  <RemoveRedEye onClick={() => setViewPassword(!viewPassword)} />
                </PasswordBox>
              </Grid>
            }

            {loginValue.UserLevel === 1 &&
              <>
                <Grid item xs={12} md={12}>
                  <FormControl>
                    <FormControlLabel
                      control={<Checkbox value={userDetails.userActive} checked={userDetails.userActive ? true : false} onChange={handleCheck} name="userActive" />}
                      label="User Active ?"
                    // labelPlacement="top"
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">User Level</InputLabel>
                    <Select
                      required
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={userDetails.UserLevel}
                      name="UserLevel"
                      label="User Level"
                      onChange={(e) => handleChange(e)}
                    >
                      <MenuItem value="">
                        <em>Select Level</em>
                      </MenuItem>
                      <MenuItem value={1}>Admin</MenuItem>
                      <MenuItem value={2}>Staff</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {userDetails.UserLevel === 2 &&
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-multiple-checkbox-label">User Module</InputLabel>
                      <Select
                        required
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        name="userModule"
                        value={userDetails.userModule}
                        onChange={(e) => handleChange(e)}
                        input={<OutlinedInput label="User Module" />}
                        renderValue={(selected) => selected.join(', ')}
                        MenuProps={MenuProps}
                      >
                        {moduleList.map((module) => (
                          <MenuItem key={module.name} value={module.name}>
                            <Checkbox checked={userDetails.userModule.indexOf(module.name) > -1} />
                            <ListItemText primary={module.name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                }

              </>
            }
            <Grid item xs={12}>
              <Box textAlign={"right"}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleBack}
                  sx={{ marginRight: "10px" }}
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                // onClick={handleSubmit}
                >
                  Submit
                </Button>
                {loading && (
                  <CircularProgress size={"small"} style={{ width: "25px", height: '25px', marginLeft: "10px", position: "relative", top: "10px" }} />
                )}
              </Box>
            </Grid>
          </Grid>
        </form>
      </RootCard>

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={messageState}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default AddUser;
