import { Grid, InputLabel, TextField } from "@mui/material";
import React from "react";

const FilterTextField = ({ label, name, value, onChange, type, onKeyDown }) => {
  return (
    <>
      <Grid item xs={12} md={4}>
        <InputLabel>{label}</InputLabel>
        <TextField
          variant="outlined"
          type={type || "text"}
          inputProps={{ min: 0 }}
          name={name}
          value={value}
          onChange={onChange}
          onKeyDown={onKeyDown}
          fullWidth
        />
      </Grid>
    </>
  );
};

export default FilterTextField;
