import React, { useEffect, useState } from "react";
import { ArrowForward, Close, FilterList } from "@mui/icons-material";
import TableComponent from "../../../Online/Stock/TableComponent";
import { httpclient } from "../../../../utils/httpClient.js";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Collapse,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  styled,
  TextField,
} from "@mui/material";
import ViewNetsuiteStockDetails from "../ViewNetsuiteStockDetails";
import TableComponentNew from "../../../Online/Stock/TableComponentNew";

const columns = [
  { id: "stockID", name: "ID" },
  { id: "stockCode", name: "Stock Code" },
  { id: "stockNameDefault", name: "Stock Name" },
  { id: "brand", name: "Brand Name" },
  //{ id: "brand", name: "Brand Name" },
  { id: "sub_class", name: "Group 1" },
  { id: "class", name: "Group 2" },
  { id: "stockExclusiveToClientName", name: "Exclusive To" },
  // { id: "soldTo", name: "Sold To" },
  { id: "active", name: "Active" },
  { id: "lastModified", name: "Last Modified" },
  // { id: "actions", name: "Actions" },
];

const FilteredBox = styled(Box)(({ theme }) => ({
  background: "#f9f9f9",
  padding: "5px 10px",
  borderRadius: "5px",
  "& p": {
    margin: "0",
    marginRight: "10px",
    display: "inline-block",
    background: "#dedede",
    borderRadius: "10px",
    padding: "2px 5px",
  },
  "& svg": {
    fontSize: "15px",
    cursor: "pointer",
    position: "relative",
    top: "3px",
    background: theme.palette.primary.dark,
    color: "#fff",
    borderRadius: "50%",
    padding: "2px",
    marginLeft: "2px",
  },
}));

const Header = styled("div")(({ theme }) => ({
  "& h1": {
    color: theme.palette.primary.dark,
    margin: "0",
  },
}));

const configRowPerPage = JSON.parse(localStorage.getItem("configRowPerPage"));

const NetsuiteCurrentStock = () => {
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [viewDetails, setViewDetails] = useState({});
  const [singleLoading, setSingleLoading] = useState(false);
  const [rows, setRows] = useState([]);

  const [loading, setLoading] = useState(false);
  const [direction, setDirection] = useState(false);
  const [currentColumn, setCurrentColumn] = useState("");
  const [page, setPage] = useState(1);
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(
    configRowPerPage && configRowPerPage
      ? configRowPerPage && configRowPerPage
      : 20
  );

  const [rowsPerPage, setRowsPerPage] = useState(
    configRowPerPage && configRowPerPage
      ? configRowPerPage && configRowPerPage
      : 20
  );
  const [total, setTotal] = useState("");
  const [filterOpen, setFilterOpen] = useState(false);
  const [groupList1, setGroupList1] = useState([]);
  const [groupList2, setGroupList2] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);

  const [filterData, setFilterData] = useState({
    stockName: "",
    stockCode: "",
    brandName: "",
    brandID: "",
    categoryName: "",
    categoryID: "",
    group1: "",
    group2: "",
    group1Name: "",
    group2Name: "",
    exclusiveTo: "",
    exclusiveName: "",
    soldTo: "",
    soldToName: "",
    active: "",
    pendingProcess: "",
    lastModified: "",
    remove: false,
  });

  const [submittedData, setSubmittedData] = useState({
    stockName: "",
    stockCode: "",
    brandName: "",
    brandID: "",
    categoryName: "",
    categoryID: "",
    group1: "",
    group2: "",
    group1Name: "",
    group2Name: "",
    exclusiveTo: "",
    exclusiveName: "",
    soldTo: "",
    soldToName: "",
    active: "",
    pendingProcess: "",
    lastModified: "",
    submit: false,
  });

  // console.log("filter data", filterData);
  // console.log('submitted data', submittedData);

  useEffect(() => {
    // getCurrentStocks();
    getGroup1();
    getGroup2();
    getAllCompany();
    getAllBrands();
    getAllCategories();
  }, []);

  useEffect(() => {
    if (
      filterData.stockCode === "" &&
      filterData.stockName === "" &&
      filterData.brandName === "" &&
      filterData.brandID === "" &&
      filterData.categoryName === "" &&
      filterData.categoryID === "" &&
      filterData.group1 === "" &&
      filterData.group1Name === "" &&
      filterData.group2 === "" &&
      filterData.group2Name === "" &&
      filterData.exclusiveTo === "" &&
      filterData.exclusiveName === "" &&
      filterData.soldTo === "" &&
      filterData.soldToName === "" &&
      filterData.pendingProcess === "" &&
      filterData.active === ""
    ) {
      setSubmittedData({
        ...submittedData,
        submit: false,
      });
    }
    if (filterData.stockCode === " ") filterData.stockCode = "";
    if (filterData.stockName === " ") filterData.stockName = "";
    if (filterData.brandName === " ") filterData.brandName = "";
    if (filterData.brandID === " ") filterData.brandID = "";
    if (filterData.categoryName === " ") filterData.categoryName = "";
    if (filterData.categoryID === " ") filterData.categoryID = "";
    if (filterData.group1 === " ") filterData.group1 = "";
    if (filterData.group1Name === " ") filterData.group1Name = "";
    if (filterData.group2 === " ") filterData.group2 = "";
    if (filterData.group2Name === " ") filterData.group2Name = "";
    if (filterData.exclusiveTo === " ") filterData.exclusiveTo = "";
    if (filterData.exclusiveName === " ") filterData.exclusiveName = "";
    if (filterData.soldTo === " ") filterData.soldTo = "";
    if (filterData.soldToName === " ") filterData.soldToName = "";
    if (filterData.pendingProcess === " ") filterData.pendingProcess = "";
    if (filterData.active === " ") filterData.active = "";

    filterData.remove === true && handleFilter();
  }, [filterData]);

  useEffect(() => {
    let currentStockStorage = JSON.parse(
      localStorage.getItem("netsuite_current_stock_filter")
    );
    currentStockStorage !== null && setFilterData(currentStockStorage);

    currentStockStorage == null
      ? getCurrentStocks()
      : currentStockStorage.stockCode == "" &&
        currentStockStorage.stockName == "" &&
        currentStockStorage.brandName == "" &&
        currentStockStorage.brandID == "" &&
        currentStockStorage.categoryName == "" &&
        currentStockStorage.categoryID == "" &&
        currentStockStorage.group1 == "" &&
        currentStockStorage.group1Name == "" &&
        currentStockStorage.group2 == "" &&
        currentStockStorage.group2Name == "" &&
        currentStockStorage.exclusiveTo == "" &&
        currentStockStorage.exclusiveName == "" &&
        currentStockStorage.soldTo == "" &&
        currentStockStorage.soldToName == "" &&
        currentStockStorage.pendingProcess == "" &&
        currentStockStorage.active == "" &&
        currentStockStorage.removed == false
      ? getCurrentStocks()
      : console.log("current stocks!");
  }, []);

  const getCurrentStocks = () => {
    setLoading(true);
    httpclient
      .get(`/netsuite/v1/getProducts?pagination=${rowsPerPage}`)
      .then(({ data }) => {
        if (data.success) {
          setRows(data.records.data);
          setTotal(data.records.total);
          setRowsPerPage(parseInt(data.records.per_page));
          setPage(data.records.current_page);
          setFrom(data.records.from);
          setTo(data.records.to);
          setLoading(false);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.clear();
          window.location = "/login";
        }
      });
  };

  const getGroup1 = () => {
    httpclient
      .get("/netsuite/v1/getSubclasses")
      .then(({ data }) => {
        if (data.success) {
          setGroupList1(data.records.data);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.clear();
          window.location = "/login";
        }
      });
  };

  const getGroup2 = () => {
    httpclient
      .get("/netsuite/v1/getClasses")
      .then(({ data }) => {
        if (data.success) {
          setGroupList2(data.records.data);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.clear();
          window.location = "/login";
        }
      });
  };

  const getAllCompany = () => {
    httpclient
      .get(`new-system/netsuite/company-account/all`)
      .then(({ data }) => {
        if (data.success) {
          setCompanyList(data.data);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.clear();
          window.location = "/login";
        }
      });
  };

  const getAllBrands = () => {
    httpclient
      .get("/netsuite/v1/getBrands")
      .then(({ data }) => {
        if (data.success) {
          setBrandList(data.records.data);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.clear();
          window.location = "/login";
        }
      });
  };

  const getAllCategories = () => {
    httpclient
      .get("/netsuite/v1/getCategories")
      .then(({ data }) => {
        if (data.success) {
          setCategoryList(data.records);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.clear();
          window.location = "/login";
        }
      });
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "group1") {
      setFilterData({
        ...filterData,
        group1Name: value,
        remove: false,
      });
    }
    if (name === "group2") {
      setFilterData({
        ...filterData,
        group2Name: value,
        remove: false,
      });
    }
    if (name === "exclusiveTo") {
      setFilterData({
        ...filterData,
        exclusiveName: value,
        remove: false,
      });
    }
    if (name === "brandID") {
      setFilterData({
        ...filterData,
        brandName: value,
        remove: false,
      });
    }
    if (name === "caregoryID") {
      setFilterData({
        ...filterData,
        categoryName: value,
        remove: false,
      });
    }

    if (name === "soldTo") {
      setFilterData({
        ...filterData,
        soldToName: value,
        remove: false,
      });
    }
  };

  const handleChangeGroup1 = (value) => {
    setFilterData({
      ...filterData,
      group1: value !== null ? value.erpGroupID1 : "",
      group1Name: value !== null ? value.groupName1 : "",
      remove: false,
    });
  };

  const handleChangeGroup2 = (value) => {
    setFilterData({
      ...filterData,
      group2: value !== null ? value.erpGroup2ID : "",
      group2Name: value !== null ? value.groupName2 : "",
      remove: false,
    });
  };

  const handleChangeExclusive = (value) => {
    setFilterData({
      ...filterData,
      exclusiveTo: value !== null ? value.id : "",
      exclusiveName: value !== null ? value.name : "",
      remove: false,
    });
  };

  const handleChangeSoldTo = (value) => {
    setFilterData({
      ...filterData,
      soldTo: value !== null ? value.id : "",
      soldToName: value !== null ? value.name : "",
      remove: false,
    });
  };

  const handleChangeBrand = (value) => {
    setFilterData({
      ...filterData,
      brandID: value !== null ? value.erpBrandID : "",
      brandName: value !== null ? value.brandName : "",
      remove: false,
    });
  };

  const handleChangeCategory = (value) => {
    setFilterData({
      ...filterData,
      categoryID: value !== null ? value.id : "",
      categoryName: value !== null ? value.name : "",
      remove: false,
    });
  };

  const hadleFilterOpen = () => {
    setFilterOpen((prev) => !prev);
  };

  const handleChangeFilter = (e) => {
    const { name, value } = e.target;
    setFilterData({
      ...filterData,
      [name]: value,
      remove: false,
    });
  };

  const handleView = (stockCode) => {
    setSingleLoading(true);
    setOpenViewDialog(true);
    httpclient.get(`/netsuite/v1/getProduct/${stockCode}`).then(({ data }) => {
      if (data.success) {
        setViewDetails(data.records[0]);
        setSingleLoading(false);
      }
    });
  };

  const sendDetails = (callback) => {
    if (callback.open === false) {
      setOpenViewDialog(false);
      setViewDetails({});
    }
  };

  const handleFilter = () => {
    setSubmittedData({
      ...submittedData,
      stockName: filterData.stockName,
      stockCode: filterData.stockCode,
      brandName: filterData.brandName,
      brandID: filterData.brandID,
      categoryName: filterData.categoryName,
      categoryID: filterData.categoryID,
      group1: filterData.group1,
      group2: filterData.group2,
      group1Name: filterData.group1Name,
      group2Name: filterData.group2Name,
      exclusiveTo: filterData.exclusiveTo,
      exclusiveName: filterData.exclusiveName,
      soldTo: filterData.soldTo,
      soldToName: filterData.soldToName,
      pendingProcess: filterData.pendingProcess,
      active: filterData.active,
      submit: true,
    });
    filterData.remove = true;
    localStorage.setItem("netsuite_current_stock_filter", JSON.stringify(filterData));
    setLoading(true);
    if (
      filterData.stockCode ||
      filterData.stockName ||
      filterData.brandID ||
      filterData.categoryID ||
      filterData.group1 ||
      filterData.group2 ||
      filterData.soldTo ||
      filterData.soldToName ||
      filterData.exclusiveName ||
      filterData.pendingProcess ||
      filterData.active
    ) {
      httpclient
        .get(
          `netsuite/v1/getProducts?stockCode=${
            filterData.stockCode
          }&stockNameDefault=${filterData.stockName}&stockBrandName=${
            filterData.brandID
          }&category=${
            filterData.categoryID
          }&stockGroupID1=${filterData.group1}&stockGroupID2=${
            filterData.group2
          }&pendingProcess=${
            filterData.pendingProcess
          }&stockExclusiveToClient=${filterData.exclusiveTo}&soldTo=${
            filterData.soldTo
          }&active=${filterData.active}&pagination=${rowsPerPage}&page=${1}`
        )
        .then(({ data }) => {
          if (data.success) {
            setRows(data.records.data);
            setTotal(data.records.total);
            setRowsPerPage(data.records.per_page);
            setPage(data.records.current_page);
            setFrom(data.records.from);
            setTo(data.records.to);
            setLoading(false);
          }
        });
    } else {
      getCurrentStocks();
    }
  };

  const handleRemove = (data) => {
    if (data === "group1") {
      filterData.group1Name = "";
      submittedData.group1Name = "";
    }
    if (data === "group2") {
      filterData.group2Name = "";
      submittedData.group2Name = "";
    }
    if (data === "exclusiveTo") {
      filterData.exclusiveName = "";
      submittedData.exclusiveName = "";
    }
    if (data === "brandID") {
      filterData.brandName = "";
      submittedData.brandName = "";
    }
    if (data === "categoryID") {
      filterData.categoryName = "";
      submittedData.categoryName = "";
    }
    if (data === "soldTo") {
      filterData.soldToName = "";
      submittedData.soldToName = "";
    }
    setFilterData({
      ...filterData,
      [data]: "",
      remove: true,
    });
    setSubmittedData({
      ...submittedData,
      [data]: "",
    });
  };

  const handleSort = (column) => {
    setDirection((prevDirection) => !prevDirection);
    setCurrentColumn(column);
    submittedData.submit
      ? httpclient
          .get(
            `netsuite/v1/getProducts?stockCode=${
              filterData.stockCode
            }&stockNameDefault=${filterData.stockName}&stockBrandName=${
              filterData.brandName
            }&category=${
              filterData.categoryID
            }&pendingProcess=${filterData.pendingProcess}&stockGroupID1=${
              filterData.group1
            }&stockGroupID2=${filterData.group2}&stockExclusiveToClient=${
              filterData.exclusiveTo
            }&soldTo=${filterData.soldTo}&active=${
              filterData.active
            }&sort_by=${column}&direction=${
              !direction ? "asc" : "desc"
            }&pagination=${rowsPerPage}&page=${page}`
          )
          .then(({ data }) => {
            if (data.success === true) {
              setRows(data.records.data);
              setTotal(data.records.total);
              setRowsPerPage(parseInt(data.records.per_page));
              setPage(data.records.current_page);
              setFrom(data.records.from);
              setTo(data.records.to);
              setLoading(false);
            }
          })
      : httpclient
          .get(
            `netsuite/v1/getProducts?sort_by=${column}&direction=${
              !direction ? "asc" : "desc"
            }&pagination=${rowsPerPage}`
          )
          .then(({ data }) => {
            if (data.success === true) {
              setRows(data.records.data);
            }
          });
  };



  const handleChangePage = (e, page) => {
    setLoading(true);
    console.log("dir >>", direction ? "asc" : "desc");
    submittedData.submit
      ? httpclient
          .get(
            `netsuite/v1/getProducts?stockCode=${
              filterData.stockCode
            }&stockNameDefault=${filterData.stockName}&pendingProcess=${
              filterData.pendingProcess
            }&stockBrandName=${filterData.brandName}&stockGroupID1=${
              filterData.group1
            }&category=${
              filterData.categoryID
            }&stockGroupID2=${filterData.group2}&stockExclusiveToClient=${
              filterData.exclusiveTo
            }&soldTo=${filterData.soldTo}&active=${
              filterData.active
            }&sort_by=${currentColumn}&direction=${
              direction ? "asc" : "desc"
            }&pagination=${rowsPerPage}&page=${page}`
          )
          .then(({ data }) => {
            if (data.success === true) {
              setRows(data.records.data);
              setTotal(data.records.total);
              setRowsPerPage(parseInt(data.records.per_page));
              setPage(data.records.current_page);
              setFrom(data.records.from);
              setTo(data.records.to);
              setLoading(false);
            }
          })
      : httpclient
          .get(
            `netsuite/v1/getProducts?direction=${
              direction ? "asc" : "desc"
            }&pagination=${rowsPerPage}&page=${page}`
          )
          .then(({ data }) => {
            if (data.success === true) {
              setRows(data.records.data);
              setTotal(data.records.total);
              setRowsPerPage(parseInt(data.records.per_page));
              setPage(data.records.current_page);
              setFrom(data.records.from);
              setTo(data.records.to);
              setLoading(false);
            }
          });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    // setLoading(true);

    localStorage.setItem("configRowPerPage", event.target.value);
    submittedData.submit
      ? httpclient
          .get(
            `netsuite/v1/getProducts?stockCode=${
              filterData.stockCode
            }&stockNameDefault=${filterData.stockName}&pendingProcess=${
              filterData.pendingProcess
            }&stockBrandName=${filterData.brandName}&stockGroupID1=${
              filterData.group1
            }&category=${
              filterData.categoryID
            }&stockGroupID2=${filterData.group2}&stockExclusiveToClient=${
              filterData.exclusiveTo
            }&soldTo=${filterData.soldTo}&active=${
              filterData.active
            }&direction=${
              direction ? "asc" : "desc"
            }&sort_by=${currentColumn}&pagination=${+event.target
              .value}&page=${page}`
          )
          .then(({ data }) => {
            setLoading(true);
            if (data.success === true) {
              setRows(data.records.data);
              setTotal(data.records.total);
              setRowsPerPage(parseInt(data.records.per_page));
              setPage(data.records.current_page);
              setFrom(data.records.from);
              setTo(data.records.to);
              setLoading(false);
            }
          })
      : httpclient
          .get(
            `netsuite/v1/getProducts?direction=${
              direction ? "asc" : "desc"
            }&pagination=${+event.target.value}&page=${1}`
          )
          .then(({ data }) => {
            setLoading(true);
            if (data.success === true) {
              setRows(data.records.data);
              setTotal(data.records.total);
              setRowsPerPage(parseInt(data.records.per_page));
              setFrom(data.records.from);
              setTo(data.records.to);
              setPage(data.records.current_page);
              setLoading(false);
            }
          });
  };

  console.log("curr colm", currentColumn);

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item md={4} xs={12}>
          <Header>
            <h1>List Current Stock (NetSuite)</h1>
          </Header>
        </Grid>
        <Grid
          item
          md={8}
          xs={12}
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Button color="primary" variant="contained" onClick={hadleFilterOpen}>
            Filter <FilterList style={{ marginLeft: "5px" }} fontSize="small" />
          </Button>
        </Grid>

        {/* Filter */}
        <Grid item xs={12}>
          <Collapse in={filterOpen}>
            <Card>
              <Box p={4}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <InputLabel>Stock Name</InputLabel>
                    <TextField
                      variant="outlined"
                      name="stockName"
                      value={filterData.stockName}
                      onChange={handleChangeFilter}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") handleFilter();
                      }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <InputLabel>Stock Code</InputLabel>
                    <TextField
                      variant="outlined"
                      name="stockCode"
                      value={filterData.stockCode}
                      onChange={handleChangeFilter}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") handleFilter();
                      }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <InputLabel>Brand Name</InputLabel>
                    <Autocomplete
                      disablePortal
                      id="brand_name"
                      options={brandList}
                      onChange={(event, newValue) => {
                        handleChangeBrand(newValue);
                      }}
                      inputValue={filterData.brandName}
                      getOptionLabel={(option) => option.brandName}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          onChange={handleChange}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") handleFilter();
                          }}
                          value={filterData.brandName}
                          variant="outlined"
                          name="brandID"
                          // label="Company"
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <InputLabel>Category</InputLabel>
                    <Autocomplete
                      disablePortal
                      id="category_name"
                      options={categoryList}
                      onChange={(event, newValue) => {
                        handleChangeCategory(newValue);
                      }}
                      inputValue={filterData.categoryName}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          onChange={handleChange}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") handleFilter();
                          }}
                          value={filterData.categoryName}
                          variant="outlined"
                          name="categoryID"
                          // label="Company"
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <InputLabel>Current Stock Group 1</InputLabel>
                    <Autocomplete
                      disablePortal
                      id="group1_name"
                      options={groupList1}
                      onChange={(event, newValue) => {
                        handleChangeGroup1(newValue);
                      }}
                      inputValue={filterData.group1Name}
                      getOptionLabel={(option) => option.groupName1}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          onChange={handleChange}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") handleFilter();
                          }}
                          value={filterData.group1Name}
                          variant="outlined"
                          name="group1"
                          // label="Company"
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <InputLabel>Current Stock Group 2</InputLabel>
                    <Autocomplete
                      disablePortal
                      id="group2_name"
                      options={groupList2}
                      onChange={(event, newValue) => {
                        handleChangeGroup2(newValue);
                      }}
                      inputValue={filterData.group2Name}
                      getOptionLabel={(option) => option.groupName2}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          onChange={handleChange}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") handleFilter();
                          }}
                          value={filterData.group2Name}
                          variant="outlined"
                          name="group2"
                          // label="Company"
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <InputLabel>Exclusive To</InputLabel>
                    <Autocomplete
                      disablePortal
                      id="exclusive2_name"
                      options={companyList}
                      onChange={(event, newValue) => {
                        handleChangeExclusive(newValue);
                      }}
                      inputValue={filterData.exclusiveName}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          onChange={handleChange}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") handleFilter();
                          }}
                          value={filterData.exclusiveName}
                          variant="outlined"
                          name="exclusiveTo"
                          // label="Company"
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <InputLabel>Sold To</InputLabel>
                    <Autocomplete
                      disablePortal
                      id="sold_to_name"
                      options={companyList}
                      onChange={(event, newValue) => {
                        handleChangeSoldTo(newValue);
                      }}
                      inputValue={filterData.soldToName}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          onChange={handleChange}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") handleFilter();
                          }}
                          value={filterData.soldToName}
                          variant="outlined"
                          name="soldTo"
                          // label="Company"
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <InputLabel>Pending Process</InputLabel>
                    <FormControl fullWidth>
                      <Select
                        value={filterData.pendingProcess}
                        // label="Exclusive Pending"
                        name="pendingProcess"
                        onChange={handleChangeFilter}
                      >
                        <MenuItem value={""}>Select</MenuItem>
                        <MenuItem value={"1"}>Yes</MenuItem>
                        <MenuItem value={"0"}>No</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <InputLabel>Active</InputLabel>
                    <FormControl fullWidth>
                      <Select
                        value={filterData.active}
                        name="active"
                        // label="Active"
                        onChange={handleChangeFilter}
                      >
                        <MenuItem value={""}>Select</MenuItem>
                        <MenuItem value={"Y"}>Yes</MenuItem>
                        <MenuItem value={"N"}>No</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <Box textAlign={"right"}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleFilter}
                      >
                        Filter{" "}
                        <ArrowForward
                          fontSize="small"
                          style={{ marginLeft: "5px" }}
                        />
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </Collapse>
        </Grid>

        {submittedData.stockCode ||
        submittedData.stockName ||
        submittedData.brandID ||
        submittedData.categoryID ||
        submittedData.group1Name ||
        submittedData.group2Name ||
        submittedData.exclusiveName ||
        submittedData.soldToName ||
        submittedData.pendingProcess ||
        submittedData.active ? (
          <Grid item xs={12}>
            <FilteredBox>
              <span>Filtered: </span>
              {submittedData.stockCode && (
                <p>
                  <span>Stock Code: {submittedData.stockCode}</span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("stockCode")}
                  />
                </p>
              )}
              {submittedData.stockName && (
                <p>
                  <span>Stock Name: {submittedData.stockName}</span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("stockName")}
                  />
                </p>
              )}
              {submittedData.brandID && (
                <p>
                  <span>Brand Name: {submittedData.brandName}</span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("brandID")}
                  />
                </p>
              )}
              {submittedData.categoryID && (
                <p>
                  <span>Category: {submittedData.categoryName}</span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("categoryID")}
                  />
                </p>
              )}
              {submittedData.group1Name && (
                <p>
                  <span>Group 1: {submittedData.group1Name}</span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("group1")}
                  />
                </p>
              )}

              {submittedData.group2Name && (
                <p>
                  <span>Group 2: {submittedData.group2Name}</span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("group2")}
                  />
                </p>
              )}
              {submittedData.exclusiveName && (
                <p>
                  <span>Exclusive To: {submittedData.exclusiveName}</span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("exclusiveTo")}
                  />
                </p>
              )}
              {submittedData.pendingProcess && (
                <p>
                  <span>
                    Pending Process:{" "}
                    {submittedData.pendingProcess === "1" ? "Yes" : "No"}
                  </span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("pendingProcess")}
                  />
                </p>
              )}
              {submittedData.soldToName && (
                <p>
                  <span>Sold To: {submittedData.soldToName}</span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("soldTo")}
                  />
                </p>
              )}
              {submittedData.active && (
                <p>
                  <span>
                    Active: {submittedData.active === "Y" ? "Yes" : "No"}
                  </span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("active")}
                  />
                </p>
              )}
            </FilteredBox>
          </Grid>
        ) : (
          <Box></Box>
        )}
        {/* Filter */}

        <Grid item xs={12}>
          <TableComponentNew
            columns={columns}
            rows={rows}
            sort={true}
            handleView={handleView}
            handleSort={handleSort}
            loading={loading}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleChangePage={handleChangePage}
            direction={direction}
            currentColumn={currentColumn}
            page={page}
            total={total && total}
            fromTable={from}
            toTable={to}
            rowsPerPage={rowsPerPage}
          />
        </Grid>
      </Grid>

      {openViewDialog && (
        <ViewNetsuiteStockDetails
          singleLoading={singleLoading}
          viewDetails={viewDetails}
          sendDetails={sendDetails}
        />
      )}
    </div>
  );
};

export default NetsuiteCurrentStock;
