import { Check, Clear, Close } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  styled,
  useTheme,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import BasicTable2 from "../../../../Components/BasicTable2";

const StyledHeaderTitle = styled(DialogTitle)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: "#fff",
  position: "relative",
  "& button": {
    position: "absolute",
    right: "15px",
    top: "15px",
    color: "#fff",
  },
}));

const FlexContent = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  fontSize: "17px",
  marginBottom: "10px",
  alignItems: "flex-start",
}));

const FlexInnerTitle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  minWidth: "180px",
  maxWidth: "250px",
  fontWeight: "600",
}));

const FlexInnerTitle2 = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  // minWidth: "180px",
  minWidth: "280px",
  fontWeight: "600",
}));

const Values = styled("div")(({ theme }) => ({
  marginLeft: "15px",
  fontWeight: "500",
  color: theme.palette.primary.dark,
}));

const AppBarTabs = styled(AppBar)(({ theme }) => ({
  background: "#fff",
  color: theme.palette.primary.dark,
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}


const priceColumns = [
  { id: "erpPolicyID", name: "Policy ID" },
  { id: "policyReference", name: "Policy Reference" },
  { id: "startDate", name: "Start Date" },
  { id: "endDate", name: "End Date" },
  { id: "policyActive", name: "Active" },
];

const ViewDetailsDialog = (props) => {
  const theme = useTheme();
  const [value, setValue] = useState(0);

  const [dialogDetails, setDialogDetails] = useState({
    open: true,
  });

  useEffect(() => {
    props.sendDetails(dialogDetails);
  }, [dialogDetails]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClose = () => {
    setDialogDetails({
      ...dialogDetails,
      open: false,
    });
  };



  return (
    <div>
      <Dialog
        open={dialogDetails.open}
        onClose={handleClose}
        maxWidth="xl"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <StyledHeaderTitle id="alert-dialog-title">
          <div>View Account Details</div>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </StyledHeaderTitle>
        <DialogContent style={{ padding:"0" }}>
          <AppBarTabs position="static">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="secondary"
              textColor="inherit"
              variant="fullWidth"
              aria-label="full width tabs example"
            >
              <Tab label="Customer Details" {...a11yProps(0)} />
              <Tab label="Customer Settings" {...a11yProps(1)} />
              <Tab label="Price Policy" {...a11yProps(2)} />
            </Tabs>
          </AppBarTabs>

          <TabPanel value={value} index={0} dir={theme.direction}>
            <Box pt={3}>
              <Grid container spacing={2}>
                {/* Left Side */}
                <Grid item xs={12} md={6}>
                  <FlexContent>
                    <FlexInnerTitle>
                      <span>Account ID</span> <span> : </span>
                    </FlexInnerTitle>
                    <Values>
                      {props.viewDetails.erpAccountCustomerID || "-"}
                    </Values>
                  </FlexContent>

                  <FlexContent>
                    <FlexInnerTitle>
                      <span>Account Name</span> <span> : </span>
                    </FlexInnerTitle>
                    <Values>{props.viewDetails.erpAccountName || "-"}</Values>
                  </FlexContent>

                  <FlexContent>
                    <FlexInnerTitle>
                      <span>Email</span> <span> : </span>
                    </FlexInnerTitle>
                    <Values>{props.viewDetails.email || "-"}</Values>
                  </FlexContent>

                  <FlexContent>
                    <FlexInnerTitle>
                      <span>Phone</span> <span> : </span>
                    </FlexInnerTitle>
                    <Values>{props.viewDetails.phone || "-"}</Values>
                  </FlexContent>

                  <FlexContent>
                    <FlexInnerTitle>
                      <span>Post Code</span> <span> : </span>
                    </FlexInnerTitle>
                    <Values>{props.viewDetails.postCode || "-"}</Values>
                  </FlexContent>

                  <FlexContent>
                    <FlexInnerTitle>
                      <span>Street Address</span> <span> : </span>
                    </FlexInnerTitle>
                    <Values>{props.viewDetails.address1 || "-"}</Values>
                  </FlexContent>

                  <FlexContent>
                    <FlexInnerTitle>
                      <span>Suburb</span> <span> : </span>
                    </FlexInnerTitle>
                    <Values>{props.viewDetails.address2 || "-"}</Values>
                  </FlexContent>

                  <FlexContent>
                    <FlexInnerTitle>
                      <span>State</span> <span> : </span>
                    </FlexInnerTitle>
                    <Values>{props.viewDetails.address3 || "-"}</Values>
                  </FlexContent>

                  <FlexContent>
                    <FlexInnerTitle>
                      <span>Address 4</span> <span> : </span>
                    </FlexInnerTitle>
                    <Values>{props.viewDetails.address4 || "-"}</Values>
                  </FlexContent>

                  <FlexContent>
                    <FlexInnerTitle>
                      <span>Address 5</span> <span> : </span>
                    </FlexInnerTitle>
                    <Values>{props.viewDetails.address5 || "-"}</Values>
                  </FlexContent>

                  <FlexContent>
                    <FlexInnerTitle>
                      <span>Fax</span> <span> : </span>
                    </FlexInnerTitle>
                    <Values>{props.viewDetails.fax || "-"}</Values>
                  </FlexContent>

                  <FlexContent>
                    <FlexInnerTitle>
                      <span>Price Number</span> <span> : </span>
                    </FlexInnerTitle>
                    <Values>{props.viewDetails.sellPriceNumber || "-"}</Values>
                  </FlexContent>
                </Grid>
                {/* Left Side */}

                {/* Right Side */}
                <Grid item xs={12} md={6}>
                  <FlexContent>
                    <FlexInnerTitle>
                      <span>Credit Limit</span> <span> : </span>
                    </FlexInnerTitle>
                    <Values>
                      {"$" + props.viewDetails.creditLimit || "-"}
                    </Values>
                  </FlexContent>

                  <FlexContent>
                    <FlexInnerTitle>
                      <span>Stop Credit</span> <span> : </span>
                    </FlexInnerTitle>
                    <Values>
                      {props.viewDetails.creditLimit === "Y" ? (
                        <Check color="primary" />
                      ) : (
                        <Clear color="primary" />
                      )}{" "}
                    </Values>
                  </FlexContent>

                  <FlexContent>
                    <FlexInnerTitle>
                      <span>Credit Status</span> <span> : </span>
                    </FlexInnerTitle>
                    <Values>{props.viewDetails.creditStatus || "-"}</Values>
                  </FlexContent>

                  <FlexContent>
                    <FlexInnerTitle>
                      <span>Delivery Address 1</span> <span> : </span>
                    </FlexInnerTitle>
                    <Values>
                      {props.viewDetails.delivery_address_1 || "-"}
                    </Values>
                  </FlexContent>

                  <FlexContent>
                    <FlexInnerTitle>
                      <span>Delivery Address 2</span> <span> : </span>
                    </FlexInnerTitle>
                    <Values>
                      {props.viewDetails.delivery_address_2 || "-"}
                    </Values>
                  </FlexContent>

                  <FlexContent>
                    <FlexInnerTitle>
                      <span>Delivery Address 3</span> <span> : </span>
                    </FlexInnerTitle>
                    <Values>
                      {props.viewDetails.delivery_address_3 || "-"}
                    </Values>
                  </FlexContent>

                  <FlexContent>
                    <FlexInnerTitle>
                      <span>Delivery Address 4</span> <span> : </span>
                    </FlexInnerTitle>
                    <Values>
                      {props.viewDetails.delivery_address_4 || "-"}
                    </Values>
                  </FlexContent>

                  <FlexContent>
                    <FlexInnerTitle>
                      <span>Active</span> <span> : </span>
                    </FlexInnerTitle>
                    <Values>
                      {props.viewDetails.active === "Y" ? (
                        <Check color="primary" />
                      ) : (
                        <Clear color="primary" />
                      )}{" "}
                    </Values>
                  </FlexContent>

                  <FlexContent>
                    <FlexInnerTitle>
                      <span>Is Marketplace</span> <span> : </span>
                    </FlexInnerTitle>
                    <Values>
                      {props.viewDetails.isMarketPlace === 1 ? (
                        <Check color="primary" />
                      ) : (
                        <Clear color="primary" />
                      )}
                    </Values>
                  </FlexContent>

                  <FlexContent>
                    <FlexInnerTitle>
                      <span>Pending Process</span> <span> : </span>
                    </FlexInnerTitle>
                    <Values>
                      {props.viewDetails.pendingProcess === 1 ? (
                        <Check color="primary" />
                      ) : (
                        <Clear color="primary" />
                      )}
                    </Values>
                  </FlexContent>

                  <FlexContent>
                    <FlexInnerTitle>
                      <span>Last Updated Date</span> <span> : </span>
                    </FlexInnerTitle>
                    <Values>
                      {moment(props.viewDetails.lastUpdated).format(
                        "ddd, MMM Do YYYY, h:mm a"
                      ) || "-"}
                    </Values>
                  </FlexContent>
                </Grid>
                {/* Right Side */}

                <Grid item xs={12}>
                  <FlexContent>
                    <FlexInnerTitle>
                      <span>Customer Notes</span> <span> : </span>
                    </FlexInnerTitle>
                    <Values>{props.viewDetails.customerNotes || "-"}</Values>
                  </FlexContent>
                </Grid>
              </Grid>
            </Box>
          </TabPanel>

          {/* Customer Settings */}
          <TabPanel value={value} index={1} dir={theme.direction}>
            <Box pt={3}>
              <Grid container spacing={2}>
                {/* Left Side */}
                <Grid item xs={12} md={6}>
                  <FlexContent>
                    <FlexInnerTitle2>
                      <span>Multi-location dispatch per PO</span>{" "}
                      <span> : </span>
                    </FlexInnerTitle2>
                    <Values>
                      {props.viewDetails.multiLoc_desp_per_po == "1" ? (
                        <Check color="primary" />
                      ) : (
                        <Clear color="primary" />
                      )}
                    </Values>
                  </FlexContent>

                  <FlexContent>
                    <FlexInnerTitle2>
                      <span>Multi-location dispatch for order line</span>{" "}
                      <span> : </span>
                    </FlexInnerTitle2>
                    <Values>
                      {props.viewDetails.multiLoc_desp_per_line == "1" ? (
                        <Check color="primary" />
                      ) : (
                        <Clear color="primary" />
                      )}
                    </Values>
                  </FlexContent>

                  <FlexContent>
                    <FlexInnerTitle2>
                      <span>Supply all PO line</span> <span> : </span>
                    </FlexInnerTitle2>
                    <Values>
                      {props.viewDetails.supply_entire_po == "1" ? (
                        <Check color="primary" />
                      ) : (
                        <Clear color="primary" />
                      )}
                    </Values>
                  </FlexContent>
                </Grid>

                <Grid item xs={12} md={6}>
                  <FlexContent>
                    <FlexInnerTitle2>
                      <span>Pre-sale allowed</span> <span> : </span>
                    </FlexInnerTitle2>
                    <Values>
                      {props.viewDetails.allow_pre_sale == "1" ? (
                        <Check color="primary" />
                      ) : (
                        <Clear color="primary" />
                      )}
                    </Values>
                  </FlexContent>

                  <FlexContent>
                    <FlexInnerTitle2>
                      <span>SOH by dispatch location</span> <span> : </span>
                    </FlexInnerTitle2>
                    <Values>
                      {props.viewDetails.soh_by_desp_location == "1" ? (
                        <Check color="primary" />
                      ) : (
                        <Clear color="primary" />
                      )}
                    </Values>
                  </FlexContent>
                </Grid>
              </Grid>
            </Box>
          </TabPanel>
          {/* Customer Settings */}

          <TabPanel value={value} index={2} dir={theme.direction}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <h3>Active Price Policy</h3>
                  <BasicTable2
                    columns={priceColumns}
                    rows={props.viewDetails.pricePolicy}
                  />
                </Grid>
                
                <Grid item xs={12} md={6}>
                  <h3>Past Price Policy</h3>
                  <BasicTable2
                    columns={priceColumns}
                    rows={props.viewDetails.pastPricePolicy}
                  />
                </Grid>
            </Grid>
          </TabPanel>



        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ViewDetailsDialog;
