import React from "react";

import DataGridTablePage from "../../../Components/DataGridTablePage/DataGridTablePage";
import { dateFormatter } from "../../../utils/DataGrid/dateFormatter";

const fieldHeaderPairs = [
  { field: "STORE_CODE", headerName: "Store Name" },
  { field: "STOCK_CODE", headerName: "Stock Name" },
  { field: "SALES_OVERRIDE", headerName: "Sales Override" },
  { field: "CUSTOMER_ID", headerName: "Customer Name" },
  {
    field: "exoUpdatedDate",
    headerName: "Exo Updated Date",
    valueFormatter: (params) => dateFormatter(params),
  },
];

const filterColumn = [
  // {
  //   field: "STORE_CODE",
  //   label: "STORE CODE",
  //   component: "TextField",
  // },
  {
    field: "STOCK_CODE",
    label: "STOCK NAME",
    component: "TextField",
  },

  {
    field: "CUSTOMER_ID",
    label: "CUSTOMER NAME",
    component: "TextField",
  },

  // {
  //   field: "soldToa",
  //   // label: "Sold To",
  //   component: "AutocompleteField",
  //   url: "/current/company-account/all",
  // },
];

const ReplenishmentStockoverride = () => {
  return (
    <DataGridTablePage
      headerTitle="List Sales Override"
      exportFeature={true}
      columns={fieldHeaderPairs}
      filterColumn={filterColumn}
      // children={<Test />}
      pageUrl="/replenishment/sales-over-ride"
      columnsVisibility={
        {
          // HIST_ID: false,
        }
      }
    />
  );
};

export default ReplenishmentStockoverride;
