import { Check, Clear, Close, Visibility } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Skeleton,
  styled,
  useTheme,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import BasicTable from "../../../../Components/BasicTable";
import FsLightbox from "fslightbox-react";
import { httpclient } from "../../../../utils/httpClient";
import ViewPolicyDialog from "../../PricePolicy/ViewPolicyDialog";
import parse from "html-react-parser";

const StyledHeaderTitle = styled(DialogTitle)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: "#fff",
  position: "relative",
  "& button": {
    position: "absolute",
    right: "15px",
    top: "15px",
    color: "#fff",
  },
}));

const FlexContent = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  fontSize: "17px",
  marginBottom: "10px",
  alignItems: "flex-start",
}));

const FlexInnerTitle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  minWidth: "180px",
  maxWidth: "250px",
  fontWeight: "600",
}));

const FlexContent2 = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  fontSize: "17px",
}));

const FlexInnerTitle2 = styled("div")(({ theme }) => ({
  display: "flex",
  fontWeight: "600",
  gap: "5px",
  marginRight: "5px",
}));

const BoxDiv = styled("div")(({ theme }) => ({
  textAlign: "center",
}));

const Values = styled("div")(({ theme }) => ({
  marginLeft: "15px",
  fontWeight: "500",
  color: theme.palette.primary.dark,
}));

const ImageDiv = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
  flexWrap: "wrap",
  marginBottom: "10px",
}));

const ImageCell = styled("div")(({ theme }) => ({
  margin: "10px",
  width: "190px",
  borderRadius: "5px",
  overflow: "hidden",
  "& img": {
    width: "190px",
    height: "190px",
    objectFit: "cover",
    transition: "0.5s",
    boxShadow: theme.palette.primary.shadow,
    marginBottom: "10px",
    overflow: "hidden",
  },
  "& img:hover": {
    transform: "scale(1.1)",
  },
}));

const PricePolicyBox = styled(Box)(({ theme }) => ({
  display: "flex",
  marginBottom: "15px",
  "& h5": {
    margin: "5px",
  },
}));

const AppBarTabs = styled(AppBar)(({ theme }) => ({
  background: "#fff",
  color: theme.palette.primary.dark,
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const sohColumns = [
  { id: "erpBranchID", name: "Location ID" },
  { id: "erpBranchName", name: "Location Name" },
  { id: "comittedStock", name: "Comitted Stock" },
  { id: "totalInStock", name: "Total In Stock" },
  { id: "actualSOH", name: "Available Stock" },
];

const exoColumns = [
  { id: "LOCNO", name: "Location ID" },
  { id: "LNAME", name: "Location Name" },
  { id: "comittedStock", name: "Comitted Stock" },
  { id: "totalInStock", name: "Total In Stock" },
  { id: "actualSOH", name: "Available Stock" },
];

const soldColumns = [
  { id: "customerName", name: "Customer Name" },
  { id: "customerStockCode", name: "Customer Stock Code" },
  { id: "customerStockName", name: "Customer Stock Name" },
];

const ViewDetailsDialog = (props) => {
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [sohValue, setSohValue] = useState(0);

  const [togglerLanding, setTogglerLanding] = useState(false);
  const [togglerLifestyle, setTogglerLifestyle] = useState(false);
  const [togglerDims, setTogglerDims] = useState(false);
  const [togglerOther, setTogglerOther] = useState(false);

  const [openPolicyDialog, setOpenPolicyDialog] = useState(false);
  const [viewDetails, setViewDetails] = useState({});
  const [singleLoading, setSingleLoading] = useState(false);

  const [imgIndex1, setImgIndex1] = useState(0);
  const [imgIndex2, setImgIndex2] = useState(0);
  const [imgIndex3, setImgIndex3] = useState(0);
  const [imgIndex4, setImgIndex4] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeSoh = (event, newValue) => {
    setSohValue(newValue);
  };

  const [dialogDetails, setDialogDetails] = useState({
    open: true,
  });

  useEffect(() => {
    props.sendDetails(dialogDetails);
  }, [props, dialogDetails]);

  const handleClose = () => {
    setDialogDetails({
      ...dialogDetails,
      open: false,
    });
  };

  const handleImageTogglerLanding = (index) => {
    setImgIndex1(index);
    setTogglerLanding((prev) => !prev);
  };

  const handleImageTogglerLifestyle = (index) => {
    setImgIndex2(index);
    setTogglerLifestyle((prev) => !prev);
  };

  const handleImageTogglerDims = (index) => {
    setImgIndex3(index);
    setTogglerDims((prev) => !prev);
  };

  const handleImageTogglerOther = (index) => {
    setImgIndex4(index);
    setTogglerOther((prev) => !prev);
  };

  var priceList = [];
  if (!props.singleLoading) {
    for (let i = 1; i <= 16; i++) {
      let priceVal = "props.viewDetails.prices.sellPrice" + i;
      priceList.push(
        <Grid item xs={12} md={6}>
          <FlexContent>
            <FlexInnerTitle>
              <span>Selling Price {i}</span> <span> : </span>
            </FlexInnerTitle>
            <Values>
              {eval(priceVal) === 0 ? "-" : "$" + eval(priceVal).toFixed(2)}
            </Values>
          </FlexContent>
        </Grid>
      );
    }
  }

  var boxesList = [];

  if (!props.singleLoading) {
    for (let i = 1; i <= 7; i++) {
      let boxBarCode = "props.viewDetails.dimension.box" + i + "Barcode";
      let boxLength = "props.viewDetails.dimension.box" + i + "Length";
      let boxWidth = "props.viewDetails.dimension.box" + i + "Width";
      let boxHeight = "props.viewDetails.dimension.box" + i + "Height";
      let boxWeight = "props.viewDetails.dimension.box" + i + "Weight";

      eval(boxLength) !== 0 &&
        boxesList.push(
          <Grid
            item
            xs={12}
            md={4}
            display="flex"
            flexDirection={"column"}
            alignItems="center"
          >
            <BoxDiv>
              <img
                src="/box.png"
                alt="box"
                style={{ width: "120px", height: "120px", objectFit: "cover" }}
              />

              <FlexContent2>
                <FlexInnerTitle2>
                  <span>L x W x H</span> <span> : </span>
                </FlexInnerTitle2>
                <div>
                  {eval(boxLength) +
                    " x " +
                    eval(boxWidth) +
                    " x " +
                    eval(boxHeight)}
                </div>
              </FlexContent2>

              <FlexContent2>
                <FlexInnerTitle2>
                  <span>Barcode</span> <span> : </span>
                </FlexInnerTitle2>
                <div>{eval(boxBarCode)}</div>
              </FlexContent2>
              <FlexContent2>
                <FlexInnerTitle2>
                  <span>Weight</span> <span> : </span>
                </FlexInnerTitle2>
                <div>{eval(boxWeight)} kg</div>
              </FlexContent2>
            </BoxDiv>
          </Grid>
        );
    }
  }

  const handleOpenPolicy = (id) => {
    setSingleLoading(true);
    setOpenPolicyDialog(true);
    httpclient.get(`current/price-policy/${id}`).then(({ data }) => {
      if (data.success) {
        setViewDetails(data.data);
        setSingleLoading(false);
      }
    });
  };

  const sendDetails = (callback) => {
    if (callback.open === false) {
      setOpenPolicyDialog(false);
      setViewDetails({});
    }
  };

  // console.log("props", props);

  /***
   * Display text based on the short or long description props
   * If the description text is not filled and if the company id is 16 (myDeal.au company) display "Required" text
   * Else display "-"
   * Function is used in similar way in System and Current Stock. // make it reusable
   */
  const displayText = (descriptionTexts) => {
    const textIsEmpty = descriptionTexts === null || descriptionTexts === "";
    return !textIsEmpty ? (
      parse(descriptionTexts)
    ) : props.viewDetails.soldTO.some((stock) => stock.customerID === 16) ? (
      <Typography sx={{ color: "red", fontWeight: "bold" }}>
        Required
      </Typography>
    ) : (
      "-"
    );
  };

  return (
    <div>
      <Dialog
        open={dialogDetails.open}
        onClose={handleClose}
        maxWidth="xl"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <StyledHeaderTitle id="alert-dialog-title">
          <div>
            View Stock Details{" "}
            {"(" +
              (props.viewDetails.stockCode || "-") +
              "/" +
              (props.viewDetails.stockNameDefault || "-") +
              ")"}
          </div>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </StyledHeaderTitle>
        {props.singleLoading ? (
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box p={3} sx={{ width: "100%" }}>
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box p={3} sx={{ width: "100%" }}>
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
        ) : (
          <DialogContent sx={{ padding: "0" }}>
            <AppBarTabs position="static">
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="secondary"
                textColor="inherit"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab label="Details" {...a11yProps(0)} />
                <Tab label="Images" {...a11yProps(1)} />
                <Tab label="Prices" {...a11yProps(2)} />
                <Tab label="SOH By Location" {...a11yProps(3)} />
                <Tab label="Boxes" {...a11yProps(4)} />
                <Tab label="Outstanding Orders" {...a11yProps(5)} />
                <Tab label="Sold To" {...a11yProps(6)} />
              </Tabs>
            </AppBarTabs>

            <TabPanel value={value} index={0} dir={theme.direction}>
              <Box>
                <Grid container spacing={2}>
                  {/* Left Side */}
                  <Grid item xs={12} md={6}>
                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Stock ID</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>{props.viewDetails.stockID || "-"}</Values>
                    </FlexContent>

                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Stock Code</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>{props.viewDetails.stockCode || "-"}</Values>
                    </FlexContent>

                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Stock Name</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>
                        {props.viewDetails.stockNameDefault || "-"}
                      </Values>
                    </FlexContent>
                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Stock Brand Name</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>{props.viewDetails.stockBrandName || "-"}</Values>
                    </FlexContent>

                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Bar Code</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>{props.viewDetails.barcode || "-"}</Values>
                    </FlexContent>

                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Number of boxes</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>
                        <span
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                          }}
                          onClick={() => setValue(4)}
                        >
                          {props.viewDetails.numberOfBoxes}
                          <Visibility
                            color="primary"
                            fontSize="small"
                            style={{ marginLeft: "5px" }}
                          />
                        </span>
                      </Values>
                    </FlexContent>

                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Stock Group 1</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>
                        {props.viewDetails.stockGroupID1[0]
                          ? props.viewDetails.stockGroupID1[0].name
                          : "-"}
                      </Values>
                    </FlexContent>

                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Stock Group 2</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>
                        {props.viewDetails.stockGroupID2[0]
                          ? props.viewDetails.stockGroupID2[0].name
                          : "-"}
                      </Values>
                    </FlexContent>

                    {props.viewDetails.stockExclusiveToClient !== "N/A" ||
                    props.viewDetails.stockExclusiveToClient !== "" ? (
                      <>
                        <FlexContent>
                          <FlexInnerTitle>
                            <span>Exclusive Start Date</span> <span> : </span>
                          </FlexInnerTitle>
                          <Values>
                            {moment(
                              props.viewDetails.stockExclusiveStartDate
                            ).format("ddd, MMM Do YYYY, h:mm a") || "-"}
                          </Values>
                        </FlexContent>

                        <FlexContent>
                          <FlexInnerTitle>
                            <span>Exclusive End Date</span> <span> : </span>
                          </FlexInnerTitle>
                          <Values>
                            {moment(
                              props.viewDetails.stockExclusiveEndDate
                            ).format("ddd, MMM Do YYYY, h:mm a") || "-"}
                          </Values>
                        </FlexContent>

                        <FlexContent>
                          <FlexInnerTitle>
                            <span>Exclusive To</span> <span> : </span>
                          </FlexInnerTitle>
                          <Values>
                            {props.viewDetails.stockExclusiveToClient || "-"}
                          </Values>
                        </FlexContent>
                      </>
                    ) : null}
                  </Grid>

                  {/* Left Side */}

                  {/* Right Side */}
                  <Grid item xs={12} md={6}>
                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Average Cost</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>
                        {"$" + props.viewDetails.averageCost.toFixed(2) || "-"}
                      </Values>
                    </FlexContent>

                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Latest Cost</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>
                        {"$" + props.viewDetails.latestCost.toFixed(2) || "-"}
                      </Values>
                    </FlexContent>

                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Supplier Cost</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>
                        {"$" + props.viewDetails.supplierCost.toFixed(2) || "-"}
                      </Values>
                    </FlexContent>

                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Weight</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>{props.viewDetails.weight + "kg" || "-"}</Values>
                    </FlexContent>
                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Active</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>
                        {props.viewDetails.active === "Y" ? (
                          <Check color="primary" />
                        ) : (
                          <Clear color="primary" /> || "-"
                        )}
                      </Values>
                    </FlexContent>

                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Pending Process</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>
                        {props.viewDetails.pendingProcess === 1 ? (
                          <Check color="primary" />
                        ) : (
                          <Clear color="primary" /> || "-"
                        )}
                      </Values>
                    </FlexContent>

                    {/* <FlexContent>
                      <FlexInnerTitle>
                        <span>Exclusive Pending</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>
                        {props.viewDetails.exclusivePending === 1 ? (
                          <Check color="primary" />
                        ) : (
                          <Clear color="primary" /> || "-"
                        )}
                      </Values>
                    </FlexContent> */}

                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Stock Port Origin</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>
                        {props.viewDetails.stockPortOrigin || "-"}
                      </Values>
                    </FlexContent>

                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Supplier</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>
                        {props.viewDetails.supplier.supplierName || "-"}
                      </Values>
                    </FlexContent>

                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Stock Created Date</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>
                        {moment(props.viewDetails.stockCreatedDate).format(
                          "ddd, MMM Do YYYY, h:mm a"
                        ) || "-"}
                      </Values>
                    </FlexContent>

                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Stock Last Modified</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>
                        {moment(props.viewDetails.lastModified).format(
                          "ddd, MMM Do YYYY, h:mm a"
                        ) || "-"}
                      </Values>
                    </FlexContent>
                  </Grid>
                  {/* Right Side */}

                  <Grid item xs={12}>
                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Long Description</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>
                        {/* {props.viewDetails.shortDescription || "-"} */}
                        {displayText(props.viewDetails.shortDescription)}
                      </Values>
                    </FlexContent>
                  </Grid>

                  <Grid item xs={12}>
                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Sales Text</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>
                        {/* {props.viewDetails.longDescription || "-"} */}
                        {displayText(props.viewDetails.longDescription)}
                      </Values>
                    </FlexContent>
                  </Grid>
                </Grid>
              </Box>
            </TabPanel>

            <TabPanel value={value} index={1} dir={theme.direction}>
              <Box>
                {props.viewDetails.image.landingImages.length > 0 && (
                  <>
                    <h3>Landing Images</h3>
                    <ImageDiv>
                      {props.viewDetails.image.landingImages.map(
                        (imgs, index) => (
                          <ImageCell
                            onClick={() => handleImageTogglerLanding(index)}
                          >
                            <img src={imgs} alt="landing_image" />
                            <Box textAlign="center">
                              {imgs
                                .split("/")
                                .pop()
                                .replaceAll(".jpg", "")
                                .replaceAll(".png", "")}
                            </Box>
                          </ImageCell>
                        )
                      )}
                      <FsLightbox
                        toggler={togglerLanding}
                        sources={props.viewDetails.image.landingImages}
                        sourceIndex={imgIndex1}
                        type="image"
                        types={[
                          ...new Array(
                            props.viewDetails.image.landingImages.length
                          ).fill("image"),
                        ]}
                      />
                    </ImageDiv>
                  </>
                )}

                {props.viewDetails.image.lifestyleImages.length > 0 && (
                  <>
                    <h3>Lifestyle Images</h3>
                    <ImageDiv>
                      {props.viewDetails.image.lifestyleImages.map(
                        (imgs, index) => (
                          <ImageCell
                            onClick={() => handleImageTogglerLifestyle(index)}
                          >
                            <img src={imgs} alt="lifestyle_image" />
                            <Box textAlign="center">
                              {imgs
                                .split("/")
                                .pop()
                                .replaceAll(".jpg", "")
                                .replaceAll(".png", "")}
                            </Box>
                          </ImageCell>
                        )
                      )}
                      <FsLightbox
                        toggler={togglerLifestyle}
                        sources={props.viewDetails.image.lifestyleImages}
                        sourceIndex={imgIndex2}
                        type="image"
                        types={[
                          ...new Array(
                            props.viewDetails.image.lifestyleImages.length
                          ).fill("image"),
                        ]}
                      />
                    </ImageDiv>
                  </>
                )}

                {props.viewDetails.image.dimsImages.length > 0 && (
                  <>
                    <h3>Dims Images</h3>
                    <ImageDiv>
                      {props.viewDetails.image.dimsImages.map((imgs, index) => (
                        <ImageCell
                          onClick={() => handleImageTogglerDims(index)}
                        >
                          <img src={imgs} alt="dims_image" />
                          <Box textAlign="center">
                            {imgs
                              .split("/")
                              .pop()
                              .replaceAll(".jpg", "")
                              .replaceAll(".png", "")}
                          </Box>
                        </ImageCell>
                      ))}
                      <FsLightbox
                        toggler={togglerDims}
                        sources={props.viewDetails.image.dimsImages}
                        sourceIndex={imgIndex3}
                        type="image"
                        types={[
                          ...new Array(
                            props.viewDetails.image.dimsImages.length
                          ).fill("image"),
                        ]}
                      />
                    </ImageDiv>
                  </>
                )}

                {props.viewDetails.image.Otherimages.length > 0 && (
                  <>
                    <h3>Other Images</h3>
                    <ImageDiv>
                      {props.viewDetails.image.Otherimages.map(
                        (imgs, index) => (
                          <ImageCell
                            onClick={() => handleImageTogglerOther(index)}
                          >
                            <img src={imgs} alt="landing_image" />
                            <Box textAlign="center">
                              {imgs
                                .split("/")
                                .pop()
                                .replaceAll(".jpg", "")
                                .replaceAll(".png", "")}
                            </Box>
                          </ImageCell>
                        )
                      )}
                      <FsLightbox
                        toggler={togglerOther}
                        sources={props.viewDetails.image.Otherimages}
                        sourceIndex={imgIndex4}
                        type="image"
                        types={[
                          ...new Array(
                            props.viewDetails.image.Otherimages.length
                          ).fill("image"),
                        ]}
                      />
                    </ImageDiv>
                  </>
                )}

                {props.viewDetails.image.landingImages.length === 0 &&
                  props.viewDetails.image.lifestyleImages.length === 0 &&
                  props.viewDetails.image.dimsImages.length === 0 &&
                  props.viewDetails.image.Otherimages.length === 0 && (
                    <h4>Image not available</h4>
                  )}
              </Box>
            </TabPanel>

            <TabPanel value={value} index={2} dir={theme.direction}>
              <Box>
                <Grid container>
                  <Grid item xs={12} md={7}>
                    <Grid container spacing={2}>
                      {priceList}
                    </Grid>
                  </Grid>
                  {props.viewDetails.pricePolicy.length > 0 && (
                    <Grid item xs={12} md={5}>
                      <Box
                        pl={3}
                        style={{
                          borderLeft: "1px solid #999",
                          height: "100%",
                        }}
                      >
                        <h4
                          style={{
                            marginTop: "0",
                            marginBottom: "20px",
                            paddingBottom: "5px",
                            borderBottom: "2px solid #ccc",
                          }}
                        >
                          Active Price Policy
                        </h4>
                        {props.viewDetails.pricePolicy.length > 0
                          ? props.viewDetails.pricePolicy.map(
                              (policy, index) => (
                                <PricePolicyBox>
                                  <div
                                    style={{ position: "relative", top: "2px" }}
                                  >
                                    <small>
                                      {" "}
                                      {index + 1} {". "}
                                    </small>
                                  </div>
                                  <div>
                                    <h5>
                                      Policy Reference :{" "}
                                      <span
                                        style={{
                                          cursor: "pointer",
                                          color: "#3f51b5",
                                        }}
                                        onClick={() =>
                                          handleOpenPolicy(policy.erpPolicyID)
                                        }
                                      >
                                        {" "}
                                        {policy.policyReference +
                                          "[" +
                                          policy.erpPolicyID +
                                          "]" || "-"}{" "}
                                      </span>
                                    </h5>
                                    <h5>
                                      Price : {"$" + policy.price || "-"}{" "}
                                    </h5>
                                    <h5>
                                      Start Date :{" "}
                                      {moment(policy.startDate).format(
                                        "ddd, MMM Do YYYY, h:mm a"
                                      ) || "-"}
                                    </h5>
                                    <h5>
                                      End Date :{" "}
                                      {moment(policy.endDate).format(
                                        "ddd, MMM Do YYYY, h:mm a"
                                      ) || "-"}
                                    </h5>
                                    <h5>
                                      Active :{" "}
                                      {policy.policyActive === "Y" ? (
                                        <Check
                                          fontSize="small"
                                          color="primary"
                                        />
                                      ) : (
                                        (
                                          <Clear
                                            fontSize="small"
                                            color="primary"
                                          />
                                        ) || "-"
                                      )}
                                    </h5>
                                  </div>
                                </PricePolicyBox>
                              )
                            )
                          : ""}
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </TabPanel>

            <TabPanel value={value} index={3} dir={theme.direction}>
              <Tabs
                value={sohValue}
                onChange={handleChangeSoh}
                indicatorColor="secondary"
                textColor="inherit"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab label="SyncCare SOH" {...a11yProps(0)} />
                <Tab label="Exo SOH" {...a11yProps(1)} />
              </Tabs>

              <TabPanel value={sohValue} index={0} dir={theme.direction}>
                <BasicTable
                  columns={sohColumns}
                  rows={props.viewDetails.stockOnHand}
                  stocks={true}
                />
              </TabPanel>

              <TabPanel value={sohValue} index={1} dir={theme.direction}>
                <BasicTable
                  columns={exoColumns}
                  rows={props.viewDetails.exoStockOnHand}
                  stocks={true}
                />
              </TabPanel>
            </TabPanel>

            <TabPanel value={value} index={4} dir={theme.direction}>
              <Grid container>
                <Grid xs={12} md={9}>
                  <Box p={3}>
                    <Grid container spacing={2}>
                      {boxesList.length > 0 ? boxesList : <h4>No Box Found</h4>}
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={12} md={3}>
                  {props.viewDetails.cartonInnerQty ||
                  props.viewDetails.cartonQty ? (
                    <Box
                      p={3}
                      style={{ borderLeft: "1px solid #999", height: "100%" }}
                    >
                      <h4>
                        {" "}
                        Carton Inner Quantity :{" "}
                        {props.viewDetails.cartonInnerQty || ""}{" "}
                      </h4>
                      <h4>
                        Carton Quantity : {props.viewDetails.cartonQty || ""}{" "}
                      </h4>
                    </Box>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            </TabPanel>

            <TabPanel value={value} index={5} dir={theme.direction}>
              <Box p={3}>
                <h4> No Outstanding Orders Found. </h4>
              </Box>
            </TabPanel>

            <TabPanel value={value} index={6} dir={theme.direction}>
              {props.viewDetails.soldTO.length > 0 ? (
                <Box p={3}>
                  <Grid container spacing={2}>
                    <BasicTable
                      columns={soldColumns}
                      rows={props.viewDetails.soldTO}
                    />
                  </Grid>
                </Box>
              ) : (
                <Box p={3}>
                  <span>
                    <h4>No Sold To details found.</h4>
                  </span>
                </Box>
              )}
            </TabPanel>
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {openPolicyDialog && (
        <ViewPolicyDialog
          singleLoading={singleLoading}
          viewDetails={viewDetails}
          sendDetails={sendDetails}
        />
      )}
    </div>
  );
};

export default ViewDetailsDialog;
