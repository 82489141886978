import React from "react";
import { Box, Card, Container, CssBaseline, Typography } from "@mui/material";

const ModulesView = (props) => {
  return (
    <Container
      component="main"
      maxWidth="sm"
      style={{ textAlign: "center", marginTop: "150px" }}
    >
      <CssBaseline />
      <Card>
        <Box p={4}>
          <Typography variant="h6" component="h4" gutterBottom>
            {props.module === "Replenishment" ? "The module is on test for now" : "The module is offline"}
          </Typography>
        </Box>
      </Card>
    </Container>
  );
};

export default ModulesView;
