import { Close } from "@mui/icons-material";
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Snackbar,
    TextField,
    styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MuiAlert from "@mui/material/Alert";
import { httpclient } from "../../../../utils/httpClient";
 
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
 
const StyledHeaderTitle = styled(DialogTitle)(({ theme }) => ({
    background: theme.palette.primary.main,
    color: "#fff",
    position: "relative",
    "& button": {
        position: "absolute",
        right: "15px",
        top: "15px",
        color: "#fff",
    },
}));
 
 
const BrandEdit = (props) => {

    const [open1, setOpen1] = useState(false);
    const [message, setMessage] = useState("");
    const [messageState, setMessageState] = useState("");
    const [loading, setLoading] = useState(false);
    const [dialogDetails, setDialogDetails] = useState({
        open: true,
        success: false,
    });
 
    const [editDetails, setEditDetails] = useState({
        brandID: props.viewDetails.brandID,
        brandName: props.viewDetails.brandName,
 
    });
 
    const handleChange = (e) => {
        //console.log("changed", e )
        const { name, value } = e.target;
 
        setEditDetails({
            ...editDetails,
            [name]: value,
        });
    };
 
 
    useEffect(() => {
        props.sendDetails(dialogDetails);
    }, [props, dialogDetails]);
 
    const handleClose = () => {
        setDialogDetails({
            ...dialogDetails,
            open: false,
        });
    };
 
    const handleYes = () => {
        setLoading(true);
        httpclient
            .post(`new-system/brand/edit`, {
                brandID: editDetails.brandID,
                brandName: editDetails.brandName,
 
            })
            .then(({ data }) => {
                if (data.success) {
                    setOpen1(true);
                    setMessageState("success");
                    setMessage(data.message);
                    setLoading(false);
                    setTimeout(() => {
                        setDialogDetails({
                            ...dialogDetails,
                            open: false,
                            success: true,
                        });
                    }, 2500);
                } else {
                    setLoading(false);
                    setOpen1(true);
                    setMessageState("error");
                    setMessage(typeof (data.error) === "string" ? data.error : data.error[0]);
                }
            });
 
    };
    const handleCloseSnack = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen1(false);
    };
 
    return (
        <div>
            <Dialog
                open={dialogDetails.open}
                onClose={handleClose}
                maxWidth="sm"
                fullWidth
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <StyledHeaderTitle id="alert-dialog-title">
                    Edit Brand
                    <IconButton onClick={handleClose}>
                        <Close />
                    </IconButton>
                </StyledHeaderTitle>
                <DialogContent>
                    <Box pt={3}>
 
                        <Grid container spacing={2}>
 
                            <Grid item xs={12} md={12}>
                                <TextField
                                    variant="outlined"
                                    name="brandID"
                                    type="text"
                                    label={"Brand ID"}
                                    value={editDetails.brandID}
                                    onChange={(e) => handleChange(e)}
                                    sx={{ width: "100%" }}
                                    required
                                    disabled
                                />
                            </Grid>
 
 
                            <Grid item xs={12} md={12}>
                                <TextField
                                    variant="outlined"
                                    name="brandName"
                                    type="text"
                                    label="Brand Name"
                                    value={editDetails.brandName}
                                    onChange={(e) => handleChange(e)}
                                    sx={{ width: "100%" }}
                                    required
                                />
                            </Grid>
 
 
 
                        </Grid>
 
                    </Box>
                </DialogContent>
                <DialogActions sx={{ margin: "5px 10px", justifyContent: "right" }}>
                    {loading ? (
                        <CircularProgress sx={{ width: "10px", height: "10px" }} />
 
                    ) : (
                        <Button onClick={handleYes} color="primary" variant="contained" autoFocus>
                            Edit
                        </Button>
                    )
                    }
                </DialogActions>
 
            </Dialog>
            <Snackbar
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={open1}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleCloseSnack}
                    severity={messageState}
                    sx={{ width: "100%" }}
                >
                    {message}
                </Alert>
            </Snackbar>
 
        </div>
    );
};
 
export default BrandEdit;