import React from "react";

import DataGridTablePage from "../../../Components/DataGridTablePage/DataGridTablePage";
import { dateFormatter } from "../../../utils/DataGrid/dateFormatter";

const fieldHeaderPairs = [
  { field: "SYS_ID", headerName: "System ID" },
  { field: "REPORT_ID", headerName: "Report ID" },
  { field: "Q_ID", headerName: "Q ID" },
  { field: "T_ID", headerName: "T ID" },
  {
    field: "exoUpdatedDate",
    headerName: "Exo Updated Date",
    valueFormatter: (params) => dateFormatter(params),
  },
];

const filterColumn = [
  {
    field: "REPORT_ID",
    label: "REPORT ID",
    component: "TextField",
  },
  {
    field: "T_ID",
    label: "T_ID",
    component: "TextField",
  },
];

const ReplenishmentQuickref = () => {
  return (
    <DataGridTablePage
      headerTitle="List Quick Ref"
      exportFeature={true}
      columns={fieldHeaderPairs}
      filterColumn={filterColumn}
      // children={<Test />}
      pageUrl="/replenishment/quick-ref"
      columnsVisibility={
        {
          // HIST_ID: false,
        }
      }
    />
  );
};

export default ReplenishmentQuickref;
