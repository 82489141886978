import { useEffect, useState } from "react";
import { httpclient } from "../utils/httpClient";

const useGetCall = (url, renderCondition, dependencies = []) => {
  /**
   * Custom hook that handles API calls.
   * @param {string} url - The URL of the API endpoint.
   * @param {string} method - The HTTP method (GET, POST, PUT, etc.).
   * @param {Array} dependencies - An optional array of dependencies that triggers the API call when changed.
   * @param {Object} requestData - Data to be sent with the request (for POST, PUT, etc. requests).
   * @returns {Object} - An object containing the loading state, error message, data, and page information.
   */

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    setLoading(true);
    // FIGURE OUT WHY POST URL NOT WORKING
    // const config = {
    //   urlHeader,
    //   url,
    //   // Include data for POST, PUT, etc. requests
    // };
    if (!!renderCondition) {
      httpclient
        .get(url)
        .then(({ data }) => {
          if (data.success) {
            setLoading(false);
            setData(data.download_link);
            setMessage(data.message);
          }
        })
        .catch((err) => {
          setLoading(false);
          if (err.response && err.response.status === 401) {
            localStorage.clear();
            window.location = "/login";
          } else {
            setError("An error occurred while fetching data.");
          }
        });
    }
  }, dependencies);

  return { loading, error, data, message };
};

export default useGetCall;
