import {
  ArrowBack,
  Check,
  Clear,
  Close,
  Done,
  Edit,
  HighlightOff,
  KeyboardArrowLeft,
} from "@mui/icons-material";
import {

  AppBar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Skeleton,
  styled,
  useTheme,
  Snackbar,
  CircularProgress,
  Tooltip,
  TextField,
} from "@mui/material";
import moment from "moment";
import MuiAlert from "@mui/material/Alert";
import { httpclient } from "../../../../utils/httpClient";
import React, { useEffect, useState } from "react";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const StyledHeaderTitle = styled(DialogTitle)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: "#fff",
  position: "relative",
  "& button": {
    position: "absolute",
    right: "15px",
    top: "15px",
    color: "#fff",
  },
}));

const FlexContent = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  fontSize: "17px",
  marginBottom: "10px",
  alignItems: "flex-start",
}));

const FlexInnerTitle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  minWidth: "180px",
  maxWidth: "250px",
  fontWeight: "600",
}));

const Values = styled("div")(({ theme }) => ({
  marginLeft: "15px",
  fontWeight: "500",
  color: theme.palette.primary.dark,
}));

const ViewBranchDialog = (props) => {
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [dialogDetails, setDialogDetails] = useState({
    open: true,
    refetch: false
  });
  const [orderBranch, setOrderBranch] = useState({
    email: props.viewDetails.orderemailingAddress || '',
  });


  const [productDetails, setProductDetails] = useState("");

  const [showEmailEdit, setShowEmailEdit] = useState(false);

  const [emailLoading, setEmailLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [messageState, setMessageState] = useState("");

  const handleRefetch = () => {
    setDialogDetails({
      open: true,
      refetch: true,
      // orderID: props.viewDetails.orderID,
    });
    setTimeout(() => {
      setDialogDetails({
        open: true,
        refetch: false,
        // orderID: "",
      });
    }, 1000);
  };

  useEffect(() => {
    props.sendDetails(dialogDetails);
  }, [props, dialogDetails]);

  const handleClose = () => {
    setDialogDetails({
      ...dialogDetails,
      open: false,
    });
  };
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };



  // useEffect(() => {
  //   if (props.viewDetails.branchID) {
  //     //when props details is loaded the get shippit detail api is called
  //     changeEmail();  

  //   }
  // }, [props.viewDetails.branchID]);


  const changeEmail = () => {
    setEmailLoading(true);
    httpclient.post(`new-system/branch/update-email/${props.viewDetails.branchID}`, {

      email: orderBranch.orderemailingAddress

    }).then(({ data }) => {
      if (data.success) {
        setEmailLoading(false);
        setOpen(true);
        setMessageState("success");
        setMessage(data.message);

        //Refetching the data
        handleRefetch();

        // Order and Product Detail Edit Boxes set to false
        setValue(1);
        setProductDetails("");
        setShowEmailEdit(false);
      } else {
        setEmailLoading(false);
        setOpen(true);
        setMessageState("error");
        setMessage(data.error);
      }
    })
  };

  // console.log("props", props);

  return (
    <div>
      <Dialog
        open={dialogDetails.open}
        onClose={handleClose}
        maxWidth="xl"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <StyledHeaderTitle id="alert-dialog-title">
          <div>View Location Details</div>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </StyledHeaderTitle>
        <DialogContent>
          <Box pt={3}>
            <Grid container spacing={2}>
              {/* Left Side */}
              <Grid item xs={12} md={6}>
                {/* <FlexContent>
                  <FlexInnerTitle>
                    <span>Location ID</span> <span> : </span>
                  </FlexInnerTitle>
                  <Values>{(props.viewDetails.branchID) || "-"}</Values>
                </FlexContent> */}

                <FlexContent>
                  <FlexInnerTitle>
                    <span>NetSuite Location ID</span> <span> : </span>
                  </FlexInnerTitle>
                  <Values>{(props.viewDetails.branchCode || props.viewDetails.ciBranchCode) || "-"}</Values>
                </FlexContent>

                <FlexContent>
                  <FlexInnerTitle>
                    <span>Location Name</span> <span> : </span>
                  </FlexInnerTitle>
                  <Values>{(props.viewDetails.branchName || props.viewDetails.ciBranchName) || "-"}</Values>
                </FlexContent>

                {/* <FlexContent>
                  <FlexInnerTitle>
                    <span>Compare Field</span> <span> : </span>
                  </FlexInnerTitle>
                  <Values>{props.viewDetails.compareField || "-"}</Values>
                </FlexContent> */}

                <FlexContent>
                  <FlexInnerTitle>
                    <span>Active Location</span> <span> : </span>
                  </FlexInnerTitle>
                  <Values>
                    {props.viewDetails.branchActive === "Y" ? (
                      <Check color="primary" />
                    ) : (
                      <Clear color="primary" />
                    )}{" "}
                  </Values>
                </FlexContent>

                <FlexContent>
                  <FlexInnerTitle>
                    <span>Inbound Location</span> <span> : </span>
                  </FlexInnerTitle>
                  <Values>
                    {props.viewDetails.inboundBranch === "Y" ? (
                      <Check color="primary" />
                    ) : (
                      <Clear color="primary" />
                    )}{" "}
                  </Values>
                </FlexContent>
                <FlexContent>
                  <FlexInnerTitle>
                    <span>Dispatch Warehouse</span> <span> : </span>
                  </FlexInnerTitle>
                  <Values>
                    {props.viewDetails.branchSupplyLocation === 1 ? (
                      "Yes"
                    ) : (
                      "No"
                    )}{" "}
                  </Values>
                </FlexContent>
                <FlexContent>
                  <FlexInnerTitle>
                    <span>Pending Process</span> <span> : </span>
                  </FlexInnerTitle>
                  <Values>
                    {props.viewDetails.pendingProcess === 1 ? (
                      <Check color="primary" />
                    ) : (
                      <Clear color="primary" />
                    )}{" "}
                  </Values>
                </FlexContent>

                <FlexContent>
                  <FlexInnerTitle>
                    <span>Location Last Modified</span> <span> : </span>
                  </FlexInnerTitle>
                  <Values>
                    {moment(props.viewDetails.branchLastModifiedDate ||
                      props.viewDetails.ciLastModifiedDateTime).format(
                        "ddd, MMM Do YYYY, h:mm a"
                      ) || "-"}
                  </Values>
                </FlexContent>
              </Grid>
              {/* Left Side */}

              {/* Right Side */}
              <Grid item xs={12} md={6}>
              <FlexContent>
                  <FlexInnerTitle>
                    <span>Pickup Order Priority</span> <span> : </span>
                  </FlexInnerTitle>
                  <Values>{props.viewDetails.branchOrderPriority || "-"}</Values>
                </FlexContent>
                <FlexContent>
                  <FlexInnerTitle>
                    <span>Street Address</span> <span> : </span>
                  </FlexInnerTitle>
                  <Values>{(props.viewDetails.address1) || "-"}</Values>
                </FlexContent>
                <FlexContent>
                  <FlexInnerTitle>
                    <span>Suburb</span> <span> : </span>
                  </FlexInnerTitle>
                  <Values>{(props.viewDetails.address2) || "-"}</Values>
                </FlexContent>
                <FlexContent>
                  <FlexInnerTitle>
                    <span>State</span> <span> : </span>
                  </FlexInnerTitle>
                  <Values>{(props.viewDetails.address3) || "-"}</Values>
                </FlexContent>
                <FlexContent>
                  <FlexInnerTitle>
                    <span>Postcode</span> <span> : </span>
                  </FlexInnerTitle>
                  <Values>{(props.viewDetails.address4) || "-"}</Values>
                </FlexContent>
                <FlexContent>
                  <FlexInnerTitle>
                    <span>Phone</span> <span> : </span>
                  </FlexInnerTitle>
                  <Values>{(props.viewDetails.address5) || "-"}</Values>
                </FlexContent>
                <FlexContent>
                  <FlexInnerTitle>
                    <span>Address</span> <span> : </span>
                  </FlexInnerTitle>
                  <Values>{props.viewDetails.address6 || "-"}</Values>
                </FlexContent>

                <FlexContent>
                  <FlexInnerTitle>
                    <span>Location Notes</span> <span> : </span>
                  </FlexInnerTitle>
                  <Values>
                    {props.viewDetails.locationNotes}
                  </Values>
                </FlexContent>

                {props.viewDetails.address6 !== null &&
                
                  <FlexContent>
                    <FlexInnerTitle>
                      <span>Order Email Address</span> <span> : </span>
                    </FlexInnerTitle>
                    {!showEmailEdit ? (
                      <Box ml={2}>

                        <span>{orderBranch.orderemailingAddress || orderBranch.orderemailingAddress || props.viewDetails.orderemailingAddress} </span>

                        <IconButton
                          onClick={() => {
                            setShowEmailEdit(true);
                            setOrderBranch({
                              ...orderBranch,
                              orderemailingAddress: orderBranch.orderemailingAddress,
                            });
                          }}
                        >
                          <Edit fontSize="small" color="primary" />
                        </IconButton>
                      </Box>
                    ) : (
                      <Values>
                        <TextField
                          name="orderPrice"
                          variant="outlined"
                          value={orderBranch.orderemailingAddress}
                          onChange={(e) =>
                            setOrderBranch({
                              ...orderBranch,
                              orderemailingAddress: e.target.value,
                            })
                          }
                        />
                        {emailLoading ? (
                          <CircularProgress
                            style={{
                              height: "25px",
                              width: "25px",
                              marginLeft: "10px",
                              position: "relative",
                              top: "10px",
                            }}
                          />
                        ) : (
                          <>
                            <Tooltip title="Back">
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => setShowEmailEdit(false)}
                                sx={{ marginLeft: "10px" }}
                              >
                                <ArrowBack fontSize="small" />
                              </Button>
                            </Tooltip>
                            <Tooltip title="Change Email">
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => changeEmail(props.viewDetails.branchID)}
                                sx={{ marginLeft: "10px" }}
                              >
                                <Done fontSize="small" />
                              </Button>
                            </Tooltip>
                          </>
                        )}
                      </Values>
                    )}
                  </FlexContent>
                }
              </Grid>
              {/* Right Side */}
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={handleCloseSnack}
      >
        <Alert
          onClose={handleCloseSnack}
          severity={messageState}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ViewBranchDialog;
