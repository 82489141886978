import React, { useEffect, useState } from "react";
import TableComponent from "../TableComponent";
import {
  Box,
  Button,
  Card,
  Collapse,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  styled,
  TextField,
  Snackbar,
} from "@mui/material";
import { Add, ArrowForward, Close, FilterList } from "@mui/icons-material";
import { httpclient } from "../../../../utils/httpClient";
import { useNavigate } from "react-router-dom";
import MuiAlert from "@mui/material/Alert";
import DeactivateDialog from "../DeactivateDialog";
import ResetDialog from "../ResetDialog";
import DeleteDialog from "../DeleteDialog";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const login = localStorage.getItem("login");
const loginData = JSON.parse(login);

const columns = [
  { id: "userID", name: "ID" },
  { id: "firstName", name: "First Name" },
  { id: "lastName", name: "Last Name" },
  { id: "userName", name: "User Name" },
  { id: "UserLevel", name: "User Level" },
  { id: "userModules", name: "User Module" },
  { id: "userActive", name: "Active" },
  { id: "actions", name: "Actions"},
];

const adminColumns = [
  { id: "userID", name: "ID" },
  { id: "firstName", name: "First Name" },
  { id: "lastName", name: "Last Name" },
  { id: "userName", name: "User Name" },
  { id: "UserLevel", name: "User Level" },
  { id: "userActive", name: "Active" },
]

const superOptions = [
  { id: "edit", name: "Edit", action: "handleEdit" },
  { id: "deactivate", name: "Deactivate", action: "handleDeactivate" },
  { id: "reset", name: "Reset Password", action: "handleResetPassword" },
  { id: "delete", name: "Delete", action: "handleDelete" },
];

const adminOptions = [
  { id: "edit", name: "Edit", action: "handleEdit" },
  { id: "reset", name: "Reset Password", action: "handleResetPassword" },
]

const FilteredBox = styled(Box)(({ theme }) => ({
  background: "#f9f9f9",
  padding: "5px 10px",
  borderRadius: "5px",
  "& p": {
    margin: "0",
    marginRight: "10px",
    display: "inline-block",
    background: "#dedede",
    borderRadius: "10px",
    padding: "2px 5px",
  },
  "& svg": {
    fontSize: "15px",
    cursor: "pointer",
    position: "relative",
    top: "3px",
    background: theme.palette.primary.dark,
    color: "#fff",
    borderRadius: "50%",
    padding: "2px",
    marginLeft: "2px",
  },
}));

const Header = styled("div")(({ theme }) => ({
  "& h1": {
    color: theme.palette.primary.dark,
    margin: "0",
  },
}));

const AddButton = styled(Button)(({ theme }) => ({
  marginLeft: "10px",
  "& svg": {
    fontSize: "15px",
  },
}));

const configRowPerPage = JSON.parse(localStorage.getItem("configRowPerPage"));



const ListUsers = () => {
  const navigate = useNavigate();
  const [openResetDialog, setOpenResetDialog] = useState(false);
  const [viewDetails, setViewDetails] = useState({});
  const [openActiveDialog, setOpenActiveDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const [rows, setRows] = useState([]);

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [messageState, setMessageState] = useState("");

  const [loading, setLoading] = useState(false);
  const [direction, setDirection] = useState(false);
  const [currentColumn, setCurrentColumn] = useState("");
  const [page, setPage] = useState(1);
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(
    configRowPerPage && configRowPerPage
      ? configRowPerPage && configRowPerPage
      : 20
  );

  const [rowsPerPage, setRowsPerPage] = useState(
    configRowPerPage && configRowPerPage
      ? configRowPerPage && configRowPerPage
      : 20
  );
  const [total, setTotal] = useState("");
  const [filterOpen, setFilterOpen] = useState(false);

  const [filterData, setFilterData] = useState({
    firstName: "",
    lastName: "",
    userName: "",
    userActive: "",
    UserLevel: "",
    remove: false,
  });

  const [submittedData, setSubmittedData] = useState({
    firstName: "",
    lastName: "",
    userName: "",
    userActive: "",
    UserLevel: "",
    submit: false,
  });

  // useEffect(() => {
  //   getAllUsers();
  // }, []);

  useEffect(() => {
    if (
      filterData.firstName === "" &&
      filterData.lastName === "" &&
      filterData.userName === "" &&
      filterData.userActive === "" &&
      filterData.UserLevel === ""
    ) {
      setSubmittedData({
        ...submittedData,
        submit: false,
      });
    }
    if (filterData.firstName === " ") filterData.firstName = "";
    if (filterData.lastName === " ") filterData.lastName = "";
    if (filterData.userName === " ") filterData.userName = "";
    if (filterData.userActive === " ") filterData.userActive = "";
    if (filterData.UserLevel === " ") filterData.UserLevel = "";

    filterData.remove === true && handleFilter();
  }, [filterData]);

  useEffect(() => {
    let userStorage = JSON.parse(localStorage.getItem("user_filter"));
    userStorage !== null && setFilterData(userStorage);

    userStorage == null
      ? getAllUsers()
      : userStorage.firstName == "" &&
        userStorage.lastName == "" &&
        userStorage.userName == "" &&
        userStorage.userActive == "" &&
        userStorage.UserLevel == "" &&
        userStorage.removed == false
      ? getAllUsers()
      : console.log("users!");
  }, []);

  const getAllUsers = () => {
    setLoading(true);
    httpclient.get(`user?pagination=${rowsPerPage}`).then(({ data }) => {
      if (data.success) {
        setRows(data.data);
        setTotal(data.meta.total);
        setRowsPerPage(parseInt(data.meta.per_page));
        setPage(data.meta.current_page);
        setFrom(data.meta.from);
        setTo(data.meta.to);
        setLoading(false);
      }
    });
  };

  const hadleFilterOpen = () => {
    setFilterOpen((prev) => !prev);
  };

  const handleChangeFilter = (e) => {
    const { name, value } = e.target;
    setFilterData({
      ...filterData,
      [name]: value,
      remove: false,
    });
  };

  const handleFilter = () => {
    setSubmittedData({
      ...submittedData,
      firstName: filterData.firstName,
      lastName: filterData.lastName,
      userName: filterData.userName,
      UserLevel: filterData.UserLevel,
      userActive: filterData.userActive,
      submit: true,
    });
    filterData.remove = true;
    localStorage.setItem("user_filter", JSON.stringify(filterData));
    setLoading(true);
    if (
      filterData.firstName ||
      filterData.lastName ||
      filterData.userName ||
      filterData.UserLevel ||
      filterData.userActive
    ) {
      httpclient
        .get(
          `user?firstName=${filterData.firstName}&lastName=${
            filterData.lastName
          }&userName=${filterData.userName}&UserLevel=${
            filterData.UserLevel
          }&userActive=${
            filterData.userActive
          }&pagination=${rowsPerPage}&page=${1}`
        )
        .then(({ data }) => {
          if (data.success) {
            setRows(data.data);
            setTotal(data.meta.total);
            setRowsPerPage(data.meta.per_page);
            setPage(data.meta.current_page);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        });
    } else {
      getAllUsers();
    }
  };

  const handleRemove = (data) => {
    setFilterData({
      ...filterData,
      [data]: "",
      remove: true,
    });

    setSubmittedData({
      ...submittedData,
      [data]: "",
    });
  };

  const handleSort = (column) => {
    setDirection((prevDirection) => !prevDirection);
    setCurrentColumn(column);
    submittedData.submit
      ? httpclient
          .get(
            `user?firstName=${filterData.firstName}&lastName=${
              filterData.lastName
            }&userName=${filterData.userName}&UserLevel=${
              filterData.UserLevel
            }&userActive=${filterData.userActive}&sort_by=${column}&direction=${
              !direction ? "asc" : "desc"
            }&pagination=${rowsPerPage}&page=${page}`
          )
          .then(({ data }) => {
            if (data.success === true) {
              setRows(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setPage(data.meta.current_page);
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          })
      : httpclient
          .get(
            `user?sort_by=${column}&direction=${
              !direction ? "asc" : "desc"
            }&pagination=${rowsPerPage}`
          )
          .then(({ data }) => {
            if (data.success === true) {
              setRows(data.data);
            }
          });
  };

  const handleChangePage = (e, page) => {
    setLoading(true);
    submittedData.submit
      ? httpclient
          .get(
            `user?firstName=${filterData.firstName}&lastName=${
              filterData.lastName
            }&userName=${filterData.userName}&UserLevel=${
              filterData.UserLevel
            }&userActive=${
              filterData.userActive
            }&sort_by=${currentColumn}&direction=${
              direction ? "asc" : "desc"
            }&pagination=${rowsPerPage}&page=${page}`
          )
          .then(({ data }) => {
            if (data.success === true) {
              setRows(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setPage(data.meta.current_page);
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          })
      : httpclient
          .get(
            `user?direction=${
              direction ? "asc" : "desc"
            }&pagination=${rowsPerPage}&page=${page}`
          )
          .then(({ data }) => {
            if (data.success === true) {
              setRows(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setPage(data.meta.current_page);
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    // setLoading(true);

    localStorage.setItem("configRowPerPage", event.target.value);

    submittedData.submit
      ? httpclient
          .get(
            `user?firstName=${filterData.firstName}&lastName=${
              filterData.lastName
            }&userName=${filterData.userName}&UserLevel=${
              filterData.UserLevel
            }&userActive=${
              filterData.userActive
            }&sort_by=${currentColumn}&direction=${
              direction ? "asc" : "desc"
            }&pagination=${+event.target.value}&page=${page}`
          )
          .then(({ data }) => {
            setLoading(true);
            if (data.success === true) {
              setRows(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setPage(data.meta.current_page);
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          })
      : httpclient
          .get(
            `user?direction=${direction ? "asc" : "desc"}&pagination=${+event
              .target.value}&page=${1}`
          )
          .then(({ data }) => {
            setLoading(true);
            if (data.success === true) {
              setRows(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setPage(data.meta.current_page);
              setLoading(false);
            }
          });
  };


  const hadleAddNew = () => {
    navigate(`/users/add_user/`);
  };

  const handleEdit = (row) => {
    navigate(`/users/add_user/${row.userID}`);
  };

  const handleDeactivate = (row) => {
    setOpenActiveDialog(true);
    setViewDetails(row);
  };

  const sendDeactivate = (call) => {
    if (call.open === false) {
      setOpenActiveDialog(false);
      setViewDetails({});
    }
    if (call.success === true) {
      httpclient
        .get(`user/activate-deactivate/${viewDetails.userID}`)
        .then(({ data }) => {
          if (data.success) {
            setOpen(true);
            setMessageState("success");
            setMessage(data.message);
            setOpenActiveDialog(false);
            setViewDetails({});
            getAllUsers();
          } else {
            setOpen(true);
            setMessageState("error");
            setMessage(data.message);
          }
        });
    }
  };

  const handleResetPassword = (row) => {
    setOpenResetDialog(true);
    setViewDetails(row);
  };

  const sendReset = (call) => {
    if (call.open === false) {
      setOpenResetDialog(false);
      setViewDetails({});
    }
    if(call.success === true){
      httpclient
        .post(`user/reset-password/${viewDetails.userID}`,{
          password: call.password,
          password_confirmation: call.confirmPassword
        })
        .then(({ data }) => {
          if (data.success) {
            setOpen(true);
            setMessageState("success");
            setMessage(data.message);
            setOpenActiveDialog(false);
            setViewDetails({});
            getAllUsers();
          } else {
            setOpen(true);
            setMessageState("error");
            setMessage(data.message);
          }
        });
    }
  };

  const handleDelete = (row) => {
    setOpenDeleteDialog(true);
    setViewDetails(row)
  };

  const sendToDelete = (call) => {
    if(call.open === false){
      setOpenDeleteDialog(false)
      setViewDetails({})
    }
    if(call.success === true){
      httpclient
        .delete(`user/${viewDetails.userID}`)
        .then(({ data }) => {
          if (data.success) {
            setOpen(true);
            setMessageState("success");
            setMessage(data.message);
            setOpenDeleteDialog(false);
            setViewDetails({});
            getAllUsers();
          } else {
            setOpen(true);
            setMessageState("error");
            setMessage(data.message);
          }
        });
    }
  }

  const currentChange = (value, row) => {
    //console.log("value", value);
    if (value === "Edit") {
      handleEdit(row);
    }
    if (value === "Deactivate") {
      handleDeactivate(row);
    }
    if (value === "Reset Password") {
      handleResetPassword(row);
    }
    if (value === "Delete") {
      handleDelete(row);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item md={8} xs={12}>
          <Header>
            <h1>List Users</h1>
          </Header>
        </Grid>
        <Grid
          item
          md={4}
          xs={12}
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Button color="primary" variant="contained" onClick={hadleFilterOpen}>
            Filter <FilterList style={{ marginLeft: "5px" }} fontSize="small" />
          </Button>
          <AddButton
            color="secondary"
            variant="contained"
            onClick={hadleAddNew}
          >
            <Add style={{ marginRight: "5px" }} fontSize="small" /> Add User
          </AddButton>
        </Grid>

        {/* Filter */}
        <Grid item xs={12}>
          <Collapse in={filterOpen}>
            <Card>
              <Box p={4}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <InputLabel>First Name</InputLabel>
                    <TextField
                      variant="outlined"
                      name="firstName"
                      value={filterData.firstName}
                      onChange={handleChangeFilter}
                      onKeyDown={e => {if(e.key === "Enter") handleFilter()}}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <InputLabel>Last Name</InputLabel>
                    <TextField
                      variant="outlined"
                      name="lastName"
                      value={filterData.lastName}
                      onChange={handleChangeFilter}
                      onKeyDown={e => {if(e.key === "Enter") handleFilter()}}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <InputLabel>User Name</InputLabel>
                    <TextField
                      variant="outlined"
                      name="userName"
                      value={filterData.userName}
                      onChange={handleChangeFilter}
                      onKeyDown={e => {if(e.key === "Enter") handleFilter()}}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <InputLabel>User Level</InputLabel>
                    <FormControl fullWidth>
                      <Select
                        value={filterData.UserLevel}
                        name="UserLevel"
                        onChange={handleChangeFilter}
                      >
                        <MenuItem value={""}>Select Level</MenuItem>
                        <MenuItem value={"1"}>Admin</MenuItem>
                        <MenuItem value={"2"}>Staff</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <InputLabel>Active</InputLabel>
                    <FormControl fullWidth>
                      <Select
                        value={filterData.userActive}
                        name="userActive"
                        // label="userActive"
                        onChange={handleChangeFilter}
                      >
                        <MenuItem value={""}>Select</MenuItem>
                        <MenuItem value={"1"}>Yes</MenuItem>
                        <MenuItem value={"0"}>No</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <Box textAlign={"right"}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleFilter}
                      >
                        Filter{" "}
                        <ArrowForward
                          fontSize="small"
                          style={{ marginLeft: "5px" }}
                        />
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </Collapse>
        </Grid>

        {submittedData.firstName ||
        submittedData.lastName ||
        submittedData.userName ||
        submittedData.UserLevel ||
        submittedData.userActive ? (
          <Grid item xs={12}>
            <FilteredBox>
              <span>Filtered: </span>
              {submittedData.firstName && (
                <p>
                  <span>First Name: {submittedData.firstName}</span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("firstName")}
                  />
                </p>
              )}
              {submittedData.lastName && (
                <p>
                  <span>Last Name: {submittedData.lastName}</span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("lastName")}
                  />
                </p>
              )}
              {submittedData.userName && (
                <p>
                  <span>User Name: {submittedData.userName}</span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("userName")}
                  />
                </p>
              )}

              {submittedData.UserLevel && (
                <p>
                  <span>
                    User Level:{" "}
                    {submittedData.UserLevel === "1" ? "Admin" : "Staff"}
                  </span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("UserLevel")}
                  />
                </p>
              )}
              {submittedData.userActive && (
                <p>
                  <span>
                    Active: {submittedData.userActive === "1" ? "Yes" : "No"}
                  </span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("userActive")}
                  />
                </p>
              )}
            </FilteredBox>
          </Grid>
        ) : (
          <Box></Box>
        )}
        {/* Filter */}

        <Grid item xs={12}>
          <TableComponent
            columns={loginData && loginData.UserLevel === 1 ? columns : adminColumns}
            rows={rows}
            sort={true}
            handleSort={handleSort}
            // handleView={handleView}
            // options={loginData.UserLevel === 1 ? superOptions : adminOptions}
            options={superOptions}
            currentChange={currentChange}
            // handleEdit={handleEdit}
            // handleDelete={handleDelete}
            loading={loading}
            direction={direction}
            currentColumn={currentColumn}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleChangePage={handleChangePage}
            page={page}
            total={total && total}
            fromTable={from}
            toTable={to}
            rowsPerPage={rowsPerPage}
          />
        </Grid>
      </Grid>

      {openDeleteDialog && <DeleteDialog sendToDelete={sendToDelete} />}

      {openActiveDialog && (
        <DeactivateDialog
          viewDetails={viewDetails}
          sendDeactivate={sendDeactivate}
        />
      )}
      {openResetDialog && (
        <ResetDialog viewDetails={viewDetails} sendReset={sendReset} />
      )}

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={messageState}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ListUsers;
