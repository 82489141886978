import logo from "./logo.svg";
import "./App.css";
import { ThemeProvider } from "@mui/material";
import theme from "./theme/theme";
import {
  RouterProvider,
  createHashRouter,
  createBrowserRouter,
} from "react-router-dom";
import ProtectedRoute from "./Routing/ProtectedRoute";
import { protectedRotues, publicRoutes } from "./Routing/Routes";


function App() {
  // if (window.location.pathname === "/login") {
  //   window.location = "/#/login";
  // }

  // const router = createHashRouter([
  //   {
  //     link: "/",
  //     element: (
  //       <>
  //         <Outlet />
  //       </>
  //     ),
  //     children: [
  //       ...publicRoutes,
  //       { element: <ProtectedRoute />, children: protectedRotues },
  //     ],
  //     errorElement: <Login />,
  //   },
  // ]);

  const router = createHashRouter([
    ...publicRoutes,
    {
      link: "/",
      element: <ProtectedRoute />,
      children: [...protectedRotues],
    },
  ]);

  return (
    <ThemeProvider theme={theme}>
      {/* <EditDialog onPlaceSelect={handlePlaceSelect} /> */}
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}

export default App;
