import {
  Box,
  Button,
  CircularProgress,
  Grid,
  InputLabel,
  Snackbar,
  styled,
  TextField,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import MuiAlert from "@mui/material/Alert";
import theme from "../../../theme/theme";
import { httpclient } from "../../../utils/httpClient";
import qs from "qs";
import Global from "../../../utils/GlobalValues";
//import GlobalURL from "../../../utils/global";
import { fontSize } from "@mui/system";
import ReCAPTCHA from "react-google-recaptcha";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SubmitDiv = styled("div")(({ theme }) => ({
  "& .MuiButton-root": {
    color: "#fff",
    marginTop: "10px",
    padding: "10px",
    fontSize: "15px",
  },
}));

const Form = () => {
  const isLive = window.location.hostname === "uba.synccare.com.au";
  const [captcha, setCaptcha] = useState(null);
  const recaptchaRef = useRef(null);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [snackStatus, setSnackStatus] = useState("");
  const [snackMessage, setSnackMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [loginData, setLoginData] = useState({
    // email: "admin@gmail.com",
    // password: "admin123",
    email: "",
    password: "",
    GRecaptchaResponse: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginData({
      ...loginData,
      [name]: value,
    });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleKeyPassword = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    setLoading(true);
    let formData = new FormData();
    formData.append("userName", loginData.email);
    formData.append("password", loginData.password);
    formData.append("GRecaptchaResponse", loginData.GRecaptchaResponse);

    httpclient.post("/login", formData).then(({ data }) => {
      if (data.success === true) {
        localStorage.setItem("login", JSON.stringify(data.data.user));
        localStorage.setItem("token", JSON.stringify(data.data.access_token));
        setOpen(true);
        setSnackStatus("success");
        setSnackMessage("Logged in successfully!");
        setLoading(false);
        setTimeout(() => {
          window.location = "/";
        }, 1000);
      } else {
        setLoading(false);
        setOpen(true);
        setSnackStatus("error");
        setSnackMessage(
          !data.error ? "Email or password does not match" : data.error
        );
      }
    });
  };

  function onChange(value) {
    setLoginData((prevState) => ({
      ...prevState,
      GRecaptchaResponse: value,
    }));
  }

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} textAlign="center">
          {/* <h2
              style={{
                margin: "0",
                paddingBottom: "20px",
                fontSize: "35px",
              }}
            >
              Login
            </h2> */}
          <img
            src="/uba_logo.png"
            alt="Sync_Care"
            style={{ width: "100%", objectFit: "cover" }}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <InputLabel>Email</InputLabel>
          <TextField
            variant="outlined"
            // label="Email"
            name="email"
            type="text"
            value={loginData.email}
            onChange={(e) => handleChange(e)}
            style={{
              width: "100%",
              borderRadius: "5px",
              background: theme.palette.primary.lighter,
            }}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <InputLabel>Password</InputLabel>
          <TextField
            variant="outlined"
            // label="Password"
            name="password"
            type="password"
            value={loginData.password}
            onKeyDown={handleKeyPassword}
            onChange={(e) => handleChange(e)}
            style={{
              width: "100%",
              borderRadius: "5px",
              background: theme.palette.primary.lighter,
            }}
          />
        </Grid>
        {isLive && (
          <Grid item xs={12} md={12}>
            <ReCAPTCHA
              //ref={recaptchaRef}
              name="GRecaptchaResponse"
              sitekey="6LeL_hEmAAAAAPSPJGO760B3wGLKY4FgMjgthxN0"
              onChange={onChange}
            />
          </Grid>
        )}
        {/* <Grid item xs={12}>
            <span >Forgot Password?</span>
          </Grid> */}
        <Grid item xs={12} md={12}>
          <SubmitDiv>
            {loading ? (
              <Button
                variant="contained"
                color="secondary"
                // disabled
                fullWidth
              >
                <CircularProgress
                  style={{
                    height: "20px",
                    width: "20px",
                    color: "#fff",
                    marginRight: "10px",
                  }}
                />{" "}
                Loading
              </Button>
            ) : (
              <Button
                variant="contained"
                color="secondary"
                onClick={handleSubmit}
                fullWidth
              >
                Sign In
              </Button>
            )}
          </SubmitDiv>
        </Grid>

        <Box textAlign={"right"} width="100%" mt={3}>
          <small>ver: {Global.version}</small>
        </Box>
      </Grid>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity={snackStatus}
          sx={{ width: "100%" }}
        >
          {snackMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Form;
