import React, {useState} from "react";
import { dateFormatter } from "../../../../utils/DataGrid/dateFormatter";
import { extractCSVname } from "../../utility/extractCSVname.js";
import { Button, Grid, Snackbar } from "@mui/material";
import { Download } from "@mui/icons-material";
import TWDataGridTablePage from "../Temple&WebsterTable/TWDataGridTablePage.js";
import { httpclient } from "../../../../utils/httpClient.js";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const NetSuiteTempleAndWebsterSOH = () => {

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [messageState, setMessageState] = useState("");
  const [loading, setLoading] = useState(false);
  const [refetchSOH, setRefetchSOH] = useState(false);

  const fieldHeaderPairs = [
    {
      field: "createdAt",
      headerName: "CSV Generated Date",
      width: 160,
      valueFormatter: (params) => dateFormatter(params),
    },
    { field: "ID" },
    {
      field: "newingtonSummitFileName",
      headerName: "Newington (Summit) File Name",
      width: 245,
      renderCell: (cellValues) => (
        <>
          <Button href={cellValues.row.newingtonSummitFileLink}>
            <Download /> {extractCSVname(cellValues.value)}
          </Button>
        </>
      ),
    },
    {
      field: "egertonSilverwaterFileName",
      headerName: "Egerton (Sliverwater) File Name",
      width: 245,
      renderCell: (cellValues) => (
        <>
          <Button href={cellValues.row.egertonSilverwaterFileLink}>
            <Download /> {extractCSVname(cellValues.value)}
          </Button>
        </>
      ),
    },
    {
      field: "erskineParkAcrFileName",
      headerName: "Eastern Creek (ACR) File Name",
      width: 245,
  
      renderCell: (cellValues) => (
        <>
          <Button href={cellValues.row.erskineParkAcrFileLink}>
            <Download /> {extractCSVname(cellValues.value)}
          </Button>
        </>
      ),
    },
    {
      field: "yennoraSkuLogisticFileName",
      headerName: "Yennora (SKU Logistic) File Name",
      width: 245,
  
      renderCell: (cellValues) => (
        <>
          <Button href={cellValues.row.yennoraSkuLogisticFileLink}>
            <Download /> {extractCSVname(cellValues.value)}
          </Button>
        </>
      ),
    },
  
    {
      field: "newingtonSummitFileLink",
    },
    {
      field: "egertonSilverwaterFileLink",
    },
    {
      field: "erskineParkAcrFileLink",
    },
    {
      field: "yennoraSkuLogisticFileLink",
    },
    {
      // field: "soh_history_action",
      headerName: "SOH ACTION",
      type: "actions",
      width: 170,
  
      renderCell: (cellValues) => (
  
        <>
          <Grid
            width={"100%"}
            sx={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Button
              variant="contained"
              sx={{
                margin: 0,
                marginLeft: "16px",
                // padding: 0,
              }}
              disabled={cellValues.row?.isSohUploaded === 1}
              onClick={() => PUSHTOSOH(cellValues.row?.ID)}
            >
              PUSH TO SOH
            </Button>
          </Grid>
        </>
      ),
    },
  ];
  
  const filterColumn = [
    //   {
    // field: "STORE_CODE",
    // label: "Branch",
    // component: "TextField",
    // component: "AutocompleteField",
    // url: "/replenishment/pipeline",
    // overrideDataField: "branchList",
    //   },
  
    {
      field: "startDate",
      label: "From",
      component: "TextField",
      type: "date",
    },
    {
      field: "endDate",
      label: "To",
      component: "TextField",
      type: "date",
    },
    // {
    //   field: "soldToa",
    //   // label: "Sold To",
    //   component: "AutocompleteField",
    //   url: "/current/company-account/all",
    // },
  ];

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  
  
  const PUSHTOSOH = (id) => {
   setLoading(true);
    httpclient
      .post(`temple-webster/netsuite-soh-push-to-tw-portal`, {
        ID: id
      })
      .then(({ data }) => {
        if (data.success) {
          setLoading(false);
          setOpen(true)
          setMessageState("success")
          setMessage(data.message)
          setRefetchSOH(true);
          
  
        } else {
          setLoading(false);
          setOpen(true)
          setMessageState("error")
          setMessage(data.message)
        }
      });
  
      
  };

  


  return (
    <>
    <TWDataGridTablePage
      headerTitle="List Temple and Webster SOH"
      exportFeature={true}
      exportFileLink="/temple-webster/netsuit-soh-re-generate"
      customIDKey="ID"
      alert={true}
      refetchSOH={refetchSOH}
      setRefetchSOH={setRefetchSOH}
      netsuite={true}
      columns={fieldHeaderPairs}
      filterColumn={filterColumn}
      pageUrl="/temple-webster/netsuit-soh-export-report"
      columnsVisibility={{
        ID: false,
        newingtonSummitFileLink: false,
        egertonSilverwaterFileLink: false,
        erskineParkAcrFileLink: false,
        yennoraSkuLogisticFileLink: false,
      }}
    />
    <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={handleCloseSnack}
      >
        <Alert
          onClose={handleCloseSnack}
          severity={messageState}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
      </>
  );
};

export default NetSuiteTempleAndWebsterSOH;
