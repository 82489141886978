import React, { useEffect, useState } from "react";
import { ArrowForward, Check, Clear, Close, FilterList } from "@mui/icons-material";
import TableComponent from "../TableComponent";
import { httpclient } from "../../../../utils/httpClient";
import {
  Box,
  Button,
  Card,
  Collapse,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  styled,
  TextField,
  Snackbar
} from "@mui/material";
import ViewOrderDialog from "../ViewOrderDialog";
import MuiAlert from "@mui/material/Alert";
import StatusDialog from "../StatusDialog";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const columns = [
  { id: "checkColumn", name: " " },
  { id: "newSystemOrderID", name: "DSCO Order Id" },
  { id: "newSystemOrderNumber", name: "PO Number" },
  { id: "consumerOrderNumber", name: "Consumer Order Number" },
  { id: "billToEmail", name: "Email Address" },
  { id: "total_items", name: "Total Line" },
  { id: "orderTotalAmount", name: "Order Total" },
  { id: "order_created", name: "Date" },
  // { id: "shippitTrackingCode", name: "Tracking" },
  { id: "reportType", name: "Ready to process? " },
  //{ id: "actions", name: "Order Confirmation" },
];

const FilteredBox = styled(Box)(({ theme }) => ({
  background: "#f9f9f9",
  padding: "5px 10px",
  borderRadius: "5px",
  "& p": {
    margin: "0",
    marginRight: "10px",
    display: "inline-block",
    background: "#dedede",
    borderRadius: "10px",
    padding: "2px 5px",
  },
  "& svg": {
    fontSize: "15px",
    cursor: "pointer",
    position: "relative",
    top: "3px",
    background: theme.palette.primary.dark,
    color: "#fff",
    borderRadius: "50%",
    padding: "2px",
    marginLeft: "2px",
  },
}));

const Header = styled("div")(({ theme }) => ({
  "& h1": {
    color: theme.palette.primary.dark,
    margin: "0",
  },
}));

const configRowPerPage = JSON.parse(localStorage.getItem("configRowPerPage"));

const AcceptedOrders = () => {
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [viewDetails, setViewDetails] = useState({});
  const [openStatusDialog, setOpenStatusDialog] = useState(false);

  const [rows, setRows] = useState([]);
  const [rowChecked, setRowChecked] = useState([]);
  const [status, setStatus] = useState("");
  const [selected, setSelected] = useState([])

  const [loading, setLoading] = useState(false);
  const [singleLoading, setSingleLoading] = useState(false);
  const [direction, setDirection] = useState(false);
  const [currentColumn, setCurrentColumn] = useState("");
  const [page, setPage] = useState(1);
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(
    configRowPerPage && configRowPerPage
      ? configRowPerPage && configRowPerPage
      : 20
  );

  const [rowsPerPage, setRowsPerPage] = useState(
    configRowPerPage && configRowPerPage
      ? configRowPerPage && configRowPerPage
      : 20
  );
  const [total, setTotal] = useState("");
  const [filterOpen, setFilterOpen] = useState(false);

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [messageState, setMessageState] = useState("");
  

  const [filterData, setFilterData] = useState({
    exceptionalOrder: "",
    email: "",
    from: null,
    to: null,
    remove: false,
  });

  const [submittedData, setSubmittedData] = useState({
    exceptionalOrder: "",
    email: "",
    from: null,
    to: null,
    submit: false,
  });

  // useEffect(() => {
  //   getOrders();
  // }, []);

  useEffect(() => {
    if (
      filterData.exceptionalOrder === "" &&
      filterData.email === "" &&
      filterData.from === "" &&
      filterData.to === "" 
    ) {
      setSubmittedData({
        ...submittedData,
        submit: false,
      });
    }
    if (filterData.exceptionalOrder === " ") filterData.exceptionalOrder = "";
    if (filterData.email === " ") filterData.email = "";
    if (filterData.from === " ") filterData.from = "";
    if (filterData.to === " ") filterData.to = "";

    filterData.remove === true && handleFilter();
  }, [filterData]);

  useEffect(() => {
    let currentpolicy = JSON.parse(localStorage.getItem("orderlist_filter"));
    currentpolicy !== null && setFilterData(currentpolicy);

    currentpolicy == null
      ? getOrders()
      : currentpolicy.exceptionalOrder == "" &&
        currentpolicy.email == "" &&
        currentpolicy.from == "" &&
        currentpolicy.to == "" &&
        currentpolicy.removed == false
      ? getOrders()
      : console.log("orders");
  }, []);

  const getOrders = () => {
    setLoading(true);
    httpclient
      .get(`new-system/exceptional-report/status/Accepted?pagination=${rowsPerPage}`)
      .then(({ data }) => {
        if (data) {
          setRows(data.data);
          setTotal(data.meta.total);
          setRowsPerPage(parseInt(data.meta.per_page));
          setPage(data.meta.current_page);
          setFrom(data.meta.from);
          setTo(data.meta.to);
          setLoading(false);
        }
      });
  };

  const handleView = (row) => {
    setSingleLoading(true);
    setOpenViewDialog(true);
    httpclient
      .get(`new-system/exceptional-report/${row.orderID}`)
      .then(({ data }) => {
        if (data) {
          setViewDetails(data.data);
          setSingleLoading(false);
        }
      });
  };

  const sendDetails = (callback) => {
    if (callback.open === false) {
      setOpenViewDialog(false);
      setViewDetails({});
    }
  };

  const handleFilter = () => {
    setSubmittedData({
      ...submittedData,
      exceptionalOrder: filterData.exceptionalOrder,
      email: filterData.email,
      from: filterData.from,
      to: filterData.to,
      submit: true,
    });
    filterData.remove = true;
    localStorage.setItem("orderlist_filter", JSON.stringify(filterData));
    setLoading(true);
    if (
      filterData.exceptionalOrder ||
      filterData.email ||
      filterData.from ||
      filterData.to 
    ) {
      httpclient
        .get(
          `new-system/exceptional-report/status/Accepted?reportType=${
            filterData.exceptionalOrder
          }&billToEmail=${filterData.email}&startDate=${
            filterData.from || "" 
          }&endDate=${filterData.to || ""}&pagination=${rowsPerPage}&page=${1}`
        )
        .then(({ data }) => {
          if (data.success) {
            setRows(data.data);
            setTotal(data.meta.total);
            setRowsPerPage(data.meta.per_page);
            setPage(data.meta.current_page);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        });
    } else {
      getOrders();
    }
  };

  const hadleFilterOpen = () => {
    setFilterOpen((prev) => !prev);
  };

  const handleChangeFilter = (e) => {
    const { name, value } = e.target;
    setFilterData({
      ...filterData,
      [name]: value,
      remove: false,
    });
  };
  // console.log('filter data', filterData);

  const handleRemove = (data) => {
    setFilterData({
      ...filterData,
      [data]: "",
      remove: true,
    });

    setSubmittedData({
      ...submittedData,
      [data]: "",
    });
  };

  const handleRowCheck = (e, row) => {
    const { checked } = e.target;
    if (checked === true) {
      setRowChecked([...rowChecked, row.orderID]);
    } else {
      let newData = rowChecked.filter((check) => {
        return check !== row.orderID;
      });
      setRowChecked(newData);
    }
  };

  const handleAccept = (row) => {
    let currID = [row.orderID];
    setSelected(currID);
    setStatus("accept");
    setOpenStatusDialog(true);
  }

  const handleReject = (row) => {
    let currID = [row.orderID];
    setSelected(currID);
    setStatus("reject");
    setOpenStatusDialog(true);
  }

  const handleAcceptArray = () => {
    setOpenStatusDialog(true);
    setStatus("accept");
  }

  const handleRejectArray = () => {
    setOpenStatusDialog(true);
    setStatus("reject");
  }

  const sendChangeOrder = call => {
    if(call.open === false){
      setRowChecked([]);
      setStatus("");
      setOpenStatusDialog(false)
    }
    if(call.success === true){
      httpclient.post('/new-system/order/update-status',{
        orderID: selected.length > 0 ? selected : rowChecked,
        status: status === "accept" ? "Accepted" : "Rejected"
      }).then(({data}) => {
        if (data.success) {
          setOpen(true);
          setMessageState("success");
          setMessage(data.message);
          setRowChecked([]);
          setSelected([])
          setStatus("")
          setTimeout(() => {
            getOrders();
          },[1500])
        } else {
          setOpen(true);
          setMessageState("error");
          setMessage(data.error);
          setRowChecked([]);
          setStatus("")
        }
      })
    }
  }

  const handleSort = (column) => {
    setDirection((prevDirection) => !prevDirection);
    setCurrentColumn(column);
    submittedData.submit
      ? httpclient
          .get(
            `new-system/exceptional-report/status/Accepted?reportType=${
              filterData.exceptionalOrder
            }&billToEmail=${filterData.email}&startDate=${
              filterData.from || ""
            }&endDate=${filterData.to || ""}&sort_by=${column}&direction=${
              !direction ? "asc" : "desc"
            }&pagination=${rowsPerPage}&page=${page}`
          )
          .then(({ data }) => {
            if (data.success === true) {
              setRows(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setPage(data.meta.current_page);
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          })
      : httpclient
          .get(
            `new-system/exceptional-report/status/Accepted?sort_by=${column}&direction=${
              !direction ? "asc" : "desc"
            }&pagination=${rowsPerPage}`
          )
          .then(({ data }) => {
            if (data.success === true) {
              setRows(data.data);
            }
          });
  };

  const handleChangePage = (e, page) => {
    setLoading(true);
    submittedData.submit
      ? httpclient
          .get(
            `new-system/exceptional-report/status/Accepted?reportType=${
              filterData.exceptionalOrder
            }&billToEmail=${filterData.email}&startDate=${
              filterData.from || ""
            }&endDate=${filterData.to || ""}&sort_by=${currentColumn}&direction=${
              direction ? "asc" : "desc"
            }&pagination=${rowsPerPage}&page=${page}`
          )
          .then(({ data }) => {
            if (data.success === true) {
              setRows(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setPage(data.meta.current_page);
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          })
      : httpclient
          .get(
            `new-system/exceptional-report/status/Accepted?direction=${
              direction ? "asc" : "desc"
            }&pagination=${rowsPerPage}&page=${page}`
          )
          .then(({ data }) => {
            if (data.success === true) {
              setRows(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setPage(data.meta.current_page);
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    // setLoading(true);

    localStorage.setItem("configRowPerPage", event.target.value);

    submittedData.submit
      ? httpclient
          .get(
            `new-system/exceptional-report/status/Accepted?reportType=${
              filterData.exceptionalOrder
            }&billToEmail=${filterData.email}&startDate=${
              filterData.from || ""
            }&endDate=${filterData.to || ""}&sort_by=${currentColumn}&direction=${
              direction ? "asc" : "desc"
            }&pagination=${+event.target.value}&page=${page}`
          )
          .then(({ data }) => {
            setLoading(true);
            if (data.success === true) {
              setRows(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setPage(data.meta.current_page);
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          })
      : httpclient
          .get(
            `new-system/exceptional-report/status/Accepted?direction=${
              direction ? "asc" : "desc"
            }&pagination=${+event.target.value}&page=${1}`
          )
          .then(({ data }) => {
            setLoading(true);
            if (data.success === true) {
              setRows(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setPage(data.meta.current_page);
              setLoading(false);
            }
          });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item md={8} xs={12}>
          <Header>
            <h1>Accepted Orders</h1>
          </Header>
        </Grid>
        <Grid
          item
          md={4}
          xs={12}
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Button color="primary" variant="contained" onClick={hadleFilterOpen}>
            Filter <FilterList style={{ marginLeft: "5px" }} fontSize="small" />
          </Button>
        </Grid>

        {/* Filter */}
        <Grid item xs={12}>
          <Collapse in={filterOpen}>
            <Card>
              <Box p={4}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <InputLabel>Ready to Process Order</InputLabel>
                    <FormControl fullWidth>
                      <Select
                        value={filterData.exceptionalOrder}
                        // label="Exclusive exceptionalOrder"
                        name="exceptionalOrder"
                        onChange={handleChangeFilter}
                      >
                        <MenuItem value={""}>Select</MenuItem>
                        <MenuItem value={"readyToProcessOrder"}>Yes</MenuItem>
                        <MenuItem value={"exceptionalOrder"}>No</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <InputLabel>Email Address</InputLabel>
                    <TextField
                      name="email"
                      value={filterData.email}
                      onChange={handleChangeFilter}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>

                  {/* <Grid item xs={12} md={4}>
                    <InputLabel>Pending</InputLabel>
                    <FormControl fullWidth>
                      <Select
                        value={filterData.pending}
                        // label="Exclusive Pending"
                        name="pending"
                        onChange={handleChangeFilter}
                      >
                        <MenuItem value={""}>Select</MenuItem>
                        <MenuItem value={"Y"}>Yes</MenuItem>
                        <MenuItem value={"N"}>No</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <InputLabel>Order Type</InputLabel>
                    <FormControl fullWidth>
                      <Select
                        value={filterData.orderType}
                        // label="Exclusive orderType"
                        name="orderType"
                        onChange={handleChangeFilter}
                      >
                        <MenuItem value={""}>Select</MenuItem>
                        <MenuItem value={"Disco"}>Disco</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <InputLabel>Order Status</InputLabel>
                    <FormControl fullWidth>
                      <Select
                        value={filterData.pending}
                        // label="Exclusive Pending"
                        name="pending"
                        onChange={handleChangeFilter}
                      >
                        <MenuItem value={""}>Select</MenuItem>
                        <MenuItem value={"created"}>Created</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid> */}

                  {/* <Grid item xs={12} md={6}>
                    <InputLabel>From</InputLabel>
                    <TextField
                      variant="outlined"
                      name="from"
                      type="date"
                      value={filterData.from}
                      onChange={(e) => handleChangeFilter(e)}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <InputLabel>To</InputLabel>
                    <TextField
                      variant="outlined"
                      name="to"
                      type="date"
                      value={filterData.to}
                      onChange={(e) => handleChangeFilter(e)}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid> */}

                  <Grid item xs={12}>
                    <Box textAlign={"right"}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleFilter}
                      >
                        Filter{" "}
                        <ArrowForward
                          fontSize="small"
                          style={{ marginLeft: "5px" }}
                        />
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </Collapse>
        </Grid>

        {submittedData.exceptionalOrder ||
        submittedData.email ||
        submittedData.from ||
        submittedData.to ? (
          <Grid item xs={12}>
            <FilteredBox>
              <span>Filtered: </span>
              {submittedData.exceptionalOrder && (
                <p>
                  <span>
                    Ready to Process Order:{" "}
                    {submittedData.exceptionalOrder === "readyToProcessOrder" ? "Yes" : "No"}
                  </span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("exceptionalOrder")}
                  />
                </p>
              )}
              {submittedData.from && (
                <p>
                  <span>Start Date: {submittedData.from}</span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("from")}
                  />
                </p>
              )}

              {submittedData.to && (
                <p>
                  <span>End Date: {submittedData.to}</span>
                  <Close fontSize="small" onClick={() => handleRemove("to")} />
                </p>
              )}

              {submittedData.email && (
                <p>
                  <span>Email: {submittedData.email}</span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("email")}
                  />
                </p>
              )}
            </FilteredBox>
          </Grid>
        ) : (
          <Box></Box>
        )}
        {/* Filter */}

        {/* Bulk Accept and Reject */}
        {/* <Grid item xs={12}>
          <Box textAlign={"right"}>
          {rowChecked.length > 0 &&
            <span style={{ marginRight:"10px" }}>{rowChecked.length} rows selected.</span>}
            <Button
              color="primary"
              size="small"
              variant="contained"
              onClick={() => handleAcceptArray()}
              disabled={rowChecked.length > 0 ? false : true}
            >
              Accept{" "}
              <Check fontSize="small" style={{ marginLeft: "5px", top: "0" }} />
            </Button>

            <Button
              color="error"
              size="small"
              variant="contained"
              onClick={() => handleRejectArray()}
              style={{ marginLeft: "10px" }}
              disabled={rowChecked.length > 0 ? false : true}
            >
              Reject{" "}
              <Clear fontSize="small" style={{ marginLeft: "5px", top: "0" }} />
            </Button>
          </Box>
        </Grid> */}
        {/* Bulk Accept and Reject */}


        <Grid item xs={12}>
          <TableComponent
            columns={columns}
            rows={rows}
            sort={true}
            handleView={handleView}
            handleSort={handleSort}
            loading={loading}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleChangePage={handleChangePage}
            handleRowCheck={handleRowCheck}
            rowChecked={rowChecked}
            handleAccept={handleAccept}
            handleReject={handleReject}
            direction={direction}
            currentColumn={currentColumn}
            page={page}
            total={total && total}
            fromTable={from}
            toTable={to}
            rowsPerPage={rowsPerPage}
          />
        </Grid>
      </Grid>

      {openViewDialog && (
        <ViewOrderDialog
          singleLoading={singleLoading}
          viewDetails={viewDetails}
          sendDetails={sendDetails}
        />
      )}

      {openStatusDialog && (
        <StatusDialog sendChangeOrder={sendChangeOrder} status={status} />
      )}

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={messageState}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default AcceptedOrders;
