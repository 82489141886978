import React, { useEffect, useState } from "react";
import TableComponent from "../TableComponent";
import { Box, Button, Card, Collapse, FormControl, Grid, InputLabel, MenuItem, Select, styled, TextField } from "@mui/material";
import { ArrowForward, Close, FilterList } from "@mui/icons-material";
import { httpclient }from "../../../../utils/httpClient";
import ViewDetailsDialog from "../ViewDetailsDialog.js/index.js";

const columns = [
  { id: "erpAccountCustomerID", name: "ID" },
  { id: "erpAccountName", name: "Account Name" },
  { id: "email", name: "Email" },
  { id: "phone", name: "Phone" },
  // { id: "fax", name: "Fax" },
  { id: "creditStatus", name: "Credit Status" },
  { id: "customerNotes", name: "Customer Notes" },
  // { id: "address1", name: "Address" },
  // { id: "delivery_address_1", name: "Delivery Address" },
  { id: "active", name: "Active" },
  { id: "pendingProcess", name: "Pending Process" },
  { id: "lastUpdated", name: "Last Updated" },
  // { id: "actions", name: "Actions" },
];

const FilteredBox= styled(Box)(({ theme }) => ({
  background: "#f9f9f9",
  padding: "5px 10px",
  borderRadius:"5px",
  "& p": {
    margin: "0",
    marginRight: "10px",
    display: "inline-block",
    background:"#dedede",
    borderRadius:"10px",
    padding:"2px 5px",
  },
  "& svg":{
    fontSize:"15px",
    cursor:"pointer",
    position:"relative",
    top:"3px",
    background: theme.palette.primary.dark,
    color: "#fff",
    borderRadius:"50%",
    padding:"2px",
    marginLeft:"2px"
  }
}));

const Header = styled("div")(({ theme }) => ({
  "& h1": {
    color: theme.palette.primary.dark,
    margin: "0"
  },
}));

const configRowPerPage = JSON.parse(localStorage.getItem("configRowPerPage"));

const CurrentCompanyAccount = () => {

  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [viewDetails, setViewDetails] = useState({});

  const [rows, setRows] = useState([]);

  const [loading, setLoading] = useState(false);
  const [direction, setDirection] = useState(false);
  const [currentColumn, setCurrentColumn] = useState("");
  const [page, setPage] = useState(1);
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(
    configRowPerPage && configRowPerPage
      ? configRowPerPage && configRowPerPage
      : 20
  );

  const [rowsPerPage, setRowsPerPage] = useState(
    configRowPerPage && configRowPerPage
      ? configRowPerPage && configRowPerPage
      : 20
  );
  const [total, setTotal] = useState("");
  const [filterOpen, setFilterOpen] = useState(false);

  const [filterData, setFilterData] = useState({
    accountName: "",
    email: "",
    phone: "",
    pendingProcess: "",
    active: "",
    remove: false
  });

  const [submittedData, setSubmittedData] = useState({
    accountName: "",
    email: "",
    phone: "",
    pendingProcess: "",
    active: "",
    submit: false,
  });


  // useEffect(() => {
  //   getAllAccounts();
  // }, []);

  useEffect(() => {
    if (
      filterData.accountName === "" &&
      filterData.email === "" &&
      filterData.phone === "" &&
      filterData.pendingProcess === "" &&
      filterData.active === ""
    ) {
      setSubmittedData({
        ...submittedData,
        submit: false,
      });
    }
    if (filterData.accountName === " ") filterData.accountName = "";
    if (filterData.email === " ") filterData.email = "";
    if (filterData.phone === " ") filterData.phone = "";
    if (filterData.pendingProcess === " ") filterData.pendingProcess = "";
    if (filterData.active === " ") filterData.active = "";

    filterData.remove === true && handleFilter();
  }, [filterData]);

  useEffect(() => {
    let accountStorage = JSON.parse(
      localStorage.getItem("account_filter")
    );
    accountStorage !== null && setFilterData(accountStorage);

    accountStorage == null
      ? getAllAccounts()
      : accountStorage.accountName == "" &&
        accountStorage.email == "" &&
        accountStorage.phone == "" &&
        accountStorage.pendingProcess == "" &&
        accountStorage.active == "" &&
        accountStorage.removed == false
      ? getAllAccounts()
      : console.log("customer account!");
  }, []);


  const getAllAccounts = () => {
    setLoading(true);
    httpclient.get(`current/company-account?pagination=${rowsPerPage}`).then(({ data }) => {
      if (data.success) {
        setRows(data.data);
        setTotal(data.meta.total);
        setRowsPerPage(parseInt(data.meta.per_page));
        setPage(data.meta.current_page);
        setFrom(data.meta.from);
        setTo(data.meta.to);
        setLoading(false);
      }
    }).catch(err => {
      if(err.response.status === 401){
        localStorage.clear();
        window.location = "/login";
      }
    })
  };

  const hadleFilterOpen = () => {
    setFilterOpen((prev) => !prev);
  };

  const handleChangeFilter = (e) => {
    const { name, value } = e.target;
    setFilterData({
      ...filterData,
      [name]: value,
      remove: false
    });
  };

  const handleFilter = () => {
    setSubmittedData({
      ...submittedData,
      accountName: filterData.accountName,
      email: filterData.email,
      phone: filterData.phone,
      pendingProcess: filterData.pendingProcess,
      active: filterData.active,
      submit: true
    });
    filterData.remove = true;
    localStorage.setItem("account_filter", JSON.stringify(filterData));
    setLoading(true);
    if(filterData.accountName || filterData.email || filterData.phone || filterData.pendingProcess || filterData.active){
      httpclient
        .get(
          `current/company-account?erpAccountName=${filterData.accountName}&email=${filterData.email}&phone=${filterData.phone}&pendingProcess=${filterData.pendingProcess}&active=${filterData.active}&pagination=${rowsPerPage}&page=${1}`
        )
        .then(({ data }) => {
          if (data.success) {
            setRows(data.data);
            setTotal(data.meta.total);
            setRowsPerPage(data.meta.per_page);
            setPage(data.meta.current_page);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        })
    }else{
      getAllAccounts();
    }
  };

  const handleRemove = (data) =>{
    setFilterData({
      ...filterData,
      [data]: "",
      remove: true
    })

    setSubmittedData({
      ...submittedData,
      [data]: ""
    })
  }

  const handleSort = (column) => {
    setDirection((prevDirection) => !prevDirection);
    setCurrentColumn(column);
    submittedData.submit ?
    httpclient
      .get(`current/company-account?erpAccountName=${filterData.accountName}&email=${filterData.email}&phone=${filterData.phone}&pendingProcess=${filterData.pendingProcess}&active=${filterData.active}&sort_by=${column}&direction=${
        !direction ? "asc" : "desc"
      }&pagination=${rowsPerPage}&page=${page}`)
      .then(({ data }) => {
        if (data.success === true) {
          setRows(data.data);
          setTotal(data.meta.total);
          setRowsPerPage(parseInt(data.meta.per_page));
          setPage(data.meta.current_page);
          setFrom(data.meta.from);
          setTo(data.meta.to);
          setLoading(false);
        }
      })
      :
    httpclient
      .get(
        `current/company-account?sort_by=${column}&direction=${
          !direction ? "asc" : "desc"
        }&pagination=${rowsPerPage}`
      )
      .then(({ data }) => {
        if (data.success === true) {
          setRows(data.data);
        }
      });
  };


  const handleChangePage = (e, page) => {
    setLoading(true);
    submittedData.submit ?
    httpclient
      .get(`current/company-account?erpAccountName=${filterData.accountName}&email=${filterData.email}&phone=${filterData.phone}&pendingProcess=${filterData.pendingProcess}&active=${filterData.active}&sort_by=${currentColumn}&direction=${direction ? 'asc' : 'desc'}&pagination=${rowsPerPage}&page=${page}`)
      .then(({ data }) => {
        if (data.success === true) {
          setRows(data.data);
          setTotal(data.meta.total);
          setRowsPerPage(parseInt(data.meta.per_page));
          setPage(data.meta.current_page);
          setFrom(data.meta.from);
          setTo(data.meta.to);
          setLoading(false);
        }
      })
      :
      httpclient.get(`current/company-account?direction=${direction ? 'asc' : 'desc'}&pagination=${rowsPerPage}&page=${page}`).then(({ data }) => {
        if (data.success === true) {
          setRows(data.data);
          setTotal(data.meta.total);
          setRowsPerPage(parseInt(data.meta.per_page));
          setPage(data.meta.current_page);
          setFrom(data.meta.from);
          setTo(data.meta.to);
          setLoading(false);
        }
      })
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    // setLoading(true);

    localStorage.setItem("configRowPerPage", event.target.value);

    submittedData.submit ?
    httpclient
      .get(`current/current-account?erpAccountName=${filterData.accountName}&email=${filterData.email}&phone=${filterData.phone}&pendingProcess=${filterData.pendingProcess}&active=${filterData.active}&sort_by=${currentColumn}&direction=${direction ? 'asc' : 'desc'}&pagination=${+event.target.value}&page=${page}`)
      .then(({ data }) => {
        setLoading(true);
        if (data.success === true) {
          setRows(data.data);
          setTotal(data.meta.total);
          setRowsPerPage(parseInt(data.meta.per_page));
          setPage(data.meta.current_page);
          setFrom(data.meta.from);
          setTo(data.meta.to);
          setLoading(false);
        }
      })
      :
      httpclient.get(`current/current-account?direction=${direction ? 'asc' : 'desc'}&pagination=${+event.target.value}&page=${1}`).then(({ data }) => {
        setLoading(true);
        if (data.success === true) {
          setRows(data.data);
          setTotal(data.meta.total);
          setRowsPerPage(parseInt(data.meta.per_page));
          setFrom(data.meta.from);
          setTo(data.meta.to);
          setPage(data.meta.current_page);
          setLoading(false);
        }
      });
  };

  const handleView = (row) => {
    setOpenViewDialog(true);
    setViewDetails(row);
  };

  const sendDetails = (callback) => {
    if (callback.open === false) {
      setOpenViewDialog(false);
      setViewDetails({});
    }
  };


  return (
    <div>
      <Grid container spacing={2}>
        <Grid item md={8} xs={12}>
          <Header>
            <h1>Current Customers</h1>
          </Header>
        </Grid>
        <Grid
          item
          md={4}
          xs={12}
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Button color="primary" variant="contained" onClick={hadleFilterOpen}>
            Filter <FilterList style={{ marginLeft: "5px" }} fontSize="small" />
          </Button>
        </Grid>

          {/* Filter */}
          <Grid item xs={12}>
          <Collapse in={filterOpen}>
            <Card>
              <Box p={4}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <InputLabel>Account Name</InputLabel>
                    <TextField
                      variant="outlined"
                      name="accountName"
                      value={filterData.accountName}
                      onChange={handleChangeFilter}
                      onKeyDown={e => {if(e.key === "Enter") handleFilter()}}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <InputLabel>Email</InputLabel>
                    <TextField
                      variant="outlined"
                      name="email"
                      value={filterData.email}
                      onChange={handleChangeFilter}
                      onKeyDown={e => {if(e.key === "Enter") handleFilter()}}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <InputLabel>Phone</InputLabel>
                    <TextField
                      variant="outlined"
                      name="phone"
                      value={filterData.phone}
                      onChange={handleChangeFilter}
                      onKeyDown={e => {if(e.key === "Enter") handleFilter()}}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <InputLabel>Pending Process</InputLabel>
                    <FormControl fullWidth>
                      <Select
                        value={filterData.pendingProcess}
                        // label="Exclusive Pending"
                        name="pendingProcess"
                        onChange={handleChangeFilter}
                      >
                        <MenuItem value={""}>Select</MenuItem>
                        <MenuItem value={"1"}>Yes</MenuItem>
                        <MenuItem value={"0"}>No</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <InputLabel>Active</InputLabel>
                    <FormControl fullWidth>
                      <Select
                        value={filterData.active}
                        name="active"
                        // label="Active"
                        onChange={handleChangeFilter}
                      >
                        <MenuItem value={""}>Select</MenuItem>
                        <MenuItem value={"Y"}>Yes</MenuItem>
                        <MenuItem value={"N"}>No</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <Box textAlign={"right"}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleFilter}
                      >
                        Filter{" "}
                        <ArrowForward
                          fontSize="small"
                          style={{ marginLeft: "5px" }}
                        />
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </Collapse>
        </Grid>

        {(submittedData.accountName || submittedData.email || submittedData.phone|| 
        submittedData.pendingProcess|| submittedData.active) ? (
          <Grid item xs={12}>
          <FilteredBox>
            <span>Filtered: </span>
            {submittedData.accountName && (
              <p>
              <span>Account Name: {submittedData.accountName}</span>
              <Close fontSize="small" onClick={() => handleRemove("accountName")} />
              </p>
            )}
            {submittedData.email && (
              <p>
              <span>Email: {submittedData.email}</span>
              <Close fontSize="small" onClick={() => handleRemove("email")} />
              </p>
            )}
            {submittedData.phone && (
              <p>
              <span>Phone: {submittedData.phone}</span>
              <Close fontSize="small" onClick={() => handleRemove("phone")} />
              </p>
            )}

            {submittedData.pendingProcess && (
              <p>
              <span>Pending Process: {submittedData.pendingProcess === "1" ? "Yes" : "No"}</span>
              <Close fontSize="small" onClick={() => handleRemove("pendingProcess")} />
              </p>
            )}
            {submittedData.active && (
              <p>
              <span>Active: {submittedData.active === "Y" ? "Yes" : "No"}</span>
              <Close fontSize="small" onClick={() => handleRemove("active")} />
              </p>
            )}
          </FilteredBox>
        </Grid>  
        ):
          <Box></Box>
        }
        {/* Filter */}


        <Grid item xs={12}>
          <TableComponent
            columns={columns}
            rows={rows}
            sort={true}
            handleSort={handleSort}
            handleView={handleView}
            // handleEdit={handleEdit}
            // handleDelete={handleDelete}
            loading={loading}
            direction={direction}
            currentColumn={currentColumn}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleChangePage={handleChangePage}
            page={page}
            total={total && total}
            fromTable={from}
            toTable={to}
            rowsPerPage={rowsPerPage}
          />
        </Grid>
      </Grid>

      {/* {openDeleteDialog && <DeleteDialog sendDelete={sendDelete} />} */}

      {openViewDialog && (
        <ViewDetailsDialog
          viewDetails={viewDetails}
          sendDetails={sendDetails}
        />
      )}

    </div>
  );
};

export default CurrentCompanyAccount;
