import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  Skeleton,
  TableFooter,
  TablePagination,
  useTheme,
  Tooltip,
} from "@mui/material";
import moment from "moment/moment";
import {
  Check,
  Clear,
  Info,
  KeyboardArrowDown,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardArrowUp,
  UnfoldMore,
} from "@mui/icons-material";
import PropTypes from "prop-types";
import DownloadIcon from "@mui/icons-material/Download";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

const StyledTableCell = styled(TableCell)(({ theme }) => ({

  maxWidth: "140px",
  overflow: "hidden",
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.light,
    //width: "0px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  "& svg": {
    position: "relative",
    top: "5px",
  },
  "&:last-child": {
    // paddingRight: 64,
    "& svg": {
      // display: 'none',
      // color: theme.palette.primary.dark
    },
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onPageChange } = props;
  const theme = useTheme();

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  return (
    <div style={{ flexShrink: "0" }}>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 1}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage)}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default function TableComponent(props) {


  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 780 }}>
        <Table stickyHeader aria-label="sticky table" sx={{ minWidth: 700 }}>
          <TableHead>
            <TableRow>
              {props.columns.map((col, index) =>
                props.sort ? (
                  index === props.columns.length - 1 || index === 0 ? (
                    <StyledTableCell onClick={() => props.handleSort(col.id)}>
                      {col.name}
                      {/* {props.currentColumn === col.id ? <span style={{ fontWeight:"700" }}>{col.name}</span> : col.name} */}
                      {/* {props.currentColumn === col.id ? props.direction ? <KeyboardArrowUp fontSize="small" /> : <KeyboardArrowDown fontSize="small" /> : <UnfoldMore fontSize="small" />}   */}
                    </StyledTableCell>
                  ) : (
                    <StyledTableCell onClick={() => props.handleSort(col.id)}>
                      {props.currentColumn === col.id ? (
                        <span style={{ fontWeight: "700" }}>{col.name}</span>
                      ) : (
                        col.name
                      )}
                      {props.currentColumn === col.id ? (
                        props.direction ? (
                          <KeyboardArrowUp fontSize="small" />
                        ) : (
                          <KeyboardArrowDown fontSize="small" />
                        )
                      ) : (
                        <UnfoldMore fontSize="small" />
                      )}
                    </StyledTableCell>
                  )
                ) : index === 0 ? (
                  <StyledTableCell>{col.name}</StyledTableCell>
                ) : (
                  <StyledTableCell align="">{col.name}</StyledTableCell>
                )
              )}
            </TableRow>
          </TableHead>
          {props.loading ? (
            <TableBody
              sx={{ position: "relative", height: "465px", overflow: "hidden" }}
            >
              <Box p={3} sx={{ position: "absolute", width: "100%" }}>
                <Skeleton height={60} />
                <Skeleton height={60} />
                <Skeleton height={60} />
                <Skeleton height={60} />
                <Skeleton height={60} />
                <Skeleton height={60} />
                <Skeleton height={60} />
              </Box>
            </TableBody>
          ) : (
            <TableBody>
              {props.rows.length ? (
                props.rows
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    // Return null to skip rendering the row
                    var urgent = false;
                    let newShippingDate = moment(row.shippingDate);
                    let newCreatedDate = moment(row.order_created);

                    let checkhours = newShippingDate.diff(newCreatedDate, "hours");


                    // console.log('newShippingDate',newShippingDate);
                    // console.log('newCreatedDate',newCreatedDate);
                    // console.log('check hrs', checkhours);

                    if (isNaN(checkhours) === false && checkhours !== null && checkhours < 48) {
                      urgent = true;
                    } else {
                      urgent = false;
                    }


                    function accept(row) {
                      if (
                        row.company.multiLoc_desp_per_po === "0" &&
                        row.orderProduct.some((product) => product.assignToBranch.length > 1)
                      ) {
                        return true;
                      } else {
                        return false;
                      }
                    }
                    return (
                      <StyledTableRow
                        key={row.orderID}
                        style={{ cursor: "pointer" }}
                      >
                        {props.columns.map((col, index) =>
                          // (props.filterData.invoiceStatus === "invoiced" && row.order_status === "Accepted") ? row.invoiceStatus === "invoiced" :

                          col.id === "image" ? (
                            index === 0 ? (
                              <StyledTableCell component="th" scope="row">
                                <img
                                  src={row.image}
                                  style={{
                                    width: "100px",
                                    height: "100px",
                                    objectFit: "cover",
                                  }}
                                  alt={row.firstname}
                                />
                              </StyledTableCell>
                            ) : (
                              <StyledTableCell align="">
                                <img
                                  src={row.image}
                                  style={{
                                    width: "100px",
                                    height: "100px",
                                    objectFit: "cover",
                                  }}
                                  alt={row.firstname}
                                />
                              </StyledTableCell>
                            )
                          ) : index === 0 ? (
                            col.id === "checkColumn" && (
                              <StyledTableCell
                                align="left"
                                style={{ zIndex: "1", position: "relative" }}
                              >
                                {urgent ?
                                  <Tooltip title="Urgent Order!">
                                    <Info style={{ position: "absolute", top: "0", left: "0", color: "#fc4343" }} />
                                  </Tooltip>
                                  : ""}
                                {row.order_status === "Accepted" ||
                                  row.order_status === "Pre-Sales" ||
                                  row.order_status === "Rejected" ||
                                  row.order_status === "Cancelled" ||
                                  row.order_status === "Ignored" ||
                                  row.order_status === "Pre-Sales"
                                  ? (
                                    ""
                                  ) : (
                                    <Checkbox
                                      color="primary"
                                      // indeterminate={numSelected > 0 && numSelected < rowCount}
                                      onChange={(e) => props.handleRowCheck(e, row)}
                                      value={
                                        props.rowChecked.includes(row.orderID)
                                          ? true
                                          : false
                                      }
                                      checked={
                                        props.rowChecked.includes(row.orderID)
                                          ? true
                                          : false
                                      }
                                      inputProps={{
                                        "aria-labelledby": row.orderID,
                                      }}
                                    />
                                  )}
                              </StyledTableCell>
                            )
                          ) : col.id === "newSystemOrderNumber" ? (
                            <StyledTableCell
                              align="left"
                              style={{ zIndex: "1", position: "relative" }}
                            >
                              {row.company?.erpAccountCustomerID === 1373 &&
                                row.isPresellOrder === 1 ? (
                                row.isPresellOrderReceipted === 1 ? (
                                  <Tooltip title="Pre-Sales Receipted">
                                    {/* <Info style={{ position: "absolute", top: "45", right: "0", color: "green" }} /> */}
                                    <Button
                                      sx={{
                                        position: "absolute",
                                        top: "10%",
                                        right: "0",
                                        color: "white",
                                        background: "green",
                                        borderRadius: "20%",
                                        fontSize: "12px",
                                        minWidth: "10px",
                                        padding: "0px 5px 0px 5px",
                                        "&:hover": {
                                          color: "white",
                                          background: "green",
                                        },
                                      }}
                                    >
                                      PR
                                    </Button>
                                  </Tooltip>
                                ) : (
                                  <Tooltip title="Pre-Sales Order">
                                    {/* <Info style={{ position: "absolute", top: "45", right: "0", color: "green" }} /> */}
                                    <Button
                                      sx={{
                                        position: "absolute",
                                        top: "10%",
                                        right: "0",
                                        color: "white",
                                        background: "green",
                                        borderRadius: "20%",
                                        fontSize: "12px",
                                        minWidth: "10px",
                                        padding: "0px 5px 0px 5px",
                                        "&:hover": {
                                          color: "white",
                                          background: "green",
                                        },
                                      }}
                                    >
                                      PS
                                    </Button>
                                  </Tooltip>
                                )) : row.company?.erpAccountCustomerID === 1373 &&
                                  row.isLimboOrder === 1 ? (
                                <Tooltip title="Limbo Order">
                                  {/* <Info style={{ position: "absolute", top: "45", right: "0", color: "green" }} /> */}
                                  <Button
                                    sx={{
                                      position: "absolute",
                                      top: "10%",
                                      right: "0",
                                      color: "white",
                                      background: "orange",
                                      borderRadius: "20%",
                                      fontSize: "12px",
                                      minWidth: "10px",
                                      padding: "0px 5px 0px 5px",
                                      "&:hover": {
                                        color: "white",
                                        background: "orange",
                                      },
                                    }}
                                  >
                                    LO
                                  </Button>
                                </Tooltip>
                              ) : ("")}
                              {row.company?.erpAccountCustomerID === 1373 &&
                                row.isShippingLabelGenerate === 0 ? (
                                <Tooltip title="Missing Shipping Label">
                                  {/* <Info style={{ position: "absolute", top: "45", right: "0", color: "green" }} /> */}
                                  <Button
                                    sx={{
                                      position: "absolute",
                                      top: "10%",
                                      left: "0",
                                      color: "white",
                                      background: "#d32f2f",
                                      borderRadius: "20%",
                                      fontSize: "12px",
                                      minWidth: "10px",
                                      padding: "0px 5px 0px 5px",
                                      "&:hover": {
                                        color: "white",
                                        background: "#d32f2f",
                                      },
                                    }}
                                  >
                                    MS
                                  </Button>
                                </Tooltip>
                              ) : (
                                ""
                              )}
                              {/* {row[col.id]} */}
                              {row.company?.erpAccountCustomerID === 2386 ? `${row.orderProduct[0]?.newSystemProductId} (${row.newSystemOrderID})` : row.company?.erpAccountCustomerID === 2380 ? row.newSystemOrderNumber : row.newSystemOrderID}
                            </StyledTableCell>
                          ) : col.id === "actions" ? (
                            <StyledTableCell
                              align="left"
                              sx={{ minWidth: "250px" }}
                            >
                              {row.order_status === "Accepted" ||
                                row.order_status === "Pre-Sales" ||
                                row.order_status === "Rejected" ||
                                row.order_status === "Cancelled" ||
                                row.order_status === "Ignored" ? (
                                row.order_status === "Accepted" ? (
                                  "ACCEPTED"
                                ) : row.order_status === "Pre-Sales" ? (
                                  "PRE-SALES"
                                ) : row.order_status === "Cancelled" ? (
                                  "CANCELLED"
                                ) : row.order_status === "Ignored" ? (
                                  "MANUALLY PROCESSED"
                                ) : (
                                  "REJECTED"
                                )
                              ) : (
                                <>
                                  <Button
                                    color="primary"
                                    size="small"
                                    variant="contained"
                                    onClick={() => props.handleAccept(row)}
                                    disabled={row.netsuiteOrderNumber === null || props.buttonLoader
                                      || (!(row.orderProduct.some((product) => product.reasonCode === "3" || product.reasonCode === "4" || product.reasonCode === "5" || product.reasonCode === "12" || product.reasonCode === "")))
                                      || accept(row)
                                      // || row.orderProduct.some((product) => product.reasonCode === "6")
                                    }
                                  >
                                    Accept{" "}
                                    <Check
                                      fontSize="small"
                                      style={{ marginLeft: "5px", top: "0" }}
                                    />
                                  </Button>

                                  <Button
                                    color="error"
                                    size="small"
                                    variant="contained"
                                    onClick={() => props.handleReject(row)}
                                    style={{ marginLeft: "10px" }}
                                    disabled={row.netsuiteOrderNumber === null || props.buttonLoader}
                                  >
                                    Reject{" "}
                                    <Clear
                                      fontSize="small"
                                      style={{ marginLeft: "5px", top: "0" }}
                                    />
                                  </Button>
                                </>
                              )}
                            </StyledTableCell>
                          ) : (
                            <StyledTableCell
                              align=""
                              onClick={() => props.handleView(row)}
                            >
                              {col.id === "orderTotalAmount" ? (
                                "$" + row[col.id]
                              ) : col.id === "reportType" ? (
                                row[col.id] === "exceptionalOrder" &&
                                  row.order_status !== "Cancelled" ? (
                                  <Tooltip title="This order has an exception">
                                    <Check color="error" />
                                  </Tooltip>
                                ) : null
                              ) : col.id === "fullfillment_status" ? (
                                row[col.id] === "1" &&
                                row.order_status === "Accepted" || row.order_status === "Pre-Sales" ? (
                                <Tooltip title="This order has been accepted for fulfillment">
                                  <Check color="primary" />
                                </Tooltip>
                              ) :
                                !(
                                  row.order_status === "Accepted" ||
                                  row.order_status === "Rejected"
                                ) &&
                                  row["reportType"] === "exceptionalOrder" &&
                                  row.order_status !== "Cancelled" ? (
                                  <Tooltip title="Not ready for fulfillment">
                                    <Clear color="error" />
                                  </Tooltip>
                                ) : null
                              ) : col.id === "order_created" ? (
                                moment(row[col.id]).format(
                                  "DD/MM/YYYY h:mm:ss a"
                                )
                              ) : col.id === "lastUpdate" ? (
                                moment(row[col.id]).format(
                                  "ddd, MMM Do YYYY, h:mm:ss a"
                                )

                              ) : col.id === "company" ? (
                                row[col.id].erpAccountName

                              ) : col.id === "invoiceStatus" ? (
                                row[col.id] === "pending" ? null : row[col.id] ===
                                  null ? null : row[col.id] === "ready" &&
                                    (row.order_status === "Accepted" || row.order_status === "Pre-Sales") ? (
                                  <Tooltip title="Invoice generation in progress">
                                    <AccessTimeIcon
                                      color="primary"
                                      fontSize="medium"
                                    />
                                  </Tooltip>
                                ) : (row[col.id] === "invoiced" ||
                                  row[col.id] === "Invoiced" ||
                                  row[col.id] === "change-price-invoiced") &&
                                  (row.order_status === "Accepted" || row.order_status === "Pre-Sales") ? (
                                  <Tooltip title="This order has been invoiced">
                                    <Check color="primary" />
                                  </Tooltip>
                                ) : null
                              ) : col.id === "created_at" ? (
                                row[col.id] !== "0000-00-00 00:00:00" ? (
                                  row[col.id] !== null ? (
                                    moment(row[col.id]).format(
                                      "ddd, MMM Do YYYY, h:mm:ss a"
                                    )
                                  ) : (
                                    "-"
                                  )
                                ) : (
                                  "-"
                                )
                              ) : col.id === "expectedDeliveryDate" ? (
                                row[col.id] !== "0000-00-00 00:00:00" ? (
                                  row[col.id] !== null ? (
                                    moment(row[col.id]).format("DD/MM/YYYY")

                                  ) : (
                                    "-"
                                  )
                                ) : (
                                  "-"
                                )
                              ) : col.id === "url" ? (
                                <IconButton href={row[col.id]} download>
                                  <DownloadIcon />
                                </IconButton>
                              ) : col.id === "shipment" ? (row.order_status !== "Ignored" ? row[col.id]?.charAt(0).toUpperCase() + row[col.id]?.slice(1) : "-") : (
                                row[col.id] || "-"
                              )}
                            </StyledTableCell>
                          )
                        )}
                      </StyledTableRow>
                    )
                  }
                  )
              ) : (
                <TableRow sx={{ position: "relative", height: "50px" }}>
                  <TableCell
                    sx={{
                      position: "absolute",
                      right: "50%",
                      borderBottom: "none",
                    }}
                  >
                    No Orders Found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          )}

          {props.footer !== false && (
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[20, 50, 70, 100]}
                  rowsPerPage={props.rowsPerPage}
                  page={props.page}
                  count={props.total && props.total}
                  SelectProps={{
                    native: true,
                  }}
                  labelDisplayedRows={() =>
                    `${props.fromTable !== null ? props.fromTable : "0"} - ${props.toTable !== null ? props.toTable : "0"
                    } to ${props.total}`
                  }
                  onPageChange={props.handleChangePage}
                  onRowsPerPageChange={props.handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
    </Paper>
  );
}
