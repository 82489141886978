import { Box, Grid } from "@mui/material";
import { styled } from "@mui/styles";
import React from "react";
import FilterTag from "./FilterTag";

const FilteredBox = styled(Box)(({ theme }) => ({
  background: "#f9f9f9",
  padding: "5px 10px",
  borderRadius: "5px",
  "& p": {
    marginLeft: "0",
    marginRight: "10px",
    marginTop: "5px",
    marginBottom: "5px",
    display: "inline-block",
    background: "#dedede",
    borderRadius: "10px",
    padding: "2px 5px",
  },
  "& svg": {
    fontSize: "15px",
    cursor: "pointer",
    position: "relative",
    top: "3px",
    color: "#fff",
    background: "#252525",
    borderRadius: "50%",
    padding: "2px",
    marginLeft: "2px",
  },
}));
const FilterTagSection = ({ tags }) => {
  return (
    <Grid item xs={12} marginTop={2} marginBottom={1}>
      <FilteredBox>
        <span>Filtered: </span>
        {tags.map((tag, index) => (
          <FilterTag
            key={index}
            label={tag.label}
            tag={tag.tag}
            onClick={tag.onClick}
          />
        ))}
      </FilteredBox>
    </Grid>
  );
};

export default FilterTagSection;
