import React from "react";

import DataGridTablePage from "../../../Components/DataGridTablePage/DataGridTablePage";
import { dateFormatter } from "../../../utils/DataGrid/dateFormatter";

const fieldHeaderPairs = [
  { field: "STOCK_CODE", headerName: "Stock Name" },
  { field: "SUBSTITUTE_CODE", headerName: "Substitute Code" },
  { field: "CUSTOMER_ID", headerName: "Customer Name" },
  {
    field: "exoUpdatedDate",
    headerName: "Exo Updated Date",
    valueFormatter: (params) => dateFormatter(params),
  },
];

const filterColumn = [
  {
    field: "STOCK_CODE",
    label: "STOCK NAME",
    component: "TextField",
  },
  {
    field: "SUBSTITUTE_CODE",
    label: "SUBSTITUTE CODE",
    component: "TextField",
  },

  {
    field: "CUSTOMER_ID",
    label: "CUSTOMER NAME",
    component: "TextField",
  },

  // {
  //   field: "soldToa",
  //   // label: "Sold To",
  //   component: "AutocompleteField",
  //   url: "/current/company-account/all",
  // },
];

const ReplenishmentStocksubstitute = () => {
  return (
    <DataGridTablePage
      headerTitle="List Stock Substitute"
      exportFeature={true}
      columns={fieldHeaderPairs}
      filterColumn={filterColumn}
      // children={<Test />}
      pageUrl="/replenishment/stock-substitute"
      columnsVisibility={
        {
          // HIST_ID: false,
        }
      }
    />
  );
};

export default ReplenishmentStocksubstitute;
