import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  Skeleton,
  TableFooter,
  TablePagination,
  useTheme,
  Snackbar,
  TextField,
  CircularProgress,
  Tooltip
} from "@mui/material";
import moment from "moment/moment";
import {
  ArrowBack,
  Check,
  Clear,
  Done,
  Edit,
  KeyboardArrowDown,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardArrowUp,
  UnfoldMore,
} from "@mui/icons-material";
import PropTypes from "prop-types";
import DownloadIcon from "@mui/icons-material/Download";
import { useState } from "react";
import { httpclient } from "../../../../utils/httpClient";
import MuiAlert from "@mui/material/Alert";


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.light,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  // "& svg": {
  //   position: "relative",
  //   top: "5px",
  // },
  "&:last-child": {
    // paddingRight: 64,
    "& svg": {
      // display: 'none',
      // color: theme.palette.primary.dark
    },
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onPageChange } = props;
  const theme = useTheme();

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  return (
    <div style={{ flexShrink: "0" }}>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 1}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage)}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default function TableComponentPriceException(props) {
  // console.log('prossss', props);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [messageState, setMessageState] = useState("");

  const [showPriceEdit, setShowPriceEdit] = useState("");
  const [lineOrderPrice, setLineOrderPrice] = useState("");
  const [priceLoading, setPriceLoading] = useState(false);
  const [currentLineID, setCurrentLineID] = useState("")


  const handleChangeLinePrice = (row) => {
    setPriceLoading(true);
    httpclient
      .post(
        `new-system/order-product/update-price/${row.orderproductID}`,
        {
          price: lineOrderPrice,
          productID: row.orderproductID,
        }
      )
      .then(({ data }) => {
        if (data.success) {
          setPriceLoading(false);
          setOpen(true);
          setMessageState("success");
          setMessage(data.message);

          

          // Order and Product Detail Edit Boxes set to false
          
          // setProductDetails("");
          setShowPriceEdit(false);

          //Refetching the data
          
          setTimeout(() => {
            props.handleRefetch();
          },2000)

        } else {
          setPriceLoading(false);
          setOpen(true);
          setMessageState("error");
          setMessage(data.error);
        }
      })
      .catch((err) => {
        setPriceLoading(false);
        setOpen(true);
        setMessageState("error");
        setMessage(err.message);
      })
  };


  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  
  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
          <TableRow>
              {props.columns.map((col, index) =>
                props.sort ? (
                  // index === props.columns.length - 1 || index === 0 
                  // ? (
                  //   <StyledTableCell onClick={() => props.handleSort(col.id)}>
                  //     {col.name}
                  //     {/* {props.currentColumn === col.id ? <span style={{ fontWeight:"700" }}>{col.name}</span> : col.name} */}
                  //     {/* {props.currentColumn === col.id ? props.direction ? <KeyboardArrowUp fontSize="small" /> : <KeyboardArrowDown fontSize="small" /> : <UnfoldMore fontSize="small" />}   */}
                  //   </StyledTableCell>
                  // ) : (
                    <StyledTableCell onClick={() => props.handleSort(col.id)}>
                      {props.currentColumn === col.id ? (
                        <span style={{ fontWeight: "700" }}>{col.name}</span>
                      ) : (
                        col.name
                      )}
                      {props.currentColumn === col.id ? (
                        props.direction ? (
                          <KeyboardArrowUp style={{ position:"relative", top:"5px" }} fontSize="small" />
                        ) : (
                          <KeyboardArrowDown style={{ position:"relative", top:"5px" }} fontSize="small" />
                        )
                      ) : (
                        <UnfoldMore fontSize="small" style={{ position:"relative", top:"5px" }} />
                      )}
                    </StyledTableCell>
                ) : index === 0 ? (
                  <StyledTableCell>{col.name}</StyledTableCell>
                ) : (
                  <StyledTableCell align="">{col.name}</StyledTableCell>
                )
              )}
            </TableRow>
          </TableHead>
          {props.loading ? (
            <TableBody
              sx={{ position: "relative", height: "465px", overflow: "hidden" }}
            >
              <Box p={3} sx={{ position: "absolute", width: "100%" }}>
                <Skeleton height={60} />
                <Skeleton height={60} />
                <Skeleton height={60} />
                <Skeleton height={60} />
                <Skeleton height={60} />
                <Skeleton height={60} />
                <Skeleton height={60} />
              </Box>
            </TableBody>
          ) : (
            <TableBody>
              {props.rows.length ? (
                props.rows
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <StyledTableRow
                      key={Math.random()}
                      //onClick={() => props.handleView(row)}
                      // style={{ cursor: "pointer" }}
                    >
                      {props.columns.map((col, index) =>
                        col.id === "image" ? (
                          index === 0 ? (
                            <StyledTableCell component="th" scope="row">
                              <img
                                src={row.image}
                                style={{
                                  width: "100px",
                                  height: "100px",
                                  objectFit: "cover",
                                }}
                                alt={row.firstname}
                              />
                            </StyledTableCell>
                          ) : (
                            <StyledTableCell align="">
                              <img
                                src={row.image}
                                style={{
                                  width: "100px",
                                  height: "100px",
                                  objectFit: "cover",
                                }}
                                alt={row.firstname}
                              />
                            </StyledTableCell>
                          )
                        // ) : index === 0 ? (
                        //   col.id === "Id" && (
                        //     <StyledTableCell component="th" scope="row">
                        //       {row[col.id]}
                        //     </StyledTableCell>
                        //   )
                        ) : col.id === "unitPrice" ? (
                          <StyledTableCell align="" sx={{ minWidth: "250px" }}>
                            {!showPriceEdit ? (
                          <span>
                            ${row.unitPrice}
                            <IconButton
                            disabled
                              onClick={() => {
                                setShowPriceEdit(true);
                                setCurrentLineID(row.orderproductID);
                                setLineOrderPrice(row.unitPrice)
                              }}
                            >
                              <Edit fontSize="small" color="primary" />
                            </IconButton>
                          </span>
                        ) : row.orderproductID !== currentLineID ? (
                          <span>
                            ${row.unitPrice}
                            <IconButton
                            disabled
                              onClick={() => {
                                setShowPriceEdit(true);
                                setCurrentLineID(row.orderproductID);
                                setLineOrderPrice(row.unitPrice)
                              }}
                            >
                              <Edit fontSize="small" color="primary" />
                            </IconButton>
                          </span>
                        ) : (
                          <Box display={"flex"} flexDirection="row">
                            <TextField
                              autoFocus
                              name="lineOrderPrice"
                              variant="outlined"
                              value={lineOrderPrice}
                              onChange={(e) =>setLineOrderPrice(e.target.value)}
                            />
                            {priceLoading ? (
                              <CircularProgress
                                style={{
                                  height: "25px",
                                  width: "25px",
                                  marginLeft: "10px",
                                  position: "relative",
                                  top: "10px",
                                }}
                              />
                            ) : (
                              <Box display={"flex"} flexDirection="column">
                                <Tooltip title="Back">
                                  <Button
                                    variant="contained"
                                    size="small"
                                    color="primary"
                                    onClick={() => setShowPriceEdit(false)}
                                    sx={{
                                      marginLeft: "10px",
                                      marginBottom: "5px",
                                    }}
                                  >
                                    <ArrowBack fontSize="small" />
                                  </Button>
                                </Tooltip>
                                <Tooltip title="Change Invoice Price">
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    onClick={() => handleChangeLinePrice(row)}
                                    sx={{ marginLeft: "10px" }}
                                  >
                                    <Done fontSize="small" />
                                  </Button>
                                </Tooltip>
                              </Box>
                            )}
                          </Box>
                        )}
                          </StyledTableCell>
                        ) : (
                          <StyledTableCell
                            align=""
                            onClick={() => props.handleView(row)}
                          >
                            {(col.id === "msrp") ? (
                              "$" + row[col.id]
                            ): col.id === "order_created" ? (
                              moment(row[col.id]).format(
                                "ddd, MMM Do YYYY, h:mm:ss a"
                              )
                            )  :
                            col.id === "status" ?
                              (row[col.id] === "Received" ? "New" : row[col.id])
                            : col.id === "consumerOrderDate" ? (
                              row[col.id] !== "0000-00-00 00:00:00" ? (
                                moment(row[col.id]).format(
                                  "ddd, MMM Do YYYY, h:mm:ss a"
                                )
                              ) : (
                                "-"
                              )
                            ) : (
                              row[col.id] || "-"
                            )}
                          </StyledTableCell>
                        )
                      )}
                    </StyledTableRow>
                  ))
              ) : (
                <TableRow sx={{ position: "relative", height: "50px" }}>
                  <TableCell
                    sx={{
                      position: "absolute",
                      right: "50%",
                      borderBottom: "none",
                    }}
                  >
                    No Records Found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          )}

          {props.footer !== false && (
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[20, 50, 70, 100]}
                  rowsPerPage={props.rowsPerPage}
                  page={props.page}
                  count={props.total && props.total}
                  SelectProps={{
                    native: true,
                  }}
                  labelDisplayedRows={() =>
                    `${props.fromTable !== null ? props.fromTable : "0"} - ${
                      props.toTable !== null ? props.toTable : "0"
                    } to ${props.total}`
                  }
                  onPageChange={props.handleChangePage}
                  onRowsPerPageChange={props.handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={handleCloseSnack}
      >
        <Alert
          onClose={handleCloseSnack}
          severity={messageState}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </Paper>
  );
}
