import { ArrowForward } from "@mui/icons-material";
import { Box, Button, Grid } from "@mui/material";
import React from "react";

const FilterButton = ({ onClick }) => {
  return (
    <Grid item xs={12}>
      <Box textAlign={"right"}>
        <Button variant="contained" color="primary" onClick={onClick}>
          Filter <ArrowForward fontSize="small" style={{ marginLeft: "5px" }} />
        </Button>
      </Box>
    </Grid>
  );
};

export default FilterButton;
