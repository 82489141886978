import { createTheme } from "@mui/material";
import { default as ColorPalette } from "./colors";
import MuiDataGridOverrides from "./MuiDataGridOverrides";

const theme = createTheme({
  components: {
    MuiDataGrid: MuiDataGridOverrides,
  },
  palette: ColorPalette,
  typography: {
    fontFamily: "sans-serif",
  },
});

export default theme;
