import React, { useEffect, useState } from "react";
import { ArrowForward, Close, FilterList } from "@mui/icons-material";
import TableComponent from "../TableComponent";
import { httpclient } from "../../../../utils/httpClient";
import {
  AppBar,
  Box,
  Button,
  Card,
  Collapse,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  styled,
  TextField,
  useTheme,
} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";

const columns = [
  { id: "group1ID", name: "ID" },
  { id: "groupName1", name: "Group Name" },
  { id: "groupNumber1", name: "Group Number" },
  { id: "purchase_GL_Code", name: "Purchase GL Code" },
  { id: "sales_GL_Code", name: "Sales GL Code" },
  { id: "group2SequenceNumber", name: "Sequence Number" },
  { id: "pendingProcess", name: "Pending Process" },
  { id: "active", name: "Active" },
  // { id: "actions", name: "Actions" },
];

const columns2 = [
    { id: "group2ID", name: "ID" },
    { id: "groupName2", name: "Group Name" },
    { id: "groupNumber2", name: "Group Number" },
    // { id: "purchase_GL_Code", name: "Purchase GL Code" },
    // { id: "sales_GL_Code", name: "Sales GL Code" },
    // { id: "group2SequenceNumber", name: "Sequence Number" },
    // { id: "pendingProcess", name: "Pending Process" },
    { id: "status", name: "Active" },
    // { id: "actions", name: "Actions" },
  ];

const FilteredBox = styled(Box)(({ theme }) => ({
  background: "#f9f9f9",
  padding: "5px",
  "& p": {
    margin: "0",
    marginRight: "10px",
    display: "inline-block",
  },
  "& svg": {
    fontSize: "15px",
    cursor: "pointer",
    position: "relative",
    top: "3px",
    background: theme.palette.primary.dark,
    color: "#fff",
    borderRadius: "50%",
    padding: "2px",
    marginLeft: "2px",
  },
}));

const Header = styled("div")(({ theme }) => ({
  "& h1": {
    color: theme.palette.primary.dark,
    margin: "0",
  },
}));

const AppBarTabs = styled(AppBar)(({theme}) => ({
  background:"#fff",
  color:theme.palette.primary.dark,
  borderRadius:"4px",
  boxShadow:"none",
}))

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const configRowPerPage = JSON.parse(localStorage.getItem("configRowPerPage"));

const NewSystemStockGroups = () => {
  const theme = useTheme();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //   const [openViewDialog, setOpenViewDialog] = useState(false);
  const [viewDetails, setViewDetails] = useState({});
  const [rows, setRows] = useState([]);
  const [api, setApi] = useState("/new-system/stock-group1");
  const [loading, setLoading] = useState(false);
  const [direction, setDirection] = useState(false);
  const [currentColumn, setCurrentColumn] = useState("");
  const [page, setPage] = useState(1);
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(
    configRowPerPage && configRowPerPage
      ? configRowPerPage && configRowPerPage
      : 20
  );

  const [rowsPerPage, setRowsPerPage] = useState(
    configRowPerPage && configRowPerPage
      ? configRowPerPage && configRowPerPage
      : 20
  );
  const [total, setTotal] = useState("");
  //   const [filterOpen, setFilterOpen] = useState(false);

  //   const [filterData, setFilterData] = useState({
  //     branchCode: "",
  //     branchName: "",
  //     inboundBranch: "",
  //     pendingProcess: "",
  //     active: "",
  //     lastModified: "",
  //     remove: false
  //   });

  //   const [submittedData, setSubmittedData] = useState({
  //     branchCode: "",
  //     branchName: "",
  //     inboundBranch: false,
  //     pendingProcess: "",
  //     active: "",
  //     lastModified: "",
  //     submit: false,
  //   });

  useEffect(() => {
    if(value === 0){
        getCurrentStockGroup1();
        setApi("/new-system/stock-group1");
    }else{
        getCurrentStockGroup2();
        setApi("/new-system/stock-group2")
    }
  }, [value]);


  const getCurrentStockGroup1 = () => {
    setLoading(true);
    httpclient.get("/new-system/stock-group1").then(({ data }) => {
      if (data.data) {
        setRows(data.data);
        setTotal(data.meta.total);
        setRowsPerPage(parseInt(data.meta.per_page));
        setPage(data.meta.current_page);
        setFrom(data.meta.from);
        setTo(data.meta.to);
        setLoading(false);
      }
    }).catch(err => {
      if(err.response.status === 401){
        localStorage.clear();
        window.location = "/login";
      }
    })
  };
  const getCurrentStockGroup2 = () => {
    setLoading(true);
    httpclient.get("/new-system/stock-group2").then(({ data }) => {
      if (data.data) {
        setRows(data.data);
        setTotal(data.meta.total);
        setRowsPerPage(parseInt(data.meta.per_page));
        setPage(data.meta.current_page);
        setFrom(data.meta.from);
        setTo(data.meta.to);
        setLoading(false);
      }
    }).catch(err => {
      if(err.response.status === 401){
        localStorage.clear();
        window.location = "/login";
      }
    })
  };


//   const handleView = (row) => {
//     setOpenViewDialog(true);
//     setViewDetails(row);
//   };

//   const sendDetails = (callback) => {
//     if (callback.open === false) {
//       setOpenViewDialog(false);
//       setViewDetails({});
//     }
//   };

  //   const handleFilter = () => {
  //     setSubmittedData({
  //       ...submittedData,
  //       branchCode: filterData.branchCode,
  //       branchName: filterData.branchName,
  //       inboundBranch: filterData.inboundBranch,
  //       pendingProcess: filterData.pendingProcess,
  //       active: filterData.active,
  //       lastModified: filterData.lastModified,
  //     });
  //   };

  //   const hadleFilterOpen = () => {
  //     setFilterOpen((prev) => !prev);
  //   };

  //   const handleChangeFilter = (e) => {
  //     const { name, value } = e.target;
  //     setFilterData({
  //       ...filterData,
  //       [name]: value,
  //     });
  //   };

  //   const handleRemove = (data) =>{

  //     setFilterData({
  //       ...filterData,
  //       [data]: ""
  //     })

  //     setSubmittedData({
  //       ...submittedData,
  //       [data]: ""
  //     })
  //   }

  const handleSort = (column) => {
    setDirection((prevDirection) => !prevDirection);
    setCurrentColumn(column);
    httpclient
      .get(
        `${api}?sort_by=${column}&direction=${
          !direction ? "asc" : "desc"
        }&pagination=${rowsPerPage}`
      )
      .then(({ data }) => {
        if (data) {
          setRows(data.data);
          setTotal(data.meta.total);
          setRowsPerPage(parseInt(data.meta.per_page));
          setPage(data.meta.current_page);
          setFrom(data.meta.from);
          setTo(data.meta.to);
          setLoading(false);
        }
      });
  };

  const handleChangePage = (e, page) => {
    setLoading(true);
    httpclient
      .get(`${api}?sort_by=${currentColumn}&direction=${direction ? "asc" : "desc"}&pagination=${rowsPerPage}&page=${page}`)
      .then(({ data }) => {
        if (data) {
          setRows(data.data);
          setTotal(data.meta.total);
          setRowsPerPage(parseInt(data.meta.per_page));
          setPage(data.meta.current_page);
          setFrom(data.meta.from);
          setTo(data.meta.to);
          setLoading(false);
        }
      });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    // setLoading(true);

    localStorage.setItem("configRowPerPage", event.target.value);

    httpclient
      .get(`${api}?sort_by=${currentColumn}&direction=${direction ? "asc" : "desc"}&pagination=${+event.target.value}&page=${1}`)
      .then(({ data }) => {
        setLoading(true);
        if (data.success === true) {
          setRows(data.data);
          setTotal(data.meta.total);
          setRowsPerPage(parseInt(data.meta.per_page));
          setPage(data.meta.current_page);
          setFrom(data.meta.from);
          setTo(data.meta.to);
          setLoading(false);
        }
      });
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item md={8} xs={12}>
          <Header>
            <h1>List New System Groups</h1>
          </Header>
        </Grid>
        <Grid
          item
          md={4}
          xs={12}
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
        >
          {/* <Button color="primary" variant="contained" onClick={hadleFilterOpen}>
            Filter <FilterList style={{ marginLeft: "5px" }} fontSize="small" />
          </Button> */}
        </Grid>

        {/* Filter */}
        {/* <Grid item xs={12}>
          <Collapse in={filterOpen}>
            <Card>
              <Box p={4}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <InputLabel>Branch Code</InputLabel>
                    <TextField
                      variant="outlined"
                      name="branchCode"
                      value={filterData.branchCode}
                      onChange={handleChangeFilter}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <InputLabel>Branch Name</InputLabel>
                    <TextField
                      variant="outlined"
                      name="branchName"
                      value={filterData.branchName}
                      onChange={handleChangeFilter}
                      fullWidth
                    />
                  </Grid>
                 
                  <Grid item xs={12} md={4}>
                    <InputLabel>Inbound Branch</InputLabel>
                    <FormControl fullWidth>
                      <Select
                        value={filterData.inboundBranch}
                        // label="Exclusive Pending"
                        name="inboundBranch"
                        onChange={handleChangeFilter}
                      >
                        <MenuItem value={""}>Select</MenuItem>
                        <MenuItem value={"1"}>Yes</MenuItem>
                        <MenuItem value={"0"}>No</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <InputLabel>Pending Process</InputLabel>
                    <FormControl fullWidth>
                      <Select
                        value={filterData.pendingProcess}
                        // label="Exclusive Pending"
                        name="pendingProcess"
                        onChange={handleChangeFilter}
                      >
                        <MenuItem value={""}>Select</MenuItem>
                        <MenuItem value={"1"}>Yes</MenuItem>
                        <MenuItem value={"0"}>No</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <InputLabel>Active</InputLabel>
                    <FormControl fullWidth>
                      <Select
                        value={filterData.active}
                        name="active"
                        // label="Active"
                        onChange={handleChangeFilter}
                      >
                        <MenuItem value={""}>Select</MenuItem>
                        <MenuItem value={"1"}>Yes</MenuItem>
                        <MenuItem value={"0"}>No</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <Box textAlign={"right"}>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleFilter}
                      >
                        Filter{" "}
                        <ArrowForward
                          fontSize="small"
                          style={{ marginLeft: "5px" }}
                        />
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </Collapse>
        </Grid>

        {(submittedData.branchCode || submittedData.branchName || submittedData.pendingProcess || submittedData.inboundBranch || submittedData.active) ? (
          <Grid item xs={12}>
          <FilteredBox>
            <span>Filtered: </span>
            {submittedData.branchCode && (
              <p>
              <span>Branch Code: {submittedData.branchCode}</span>
              <Close fontSize="small" onClick={() => handleRemove("branchCode")} />
              </p>
            )}
            {submittedData.branchName && (
              <p>
              <span>Branch Name: {submittedData.branchName}</span>
              <Close fontSize="small" onClick={() => handleRemove("branchName")} />
              </p>
            )}
    
            {submittedData.inboundBranch && (
              <p>
              <span>Inbound Branch: {submittedData.inboundBranch === "1" ? "Yes" : "No"}</span>
              <Close fontSize="small" onClick={() => handleRemove("inboundBranch")} />
              </p>
            )}

            {submittedData.pendingProcess && (
              <p>
              <span>Pending Process: {submittedData.pendingProcess === "1" ? "Yes" : "No"}</span>
              <Close fontSize="small" onClick={() => handleRemove("pendingProcess")} />
              </p>
            )}
            {submittedData.active && (
              <p>
              <span>Active: {submittedData.active === "1" ? "Yes" : "No"}</span>
              <Close fontSize="small" onClick={() => handleRemove("active")} />
              </p>
            )}
          </FilteredBox>
        </Grid>  
        ):
          <Box></Box>
        } */}
        {/* Filter */}

        <Grid item xs={12}>
          <AppBarTabs position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="inherit"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab label="Group 1" {...a11yProps(0)} />
            <Tab label="Group 2" {...a11yProps(1)} />
          </Tabs>
          </AppBarTabs>
        </Grid>

        <Grid item xs={12}>
          
          <TabPanel value={value} index={0} dir={theme.direction}>
            <TableComponent
              columns={columns}
              rows={rows}
              sort={true}
            //   handleView={handleView}
              handleSort={handleSort}
              loading={loading}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleChangePage={handleChangePage}
              direction={direction}
              currentColumn={currentColumn}
              page={page}
              total={total && total}
              fromTable={from}
              toTable={to}
              rowsPerPage={rowsPerPage}
            />
          </TabPanel>
          
          <TabPanel value={value} index={1} dir={theme.direction} >
          <TableComponent
              columns={columns2}
              rows={rows}
              sort={true}
            //   handleView={handleView}
              handleSort={handleSort}
              loading={loading}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleChangePage={handleChangePage}
              direction={direction}
              currentColumn={currentColumn}
              page={page}
              total={total && total}
              fromTable={from}
              toTable={to}
              rowsPerPage={rowsPerPage}
            />
          </TabPanel>
        </Grid>
      </Grid>
    </div>
  );
};

export default NewSystemStockGroups;
