import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Grid,
  InputLabel,
  Snackbar,
  TextField,
  styled,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { httpclient } from "../../../../utils/httpClient";
import Dialogue from "../Dialogue";
import { Sync } from "@mui/icons-material";
import featureServer from "../../../../utils/featureServer";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const Header = styled("div")(({ theme }) => ({
  "& h1": {
    color: theme.palette.primary.dark,
    margin: "0",
  },
}));

const ResyncFromAx = () => {
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [message, setMessage] = useState("");
  const [messageState, setMessageState] = useState("");
  const [syncList, setSyncList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [keyLoading, setKeyLoading] = useState(false);
  const [keyLoading1, setKeyLoading1] = useState(false);
  const [sync, setSync] = useState();
  const [customerID, setCustomerID] = useState("");
  const [customerName, setCustomerName] = useState("");

  useEffect(() => {
    getSync();
  }, []);

  const getSync = () => {
    setLoading(true);
    httpclient.get(`current/company-account/all`).then(({ data }) => {
      if (data.success) {
        setLoading(false);
        setSyncList(data.data);
        // console.log("sysnc-list", syncList)
      } else {
        setLoading(false);
      }
    });
  };

  const handleDialogue = (event, sync) => {
    const checked = event.target.checked;
    const updatedStatus = checked ? true : false;
    setOpenDialog(true);
    setSync({ ...sync, status: updatedStatus });
    //setPassword(password);
  };

  const sendToConfirm = (call, sync) => {
    // console.log("propss", sync);
    if (call.open === false) {
      setOpenDialog(false);
    }
    if (call.success === true) {
      setLoading(true);
      httpclient.post(`company-stock-relation/${customerID}`).then(({ data }) => {
        if (data.success) {
          setOpen(true);
          setMessageState("success");
          setMessage(data.message);
          setLoading(false);
          setCustomerID("");
          setCustomerName("");
          //getSync();
        } else {
          setLoading(false);
          setOpen(true);
          setMessageState("error");
          setMessage(data.error);
        }
      });
    }
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleChange = (e) => {
    setCustomerID("");
    setCustomerName(e.target.value);
  }

  const handleChangeCustomer = (value) => {
    //console.log("value", value)
    setCustomerID(value !== null ? `${value.id}` : "");
    setCustomerName(value !== null ? `${value.name}` : "");

  };
  // console.log("customerid", customerID);
  // console.log("customername", customerName);

  const handleSyncPrice = () => {
    setKeyLoading(true);
    httpclient
      .post(
        `resync-stock-price`
      )
      .then(({ data }) => {
        if (data.success) {
          setOpen(true);
          setMessageState("success");
          setMessage(data.message);
          setKeyLoading(false);
        } else {
          setKeyLoading(false);
          setOpen(true);
          setMessageState("error");
          setMessage(data.error);
        }
      });
  };

  const handleSyncSOH = () => {
    setKeyLoading1(true);
    httpclient
      .post(
        `resync-stock-soh`
      )
      .then(({ data }) => {
        if (data.success) {
          setOpen(true);
          setMessageState("success");
          setMessage(data.message);
          setKeyLoading1(false);
        } else {
          setKeyLoading1(false);
          setOpen(true);
          setMessageState("error");
          setMessage(data.error);
        }
      });
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item md={8} xs={12}>
          <Header>
            <h1>Resync Stock Customer Relation</h1>
          </Header>
        </Grid>
        <Grid item xs={12}>
          <FormGroup>
            <div className="grid-block-content">
              <div className="grid-item title1">Sync Customer</div>
              {loading ? (
                <Box p={2}>
                  <CircularProgress color="secondary" />
                </Box>
              ) : (
                <Box p={2} style={{ background: "#fff" }}>
                  <InputLabel>Customer</InputLabel>
                  <Autocomplete
                    disablePortal
                    id="customerName"
                    options={syncList}
                    onChange={(event, newValue) => {
                      handleChangeCustomer(newValue);
                    }}
                    inputValue={customerName}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        onChange={handleChange}
                        value={customerName}
                        variant="outlined"
                        name="customerName"
                      />
                    )}
                  />

                  <Box pt={2} pb={2} textAlign={"right"}>
                    <Button color="primary" variant="contained" disabled={customerID === ""} onClick={handleDialogue}>
                      Sync
                    </Button>
                  </Box>

                </Box>
              )}
            </div>

          </FormGroup>
        </Grid>
        {featureServer &&
        <>
        <Grid item md={8} xs={12}>
          <Header>
            <Box pt={2} pb={2} textAlign={"left"}>
              {keyLoading ? (
                <CircularProgress
                  style={{
                    height: "25px",
                    width: "25px",
                    marginLeft: "10px",
                    position: "relative",

                  }}
                />
              ) : (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleSyncPrice()}
                    disableElevation
                  >

                    Resync Price
                    <Sync
                      fontSize="small"
                      style={{ marginLeft: "5px" }}
                    />

                  </Button>

                </>
              )}
            </Box>
          </Header>
        </Grid>
        <Grid item md={8} xs={12}>
          <Header>
            <Box pt={2} pb={2} textAlign={"left"}>
              {keyLoading1 ? (
                <CircularProgress
                  style={{
                    height: "25px",
                    width: "25px",
                    marginLeft: "10px",
                    position: "relative",

                  }}
                />
              ) : (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleSyncSOH()}
                    disableElevation
                  >

                    Resync SOH
                    <Sync
                      fontSize="small"
                      style={{ marginLeft: "5px" }}
                    />

                  </Button>

                </>
              )}
            </Box>
          </Header>
        </Grid>
        </>
}
      </Grid>

      {openDialog && (
        <Dialogue sendToConfirm={(call) => sendToConfirm(call, sync)} />
      )}
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={handleCloseSnack}
      >
        <Alert
          onClose={handleCloseSnack}
          severity={messageState}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
      <style jsx>{`
        .grid-block-content {
          display: grid;
          max-width: 50%;
          grid-template-columns: auto;
          gap: 1px solid #ccc;
          font-family: "Trebechut" sans-serif;
          font-size: 14px;
          font-weight: bold;
        }

        .grid-item.title {
          //border-right: 1px solid #f1f1f1;
          border-bottom: 1px solid #f1f1f1;
          background-color: #ffffff;
          padding: 16px;

          text-align: center;
        }

        .grid-item.title1 {
          //border-right: 1px solid #f1f1f1;
          border-bottom: 1px solid #f1f1f1;
          background-color: ${window.location.hostname ===
          "ubatemp.retailcare.com.au"
          ? "#9D182D"
          : "#3f51b5"};
          color: #ffffff;
          padding: 16px;
          font-size: 16px;
          text-align: center;
        }
      `}</style>
    </div>
  );
};

export default ResyncFromAx;

