import { Close } from "@mui/icons-material";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    styled,
    Grid,
    Skeleton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import moment from "moment/moment";
import ViewMoreDetailsOrder from "./ViewMoreDetailsOrder";
import { httpclient } from "../../../../utils/httpClient";

const StyledHeaderTitle = styled(DialogTitle)(({ theme }) => ({
    background: theme.palette.primary.main,
    color: "#fff",
    position: "relative",
    "& button": {
        position: "absolute",
        right: "15px",
        top: "15px",
        color: "#fff",
    },
}));

const FlexContent = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    fontSize: "17px",
    marginBottom: "10px",
    alignItems: "flex-start",
}));

const FlexInnerTitle = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    minWidth: "210px",
    maxWidth: "220px",
    fontWeight: "600",
}));

const Values = styled("div")(({ theme }) => ({
    marginLeft: "15px",
    fontWeight: "500",
    color: theme.palette.primary.dark,
    "& input": {
        padding: "10px",
        width: "150px",
    },
    "& .MuiSelect-select": {
        padding: "10px",
    },
    "& button": {
        padding: "11px !important",
        minWidth: "40px",
    },
}));


const ViewMoreDetails = (props) => {
    const [dialogDetails, setDialogDetails] = useState({
        open: true,
        success: false,
    });

    const [results, setResults] = useState([]);

    const [openViewDialog, setOpenViewDialog] = useState(false);
    const [viewDetails, setViewDetails] = useState({});

    const [singleLoading, setSingleLoading] = useState(false);

    useEffect(() => {
        props.sendDetails(dialogDetails);
    }, [dialogDetails]);

    useEffect(() => {
        setResults(props.viewDetails.results);
    }, [props.viewDetails]);
    console.log("r", results);

    const handleClose = () => {
        setDialogDetails({
            ...dialogDetails,
            open: false,
        });
    };

    const handleYes = () => {
        setDialogDetails({
            ...dialogDetails,
            open: false,
            success: true,
        });
    };

    const handleView = (row) => {
        setSingleLoading(true);
        setOpenViewDialog(true);
        httpclient
            .get(`workflow/orderDetail/${row}`)
            .then(({ data }) => {
                if (data) {
                    setViewDetails(data.orderDetail);
                    setSingleLoading(false);
                }
            })
    };

    const sendDetails = (callback) => {
        if (callback.open === false) {
            setOpenViewDialog(false);
            setViewDetails({});
        }
    };




    return (
        <div>
            <Dialog
                open={dialogDetails.open}
                onClose={handleClose}
                maxWidth="md"
                fullWidth
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <StyledHeaderTitle id="alert-dialog-title">
                    <div>{`View Details (${props.viewDetails?.name || "-"
                        })`}</div>
                    <IconButton onClick={handleClose}>
                        <Close />
                    </IconButton>
                </StyledHeaderTitle>
                {props.singleLoading ? (
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Box p={3} sx={{ width: "100%" }}>
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                </Box>
                            </Grid>


                        </Grid>
                    </DialogContent>
                ) : (
                    <DialogContent>
                        <Box pt={3}>
                            <Grid container spacing={2}>
                                <Grid item sx={12} md={12}>
                                    {/* <FlexContent>
                                    <FlexInnerTitle>
                                        <span>Code</span> <span> : </span>
                                    </FlexInnerTitle>
                                    <Values>
                                        {props.viewDetails?.code || "-"}
                                    </Values>
                                </FlexContent> */}

                                    {/* <FlexContent>
                                        <FlexInnerTitle>
                                            <span>Name</span> <span> : </span>
                                        </FlexInnerTitle>
                                        <Values>
                                            {props.viewDetails?.name || "-"}
                                        </Values>
                                    </FlexContent> */}

                                    {/* <FlexContent>
                                    <FlexInnerTitle>
                                        <span>Type</span> <span> : </span>
                                    </FlexInnerTitle>
                                    <Values>
                                        {props.viewDetails?.type || "-"}
                                    </Values>
                                </FlexContent> */}

                                    <FlexContent>
                                        <FlexInnerTitle>
                                            <span>Status</span> <span> : </span>
                                        </FlexInnerTitle>
                                        <Values>
                                            {(props.viewDetails?.status === "error" || props.viewDetails?.status === "Error") ? <span style={{ color: "red", fontWeight: "600" }}>Error</span> :
                                                props.viewDetails?.status === "Pending" ? <span style={{ color: "#F7B500", fontWeight: "600" }}>Pending</span> :
                                                    props.viewDetails?.status === "Complete" ? <span style={{ color: "green", fontWeight: "600" }}>Completed</span> :
                                                        "-"
                                            }
                                        </Values>
                                    </FlexContent>

                                    <FlexContent>
                                        <FlexInnerTitle>
                                            <span>Details</span> <span> : </span>
                                        </FlexInnerTitle>
                                        <Values>
                                            {props.viewDetails?.details || "-"}
                                        </Values>
                                    </FlexContent>

                                    <FlexContent>
                                        <FlexInnerTitle>
                                            <span>Message</span> <span> : </span>
                                        </FlexInnerTitle>
                                        <Values>
                                            {props.viewDetails?.message || "-"}
                                            {results && results.length > 0
                                                ? results.map((r, index) => (
                                                    <React.Fragment key={index}>
                                                        {props.viewDetails?.type === "order" ? (
                                                            <a
                                                                href="#"
                                                                onClick={(e) => {
                                                                    e.preventDefault(); // Prevent the default anchor behavior
                                                                    handleView(r); // Call your function with r as a parameter
                                                                }}
                                                            >
                                                                {r}
                                                            </a>
                                                        ) : (
                                                            <span>{r}</span>
                                                        )}
                                                        {index < results.length - 1 && ", "}
                                                    </React.Fragment>
                                                ))
                                                : ""}
                                        </Values>
                                    </FlexContent>

                                    <FlexContent>
                                        <FlexInnerTitle>
                                            <span> Last Error </span> <span> : </span>
                                        </FlexInnerTitle>
                                        <Values>
                                            {props.viewDetails?.lastError || "-"}
                                        </Values>
                                    </FlexContent>

                                    <FlexContent>
                                        <FlexInnerTitle>
                                            <span>Last Run</span>{" "}
                                            <span> : </span>
                                        </FlexInnerTitle>
                                        <Values>
                                            {props.viewDetails?.lastRun || "-"}
                                        </Values>
                                    </FlexContent>

                                    <FlexContent>
                                        <FlexInnerTitle>
                                            <span>Last Updated</span> <span> : </span>
                                        </FlexInnerTitle>
                                        <Values>
                                            {props.viewDetails?.lastUpdated || "-"}
                                        </Values>
                                    </FlexContent>
                                </Grid>

                            </Grid>
                        </Box>
                    </DialogContent>
                )}
                <DialogActions sx={{ margin: "5px 10px", justifyContent: "right" }}>
                    <Button onClick={handleClose} variant="outlined" autoFocus>
                        Close
                    </Button>

                </DialogActions>

            </Dialog>

            {openViewDialog && (
                <ViewMoreDetailsOrder
                    singleLoading={singleLoading}
                    viewDetails={viewDetails}
                    sendDetails={sendDetails}
                />
            )}
        </div>
    );
};

export default ViewMoreDetails;
