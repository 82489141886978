import { Tooltip } from "@mui/material";

export const colDataExtendAttributes = (
  columnObject,
  defaultFlex = 1,
  defaultEditable = false
) =>
  columnObject.map((item) => ({
    ...item,
    flex: item.width ? undefined : item.flex || defaultFlex,
    width: item.width,
    editable: item.editable || defaultEditable,
    renderHeader: item.tooltip
      ? (params) => (
          <Tooltip title={item.tooltip} arrow>
            <span> {params.colDef.headerName}</span>
          </Tooltip>
        )
      : null,
  }));
