import {
  Dns,
  GppMaybe,
  Groups,
  Inventory,
  LocalShipping,
  Navigation,
  People,
  Sync,
  TempleHindu,
  ViewStream,
} from "@mui/icons-material";
import PATH from "../../../Routing/PATHS";

const onlineSubMenus = [
  {
    menu: "Current",
    link: null,
    icon: <Dns />,
    multiLevel: true,
    multiMenus: [
      {
        menuName: "Current Stock",
        link: PATH.ONLINE_CURRENT_STOCK,
      },
      {
        menuName: "Current Location",
        link: PATH.ONLINE_CURRENT_BRANCH,
      },
      {
        menuName: "Current Supplier",
        link: PATH.ONLINE_CURRENT_SUPPLIER,
        // link: "/supplier/current_supplier",
      },
      {
        menuName: "Current Groups",
        link: PATH.ONLINE_CURRENT_STOCKGROUP,
        // link: "/groups/current_stock_groups",
      },
      {
        menuName: "Current Price Policy",
        link: PATH.ONLINE_CURRENT_POLICY,
        // link: "/current/price_policy",
      },
    ],
  },
  {
    menu: "New System",
    link: null,
    icon: <Inventory />,
    multiLevel: true,
    multiMenus: [
      {
        menuName: "New System Stock",
        link: PATH.ONLINE_NEW_STOCK,
        // link: "/stock/new_system_stock",
      },
      {
        menuName: "New System Location",
        link: PATH.ONLINE_NEW_BRANCH,
        // link: "/stock/new_system_branch",
      },
      {
        menuName: "New System Brand",
        link: PATH.ONLINE_NEW_BRAND,
        // link: "/stock/new_system_branch",
      },
      {
        menuName: "New System Supplier",
        link: PATH.ONLINE_NEW_SUPPLIER,
        // link: "/supplier/new_system_supplier",
      },
      {
        menuName: "New System Groups",
        link: PATH.ONLINE_NEW_STOCKGROUP,
        // link: "/groups/new_system_stock_groups",
      },
      {
        menuName: "New System Price Policy",
        link: PATH.ONLINE_NEW_PRICE,
        // link: "/new-system/price_policy",
      },
    ],
  },
  {
    menu: "Orders",
    link: null,
    icon: <ViewStream />,
    multiLevel: true,
    multiMenus: [
      {
        menuName: "All Orders",
        link: PATH.ONLINE_ORDERS_ALL,
        // link: "/orders/list_orders"
      },
      {
        menuName: "CSV Export",
        link: PATH.ONLINE_ORDERS_EXPORT,
        // link: "/orders/csv_export"
      },
    ],
  },
  {
    menu: "Exception Report",
    link: null,
    icon: <GppMaybe />,
    multiLevel: true,
    multiMenus: [
      {
        menuName: "Price Orders",
        link: PATH.ONLINE_EXCEPTION_PRICEORDER,
        // link: "/orders/list_orders_price_exception",
      },
      {
        menuName: "Zero Price",
        link: PATH.ONLINE_EXCEPTION_ZERO,
        // link: "/stock/exceptions/zero_price",
      },
      {
        menuName: "Long Description",
        link: PATH.ONLINE_EXCEPTION_LONGDESCRIPTION,
        // link: "/stock/exceptions/title_exception",
      },
      {
        menuName: "Category",
        link: PATH.ONLINE_EXCEPTION_CATEGORY,
        // link: "/stock/exceptions/category_exception",
      },
      {
        menuName: "Image Missing",
        link: PATH.ONLINE_EXCEPTION_IMAGE,
        // link: "/stock/exceptions/image_missing",
      },
      {
        menuName: "Sync Status",
        link: PATH.ONLINE_EXCEPTION_SYNC,
        // link: "/stock/exceptions/sync_status",
      },
    ],
  },

  {
    menu: "Shipping Class",
    link: null,
    icon: <LocalShipping />,
    multiLevel: true,
    multiMenus: [
      {
        menuName: "Empty Shipping Class",
        link: PATH.ONLINE_NEW_STOCK,
        // link: "/stock/new_system_stock",
        state: "empty",
      },
      {
        menuName: "Non-Empty Shipping Class",
        link: PATH.ONLINE_NEW_STOCK,
        // link: "/stock/new_system_stock",
        state: "nonEmpty",
      },
    ],
  },
  {
    menu: "Accounts",
    link: null,
    icon: <People />,
    multiLevel: true,
    multiMenus: [
      {
        menuName: "Current Customers",
        link: PATH.ONLINE_ACCOUNT_CURRENT,
        // link: "/account/current_customers",
      },
    ],
  },
  // {
  //   menu: "Temple and Webster",
  //   link: null,
  //   icon: <TempleHindu />,
  //   multiLevel: true,
  //   multiMenus: [
  //     {
  //       menuName: "SOH",
  //       link: PATH.ONLINE_TEMPLE_SOH,
  //       // link: "/account/current_customers",
  //     },

  //     {
  //       menuName: "Orders By Location",
  //       link: PATH.ONLINE_TEMPLE_ORDERBYBRANCH,
  //       // link: "/account/current_customers",
  //     },
  //     {
  //       menuName: "Upload Shipping Label",
  //       link: PATH.ONLINE_TEMPLE_UPLOADSHIPPINGLABEL,
  //       // link: "/account/current_customers",
  //     },
  //     {
  //       menuName: "Upload CSV",
  //       link: PATH.ONLINE_TEMPLE_UPLOADORDER,
  //       // link: "/account/current_customers",
  //     },
  //     {
  //       menuName: "TW Despatch Report",
  //       link: PATH.ONLINE_TW_ORDERS_TESTING,
  //       // link: "/account/current_customers",
  //     },
  //   ],
  // },
  {
    menu: "Resync",
    link: PATH.ONLINE_RESYNCSKU,
    // link: "/sync/resync_from_ax",
    icon: <Sync />,
  },
  {
    menu: "Shippit Testing",
    link: PATH.ONLINE_SHIPPIT,
    // link: "/shippit/shippit_testing",
    icon: <Navigation />,
  },

  {
    menu: "Users",
    link: null,
    icon: <Groups />,
    multiLevel: true,
    multiMenus: [
      {
        menuName: "List Users",

        link: PATH.ONLINE_USERS_LIST,
        // link: "/users",
      },
      {
        menuName: "Add User",
        link: PATH.ONLINE_USERS_ADD,
        // link: "/users/add_user",
      },
      {
        menuName: "List User Modules",
        link: PATH.ONLINE_USERS_LISTMODULES,
        //  link: "/modules"
      },
      {
        menuName: "Add User Module",
        link: PATH.ONLINE_USERS_ADDMODULE,
        // link: "/modules/add_module",
      },
    ],
  },
];

export default onlineSubMenus;
