import { Check, Clear, Close, KeyboardArrowLeft } from "@mui/icons-material";
import {
    AppBar,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Skeleton,
    styled,
    useTheme,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import BasicTableView1 from "../../../../Components/BasicTableView1";



const StyledHeaderTitle = styled(DialogTitle)(({ theme }) => ({
    background: theme.palette.primary.main,
    color: "#fff",
    position: "relative",
    "& button": {
        position: "absolute",
        right: "15px",
        top: "15px",
        color: "#fff",
    },
}));

const FlexContent = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    fontSize: "17px",
    marginBottom: "10px",
    alignItems: "flex-start",
}));

const FlexInnerTitle = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    minWidth: "280px",
    maxWidth: "320px",
    fontWeight: "600",
}));

const Values = styled("div")(({ theme }) => ({
    marginLeft: "15px",
    fontWeight: "500",
    color: theme.palette.primary.dark,
    "& input": {
        padding: "10px",
        width: "150px",
    },
    "& .MuiSelect-select": {
        padding: "10px",
    },
    "& button": {
        padding: "11px !important",
        minWidth: "40px",
    },
}));


const AppBarTabs = styled(AppBar)(({ theme }) => ({
    background: "#fff",
    color: theme.palette.primary.dark,
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        "aria-controls": `full-width-tabpanel-${index}`,
    };
}

const ViewContainer = (props) => {

    const theme = useTheme();
    const [value, setValue] = useState(0);

    const [dialogDetails, setDialogDetails] = useState({
        open: true,
    });
    const [productDetails, setProductDetails] = useState("");


    useEffect(() => {
        props.sendDetails(dialogDetails);

    }, [props, dialogDetails]);


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleClose = () => {
        setDialogDetails({
            ...dialogDetails,
            open: false,
        });
    };

    const handleView = (row) => {
        setProductDetails(row);
    };

    const handleBack = () => {
        setProductDetails("");
    };

  

    return (
        <div>
            <Dialog
                open={dialogDetails.open}
                onClose={handleClose}
                maxWidth="xl"
                fullWidth
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <StyledHeaderTitle id="alert-dialog-title">
                    <div>{`View Order Details (${props.viewDetails?.netsuiteOrderNumber || "-"
                        })`}</div>
                    <IconButton onClick={handleClose}>
                        <Close />
                    </IconButton>
                </StyledHeaderTitle>
                {props.singleLoading ? (
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Box p={3} sx={{ width: "100%" }}>
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                </Box>
                            </Grid>


                        </Grid>
                    </DialogContent>
                ) : (
                    <DialogContent style={{ margin: "0", padding: "0" }}>
                        <AppBarTabs position="static">
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                indicatorColor="secondary"
                                textColor="inherit"
                                variant="fullWidth"
                                aria-label="full width tabs example"
                            >
                                <Tab label="Order Details" {...a11yProps(0)} />
                               

                            </Tabs>
                        </AppBarTabs>

                        <TabPanel value={value} index={0} dir={theme.direction}>

                            <Box p={3}>
                                <Grid container spacing={2}>
                                    {/* Left Side */}
                                    <Grid item xs={12} md={6}>
                                        
                                        <FlexContent>
                                            <FlexInnerTitle>
                                                <span>NetSuite Order Number</span> <span> : </span>
                                            </FlexInnerTitle>
                                            <Values>
                                                {props.viewDetails.netsuiteOrderNumber}
                                            </Values>
                                        </FlexContent>
                                        <FlexContent>
                                            <FlexInnerTitle>
                                                <span>Order Number</span> <span> : </span>
                                            </FlexInnerTitle>
                                            <Values>
                                                {props.viewDetails.orderNumber}
                                            </Values>
                                        </FlexContent>

                                        <FlexContent>
                                            <FlexInnerTitle>
                                                <span>Label PDF Generate?</span> <span> : </span>
                                            </FlexInnerTitle>
                                            <Values>
                                                {props.viewDetails.isLabelPdfGenerated}
                                            </Values>
                                        </FlexContent>


                                        <FlexContent>
                                            <FlexInnerTitle>
                                                <span>Despatch Report Generate?</span> <span> : </span>
                                            </FlexInnerTitle>
                                            <Values>
                                                {props.viewDetails.isDespatchReportGenerated}
                                            </Values>
                                        </FlexContent>

                                        <FlexContent>
                                            <FlexInnerTitle>
                                                <span>Tracking Generated?</span> <span> : </span>
                                            </FlexInnerTitle>
                                            <Values>
                                                {props.viewDetails.isTrackingGenerated}
                                            </Values>
                                        </FlexContent>

                                        <FlexContent>
                                            <FlexInnerTitle>
                                                <span>Tracking Uploaded?</span> <span> : </span>
                                            </FlexInnerTitle>
                                            <Values>
                                                {props.viewDetails.isTrackingUploaded}
                                            </Values>
                                        </FlexContent>

                                        <FlexContent>
                                            <FlexInnerTitle>
                                                <span>Shipment Update?</span> <span> : </span>
                                            </FlexInnerTitle>
                                            <Values>
                                                {props.viewDetails.isShipmentUpdated}
                                            </Values>
                                        </FlexContent>
                                        <FlexContent>
                                            <FlexInnerTitle>
                                                <span>Invoice Generate?</span> <span> : </span>
                                            </FlexInnerTitle>
                                            <Values>
                                                {props.viewDetails.isInvoiceGenerated}
                                            </Values>
                                        </FlexContent>
                                        

                                        


                                    </Grid>

                                    <Grid item xs={12} md={6}>

                                    <FlexContent>
                                            <FlexInnerTitle>
                                                <span>Location</span> <span> : </span>
                                            </FlexInnerTitle>
                                            <Values>
                                                {props.viewDetails.location}
                                            </Values>
                                        </FlexContent>



                                        <FlexContent>
                                            <FlexInnerTitle>
                                                <span>Order Date</span> <span> : </span>
                                            </FlexInnerTitle>
                                            <Values>
                                                {props.viewDetails.orderDate !==
                                                    "0000-00-00 00:00:00"
                                                    ? props.viewDetails.orderDate !== ""
                                                        ? moment(props.viewDetails.orderDate).format(
                                                            "ddd, MMM Do YYYY, hh:mm:ss A"
                                                        )
                                                        : "-"
                                                    : "-"}
                                            </Values>
                                        </FlexContent>
                                        <FlexContent>
                                            <FlexInnerTitle>
                                                <span>Label PDF Generate Date</span> <span> : </span>
                                            </FlexInnerTitle>
                                            <Values>
                                            {props.viewDetails.labelPdfGeneratedDate !==
                                                    "0000-00-00 00:00:00"
                                                    ? props.viewDetails.labelPdfGeneratedDate !== ""
                                                        ? moment(props.viewDetails.labelPdfGeneratedDate).format(
                                                            "ddd, MMM Do YYYY, hh:mm:ss A"
                                                        )
                                                        : "-"
                                                    : "-"}
                                            </Values>
                                        </FlexContent>

                                        
                                        <FlexContent>
                                            <FlexInnerTitle>
                                                <span> Despatch Report Generated Date</span> <span> : </span>
                                            </FlexInnerTitle>
                                            <Values>
                                                {props.viewDetails.despatchReportgeneratedDate !==
                                                    "0000-00-00 00:00:00"
                                                    ? props.viewDetails.despatchReportgeneratedDate !== ""
                                                        ? moment(props.viewDetails.despatchReportgeneratedDate).format(
                                                            "ddd, MMM Do YYYY, hh:mm:ss A"
                                                        )
                                                        : "-"
                                                    : "-"}
                                            </Values>
                                        </FlexContent>
                                        <FlexContent>
                                            <FlexInnerTitle>
                                                <span> Tracking Generated Date</span> <span> : </span>
                                            </FlexInnerTitle>
                                            <Values>
                                                {props.viewDetails.trackingGeneratedDate !==
                                                    "0000-00-00 00:00:00"
                                                    ? props.viewDetails.trackingGeneratedDate !== ""
                                                        ? moment(props.viewDetails.trackingGeneratedDate).format(
                                                            "ddd, MMM Do YYYY, hh:mm:ss A"
                                                        )
                                                        : "-"
                                                    : "-"}
                                            </Values>
                                        </FlexContent>
                                        

                                        <FlexContent>
                                            <FlexInnerTitle>
                                                <span> Tracking Uploaded Date</span> <span> : </span>
                                            </FlexInnerTitle>
                                            <Values>
                                                {props.viewDetails.trackingUploadedDate !==
                                                    "0000-00-00 00:00:00"
                                                    ? props.viewDetails.trackingUploadedDate !== ""
                                                        ? moment(props.viewDetails.trackingUploadedDate).format(
                                                            "ddd, MMM Do YYYY, hh:mm:ss A"
                                                        )
                                                        : "-"
                                                    : "-"}
                                            </Values>
                                        </FlexContent>

                                        <FlexContent>
                                            <FlexInnerTitle>
                                                <span> Shipment Updated Date</span> <span> : </span>
                                            </FlexInnerTitle>
                                            <Values>
                                                {props.viewDetails.shipmentUpdatedDate !==
                                                    "0000-00-00 00:00:00"
                                                    ? props.viewDetails.shipmentUpdatedDate !== ""
                                                        ? moment(props.viewDetails.shipmentUpdatedDate).format(
                                                            "ddd, MMM Do YYYY, hh:mm:ss A"
                                                        )
                                                        : "-"
                                                    : "-"}
                                            </Values>
                                        </FlexContent>

                                        <FlexContent>
                                            <FlexInnerTitle>
                                                <span> Invoice Generated Date</span> <span> : </span>
                                            </FlexInnerTitle>
                                            <Values>
                                                {props.viewDetails.invoiceGeneratedDate !==
                                                    "0000-00-00 00:00:00"
                                                    ? props.viewDetails.invoiceGeneratedDate !== ""
                                                        ? moment(props.viewDetails.invoiceGeneratedDate).format(
                                                            "ddd, MMM Do YYYY, hh:mm:ss A"
                                                        )
                                                        : "-"
                                                    : "-"}
                                            </Values>
                                        </FlexContent>


                                    </Grid>
                                </Grid>
                            </Box>
                        </TabPanel>

                       
                    </DialogContent>
                )}
                <DialogActions>
                    <Button onClick={handleClose} variant="outlined" color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ViewContainer;
