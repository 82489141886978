import { Check, Clear, Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  styled,
  useTheme,
  AppBar,
} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import moment from "moment";
import React, { useEffect, useState } from "react";
import BasicTable from "../../../../Components/BasicTable";

const StyledHeaderTitle = styled(DialogTitle)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: "#fff",
  position: "relative",
  "& button": {
    position: "absolute",
    right: "15px",
    top: "15px",
    color: "#fff",
  },
}));

const FlexContent = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  fontSize: "17px",
  marginBottom: "10px",
  alignItems: "flex-start",
}));

const FlexInnerTitle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  minWidth: "180px",
  maxWidth: "250px",
  fontWeight: "600",
}));

const Values = styled("div")(({ theme }) => ({
  marginLeft: "15px",
  fontWeight: "500",
  color: theme.palette.primary.dark,
}));

const AppBarTabs = styled(AppBar)(({ theme }) => ({
  background: "#fff",
  color: theme.palette.primary.dark,
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const productColumns = [
  { id: "stockCode", name: "Stock Code" },
  { id: "stockNameDefault", name: "Stock Name" },
  { id: "latestCost", name: "Latest Cost" },
  { id: "averageCost", name: "Average Cost" },
  { id: "supplierCost", name: "Supplier Cost" },
];

const ViewSupplierDialog = (props) => {
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [dialogDetails, setDialogDetails] = useState({
    open: true,
  });

  useEffect(() => {
    props.sendDetails(dialogDetails);
  }, [props, dialogDetails]);

  const handleClose = () => {
    setDialogDetails({
      ...dialogDetails,
      open: false,
    });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Dialog
        open={dialogDetails.open}
        onClose={handleClose}
        maxWidth="xl"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <StyledHeaderTitle id="alert-dialog-title">
          <div>View Supplier Details</div>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </StyledHeaderTitle>
        <DialogContent style={{ margin: "0", padding: "0" }}>
          <AppBarTabs position="static">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="secondary"
              textColor="inherit"
              variant="fullWidth"
              aria-label="full width tabs example"
            >
              <Tab label="Supplier Details" {...a11yProps(0)} />
              <Tab label="Product Association" {...a11yProps(1)} />
            </Tabs>
          </AppBarTabs>

          {/* Supplier Details Tab */}
          <TabPanel value={value} index={0} dir={theme.direction}>
            <Box pt={3}>
              <Grid container spacing={2}>
                {/* Left Side */}
                <Grid item xs={12} md={6}>
                  <FlexContent>
                    <FlexInnerTitle>
                      <span>Supplier ID</span> <span> : </span>
                    </FlexInnerTitle>
                    <Values>{props.viewDetails.id || "-"}</Values>
                  </FlexContent>

                  <FlexContent>
                    <FlexInnerTitle>
                      <span>Supplier Name</span> <span> : </span>
                    </FlexInnerTitle>
                    <Values>{props.viewDetails.NAME || "-"}</Values>
                  </FlexContent>

                  <FlexContent>
                    <FlexInnerTitle>
                      <span>Account Number</span> <span> : </span>
                    </FlexInnerTitle>
                    <Values>{props.viewDetails.ACCNO || "-"}</Values>
                  </FlexContent>

                  <FlexContent>
                    <FlexInnerTitle>
                      <span>Alpha Code</span> <span> : </span>
                    </FlexInnerTitle>
                    <Values>{props.viewDetails.ALPHACODE || "-"}</Values>
                  </FlexContent>

                  <FlexContent>
                    <FlexInnerTitle>
                      <span>Email</span> <span> : </span>
                    </FlexInnerTitle>
                    <Values>{props.viewDetails.EMAIL || "-"}</Values>
                  </FlexContent>

                  <FlexContent>
                    <FlexInnerTitle>
                      <span>Pending Process</span> <span> : </span>
                    </FlexInnerTitle>
                    <Values>
                      {props.viewDetails.pendingProcess === 1 ? (
                        <Check color="primary" />
                      ) : (
                        <Clear color="primary" />
                      )}{" "}
                    </Values>
                  </FlexContent>

                  <FlexContent>
                    <FlexInnerTitle>
                      <span>Active</span> <span> : </span>
                    </FlexInnerTitle>
                    <Values>
                      {props.viewDetails.ISACTIVE === "Y" ? (
                        <Check color="primary" />
                      ) : (
                        <Clear color="primary" />
                      )}{" "}
                    </Values>
                  </FlexContent>

                  <FlexContent>
                    <FlexInnerTitle>
                      <span>Last Modified</span> <span> : </span>
                    </FlexInnerTitle>
                    <Values>
                      {moment(props.viewDetails.LAST_MODIFIED).format(
                        "ddd, MMM Do YYYY, h:mm a"
                      ) || "-"}
                    </Values>
                  </FlexContent>
                </Grid>
                {/* Left Side */}

                {/* Right Side */}
                <Grid item xs={12} md={6}>
                  <FlexContent>
                    <FlexInnerTitle>
                      <span>Address 1</span> <span> : </span>
                    </FlexInnerTitle>
                    <Values>{props.viewDetails.ADDRESS1 || "-"}</Values>
                  </FlexContent>
                  <FlexContent>
                    <FlexInnerTitle>
                      <span>Address 2</span> <span> : </span>
                    </FlexInnerTitle>
                    <Values>{props.viewDetails.ADDRESS2 || "-"}</Values>
                  </FlexContent>
                  <FlexContent>
                    <FlexInnerTitle>
                      <span>Address 3</span> <span> : </span>
                    </FlexInnerTitle>
                    <Values>{props.viewDetails.address3 || "-"}</Values>
                  </FlexContent>

                  <FlexContent>
                    <FlexInnerTitle>
                      <span>Phone</span> <span> : </span>
                    </FlexInnerTitle>
                    <Values>{props.viewDetails.PHONE || "-"}</Values>
                  </FlexContent>

                  <FlexContent>
                    <FlexInnerTitle>
                      <span>Fax</span> <span> : </span>
                    </FlexInnerTitle>
                    <Values>{props.viewDetails.FAX || "-"}</Values>
                  </FlexContent>

                  <FlexContent>
                    <FlexInnerTitle>
                      <span>Credit Status</span> <span> : </span>
                    </FlexInnerTitle>
                    <Values>{props.viewDetails.CREDITSTATUS || "-"}</Values>
                  </FlexContent>

                  <FlexContent>
                    <FlexInnerTitle>
                      <span>Credit Limit</span> <span> : </span>
                    </FlexInnerTitle>
                    <Values>{"$" + props.viewDetails.CREDLIMIT || "-"}</Values>
                  </FlexContent>

                  <FlexContent>
                    <FlexInnerTitle>
                      <span>Stop Credit</span> <span> : </span>
                    </FlexInnerTitle>
                    <Values>
                      {props.viewDetails.STOPCREDIT === "Y" ? (
                        <Check color="primary" />
                      ) : (
                        <Clear color="primary" />
                      )}{" "}
                    </Values>
                  </FlexContent>
                </Grid>
                {/* Right Side */}

                <Grid item xs={12}>
                  <FlexContent>
                    <FlexInnerTitle>
                      <span>Notes</span> <span> : </span>
                    </FlexInnerTitle>
                    <Values>{props.viewDetails.NOTES || "-"}</Values>
                  </FlexContent>
                </Grid>
              </Grid>
            </Box>
          </TabPanel>
          {/* Supplier Details Tab */}

          <TabPanel value={value} index={1} dir={theme.direction}>
            <BasicTable
              columns={productColumns}
              rows={props.viewDetails.stock}
            />
          </TabPanel>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ViewSupplierDialog;
