const displayFilterTags = (filterColumn, stateData, removeTag) => {
  const tags = filterColumn
    // .filter((filter) => filterData[filter.field])
    .filter((filter) => {
      const { field, component } = filter;

      if (component === "AutocompleteField") {
        // Include the filter if filterData[field] is 0. for eg. filterData.soldTo has 0 value for Cash Sales
        return stateData[field] === 0 || stateData[field];
      }

      // For other components, include the filter if filterData[field] is truthy
      return stateData[field];
    })
    .map((filter, index) => {
      const { field, component, label } = filter;
      const tagValue =
        component === "AutocompleteField" ? `${field}Name` : field;

      return {
        label: label || field,
        tag: stateData[tagValue],
        onClick: () => removeTag(field, component),
      };
    });
  return tags;
};

export default displayFilterTags;
