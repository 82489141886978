import {
  BatchPrediction,
  Bolt,
  ChangeCircle,
  Dns,
  EmojiPeople,
  History,
  InsightsSharp,
  LocalConvenienceStore,
  MultipleStop,
  OnlinePrediction,
  ProductionQuantityLimits,
  PublishedWithChanges,
  UploadFile,
  Water,
} from "@mui/icons-material";
import PATH from "../../../Routing/PATHS";

const replenishmentSubMenus = [
  {
    menu: "Excel File",
    link: PATH.REPLENISHMENT_EXCEL_FILE,
    icon: <UploadFile />,
    multiLevel: false,
  },
  {
    menu: "SOH History",
    link: PATH.REPLENISHMENT_HISTORY_SOH,
    icon: <History />,
    multiLevel: false,
    // multiMenus: [
    //   {
    //     menuName: "SOH",
    //     link: PATH.REPLENISHMENT_HISTORY_SOH,
    //     icon: null,
    //   },
    // ],
  },
  {
    menu: "Pipeline",
    link: PATH.REPLENISHMENT_PIPELINE,
    icon: <Water />,
    multiLevel: false,
  },
  {
    menu: "Store SOH",
    link: PATH.REPLENISHMENT_STORESOH,
    icon: <LocalConvenienceStore />,
    multiLevel: false,
  },
  {
    menu: "Store Sales",
    link: PATH.REPLENISHMENT_STORESALES,
    icon: <InsightsSharp />,
    multiLevel: false,
  },
  {
    menu: "Msl Level",
    link: PATH.REPLENISHMENT_MSLLEVEL,
    icon: <ProductionQuantityLimits />,
    multiLevel: false,
  },
  {
    menu: "Quick Ref",
    link: PATH.REPLENISHMENT_QUICKREF,
    icon: <Bolt />,
    multiLevel: false,
  },
  {
    menu: "Sales Override",
    link: PATH.REPLENISHMENT_SALESOVERRIDE,
    icon: <ChangeCircle />,
    multiLevel: false,
  },
  {
    menu: "Stock Substitute",
    link: PATH.REPLENISHMENT_STOCKSUBSTITUTE,
    icon: <PublishedWithChanges />,
    multiLevel: false,
  },
  {
    menu: "Store Stock Master",
    link: PATH.REPLENISHMENT_STORESTOCKMASTER,
    icon: <EmojiPeople />,
    multiLevel: false,
  },
  {
    menu: "Forecast",
    link: PATH.REPLENISHMENT_FORECAST,
    icon: <BatchPrediction />,
    //multiLevel: false,
    multiLevel: true,
    multiMenus: [
      {
        menuName: "Missing Stock Codes",
        link: PATH.REPLENISHMENT_MISSING_CODES,
        icon: null,
      },
    ]
  },
  {
    menu: "Allocations",
    link: null,
    icon: <MultipleStop />,
    multiLevel: true,
    multiMenus: [
      {
        menuName: "Adjusted",
        link: PATH.REPLENISHMENT_ALLOCATIONS_ADJUSTED,
        icon: null,
      },
      // {
      //   menuName: "Standard",
      //   link: PATH.REPLENISHMENT_ALLOCATIONS_STANDARD,
      //   icon: null,
      // },
    ],
  },
];

export default replenishmentSubMenus;
