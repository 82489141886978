import React from "react";
import DataGridTablePage from "../../../Components/DataGridTablePage/DataGridTablePage";
import { useLocation } from "react-router-dom";
import { dateFormatter } from "../../../utils/DataGrid/dateFormatter";

const fieldHeaderPairs = [
  { field: "STORE_CODE", headerName: "Branch" },
  { field: "STOCK_CODE", headerName: "Style" },
  { field: "STOCK_ONHAND", headerName: "SOH" },
  {
    field: "updatedDate",
    headerName: "Updated Date",
    valueFormatter: (params) => dateFormatter(params),
  },
  // { field: "CUSTOMER_ID", headerName: "Customer Name" },
];

const filterColumn = [
  {
    field: "STORE_CODE",
    label: "Branch",
    component: "AutocompleteField",
    url: "/replenishment/store-soh",
    overrideDataField: "branchList",
  },
  {
    field: "STOCK_CODE",
    label: "Style",
    component: "TextField",
  },
  {
    field: "sohGreater",
    label: "SOH Greater Than",
    component: "TextField",
    type: "number",
  },
  {
    field: "sohLess",
    label: "SOH Less Than",
    component: "TextField",
    type: "number",
  },
  {
    field: "startDate",
    label: "Start Date",
    component: "TextField",
    type: "date",
  },
  {
    field: "endDate",
    label: "End Date",
    component: "TextField",
    type: "date",
  },
];
const ReplenishmentStoresoh = () => {
  const location = useLocation();
  return (
    <DataGridTablePage
      hideMenu={3}
      headerTitle="List Store SOH"
      navigateState={location.state}
      exportFeature={true}
      columns={fieldHeaderPairs}
      filterColumn={filterColumn}
      pageUrl="/replenishment/store-soh"
      columnsVisibility={
        {
          // HIST_ID: false,
        }
      }
    />
  );
};

export default ReplenishmentStoresoh;
