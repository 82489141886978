import React, { useEffect, useState, useRef } from "react";
import { ArrowForward, Close, Download, FilterList } from "@mui/icons-material";
import TableComponentBranch from "../TableComponentBranch";
import { httpclient } from "../../../../utils/httpClient";
import {
    Box,
    Button,
    Card,
    Collapse,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    styled,
    TextField,
} from "@mui/material";
import ViewBranchDialog from "../ViewBranchDialog";
import BrandEdit from "../BrandEdit";
import { CSVLink } from "react-csv";
import { dateFormatter } from "../../../../utils/DataGrid/dateFormatter";

const columns = [
    { id: "brandID", name: "ID" },

    { id: "code", name: "Code" },
    { id: "brandName", name: "Brand Name" },
    { id: "bunningsBrandID", name: "Bunnings Brand ID" },
    //{ id: "compareField", name: "Compare Field" },
    { id: "pendingProcess", name: "Pending Process" },
    { id: "actions", name: "Actions" },
];

const FilteredBox = styled(Box)(({ theme }) => ({
    background: "#f9f9f9",
    padding: "5px 10px",
    borderRadius: "5px",
    "& p": {
        margin: "0",
        marginRight: "10px",
        display: "inline-block",
        background: "#dedede",
        borderRadius: "10px",
        padding: "2px 5px",
    },
    "& svg": {
        fontSize: "15px",
        cursor: "pointer",
        position: "relative",
        top: "3px",
        background: theme.palette.primary.dark,
        color: "#fff",
        borderRadius: "50%",
        padding: "2px",
        marginLeft: "2px",
    },
}));

const Header = styled("div")(({ theme }) => ({
    "& h1": {
        color: theme.palette.primary.dark,
        margin: "0",
    },
}));

const configRowPerPage = JSON.parse(localStorage.getItem("configRowPerPage"));

const NewSystemBrand = () => {
    const buttonRef = useRef(null);
    const [loadingExport, setLoadingExport] = useState(false);
    const [exportFile, setExportFile] = useState(false);
    const [exportRows, setExportRows] = useState([]);
    const [openViewDialog, setOpenViewDialog] = useState(false);
    const [viewDetails, setViewDetails] = useState({});

    const [rows, setRows] = useState([]);

    const [loading, setLoading] = useState(false);
    const [direction, setDirection] = useState(false);
    const [currentColumn, setCurrentColumn] = useState("");
    const [page, setPage] = useState(1);
    const [from, setFrom] = useState(1);
    const [to, setTo] = useState(
        configRowPerPage && configRowPerPage
            ? configRowPerPage && configRowPerPage
            : 20
    );

    const [rowsPerPage, setRowsPerPage] = useState(
        configRowPerPage && configRowPerPage
            ? configRowPerPage && configRowPerPage
            : 20
    );
    const [total, setTotal] = useState("");
    const [filterOpen, setFilterOpen] = useState(false);

    const [filterData, setFilterData] = useState({
        brandID: "",
        brandName: "",
        code: "",
        pendingProcess: "",
        active: "",
        lastModified: "",
        remove: false,
    });

    const [submittedData, setSubmittedData] = useState({
        brandID: "",
        brandName: "",
        code: "",
        pendingProcess: "",
        active: "",
        lastModified: "",
        submit: false,
    });

    // useEffect(() => {
    //   getCurrentBranch();
    // }, []);

    useEffect(() => {
        if (
            filterData.brandID === "" &&
            filterData.brandName === "" &&
            filterData.code === "" &&
            filterData.pendingProcess === "" &&
            filterData.active === ""
        ) {
            setSubmittedData({
                ...submittedData,
                submit: false,
            });
        }
        if (filterData.brandID === " ") filterData.brandID = "";
        if (filterData.brandName === " ") filterData.brandName = "";
        if (filterData.code === " ") filterData.code = "";
        if (filterData.pendingProcess === " ") filterData.pendingProcess = "";
        if (filterData.active === " ") filterData.active = "";

        filterData.remove === true && handleFilter();
    }, [filterData]);

    useEffect(() => {
        let currentBranch = JSON.parse(
            localStorage.getItem("new_system_brand_filter")
        );
        currentBranch !== null && setFilterData(currentBranch);

        currentBranch == null
            ? getCurrentBranch()
            : currentBranch.brandID == "" &&
                currentBranch.brandName == "" &&
                currentBranch.code == "" &&
                currentBranch.pendingProcess == "" &&
                currentBranch.active == "" &&
                currentBranch.removed == false
                ? getCurrentBranch()
                : console.log("current branch!");
    }, []);

    const getCurrentBranch = () => {
        setLoading(true);
        httpclient
            .get(`new-system/brand/all?pagination=${rowsPerPage}`)
            .then(({ data }) => {
                if (data.success) {
                    setRows(data.data);
                    setTotal(data.count);
                    //   setRowsPerPage(parseInt(data.meta.per_page));
                    //   setPage(data.meta.current_page);
                    //   setFrom(data.meta.from);
                    //   setTo(data.meta.to);
                    setLoading(false);
                }
            })
            .catch((err) => {
                if (err.response.status === 401) {
                    localStorage.clear();
                    window.location = "/login";
                }
            });
    };

    const handleView = (row) => {
        setOpenViewDialog(true);
        setViewDetails(row);
    };

    const sendDetails = (callback) => {
        if (callback.open === false) {
            setOpenViewDialog(false);
            setViewDetails({});
            getCurrentBranch();

        }
    };

    const handleFilter = () => {
        setSubmittedData({
            ...submittedData,
            brandID: filterData.brandID,
            brandName: filterData.brandName,
            code: filterData.code,
            pendingProcess: filterData.pendingProcess,
            active: filterData.active,
            submit: true,
        });
        filterData.remove = true;
        localStorage.setItem("new_system_brand_filter", JSON.stringify(filterData));
        setLoading(true);
        if (
            filterData.brandID ||
            filterData.brandName ||
            filterData.code ||
            filterData.pendingProcess ||
            filterData.active
        ) {
            setExportRows([]);
            httpclient
                .get(
                    `new-system/brand/all?brandID=${filterData.brandID}&brandName=${filterData.brandName
                    }&code=${filterData.code}&pendingProcess=${filterData.pendingProcess
                    }&branchActive=${filterData.active
                    }&pagination=${rowsPerPage}&page=${1}`
                )
                .then(({ data }) => {
                    if (data.success) {
                        setRows(data.data);
                        setTotal(data.count);
                        //   setRowsPerPage(parseInt(data.meta.per_page));
                        //   setPage(data.meta.current_page);
                        //   setFrom(data.meta.from);
                        //   setTo(data.meta.to);
                        setExportFile(true);
                        setLoading(false);
                    }
                });
        } else {
            getCurrentBranch();
        }
    };

    const handleExport = () => {
        setLoadingExport(true);
        httpclient
            .get(
                `new-system/brand/all?brandID=${filterData.brandID}&brandName=${filterData.brandName
                }&code=${filterData.code}&pendingProcess=${filterData.pendingProcess
                }&branchActive=${filterData.active
                }&pagination=${total}`
            )
            .then(({ data }) => {
                if (data.success) {
                    setExportRows(data.data);
                    setLoadingExport(false);
                }
            });
    };

    const hadleFilterOpen = () => {
        setFilterOpen((prev) => !prev);
    };

    const handleChangeFilter = (e) => {
        const { name, value } = e.target;
        setFilterData({
            ...filterData,
            [name]: value,
            remove: false,
        });
    };

    const handleRemove = (data) => {
        setExportFile(false);
        setExportRows([]);
        setFilterData({
            ...filterData,
            [data]: "",
            remove: true,
        });

        setSubmittedData({
            ...submittedData,
            [data]: "",
        });
    };

    const handleSort = (column) => {
        setDirection((prevDirection) => !prevDirection);
        setCurrentColumn(column);
        submittedData.submit
            ? httpclient
                .get(
                    `new-system/brand/all?brandID=${filterData.brandID}&brandName=${filterData.brandName
                    }&code=${filterData.code}&pendingProcess=${filterData.pendingProcess
                    }&branchActive=${filterData.active}&sort_by=${column}&direction=${!direction ? "asc" : "desc"
                    }&pagination=${rowsPerPage}&page=${page}`
                )
                .then(({ data }) => {
                    if (data.success === true) {
                        setRows(data.data);
                        setTotal(data.count);
                        //   setRowsPerPage(parseInt(data.meta.per_page));
                        //   setPage(data.meta.current_page);
                        //   setFrom(data.meta.from);
                        //   setTo(data.meta.to);
                        setLoading(false);
                    }
                })
            : httpclient
                .get(
                    `new-system/brand/all?sort_by=${column}&direction=${!direction ? "asc" : "desc"
                    }&pagination=${rowsPerPage}`
                )
                .then(({ data }) => {
                    if (data.success === true) {
                        setRows(data.data);
                    }
                });
    };

    const handleChangePage = (e, page) => {
        setLoading(true);
        submittedData.submit
            ? httpclient
                .get(
                    `new-system/brand/all?brandID=${filterData.brandID}&brandName=${filterData.brandName
                    }&code=${filterData.code}&pendingProcess=${filterData.pendingProcess
                    }&branchActive=${filterData.active
                    }&sort_by=${currentColumn}&direction=${direction ? "asc" : "desc"
                    }&pagination=${rowsPerPage}&page=${page}`
                )
                .then(({ data }) => {
                    if (data.success === true) {
                        setRows(data.data);
                        setTotal(data.count);
                        //   setRowsPerPage(parseInt(data.meta.per_page));
                        //   setPage(data.meta.current_page);
                        //   setFrom(data.meta.from);
                        //   setTo(data.meta.to);
                        setLoading(false);
                    }
                })
            : httpclient
                .get(
                    `new-system/brand/all?direction=${direction ? "asc" : "desc"
                    }&pagination=${rowsPerPage}&page=${page}`
                )
                .then(({ data }) => {
                    if (data.success === true) {
                        setRows(data.data);
                        setTotal(data.count);
                        //   setRowsPerPage(parseInt(data.meta.per_page));
                        //   setPage(data.meta.current_page);
                        //   setFrom(data.meta.from);
                        //   setTo(data.meta.to);
                        setLoading(false);
                    }
                });
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        // setLoading(true);

        localStorage.setItem("configRowPerPage", event.target.value);

        submittedData.submit
            ? httpclient
                .get(
                    `new-system/brand/all?brandID=${filterData.brandID}&brandName=${filterData.brandName
                    }&code=${filterData.code}&pendingProcess=${filterData.pendingProcess
                    }&branchActive=${filterData.active
                    }&sort_by=${currentColumn}&direction=${direction ? "asc" : "desc"
                    }&pagination=${+event.target.value}&page=${page}`
                )
                .then(({ data }) => {
                    setLoading(true);
                    if (data.success === true) {
                        setRows(data.data);
                        setTotal(data.count);
                        //   setRowsPerPage(parseInt(data.meta.per_page));
                        //   setPage(data.meta.current_page);
                        //   setFrom(data.meta.from);
                        //   setTo(data.meta.to);
                        setLoading(false);
                    }
                })
            : httpclient
                .get(
                    `new-system/brand/all?direction=${direction ? "asc" : "desc"
                    }&pagination=${+event.target.value}&page=${1}`
                )
                .then(({ data }) => {
                    setLoading(true);
                    if (data.success === true) {
                        setRows(data.data);
                        setTotal(data.count);
                        //   setRowsPerPage(parseInt(data.meta.per_page));
                        //   setPage(data.meta.current_page);
                        //   setFrom(data.meta.from);
                        //   setTo(data.meta.to);
                        setLoading(false);
                    }
                });
    };

    useEffect(() => {
        if (!loadingExport && exportRows.length > 0 && buttonRef.current) {
            buttonRef.current.click();
        }
    }, [loadingExport, exportRows]);

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item md={8} xs={12}>
                    <Header>
                        <h1>List New System Brand</h1>
                    </Header>
                </Grid>
                <Grid
                    item
                    md={4}
                    xs={12}
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    {exportRows.length > 0 ? (
                        <CSVLink data={exportRows} filename={"List New System Brand" + String(dateFormatter(Date.now())) + ".csv"}>

                            <Button

                                color="primary"
                                variant="contained"
                                style={{ marginRight: "10px" }}
                                ref={buttonRef}
                            >
                                Export <Download style={{ marginLeft: "5px" }} fontSize="small" />
                            </Button>

                        </CSVLink>
                    ) : (
                        <Button

                            color="primary"
                            variant="contained"
                            style={{ marginRight: "10px" }}
                            disabled={!exportFile}
                            onClick={handleExport}
                        >
                            {loadingExport ? "Generating Data to Export" : "Export"}{loadingExport ? `...` : <Download style={{ marginLeft: "5px" }} fontSize="small" />}
                        </Button>

                    )}
                    <Button color="primary" variant="contained" onClick={hadleFilterOpen}>
                        Filter <FilterList style={{ marginLeft: "5px" }} fontSize="small" />
                    </Button>
                </Grid>

                {/* Filter */}
                <Grid item xs={12}>
                    <Collapse in={filterOpen}>
                        <Card>
                            <Box p={4}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={4}>
                                        <InputLabel>Brand ID</InputLabel>
                                        <TextField
                                            variant="outlined"
                                            name={Object.keys(filterData)[1]}
                                            value={filterData.brandID}
                                            onChange={handleChangeFilter}
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") handleFilter();
                                            }}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <InputLabel>Brand Name</InputLabel>
                                        <TextField
                                            variant="outlined"
                                            name="brandName"
                                            value={filterData.brandName}
                                            onChange={handleChangeFilter}
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") handleFilter();
                                            }}
                                            fullWidth
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={4}>
                                        <InputLabel>Code</InputLabel>
                                        <TextField
                                            variant="outlined"
                                            name="code"
                                            value={filterData.code}
                                            onChange={handleChangeFilter}
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") handleFilter();
                                            }}
                                            fullWidth
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={4}>
                                        <InputLabel>Pending Process</InputLabel>
                                        <FormControl fullWidth>
                                            <Select
                                                value={filterData.pendingProcess}
                                                // label="Exclusive Pending"
                                                name="pendingProcess"
                                                onChange={handleChangeFilter}
                                            >
                                                <MenuItem value={""}>Select</MenuItem>
                                                <MenuItem value={"1"}>Yes</MenuItem>
                                                <MenuItem value={"0"}>No</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    {/* <Grid item xs={12} md={4}>
                    <InputLabel>Active</InputLabel>
                    <FormControl fullWidth>
                      <Select
                        value={filterData.active}
                        name="active"
                        // label="Active"
                        onChange={handleChangeFilter}
                      >
                        <MenuItem value={""}>Select</MenuItem>
                        <MenuItem value={"Y"}>Yes</MenuItem>
                        <MenuItem value={"N"}>No</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid> */}

                                    <Grid item xs={12}>
                                        <Box textAlign={"right"}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleFilter}
                                            >
                                                Filter{" "}
                                                <ArrowForward
                                                    fontSize="small"
                                                    style={{ marginLeft: "5px" }}
                                                />
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Card>
                    </Collapse>
                </Grid>

                {submittedData.brandID ||
                    submittedData.brandName ||
                    submittedData.pendingProcess ||
                    submittedData.code ||
                    submittedData.active ? (
                    <Grid item xs={12}>
                        <FilteredBox>
                            <span>Filtered: </span>
                            {submittedData.brandID && (
                                <p>
                                    <span>Brand ID: {submittedData.brandID}</span>
                                    <Close
                                        fontSize="small"
                                        onClick={() => handleRemove("brandID")}
                                    />
                                </p>
                            )}
                            {submittedData.brandName && (
                                <p>
                                    <span>Brand Name: {submittedData.brandName}</span>
                                    <Close
                                        fontSize="small"
                                        onClick={() => handleRemove("brandName")}
                                    />
                                </p>
                            )}

                            {submittedData.code && (
                                <p>
                                    <span>Code: {submittedData.code}</span>
                                    <Close
                                        fontSize="small"
                                        onClick={() => handleRemove("code")}
                                    />
                                </p>
                            )}

                            {submittedData.pendingProcess && (
                                <p>
                                    <span>
                                        Pending Process:{" "}
                                        {submittedData.pendingProcess === "1" ? "Yes" : "No"}
                                    </span>
                                    <Close
                                        fontSize="small"
                                        onClick={() => handleRemove("pendingProcess")}
                                    />
                                </p>
                            )}
                            {/* {submittedData.active && (
                <p>
                  <span>
                    Active: {submittedData.active === "Y" ? "Yes" : "No"}
                  </span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("active")}
                  />
                </p>
              )} */}
                        </FilteredBox>
                    </Grid>
                ) : (
                    <Box></Box>
                )}
                {/* Filter */}

                <Grid item xs={12}>
                    <TableComponentBranch
                        columns={columns}
                        rows={rows}
                        sort={true}
                        handleView1={handleView}
                        handleSort={handleSort}
                        loading={loading}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        handleChangePage={handleChangePage}
                        direction={direction}
                        currentColumn={currentColumn}
                        page={page}
                        total={total && total}
                        fromTable={from}
                        toTable={to}
                        rowsPerPage={rowsPerPage}
                    />
                </Grid>
            </Grid>

            {openViewDialog && (
                <BrandEdit viewDetails={viewDetails} sendDetails={sendDetails} />
            )}
        </div>
    );
};

export default NewSystemBrand;