import React, { useEffect, useState } from "react";
import DataGridTablePage from "../../../Components/DataGridTablePage/DataGridTablePage";

import moment from "moment";
import {
    
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Grid,
    IconButton,
    Snackbar,
    TextField,
    styled,
} from "@mui/material";
import { httpclient } from "../../../utils/httpClient";
import MuiAlert from "@mui/material/Alert";


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

const FlexContent = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    fontSize: "17px",
    marginBottom: "10px",
    alignItems: "flex-start",
}));

const FlexInnerTitle = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    minWidth: "180px",
    maxWidth: "250px",
    fontWeight: "600",
}));


const Header = styled("div")(({ theme }) => ({
    "& h1": {
        color: theme.palette.primary.dark,
        margin: "0",
    },
}));

const NetSuitePrice = () => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [messageState, setMessageState] = useState("");
    const [data, setData] = useState([]);
    const [dataDisplay, setDataDisplay] = useState([]);
    const [checkedItems, setCheckedItems] = useState([]);

    useEffect(() => {
        getPriceName();
        getPriceNameDisplay();
    }, []);

    const getPriceName = () => {

        httpclient.get(`netsuite/v1/getPriceLevelName`).then(({ data }) => {
            if (data.success) {
                setData(data.records.data);
            }
        });
    };

    const getPriceNameDisplay = () => {

        httpclient.get(`netsuite/v1/getPriceLevelName?displayFlag=1`).then(({ data }) => {
            if (data.success) {
                //setDataDisplay(data.records.data);
                const dataD = data.records.data;
                setCheckedItems(dataD.map(item => parseInt(item.priceLevelID)));
            }
        });
    };



    const handleCheckboxChange = (event, i) => {
        if (event.target.checked) {
            setCheckedItems([...checkedItems, i]);

        } else {
            setCheckedItems(checkedItems.filter(item => item !== i));
        }
    };

    const handleSelectAll = (event) => {
        if (event.target.checked) {
            const allItems = data.map(d => d.priceLevelID);
            setCheckedItems(allItems);

        } else {
            setCheckedItems([]);

        }
    };

    const handleCheckboxSelect = () => {
        setLoading(true);
        httpclient
            .post(`netsuite/v1/priceLevelDisplayFlagUpdate?ids=${checkedItems}&displayFlag=1`)
            .then(({ data }) => {
                if (data.success) {
                    setOpen(true);
                    setMessageState("success");
                    setMessage(data.message);
                    setLoading(false);

                } else {
                    setLoading(false);
                    setOpen(true);
                    setMessageState("error");
                    setMessage(data.message);
                }
            });
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };


    console.log("handle check", checkedItems);



    return (
        <>
            <Grid container spacing={2}>
                <Grid item md={10} xs={12}>
                    <Box p={2}>
                        <Header>
                            <h1>Price Settings (NetSuite)</h1>
                        </Header>
                    </Box>
                    <Box p={2}>
                        <div style={{ display: "flex", flexDirection: "row", lineHeight: "2.2", fontFamily: "Poppins", alignItems: "flex-start" }}>

                            <Checkbox
                                onChange={handleSelectAll}
                                inputProps={{ 'aria-label': 'select all' }}
                            />
                            <span>Select All</span>

                        </div>
                    </Box>
                </Grid>
                <Grid item md={2} xs={12}>
                    <Box p={2}>
                        <div style={{ display: "flex", flexDirection: "row", lineHeight: "2.2", fontFamily: "Poppins", alignItems: "flex-start" }}>
                            <Button onClick={handleCheckboxSelect} variant="contained" color="primary">
                                {loading ? <CircularProgress style={{
                                    height: "20px",
                                    width: "20px",
                                    marginRight: "20px",
                                    marginLeft: "20px",
                                    color: "#ffffff",
                                }} /> : "Save Changes"}
                            </Button>
                        </div>
                    </Box>
                </Grid>

                <Grid item md={12} xs={12}>
                    <Box p={2} sx={{ backgroundColor: "#ffffff" }}>
                        <Grid container spacing={2}>
                            {data ? data.map((d) => (
                                <Grid item xs={12} md={4} key={d.id}>
                                    <FlexContent>
                                        <Checkbox
                                            checked={checkedItems.includes(d.priceLevelID)}
                                            onChange={(event) => handleCheckboxChange(event, d.priceLevelID)}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                        <FlexInnerTitle>
                                            <span>{d.name}</span>
                                        </FlexInnerTitle>


                                    </FlexContent>
                                </Grid>
                            ))

                                : <h4>No Price List Found</h4>}
                        </Grid>
                    </Box>
                </Grid>

            </Grid>

            <Snackbar
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={open}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity={messageState}
                    sx={{ width: "100%" }}
                >
                    {message}
                </Alert>
            </Snackbar>
        </>
    );
};

export default NetSuitePrice;
