const extractFilterField = (filterColumn, navigateState) => {
  const filterData = filterColumn.reduce((acc, column) => {
    // Extract field and component from the current column
    const { field, component } = column;

    //IF STATE EXIST INITIALIZE THE FILTER WITH THAT VALUE HERE.

    // Initialize the field in filterData with an empty string
    acc[field] =
      navigateState && navigateState[field] !== undefined
        ? navigateState[field]
        : "";

    // If the component is "Autocomplete", add an extra field with "Name"
    if (component === "Autocomplete") {
      const nameField = `${field}Name`;
      acc[nameField] =
        navigateState && navigateState[nameField] !== undefined
          ? navigateState[nameField]
          : "";
    }
    return acc;
  }, {});
  return filterData;
};

export default extractFilterField;
