
import React, { useEffect, useState } from "react";
import {

    Radio,
    Skeleton

} from "@mui/material";
import { red, yellow, green } from '@mui/material/colors';
import { httpclient } from "../../../utils/httpClient";
import moment from 'moment-timezone';


const title = [
    { id: "", name: " " },
    { id: "Shippit", name: "Shippit" },
    { id: "EXO", name: "Exo" },
    { id: "DSCO", name: "DSCO" },

];

const DashboardGrid5 = () => {
    const [loading, setLoading] = useState(false);

    const [productStatus, setProductStatus] = useState({});


    useEffect(() => {
        fetchData();
        const intervalId = setInterval(fetchData, 60 * 1000); // Fetch data every 60 seconds
        return () => clearInterval(intervalId); // Cleanup the interval on component unmount
    }, []);

    const fetchData = () => {
        setLoading(true);
        httpclient.get(`realtime-status/last-synced-log`).then(({ data }) => {
            if (data.success) {
                const records = data.data;
               

                const productStatus = {};
                records.forEach((record) => {
                    const { platform, lastSync, nextSync, expiredSync } = record;
                    const platformObj = title.find((item) => item.id === platform);
                    if (platformObj) {
                        productStatus[platformObj.id] = { lastSync, nextSync, expiredSync };
                    }
                });

                setProductStatus(productStatus);
              
                setLoading(false);
            } else {
                console.log("Error!");
            }
        });
    };


    const sydneyTime = moment.tz('Australia/Sydney').format("YYYY-MM-DD HH:mm:ss");
    // console.log("sydney time>>>",sydneyTime); 


    const renderContent = () => {
        if (loading) {
            return (
                <div className="grid-block-content-traffic">
                    {title.map((item) => (
                        <>
                            <div className="grid-item title">{item.name}</div>
                            <div className="grid-item content1">
                                <Skeleton variant="text" />
                            </div>
                            <div className="grid-item content1">
                                <Skeleton variant="text" />
                            </div>
                            <div className="grid-item content-traffic">
                                <Skeleton variant="circular" width={30} height={30} />
                            </div>
                            

                        </>
                    ))}
                </div>
            );
        }
        return (
            <div className="grid-block-content-traffic">
                {title.map((item) => (

                    <>
                        <div className="grid-item title">{item.name}</div>
                        
                        <div className="grid-item content1">
                            {productStatus[item.id] ? moment(productStatus[item.id].lastSync).format("ddd, MMM Do YYYY, h:mm:ss a") : "Last Sync"}
                        </div>
                        <div className="grid-item content1">
                            {productStatus[item.id] ? moment(productStatus[item.id].nextSync).format("ddd, MMM Do YYYY, h:mm:ss a") : "Next Sync"}
                        </div>
                        <div className="grid-item content-traffic">
                            {productStatus[item.id] && productStatus[item.id].lastSync && productStatus[item.id].nextSync && (
                                moment(sydneyTime).isBetween(moment(productStatus[item.id].lastSync), moment(productStatus[item.id].nextSync))
                            ) ? (
                                <Radio
                                    //{...controlProps('e')}
                                    checked={true}
                                    sx={{
                                        color: green[800],
                                        '&.Mui-checked': {
                                            color: green[600],
                                        },
                                    }}
                                />
                            ) : (
                                productStatus[item.id] && productStatus[item.id].expiredSync && productStatus[item.id].nextSync && (
                                    moment(sydneyTime).isBetween(moment(productStatus[item.id].nextSync), moment(productStatus[item.id].expiredSync))
                                ) ? (
                                    <Radio
                                        //{...controlProps('e')}
                                        checked={true}
                                        sx={{
                                            color: yellow[800],
                                            '&.Mui-checked': {
                                                color: yellow[600],
                                            },
                                        }}
                                    />
                                ) : (
                                    productStatus[item.id] && productStatus[item.id].expiredSync && moment(sydneyTime).isAfter(moment(productStatus[item.id].expiredSync)) && (
                                        <Radio
                                            //{...controlProps('e')}
                                            checked={true}
                                            sx={{
                                                color: red[800],
                                                '&.Mui-checked': {
                                                    color: red[600],
                                                },
                                            }}
                                        />
                                    )
                                )
                            )}
                        </div>



                    </>
                ))}

            </div>
        );
    };



    return (
        <div className="grid-block">
            <div className="grid-block-header">
                Last Synced Log
            </div>

            {renderContent()}

        </div>
    );
}

export default DashboardGrid5;
