import { Check, Clear, Close, KeyboardArrowLeft } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Skeleton,
  styled,
  useTheme,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import BasicTableView1 from "../../../../Components/BasicTableView1";



const StyledHeaderTitle = styled(DialogTitle)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: "#fff",
  position: "relative",
  "& button": {
    position: "absolute",
    right: "15px",
    top: "15px",
    color: "#fff",
  },
}));




const AppBarTabs = styled(AppBar)(({ theme }) => ({
  background: "#fff",
  color: theme.palette.primary.dark,
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const ViewCsvDialog = (props) => {
  const theme = useTheme();
  const [value, setValue] = useState(0);

  const [branchState, setBranchState] = useState({
    orderBranch: "",
    orderBranchSet: "",
    lineBranch: [],
    lineBranchSet: []
  })

  const [dialogDetails, setDialogDetails] = useState({
    open: true,
  });
  const [productDetails, setProductDetails] = useState("");
  const [showActive, setShowActive] = useState(false);
  const [showPricePolicy, setShowPricePolicy] = useState(false);



  useEffect(() => {
    props.sendDetails(dialogDetails);

  }, [props, dialogDetails]);

  

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  

  const handleClose = () => {
    setDialogDetails({
      ...dialogDetails,
      open: false,
    });
  };

  const handleView = (row) => {
    setProductDetails(row);
  };


  

  const handleBack = () => {
    setProductDetails("");
  };

  const productColumns = [
    { id: "exoNumber", name: "Exo Order Number" },
    { id: "poNumber", name: "PO Number" },
    { id: "stockCode", name: "Stock Code" },
    { id: "name", name: "Stock Name" },
    { id: "quantity", name: "Quantity" },
    { id: "created_at", name: "Created At" },
  ];

  

  return (
    <div>
      <Dialog
        open={dialogDetails.open}
        onClose={handleClose}
        maxWidth="xl"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <StyledHeaderTitle id="alert-dialog-title">
          <div>View CSV Details</div>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </StyledHeaderTitle>
        {props.singleLoading ? (
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box p={3} sx={{ width: "100%" }}>
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                </Box>
              </Grid>
              
              
            </Grid>
          </DialogContent>
        ) : (
          <DialogContent style={{ margin: "0", padding: "0" }}>
            <AppBarTabs position="static">
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="secondary"
                textColor="inherit"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab label="CSV Details" {...a11yProps(0)} />
                
                
              </Tabs>
            </AppBarTabs>

        

       
            {/* CSV Details */}
            <TabPanel value={value} index={0} dir={theme.direction}>
          
                <BasicTableView1
                  columns={productColumns}
                  rows={props.viewDetails}
                  showActive={showActive}
                  showPricePolicy={showPricePolicy}
                  handleView={handleView}
                />
           
            </TabPanel>
            
            {/* CSV Details */}
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ViewCsvDialog;
