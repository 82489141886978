
import React, { useEffect, useState } from "react";
import {


    Skeleton

} from "@mui/material";

import { httpclient } from "../../../utils/httpClient";




const title = [

    { id: "SUMMIT 3PL", name: "SUMMIT 3PL" },
    { id: "SILVERWATER", name: "SILVERWATER" },
    { id: "ACR SYDNEY", name: "ACR SYDNEY" },
    { id: "SKU Logistics", name: "SKU Logistics" },

];

const DashboardGrid6 = () => {
    const [loading, setLoading] = useState(false);

    const [productStatus, setProductStatus] = useState({});


    useEffect(() => {
        fetchData();
        const intervalId = setInterval(fetchData, 60 * 1000); // Fetch data every 60 seconds
        return () => clearInterval(intervalId); // Cleanup the interval on component unmount
    }, []);

    const fetchData = () => {
        setLoading(true);
        httpclient.get(`realtime-status/total-order-assigned`).then(({ data }) => {
            if (data.success) {
                const productStatus = data.data;
                //console.log("branches", productStatus);
                setProductStatus(productStatus);
                setLoading(false);
            } else {
                console.log("Error!");
            }
        });
    };
   // console.log("ststye", productStatus);
    const renderContent = () => {
        if (loading) {
            return (
                <div className="grid-block-content">
                    {title.map((item) => (
                        <React.Fragment key={item.id}>
                            <div className="grid-item title">{item.name}</div>
                            <div className="grid-item content">
                                <Skeleton variant="text" />
                            </div>
                        </React.Fragment>
                    ))}
                </div>
            );
        }
        return (
            <div className="grid-block-content">
                {title.map((item) => {
                    let totalOrderAssigned = "";
                    for (let branchID in productStatus) {
                        const branch = productStatus[branchID];
                        if (branch.branch.branchName === item.name) {
                            totalOrderAssigned = branch.totalOrderAssigned;
                            break;
                        }
                    }
                    return (
                        <React.Fragment key={item.id}>
                            <div className="grid-item title">{item.name}</div>
                            <div className="grid-item content">{totalOrderAssigned || "0"}</div>
                        </React.Fragment>
                    );
                })}
            </div>
        );
    };




    return (
        <div className="grid-block">
            <div className="grid-block-header">
                Order Assigned to Branch
            </div>

            {renderContent()}

        </div>
    );
}

export default DashboardGrid6;
