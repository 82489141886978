import { Autocomplete, Grid, InputLabel, TextField } from "@mui/material";
import React from "react";
import useApiCall from "../../hooks/useApiCall";
const FilterAutoCompleteField = ({
  label,
  listDataFromUrl,
  name,
  value,
  onChange,
  onChangeAuto,
  onKeyDown,
  overrideDataField,
}) => {
  const { loading, error, data, pageInfo } = useApiCall(
    listDataFromUrl,
    `GET`,
    [],
    overrideDataField
  );

  //AUTO COMPLETE FILTER SECTION REFACTOR PENDING
  return (
    <Grid item xs={12} md={4}>
      <InputLabel>{label}</InputLabel>
      <Autocomplete
        disablePortal
        isOptionEqualToValue={(option, value) => option.id === value.id}
        options={data}
        inputValue={value}
        getOptionLabel={(option) => option.name}
        onChange={(event, newValue) => onChangeAuto(name, newValue)}
        renderInput={(params) => (
          <TextField {...params} value={value} onKeyDown={onKeyDown} />
        )}
      />
    </Grid>
  );
};

export default FilterAutoCompleteField;
