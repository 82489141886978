import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// const script = document.createElement('script');
// script.src = `https://maps.googleapis.com/maps/api/js?key='AIzaSyDIYIy1sOvGYB2-WxXdHCXnF0tBHyK5npo'&libraries=places`;
// document.head.appendChild(script);

// document.addEventListener('DOMContentLoaded', function() {
//   if (window.location.href === `${window.location.origin}/login`) {
//       // Redirect to the desired URL
//       window.location.href = `${window.location.origin}/#/login`;
//   }
//   });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
