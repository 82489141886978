import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";

const FilterSelectField = ({ label, value, name, onChange, onKeyDown, orderFile }) => {
  return (
    <>
      <Grid item xs={12} md={4}>
        <InputLabel>{label}</InputLabel>
        <FormControl fullWidth>
          <Select
            value={value}
            name={name}
            onChange={onChange}
            onKeyDown={onKeyDown}
          >
            <MenuItem value={""}>Select</MenuItem>
            <MenuItem value={orderFile ? 0 : "Y"}>Yes</MenuItem>
            <MenuItem value={orderFile ? 1 : "N"}>No</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </>
  );
};

export default FilterSelectField;
