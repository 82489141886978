
import React, { useEffect, useState } from "react";
import {
 
  Skeleton

} from "@mui/material";
import { httpclient } from "../../../utils/httpClient";

const title = [

  { id: "total_stocks", name: "Total Stocks" },
  { id: "sync_pending", name: "Sync Pending" },
  { id: "sync_completed", name: "Sync Completed" },
  { id: "synced_today", name: "Synced Today" },
  { id: "synced_yesterday", name: "Synced Yesterday" },
  { id: "synced_7days", name: "Synced 7 days" },
  
  
];

const DashboardGrid2 = () => {
    const [loading, setLoading] = useState(false);

    const [productStatus, setProductStatus] = useState({});

    useEffect(() => {
      fetchData();
      const intervalId = setInterval(fetchData, 60 * 1000); // Fetch data every 60 seconds
      return () => clearInterval(intervalId); // Cleanup the interval on component unmount
    }, []);
  
    const fetchData = () => {
      setLoading(true);
      httpclient
        .get(`realtime-status/new-system-product`)
        .then(({ data }) => {
          if (data.success) {
            setProductStatus(data.data);
            setLoading(false);
          } else {
            console.log("Error!");
          }
        });
    };

    const renderContent = () => {
      if (loading) {
        return (
          <div className="grid-block-content">
            {title.map((item) => (
              <>
                <div className="grid-item title">{item.name}</div>
                <div className="grid-item content">
                  <Skeleton variant="text" />
                </div>
              </>
            ))}
          </div>
        );
      }
      return (
        <div className="grid-block-content">
          {title.map((item) => (
            <>
              <div className="grid-item title">{item.name}</div>
              <div className="grid-item content">{productStatus[item.id]}</div>
            </>
          ))}
        </div>
      );
    };

  return (
    <div className="grid-block">
      <div className="grid-block-header">
      SyncCare Internal Process
      </div>
         
      {renderContent()}
      
    </div>
  );
}

export default DashboardGrid2;
