import React from "react";
import DataGridTablePage from "../../../Components/DataGridTablePage/DataGridTablePage";
import { Button, Grid, Typography } from "@mui/material";
import { dateFormatter } from "../../../utils/DataGrid/dateFormatter";
import { useNavigate } from "react-router-dom";
import PATH from "../../../Routing/PATHS";
import moment from "moment";

const filterColumn = [
  {
    field: "startDate",
    label: "Start Date",
    component: "TextField",
    type: "date",
  },
  {
    field: "endDate",
    label: "End Date",
    component: "TextField",
    type: "date",
  },
];

const ReplenishmentExcelFile = () => {
  const navigate = useNavigate();
  const fieldHeaderPairs = [
    {
      field: "date",
      headerName: "Date",
      valueFormatter: (params) => dateFormatter(params),
      width: 160,
    },

    { field: "fileName", headerName: "Name", width: 140 },
    { field: "description", headerName: "Description", width: 200 },
    // { field: "CUSTOMER_ID", headerName: "Customer Name" },
    {
      field: "soh_history_action",
      headerName: "SOH History",
      type: "actions",
      width: 170,

      renderCell: (cellValues) => (
        <>
          <Grid width={"100%"}>
            <Button
              variant="text"
              sx={{ margin: 0, marginLeft: "16px", padding: 0 }}
              onClick={(event) => {
                navigate(PATH.REPLENISHMENT_HISTORY_SOH, {
                  state: {
                    startDate: moment(cellValues.row.date).format("YYYY-MM-DD"),
                    fileName: cellValues.row.fileName,
                  },
                });
              }}
            >
              Open SOH History
            </Button>
          </Grid>
        </>
      ),
    },
    {
      field: "pipeline_action",
      headerName: "Pipeline",
      type: "actions",
      width: 130,

      renderCell: (cellValues) => (
        <>
          <Grid width={"100%"}>
            <Button
              variant="text"
              sx={{ margin: 0, padding: 0 }}
              size="small"
              style={{ marginLeft: 16 }}
              onClick={(event) => {
                navigate(PATH.REPLENISHMENT_PIPELINE, {
                  state: {
                    startDate: moment(cellValues.row.date).format("YYYY-MM-DD"),
                    fileName: cellValues.row.fileName,
                  },
                });
              }}
            >
              Open Pipeline
            </Button>
          </Grid>
        </>
      ),
    },
    {
      field: "store_soh_actions",
      headerName: "Store SOH",
      type: "actions",
      width: 160,

      renderCell: (cellValues) => (
        <>
          <Grid width={"100%"}>
            <Button
              variant="text"
              sx={{ margin: 0, padding: 0 }}
              size="small"
              style={{ marginLeft: 16 }}
              onClick={(event) => {
                navigate(PATH.REPLENISHMENT_STORESOH, {
                  state: {
                    startDate: moment(cellValues.row.date).format("YYYY-MM-DD"),
                    fileName: cellValues.row.fileName,
                  },
                });
              }}
            >
              Open Store SOH
            </Button>
          </Grid>
        </>
      ),
    },
    {
      field: "store_sales_actions",
      headerName: "Store Sales",
      type: "actions",
      width: 160,

      renderCell: (cellValues) => (
        <>
          <Grid width={"100%"}>
            <Button
              variant="text"
              sx={{ margin: 0, padding: 0 }}
              size="small"
              style={{ marginLeft: 16 }}
              onClick={(event) => {
                navigate(PATH.REPLENISHMENT_STORESALES, {
                  state: {
                    startDate: moment(cellValues.row.date).format("YYYY-MM-DD"),
                    fileName: cellValues.row.fileName,
                  },
                });
              }}
            >
              Open Store Sales
            </Button>
          </Grid>
        </>
      ),
    },
    {
      field: "msl_actions",
      headerName: "MSL Level",
      type: "actions",
      width: 160,

      renderCell: (cellValues) => (
        <>
          <Grid>
            <Button
              variant="text"
              sx={{ margin: 0, padding: 0 }}
              size="small"
              style={{ marginLeft: 16 }}
              onClick={(event) => {
                navigate(PATH.REPLENISHMENT_MSLLEVEL, {
                  state: {
                    startDate: moment(cellValues.row.date).format("YYYY-MM-DD"),
                    fileName: cellValues.row.fileName,
                  },
                });
              }}
            >
              Open MSI Level
            </Button>
          </Grid>
        </>
      ),
    },
  ];

  return (
    <Grid container width={"100%"}>
      <Grid item xs={12} md={12} sm={12}>
        <DataGridTablePage
          headerTitle="List Excel File"
          exportFeature={false}
          uploadFeature={false}
          text={true}
          uploadFile={true}
          columns={fieldHeaderPairs}
          filterColumn={filterColumn}
          pageUrl="replenishment/pull-excel-files"
          columnsVisibility={
            {
              // HIST_ID: false,
            }
          }
        />
      </Grid>
    </Grid>
  );
};

export default ReplenishmentExcelFile;
