export const extractCompanySettings = (data, companyIdToExtract) => {
  // Find settings for the specific companyID
  const companySettings = data.find(
    (item) => item.companyId === companyIdToExtract
  );
  if (companySettings) {
    const { companyId, ...rest } = companySettings;
    // Return settings excluding companyId
    return rest;
  } else {
    // Return an empty object if companySettings is undefined
    return {};
  }
};
