import { Check, Clear, Close } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Skeleton,
  styled,
  useTheme,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import BasicTable from "../../../../Components/BasicTable";


const StyledHeaderTitle = styled(DialogTitle)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: "#fff",
  position: "relative",
  "& button": {
    position: "absolute",
    right: "15px",
    top: "15px",
    color: "#fff",
  },
}));

const FlexContent = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  fontSize: "17px",
  marginBottom: "10px",
  alignItems: "flex-start",
}));

const FlexInnerTitle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  minWidth: "180px",
  maxWidth: "250px",
  fontWeight: "600",
}));

const Values = styled("div")(({ theme }) => ({
  marginLeft: "15px",
  fontWeight: "500",
  color: theme.palette.primary.dark,
}));

const AppBarTabs = styled(AppBar)(({ theme }) => ({
  background: "#fff",
  color: theme.palette.primary.dark,
}));



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}


const productColumns = [
  { id: "stockCode", name: "Stock Code" },
  { id: "stockName", name: "Stock Name" },
  { id: "price1", name: "Price" },
  { id: "startDate", name: "Start Date" },
  { id: "endDate", name: "End Date" },
];

const customerColumns = [
  { id: "erpAccountCustomerIDNet", name: "Customer ID" },
  { id: "erpAccountNameNet", name: "Customer Name" },
];


const ViewPolicyDialog = (props) => {
  const theme = useTheme();
  const [dialogDetails, setDialogDetails] = useState({
    open: true,
  });
  const [value, setValue] = useState(0);

  useEffect(() => {
    props.sendDetails(dialogDetails);
  }, [props, dialogDetails]);

  const handleClose = () => {
    setDialogDetails({
      ...dialogDetails,
      open: false,
    });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // console.log("props", props);

  return (
    <div>
      <Dialog
        open={dialogDetails.open}
        onClose={handleClose}
        maxWidth="xl"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <StyledHeaderTitle id="alert-dialog-title">
          <div>View Price Policy Details</div>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </StyledHeaderTitle>
        {props.singleLoading ? (
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box p={3} sx={{ width: "100%" }}>
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box p={3} sx={{ width: "100%" }}>
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
        ) : (
          <DialogContent style={{ padding:"0", margin:"0" }}>
          <AppBarTabs position="static">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="secondary"
              textColor="inherit"
              variant="fullWidth"
              aria-label="full width tabs example"
            >
              <Tab label="Policy Details" {...a11yProps(0)} />
              <Tab label="Customers" {...a11yProps(1)} />
              <Tab label="Product List" {...a11yProps(2)} />
            </Tabs>
          </AppBarTabs>

            {/* Prolicy Details */}
          <TabPanel value={value} index={0} dir={theme.direction}>
            <Box pt={3}>
              <Grid container spacing={2}>
                {/* Left Side */}
                <Grid item xs={12} md={6}>
                  <FlexContent>
                    <FlexInnerTitle>
                      <span>Policy ID</span> <span> : </span>
                    </FlexInnerTitle>
                    <Values>{props.viewDetails.policyID || "-"}</Values>
                  </FlexContent>

                  <FlexContent>
                    <FlexInnerTitle>
                      <span>Policy Reference</span> <span> : </span>
                    </FlexInnerTitle>
                    <Values>{props.viewDetails.policyReference || "-"}</Values>
                  </FlexContent>

                  {/* <FlexContent>
                    <FlexInnerTitle>
                      <span>Customer Name</span> <span> : </span>
                    </FlexInnerTitle>
                    <Values>
                      {(props.viewDetails.customer.length > 0 &&
                        props.viewDetails.customer[0].erpAccountCustomerID.erpAccountName) ||
                        "-"}
                    </Values>
                  </FlexContent> */}

                  <FlexContent>
                    <FlexInnerTitle>
                      <span>Policy Active</span> <span> : </span>
                    </FlexInnerTitle>
                    <Values>
                      {props.viewDetails.policyActive === "T" ? (
                        <Check color="primary" />
                      ) : (
                        <Clear color="primary" />
                      )}{" "}
                    </Values>
                  </FlexContent>

                  
                </Grid>
                {/* Left Side */}

                {/* Right Side */}
                <Grid item xs={12} md={6}>
                  <FlexContent>
                    <FlexInnerTitle>
                      <span>Policy Last Modified</span> <span> : </span>
                    </FlexInnerTitle>
                    <Values>
                      {moment(props.viewDetails.lastModified).format(
                        "ddd, MMM Do YYYY, h:mm a"
                      ) || "-"}
                    </Values>
                  </FlexContent>

                  <FlexContent>
                    <FlexInnerTitle>
                      <span>Policy Start Date</span> <span> : </span>
                    </FlexInnerTitle>
                    <Values>
                      {moment(props.viewDetails.startDate).format(
                        "ddd, MMM Do YYYY, h:mm a"
                      ) || "-"}
                    </Values>
                  </FlexContent>

                  <FlexContent>
                    <FlexInnerTitle>
                      <span>Policy End Date</span> <span> : </span>
                    </FlexInnerTitle>
                    <Values>
                      {moment(props.viewDetails.endDate).format(
                        "ddd, MMM Do YYYY, h:mm a"
                      ) || "-"}
                    </Values>
                  </FlexContent>

                  <FlexContent>
                    <FlexInnerTitle>
                      <span>Pending Process</span> <span> : </span>
                    </FlexInnerTitle>
                    <Values>
                      {props.viewDetails.pendingProcess === 1 ? (
                        <Check color="primary" />
                      ) : (
                        <Clear color="primary" />
                      )}{" "}
                    </Values>
                  </FlexContent>
                </Grid>


                {/* Right Side */}

                <Grid item xs={12}>
                  <FlexContent>
                    <FlexInnerTitle>
                      <span>Policy Reference Note</span> <span> : </span>
                    </FlexInnerTitle>
                    <Values>
                      {props.viewDetails.policyCustomerReferenceNote}
                    </Values>
                  </FlexContent>
                </Grid>
              </Grid>
            </Box>
            </TabPanel>
            {/* Prolicy Details */}

            {/* Product Price */}
            <TabPanel value={value} index={1} dir={theme.direction}>
              <BasicTable
                columns={customerColumns}
                rows={props.viewDetails.price_policy_customer_account}
              />
            </TabPanel>
            {/* Product Price */}
            
            {/* Product Price */}
            <TabPanel value={value} index={2} dir={theme.direction}>
              <BasicTable
                columns={productColumns}
                rows={props.viewDetails.price_policy_stock}
              />
            </TabPanel>
            {/* Product Price */}

          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ViewPolicyDialog;
