import { Check, Clear, Close, DoneAll, Error, KeyboardArrowLeft, LockOpen, Send, Sync, Visibility } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Skeleton,
  styled,
  useTheme,
  Snackbar,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import BasicTable from "../../../../Components/BasicTable";
import FsLightbox from "fslightbox-react";
import ViewPolicyDialog from "../../PricePolicy/ViewPolicyDialog";
import { httpclient } from "../../../../utils/httpClient";
import ProcessDialog from "../ProcessStock";
import MuiAlert from "@mui/material/Alert";
import parse from "html-react-parser";
import ProcessDialog1 from "../ProcessStock1";
import BasicTable2 from "../../../../Components/BasicTable2";
import featureServer from "../../../../utils/featureServer";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const StyledHeaderTitle = styled(DialogTitle)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: "#fff",
  position: "relative",
  "& button": {
    position: "absolute",
    right: "15px",
    top: "15px",
    color: "#fff",
  },
}));

const FlexContent = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  fontSize: "17px",
  marginBottom: "10px",
  alignItems: "flex-start",
}));

const FlexInnerTitle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  minWidth: "180px",
  maxWidth: "250px",
  fontWeight: "600",
}));

const FlexContent2 = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  fontSize: "17px",
}));

const FlexInnerTitle2 = styled("div")(({ theme }) => ({
  display: "flex",
  fontWeight: "600",
  gap: "5px",
  marginRight: "5px",
}));

const BoxDiv = styled("div")(({ theme }) => ({
  textAlign: "center",
}));

const Values = styled("div")(({ theme }) => ({
  marginLeft: "15px",
  fontWeight: "500",
  color: theme.palette.primary.dark,
}));

const ImageDiv = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
  flexWrap: "wrap",
  marginBottom: "10px",
}));

const ImageCell = styled("div")(({ theme }) => ({
  margin: "10px",
  width: "190px",
  borderRadius: "5px",
  overflow: "hidden",
  "& img": {
    width: "190px",
    height: "190px",
    objectFit: "cover",
    transition: "0.5s",
    boxShadow: theme.palette.primary.shadow,
    marginBottom: "10px",
    overflow: "hidden",
  },
  "& img:hover": {
    transform: "scale(1.1)",
  },
}));

const PricePolicyBox = styled(Box)(({ theme }) => ({
  display: "flex",
  marginBottom: "15px",
  "& h5": {
    margin: "5px",
  }
}))

const AppBarTabs = styled(AppBar)(({ theme }) => ({
  background: "#fff",
  color: theme.palette.primary.dark,
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const auditColumns = [
  { id: "sn", name: "SN" },
  { id: "log", name: "Log" },
  { id: "date", name: "Date" },
  { id: "actionBy", name: "Action By" },
]

const sohColumns = [
  { id: "erpBranchID", name: "Location ID" },
  { id: "erpBranchName", name: "Location Name" },
  { id: "comittedStock", name: "Comitted Stock" },
  { id: "totalInStock", name: "Total In Stock" },
  { id: "actualSOH", name: "Available Stock" },
];

const exoColumns = [
  { id: "LOCNO", name: "Location ID" },
  { id: "LNAME", name: "Location Name" },
  { id: "comittedStock", name: "Comitted Stock" },
  { id: "totalInStock", name: "Total In Stock" },
  { id: "actualSOH", name: "Available Stock" },
];

const soldColumns = [
  { id: "customerName", name: "Customer Name" },
  { id: "customerStockCode", name: "Customer Stock Code" },
  { id: "customerStockName", name: "Customer Stock Name" },
];
const shippingClassColumns = [
  { id: "class", name: "Class" },
  { id: "company_name", name: "Company Name" },

];

const ViewDetailsSystemDialog = (props) => {
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [sohValue, setSohValue] = useState(0);

  const [togglerLanding, setTogglerLanding] = useState(false);
  const [togglerLifestyle, setTogglerLifestyle] = useState(false);
  const [togglerDims, setTogglerDims] = useState(false);
  const [togglerFeature, setTogglerFeature] = useState(false);
  const [togglerOther, setTogglerOther] = useState(false);

  const [openPolicyDialog, setOpenPolicyDialog] = useState(false);
  const [viewDetails, setViewDetails] = useState({});
  const [singleLoading, setSingleLoading] = useState(false);

  const [imgIndex1, setImgIndex1] = useState(0);
  const [imgIndex2, setImgIndex2] = useState(0);
  const [imgIndex3, setImgIndex3] = useState(0);
  const [imgIndex4, setImgIndex4] = useState(0);
  const [imgIndex5, setImgIndex5] = useState(0);
  const [keyLoading, setKeyLoading] = useState(false);
  const [keyLoading1, setKeyLoading1] = useState(false);
  const [keyLoading2, setKeyLoading2] = useState(false);
  const [keyLoading3, setKeyLoading3] = useState(false);
  const [openProcessDialog, setOpenProcessDialog] = useState(false);
  const [openProcessDialog1, setOpenProcessDialog1] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [messageState, setMessageState] = useState("");

  const [auditValues, setAuditValues] = useState("");
  const [auditLoading, setAuditLoading] = useState(false);

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeSoh = (event, newValue) => {
    setSohValue(newValue);
  };

  const [dialogDetails, setDialogDetails] = useState({
    open: true,
    refetch: false,
    stockID: "",
  });

  useEffect(() => {
    props.sendDetails(dialogDetails);
  }, [props, dialogDetails]);

  useEffect(() => {

    const fetchData = () => {
      if (props.viewDetails.mydealPushStatus !== null && props.viewDetails.mydealPushStatus < 2) {
        handleRefetch();
      }
    };

    const intervalId = setInterval(fetchData, 60000);

    return () => {
      clearInterval(intervalId);
    };
  }, [props.viewDetails.mydealPushStatus]);

  const handleClose = () => {
    setDialogDetails({
      ...dialogDetails,
      open: false,
    });
  };

  const handleImageTogglerLanding = (index) => {
    setImgIndex1(index);
    setTogglerLanding((prev) => !prev);
  };

  const handleImageTogglerLifestyle = (index) => {
    setImgIndex2(index);
    setTogglerLifestyle((prev) => !prev);
  };

  const handleImageTogglerDims = (index) => {
    setImgIndex3(index);
    setTogglerDims((prev) => !prev);
  };
  const handleImageTogglerFeature = (index) => {
    setImgIndex5(index);
    setTogglerFeature((prev) => !prev);
  };

  const handleImageTogglerOther = (index) => {
    setImgIndex4(index);
    setTogglerOther((prev) => !prev);
  };

  var priceList = [];
  if (!props.singleLoading) {
    for (let i = 1; i <= 16; i++) {
      let priceVal = "props.viewDetails.prices.Price" + i;
      priceList.push(
        <Grid item xs={12} md={6}>
          <FlexContent>
            <FlexInnerTitle>
              <span>Selling Price {i}</span> <span> : </span>
            </FlexInnerTitle>
            <Values>
              {eval(priceVal) === 0 ? "-" : "$" + eval(priceVal)}
            </Values>
          </FlexContent>
        </Grid>
      );
    }
  }

  var boxesList = [];
  if (!props.singleLoading) {
    for (let i = 1; i <= 7; i++) {
      let boxBarCode = "props.viewDetails.dimension.box" + i + "Barcode";
      let boxLength = "props.viewDetails.dimension.box" + i + "Length";
      let boxWidth = "props.viewDetails.dimension.box" + i + "Width";
      let boxHeight = "props.viewDetails.dimension.box" + i + "Height";
      let boxWeight = "props.viewDetails.dimension.box" + i + "Weight";

      eval(boxLength) !== 0 &&
        boxesList.push(
          <Grid
            item
            xs={12}
            md={4}
            display="flex"
            flexDirection={"column"}
            alignItems="center"
          >
            <BoxDiv>
              <img
                src="/box.png"
                alt="box"
                style={{ width: "120px", height: "120px", objectFit: "cover" }}
              />

              <FlexContent2>
                <FlexInnerTitle2>
                  <span>L x W x H</span> <span> : </span>
                </FlexInnerTitle2>
                <div>
                  {eval(boxLength) +
                    " x " +
                    eval(boxWidth) +
                    " x " +
                    eval(boxHeight)}
                </div>
              </FlexContent2>

              <FlexContent2>
                <FlexInnerTitle2>
                  <span>Barcode</span> <span> : </span>
                </FlexInnerTitle2>
                <div>{eval(boxBarCode)}</div>
              </FlexContent2>
              <FlexContent2>
                <FlexInnerTitle2>
                  <span>Weight</span> <span> : </span>
                </FlexInnerTitle2>
                <div>{eval(boxWeight)} kg</div>
              </FlexContent2>
            </BoxDiv>
          </Grid>
        );
    }
  }

  const handleOpenPolicy = (id) => {
    setSingleLoading(true);
    setOpenPolicyDialog(true);
    httpclient.get(`new-system/price-policy/${id}`).then(({ data }) => {
      if (data.success) {
        setViewDetails(data.data);
        setSingleLoading(false);
      }
    });
  };

  const sendDetails = (callback) => {
    if (callback.open === false) {
      setOpenPolicyDialog(false);
      setViewDetails({});
    }
  };


  const handleRefetch = () => {

    setDialogDetails({
      open: true,
      refetch: true,
      stockID: props.viewDetails.stockID,
    });

    setTimeout(() => {
      setDialogDetails({
        open: true,
        refetch: false,
        stockID: "",
      });
    }, 100);

  };


  const handleViewAuditTrial = () => {
    setAuditLoading(true);
    httpclient(`new-system/mydeal-product-log/${props.viewDetails.ciStockCode}`).then(({ data }) => {
      if (data) {
        setAuditValues(data);
        setAuditLoading(false);
      }
    })
  }

  const handleBackFromAudit = () => {
    setAuditValues("");
  }

  const handleProcessDialogue = () => {
    setOpenProcessDialog(true);
  };

  const sendToProcess = (call) => {

    if (call.open === false) {
      setOpenProcessDialog(false);
    }
    if (call.success === true) {
      setKeyLoading(true);
      const api = featureServer ? `https://ubatemp.retailcare.com.au/php/bunnings/products-update/${props.viewDetails.ciStockCode}` : `https://uba.synccare.com.au/php/bunnings/products-update/${props.viewDetails.ciStockCode}`
      httpclient
        .get(
          api,
        )
        .then(({ data }) => {
          if (data.success) {
            setOpen(true);
            setMessageState("success");
            //setOpenActiveDialog(false);
            setMessage(data.message);
            setKeyLoading(false);
            //Refetching the data
            handleRefetch();
            //setProductDetails("");
          } else {
            setKeyLoading(false);
            setOpen(true);
            setMessageState("error");
            setMessage(data.error);
          }
        });
    }
  };

  const handleProcessDialogue1 = () => {
    setOpenProcessDialog1(true);
  };

  const sendToProcess1 = (call) => {

    if (call.open === false) {
      setOpenProcessDialog1(false);
    }
    if (call.success === true) {
      setKeyLoading1(true);
      httpclient
        .post(
          "new-system/push-mydeal", {
          stockCode: props.viewDetails.ciStockCode
        },
        )
        .then(({ data }) => {
          if (data.success) {
            setOpen(true);
            setMessageState("success");
            //setOpenActiveDialog(false);
            setMessage(data.message);
            setKeyLoading1(false);
            //Refetching the data
            handleRefetch();
            //setProductDetails("");
          } else {
            setKeyLoading1(false);
            setOpen(true);
            setMessageState("error");
            setMessage(data.error);
          }
        });
    }
  };

  const handleProcessDialogue2 = () => {
    setKeyLoading2(true);
    httpclient
      .post(
        `resync-stock-price/${props.viewDetails.ciStockCode}`
      )
      .then(({ data }) => {
        if (data.success) {
          setOpen(true);
          setMessageState("success");
          //setOpenActiveDialog(false);
          setMessage(data.message);
          setKeyLoading2(false);
          //Refetching the data
          //handleRefetch();
          //setProductDetails("");
        } else {
          setKeyLoading2(false);
          setOpen(true);
          setMessageState("error");
          setMessage(data.error);
        }
      });
  };
  const handleProcessDialogue3 = () => {
    setKeyLoading3(true);
    httpclient
      .post(
        `resync-stock-soh/${props.viewDetails.ciStockCode}`
      )
      .then(({ data }) => {
        if (data.success) {
          setOpen(true);
          setMessageState("success");
          //setOpenActiveDialog(false);
          setMessage(data.message);
          setKeyLoading3(false);
          //Refetching the data
          //handleRefetch();
          //setProductDetails("");
        } else {
          setKeyLoading3(false);
          setOpen(true);
          setMessageState("error");
          setMessage(data.error);
        }
      });
  };





  return (
    <div>
      <Dialog
        open={dialogDetails.open}
        onClose={handleClose}
        maxWidth="xl"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <StyledHeaderTitle id="alert-dialog-title">
          <div>
            View Stock Details{" "}
            {"(" +
              (props.viewDetails.ciStockCode || "-") +
              "/" +
              (props.viewDetails.ciStockName || "-") +
              ")"}
          </div>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </StyledHeaderTitle>
        {props.singleLoading ? (
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box p={3} sx={{ width: "100%" }}>
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box p={3} sx={{ width: "100%" }}>
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
        ) : (
          <DialogContent sx={{ padding: "0" }}>
            <AppBarTabs position="static">
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="secondary"
                textColor="inherit"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab label="Details" {...a11yProps(0)} />
                <Tab label="Images" {...a11yProps(1)} />
                <Tab label="Prices" {...a11yProps(2)} />
                <Tab label="SOH By Location" {...a11yProps(3)} />
                <Tab label="Boxes" {...a11yProps(4)} />
                <Tab label="Outstanding Orders" {...a11yProps(5)} />
                <Tab label="Sold To" {...a11yProps(6)} />
                <Tab label="Stock Shipping Class" {...a11yProps(6)} />
                <Tab label="Sync" {...a11yProps(7)} />
              </Tabs>
            </AppBarTabs>

            <TabPanel value={value} index={0} dir={theme.direction}>
              {auditValues ?
                <>
                  <Box display={"flex"} justifyContent={"space-between"}>
                    <h3>Audit Trial</h3>
                    <Button onClick={handleBackFromAudit}>
                      <KeyboardArrowLeft
                        fontSize="small"
                        sx={{ marginRight: "5px" }}
                      />{" "}
                      <span>Back</span>
                    </Button>
                  </Box>

                  <BasicTable2
                    columns={auditColumns}
                    rows={auditValues}
                  />

                </>
                :
                <Box >
                  <Grid container spacing={2}>
                    {/* Left Side */}
                    <Grid item xs={12} md={6}>
                      <FlexContent>
                        <FlexInnerTitle>
                          <span>Stock ID</span> <span> : </span>
                        </FlexInnerTitle>
                        <Values>{props.viewDetails.stockID || "-"}</Values>
                      </FlexContent>

                      <FlexContent>
                        <FlexInnerTitle>
                          <span>Stock Code</span> <span> : </span>
                        </FlexInnerTitle>
                        <Values>{props.viewDetails.ciStockCode || "-"}</Values>
                      </FlexContent>

                      <FlexContent>
                        <FlexInnerTitle>
                          <span>Stock Name</span> <span> : </span>
                        </FlexInnerTitle>
                        <Values>{props.viewDetails.ciStockName || "-"}</Values>
                      </FlexContent>
                      <FlexContent>
                        <FlexInnerTitle>
                          <span>Stock Brand Name</span> <span> : </span>
                        </FlexInnerTitle>
                        <Values>{props.viewDetails.ciStockBrand || "-"}</Values>
                      </FlexContent>

                      <FlexContent>
                        <FlexInnerTitle>
                          <span>Bar Code</span> <span> : </span>
                        </FlexInnerTitle>
                        <Values>{props.viewDetails.ciBarCode || "-"}</Values>
                      </FlexContent>

                      <FlexContent>
                        <FlexInnerTitle>
                          <span>Number of boxes</span> <span> : </span>
                        </FlexInnerTitle>
                        <Values>
                          <span
                            style={{
                              cursor: "pointer",
                              display: "flex",
                              alignItems: "center",
                            }}
                            onClick={() => setValue(4)}
                          >
                            {props.viewDetails.numberOfBoxes}
                            <Visibility
                              color="primary"
                              fontSize="small"
                              style={{ marginLeft: "5px" }}
                            />
                          </span>
                        </Values>
                      </FlexContent>

                      <FlexContent>
                        <FlexInnerTitle>
                          <span>Dimension</span> <span> : </span>
                        </FlexInnerTitle>
                        <Values>{props.viewDetails.stockAssembledDimension || "-"}</Values>
                      </FlexContent>



                      <FlexContent>
                        <FlexInnerTitle>
                          <span>Stock Group 1</span> <span> : </span>
                        </FlexInnerTitle>
                        <Values>
                          {props.viewDetails.stockGroupID1[0]
                            ? props.viewDetails.stockGroupID1[0].name
                            : "-"}
                        </Values>
                      </FlexContent>

                      <FlexContent>
                        <FlexInnerTitle>
                          <span>Stock Group 2</span> <span> : </span>
                        </FlexInnerTitle>
                        <Values>
                          {props.viewDetails.stockGroupID2[0]
                            ? props.viewDetails.stockGroupID2[0].name
                            : "-"}
                        </Values>
                      </FlexContent>

                      {(props.viewDetails.stockExclusiveToClient !== "N/A" || props.viewDetails.stockExclusiveToClient !== "") ?
                        <>

                          <FlexContent>
                            <FlexInnerTitle>
                              <span>Exclusive Start Date</span> <span> : </span>
                            </FlexInnerTitle>
                            <Values>
                              {moment(
                                props.viewDetails.stockExclusiveStartDate
                              ).format("ddd, MMM Do YYYY, h:mm a") || "-"}
                            </Values>
                          </FlexContent>

                          <FlexContent>
                            <FlexInnerTitle>
                              <span>Exclusive End Date</span> <span> : </span>
                            </FlexInnerTitle>
                            <Values>
                              {moment(props.viewDetails.stockExclusiveEndDate).format(
                                "ddd, MMM Do YYYY, h:mm a"
                              ) || "-"}
                            </Values>
                          </FlexContent>

                          <FlexContent>
                            <FlexInnerTitle>
                              <span>Exclusive To</span> <span> : </span>

                            </FlexInnerTitle>
                            <Values>
                              {props.viewDetails.stockExclusiveToClient || "-"}
                            </Values>
                          </FlexContent>
                        </>
                        : null
                      }

                    </Grid>
                    {/* Left Side */}

                    {/* Right Side */}
                    <Grid item xs={12} md={6}>
                      <FlexContent>
                        <FlexInnerTitle>
                          <span>Active</span> <span> : </span>
                        </FlexInnerTitle>
                        <Values>
                          {props.viewDetails.Active === "Y" ? (
                            <Check color="primary" />
                          ) : (
                            <Clear color="primary" /> || "-"
                          )}
                        </Values>
                      </FlexContent>

                      <FlexContent>
                        <FlexInnerTitle>
                          <span>Average Cost</span> <span> : </span>
                        </FlexInnerTitle>
                        <Values>
                          {"$" + props.viewDetails.averageCost.toFixed(2) || "-"}
                        </Values>
                      </FlexContent>

                      <FlexContent>
                        <FlexInnerTitle>
                          <span>Latest Cost</span> <span> : </span>
                        </FlexInnerTitle>
                        <Values>
                          {"$" + props.viewDetails.latestCost.toFixed(2) || "-"}
                        </Values>
                      </FlexContent>

                      <FlexContent>
                        <FlexInnerTitle>
                          <span>Supplier Cost</span> <span> : </span>
                        </FlexInnerTitle>
                        <Values>
                          {"$" + props.viewDetails.supplierCost.toFixed(2) || "-"}
                        </Values>
                      </FlexContent>

                      {/* <FlexContent>
                      <FlexInnerTitle>
                        <span>Weight</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>{props.viewDetails.weight + "kg" || "-"}</Values>
                    </FlexContent> */}

                      <FlexContent>
                        <FlexInnerTitle>
                          <span>Price With Vat</span> <span> : </span>
                        </FlexInnerTitle>
                        <Values>
                          {"$" +
                            props.viewDetails.ciSalePricewithVAT.toFixed(2) ||
                            "-"}
                        </Values>
                      </FlexContent>

                      <FlexContent>
                        <FlexInnerTitle>
                          <span>Supplier</span> <span> : </span>
                        </FlexInnerTitle>
                        <Values>
                          {props.viewDetails.supplier.supplierName || "-"}
                        </Values>
                      </FlexContent>

                      <FlexContent>
                        <FlexInnerTitle>
                          <span>Pending Process</span> <span> : </span>
                        </FlexInnerTitle>
                        <Values>
                          {props.viewDetails.pendingProcess === "1" ? (
                            <Check color="primary" />
                          ) : (
                            <Clear color="primary" /> || "-"
                          )}
                        </Values>
                      </FlexContent>

                      <FlexContent>
                        <FlexInnerTitle>
                          <span>Price Pending</span> <span> : </span>
                        </FlexInnerTitle>
                        <Values>
                          {props.viewDetails.pricePending === "1" ? (
                            <Check color="primary" />
                          ) : (
                            <Clear color="primary" /> || "-"
                          )}
                        </Values>
                      </FlexContent>

                      <FlexContent>
                        <FlexInnerTitle>
                          <span>Changed Date/Time</span> <span> : </span>
                        </FlexInnerTitle>
                        <Values>
                          {props.viewDetails.ciSOHChangedDateTime !==
                            "0000-00-00 00:00:00"
                            ? moment(
                              props.viewDetails.ciSOHChangedDateTime
                            ).format("dddd, MMMM Do YYYY, h:mm a")
                            : "-" || "-"}
                        </Values>
                      </FlexContent>

                      <FlexContent>
                        <FlexInnerTitle>
                          <span>Stock Modified Date</span> <span> : </span>
                        </FlexInnerTitle>
                        <Values>
                          {moment(
                            props.viewDetails.ciLastModifiedDateTime
                          ).format("ddd, MMM Do YYYY, h:mm a") || "-"}
                        </Values>
                      </FlexContent>




                      {/* <FlexContent>
                        <FlexInnerTitle></FlexInnerTitle>
                        <Values style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                          <Button size="small"
                            variant="contained"
                            disabled={auditLoading}
                            onClick={handleViewAuditTrial}>
                            <LockOpen fontSize="small" style={{ marginRight: "5px" }} />  View Audit Trial
                          </Button>
                        </Values>
                      </FlexContent> */}

                    </Grid>
                    {/* Right Side */}

                    <Grid item xs={12}>
                      <FlexContent>
                        <FlexInnerTitle>
                          <span>Long Description</span> <span> : </span>
                        </FlexInnerTitle>
                        <Values>
                          {props.viewDetails.ciShortDescription || "-"}
                        </Values>
                      </FlexContent>
                    </Grid>

                    <Grid item xs={12}>
                      <FlexContent>
                        <FlexInnerTitle>
                          <span>Sales Text</span> <span> : </span>
                        </FlexInnerTitle>
                        <Values>
                          {parse(props.viewDetails.ciLongDescription || "-")}
                          {/* {props.viewDetails.soldTO[0].customerID === 16 && "Required"} */}
                        </Values>
                      </FlexContent>
                    </Grid>
                  </Grid>
                </Box>
              }
            </TabPanel>

            <TabPanel value={value} index={1} dir={theme.direction}>
              <Box>
                {props.viewDetails.image.landingImages.length > 0 && (
                  <>
                    <h3>Landing Images</h3>
                    <ImageDiv>
                      {props.viewDetails.image.landingImages.map(
                        (imgs, index) => (
                          <ImageCell
                            onClick={() => handleImageTogglerLanding(index)}
                          >
                            <img src={imgs} alt="landing_image" />
                            <Box textAlign="center">
                              {imgs.split("/").pop().replaceAll(".jpg", "").replaceAll(".png", "")}
                            </Box>
                          </ImageCell>
                        )
                      )}
                      <FsLightbox
                        toggler={togglerLanding}
                        sources={props.viewDetails.image.landingImages}
                        sourceIndex={imgIndex1}
                        type="image"
                        types={[
                          ...new Array(props.viewDetails.image.landingImages.length).fill('image')
                        ]}
                      />
                    </ImageDiv>
                  </>
                )}

                {props.viewDetails.image.lifestyleImages.length > 0 && (
                  <>
                    <h3>Lifestyle Images</h3>
                    <ImageDiv>
                      {props.viewDetails.image.lifestyleImages.map(
                        (imgs, index) => (
                          <ImageCell
                            onClick={() => handleImageTogglerLifestyle(index)}
                          >
                            <img src={imgs} alt="lifestyle_image" />
                            <Box textAlign="center">
                              {imgs.split("/").pop().replaceAll(".jpg", "").replaceAll(".png", "")}
                            </Box>
                          </ImageCell>
                        )
                      )}
                      <FsLightbox
                        toggler={togglerLifestyle}
                        sources={props.viewDetails.image.lifestyleImages}
                        sourceIndex={imgIndex2}
                        type="image"
                        types={[
                          ...new Array(props.viewDetails.image.lifestyleImages.length).fill('image')
                        ]}
                      />
                    </ImageDiv>
                  </>
                )}

                {props.viewDetails.image.dimsImages.length > 0 && (
                  <>
                    <h3>Dims Images</h3>
                    <ImageDiv>
                      {props.viewDetails.image.dimsImages.map((imgs, index) => (
                        <ImageCell
                          onClick={() => handleImageTogglerDims(index)}
                        >
                          <img src={imgs} alt="dims_image" />
                          <Box textAlign="center">
                            {imgs.split("/").pop().replaceAll(".jpg", "").replaceAll(".png", "")}
                          </Box>
                        </ImageCell>
                      ))}
                      <FsLightbox
                        toggler={togglerDims}
                        sources={props.viewDetails.image.dimsImages}
                        sourceIndex={imgIndex3}
                        type="image"
                        types={[
                          ...new Array(props.viewDetails.image.dimsImages.length).fill('image')
                        ]}
                      />
                    </ImageDiv>
                  </>
                )}
                {props.viewDetails.image.featureImages.length > 0 && (
                  <>
                    <h3>Feature Images</h3>
                    <ImageDiv>
                      {props.viewDetails.image.featureImages.map(
                        (imgs, index) => (
                          <ImageCell
                            onClick={() => handleImageTogglerFeature(index)}
                          >
                            <img src={imgs} alt="feature_image" />
                            <Box textAlign="center">
                              {imgs.split("/").pop().replaceAll(".jpg", "").replaceAll(".png", "")}
                            </Box>
                          </ImageCell>
                        )
                      )}
                      <FsLightbox
                        toggler={togglerFeature}
                        sources={props.viewDetails.image.featureImages}
                        sourceIndex={imgIndex5}
                        type="image"
                        types={[
                          ...new Array(props.viewDetails.image.featureImages.length).fill('image')
                        ]}
                      />
                    </ImageDiv>
                  </>
                )}

                {props.viewDetails.image.Otherimages.length > 0 && (
                  <>
                    <h3>Other Images</h3>
                    <ImageDiv>
                      {props.viewDetails.image.Otherimages.map(
                        (imgs, index) => (
                          <ImageCell
                            onClick={() => handleImageTogglerOther(index)}
                          >
                            <img src={imgs} alt="landing_image" />
                            <Box textAlign="center">
                              {imgs.split("/").pop().replaceAll(".jpg", "").replaceAll(".png", "")}
                            </Box>
                          </ImageCell>
                        )
                      )}
                      <FsLightbox
                        toggler={togglerOther}
                        sources={props.viewDetails.image.Otherimages}
                        sourceIndex={imgIndex4}
                        type="image"
                        types={[
                          ...new Array(props.viewDetails.image.Otherimages.length).fill('image')
                        ]}
                      />
                    </ImageDiv>
                  </>
                )}

                {props.viewDetails.image.landingImages.length === 0 &&
                  props.viewDetails.image.lifestyleImages.length === 0 &&
                  props.viewDetails.image.dimsImages.length === 0 &&
                  props.viewDetails.image.featureImages.length === 0 &&
                  props.viewDetails.image.Otherimages.length === 0 && (
                    <h4>Image not available</h4>
                  )}
              </Box>
            </TabPanel>

            <TabPanel value={value} index={2} dir={theme.direction}>
              <Box >
                {/* <Grid container spacing={2}>
                  {priceList}
                </Grid> */}

                <Grid container spacing={2}>
                  <Grid item xs={12} md={7}>
                    <Grid container spacing={2}>
                      {priceList}
                    </Grid>
                  </Grid>
                  {props.viewDetails.pricePolicy.length > 0 && (
                    <Grid item xs={12} md={5}>
                      <Box
                        pl={3}
                        style={{
                          borderLeft: "1px solid #999",
                          height: "100%",
                        }}
                      >
                        <h4 style={{ marginTop: "0", marginBottom: "20px", paddingBottom: "5px", borderBottom: "2px solid #ccc" }}>Active Price Policy</h4>
                        {props.viewDetails.pricePolicy.length > 0
                          ? props.viewDetails.pricePolicy.map((policy, index) => (
                            <PricePolicyBox>
                              <div style={{ position: "relative", top: "2px" }}>
                                <small> {index + 1} {". "}</small>
                              </div>
                              <div>
                                <h5>Policy Reference :{" "} <span style={{ cursor: "pointer", color: "#3f51b5" }} onClick={() => handleOpenPolicy(policy.erpPolicyID)} >{policy.policyReference + "[" + policy.erpPolicyID + "]" || "-"}{" "}</span></h5>
                                <h5>Price : {"$" + policy.price || "-"} </h5>
                                <h5>Start Date :  {moment(policy.startDate).format("ddd, MMM Do YYYY, h:mm a") || "-"}</h5>
                                <h5>End Date :  {moment(policy.endDate).format("ddd, MMM Do YYYY, h:mm a") || "-"}</h5>
                                <h5>Active:
                                  <span style={{ position: "relative", top: "5px" }}>
                                    {policy.policyActive === "Y" ? (
                                      <Check fontSize="small" color="primary" />
                                    ) : (
                                      <Clear fontSize="small" color="primary" />
                                    )}
                                  </span>
                                </h5>

                              </div>
                            </PricePolicyBox>
                          ))
                          : ""}
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </TabPanel>

            <TabPanel value={value} index={3} dir={theme.direction}>
              <Tabs
                value={sohValue}
                onChange={handleChangeSoh}
                indicatorColor="secondary"
                textColor="inherit"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab label="SyncCare SOH" {...a11yProps(0)} />
                <Tab label="Exo SOH" {...a11yProps(1)} />
              </Tabs>

              <TabPanel value={sohValue} index={0} dir={theme.direction}>
                <BasicTable
                  columns={sohColumns}
                  rows={props.viewDetails.stockOnHand}
                  stocks={true}
                />
              </TabPanel>

              <TabPanel value={sohValue} index={1} dir={theme.direction}>
                <BasicTable
                  columns={exoColumns}
                  rows={props.viewDetails.exoStockOnHand}
                  stocks={true}
                />
              </TabPanel>
            </TabPanel>

            <TabPanel value={value} index={4} dir={theme.direction}>
              <Grid container>
                <Grid item xs={12} md={9}>
                  <Box p={3}>
                    <Grid container spacing={2}>
                      {boxesList.length > 0 ? boxesList : <h4>No Box Found</h4>}
                    </Grid>
                  </Box>
                </Grid>

                <Grid item xs={12} md={3}>
                  {props.viewDetails.cartonInnerQty ||
                    props.viewDetails.cartonQty ? (
                    <Box
                      p={3}
                      style={{ borderLeft: "1px solid #999", height: "100%" }}
                    >
                      <h4>
                        {" "}
                        Carton Inner Quantity :{" "}
                        {props.viewDetails.cartonInnerQty || ""}{" "}
                      </h4>
                      <h4>
                        Carton Quantity : {props.viewDetails.cartonQty || ""}{" "}
                      </h4>
                    </Box>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            </TabPanel>

            <TabPanel value={value} index={5} dir={theme.direction}>
              <Box p={3}>
                <h4>No Outstanding Orders Found</h4>
              </Box>
            </TabPanel>

            <TabPanel value={value} index={6} dir={theme.direction}>
              {props.viewDetails.soldTO.length > 0 ? (
                <Box p={3}>
                  <Grid container spacing={2}>
                    <BasicTable
                      columns={soldColumns}
                      rows={props.viewDetails.soldTO}
                    />
                  </Grid>
                </Box>
              )
                :
                (
                  <Box p={3}>
                    <span><h4>No Sold To details found.</h4></span>
                  </Box>
                )}
            </TabPanel>
            <TabPanel value={value} index={7} dir={theme.direction}>
              {props.viewDetails.stockShippingClass?.length > 0 ? (
                <Box p={3}>
                  <Grid container spacing={2}>
                    <BasicTable
                      columns={shippingClassColumns}
                      rows={props.viewDetails.stockShippingClass}
                    />
                  </Grid>
                </Box>
              )
                :
                (
                  <Box p={3}>
                    <span><h4>No Stock Shipping Class details found.</h4></span>
                  </Box>
                )}
            </TabPanel>
            <TabPanel value={value} index={8} dir={theme.direction}>
              <Box>
                <Grid container spacing={2}>
                  {/* Left Side */}
                  <Grid item xs={12} md={6}>
                    {(props.viewDetails.Active === "Y" && props.viewDetails.soldTO.some((stock) => stock.customerID === 28))
                      ? (
                        <FlexContent>
                          <FlexInnerTitle><span>Sync To Bunnings</span> <span> : </span></FlexInnerTitle>
                          <Values style={{ display: "flex", justifyContent: "flex-start", width: "100%" }}>


                            <>
                              {keyLoading ? (
                                <CircularProgress
                                  style={{
                                    height: "25px",
                                    width: "25px",
                                    marginLeft: "10px",
                                    position: "relative",
                                    //top: "10px",
                                  }}
                                />
                              ) : (
                                <>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleProcessDialogue()}
                                    disableElevation
                                  >

                                    Push this stock to bunnings
                                    <Send
                                      fontSize="small"
                                      style={{ marginLeft: "5px" }}
                                    />

                                  </Button>

                                </>
                              )}

                            </>

                          </Values>
                        </FlexContent>
                      ) : null}

                    {(props.viewDetails.Active === "Y" && props.viewDetails.soldTO.some((stock) => stock.customerID === 16))
                      ? (
                        <FlexContent>
                          <FlexInnerTitle><span>Sync To MyDeal</span> <span> : </span></FlexInnerTitle>
                          <Values style={{ display: "flex", justifyContent: "flex-start", width: "100%" }}>


                            <>
                              {keyLoading1 ? (
                                <CircularProgress
                                  style={{
                                    height: "25px",
                                    width: "25px",
                                    marginLeft: "10px",
                                    position: "relative",
                                    //top: "10px",
                                  }}
                                />
                              ) : (
                                <>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleProcessDialogue1()}
                                    disableElevation
                                    disabled={props.viewDetails.mydealPushStatus === 1 || props.viewDetails.mydealPushStatus === 2}
                                  >
                                    {props.viewDetails.mydealPushStatus === 1 ? (
                                      <>
                                        Stock Push In Progress
                                        <CircularProgress fontSize="small" style={{
                                          color: "#888",
                                          height: "25px",
                                          width: "25px",
                                          marginLeft: "10px",
                                          position: "relative",

                                        }} />
                                      </>
                                    ) : props.viewDetails.mydealPushStatus === 2 ? (
                                      <>
                                        Stock Pushed To MyDeal
                                        {/* Replace the Send icon with the icon you want for this condition */}
                                        <DoneAll fontSize="small" style={{
                                          height: "25px",
                                          width: "25px",
                                          marginLeft: "10px",
                                          position: "relative",

                                        }} />
                                      </>
                                    ) : props.viewDetails.mydealPushStatus === 3 ? (
                                      <>
                                        Failed To Push
                                        {/* Replace the Send icon with the icon you want for this condition */}
                                        <Error fontSize="small" style={{
                                          color: "red",
                                          height: "25px",
                                          width: "25px",
                                          marginLeft: "10px",
                                          position: "relative",

                                        }} />
                                      </>
                                    ) : (
                                      <>
                                        Push this stock to MyDeal
                                        <Send fontSize="small" style={{ marginLeft: "5px" }} />
                                      </>
                                    )}
                                  </Button>
                                </>
                              )}
                            </>
                          </Values>
                        </FlexContent>
                      ) : null}
                    {featureServer &&
                      <>
                        <FlexContent>
                          <FlexInnerTitle>
                            <span>Resync Price</span> <span> : </span>
                          </FlexInnerTitle>
                          <Values style={{ display: "flex", justifyContent: "flex-start", width: "100%" }}>


                            <>
                              {keyLoading2 ? (
                                <CircularProgress
                                  style={{
                                    height: "25px",
                                    width: "25px",
                                    marginLeft: "10px",
                                    position: "relative",

                                  }}
                                />
                              ) : (
                                <>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleProcessDialogue2()}
                                    disableElevation
                                  >

                                    Resync Stock Price
                                    <Sync
                                      fontSize="small"
                                      style={{ marginLeft: "5px" }}
                                    />

                                  </Button>

                                </>
                              )}

                            </>

                          </Values>
                        </FlexContent>
                        <FlexContent>
                          <FlexInnerTitle>
                            <span>Resync SOH</span> <span> : </span>
                          </FlexInnerTitle>
                          <Values style={{ display: "flex", justifyContent: "flex-start", width: "100%" }}>


                            <>
                              {keyLoading3 ? (
                                <CircularProgress
                                  style={{
                                    height: "25px",
                                    width: "25px",
                                    marginLeft: "10px",
                                    position: "relative",

                                  }}
                                />
                              ) : (
                                <>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleProcessDialogue3()}
                                    disableElevation
                                  >

                                    Resync Stock SOH
                                    <Sync
                                      fontSize="small"
                                      style={{ marginLeft: "5px" }}
                                    />

                                  </Button>

                                </>
                              )}

                            </>

                          </Values>
                        </FlexContent>
                      </>
                    }
                  </Grid>
                </Grid>
              </Box>
            </TabPanel>
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {openProcessDialog && (
        <ProcessDialog
          sendToProcess={sendToProcess}
          viewDetails={props.viewDetails}
        />
      )}
      {openProcessDialog1 && (
        <ProcessDialog1

          sendToProcess1={sendToProcess1}
          viewDetails={props.viewDetails}
        />
      )}

      {openPolicyDialog && (
        <ViewPolicyDialog singleLoading={singleLoading} viewDetails={viewDetails} sendDetails={sendDetails} />
      )}

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={handleCloseSnack}
      >
        <Alert
          onClose={handleCloseSnack}
          severity={messageState}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ViewDetailsSystemDialog;
