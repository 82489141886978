import React, { useEffect, useState, useRef } from "react";
import {
    ArrowForward,
    Close,
    Download,
    FilterList,
} from "@mui/icons-material";

import { httpclient } from "../../../../utils/httpClient";
import {
    Box,
    Button,
    Card,
    Collapse,
    Grid,
    InputLabel,
    styled,
    TextField,
    Snackbar,
    Select,
    MenuItem,
    FormControl,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { useLocation, useNavigate } from "react-router";
import { CSVLink } from "react-csv";
import { dateFormatter } from "../../../../utils/DataGrid/dateFormatter";
import TWTableComponent from "../Temple&WebsterTable/TWTableComponent";
import ViewContainer from "./ViewContainer";


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});



const columns = [
    { id: "ID", name: "" },
    { id: "cusDispatchDate", name: "Customer Dispatch Date" },
    { id: "ponNum", name: "Inbound Shipment" },
    { id: "containerName", name: "Container Name" },
    { id: "status", name: "Status" },
    { id: "totalSku", name: "Total SKU" },

    //   { id: "url", name: "Download" },
];

const FilteredBox = styled(Box)(({ theme }) => ({
    background: "#f9f9f9",
    padding: "5px 10px",
    borderRadius: "5px",
    "& p": {
        margin: "0",
        marginRight: "10px",
        display: "inline-block",
        background: "#dedede",
        borderRadius: "10px",
        padding: "2px 5px",
    },
    "& svg": {
        fontSize: "15px",
        cursor: "pointer",
        position: "relative",
        top: "3px",
        background: theme.palette.primary.dark,
        color: "#fff",
        borderRadius: "50%",
        padding: "2px",
        marginLeft: "2px",
    },
}));

const Header = styled("div")(({ theme }) => ({
    "& h1": {
        color: theme.palette.primary.dark,
        margin: "0",
    },
}));

const configRowPerPage = JSON.parse(localStorage.getItem("configRowPerPage"));

const TWNetSuiteContainer = () => {
    const navigate = useNavigate();
    const buttonRef = useRef(null);
    const [openViewDialog, setOpenViewDialog] = useState(false);
    const [viewDetails, setViewDetails] = useState({});

    const [rows, setRows] = useState([]);
    const [rowChecked, setRowChecked] = useState([]);

    const [loading, setLoading] = useState(false);
    const [loadingExport, setLoadingExport] = useState(false);
    const [singleLoading, setSingleLoading] = useState(false);
    const [direction, setDirection] = useState(false);
    const [currentColumn, setCurrentColumn] = useState("");
    const [page, setPage] = useState(1);
    const [from, setFrom] = useState(1);
    const [to, setTo] = useState(
        configRowPerPage && configRowPerPage
            ? configRowPerPage && configRowPerPage
            : 20
    );

    const [rowsPerPage, setRowsPerPage] = useState(
        configRowPerPage && configRowPerPage
            ? configRowPerPage && configRowPerPage
            : 20
    );
    const [total, setTotal] = useState("");
    const [filterOpen, setFilterOpen] = useState(false);

    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [messageState, setMessageState] = useState("");
    const [exportFile, setExportFile] = useState(false);
    const [exportRows, setExportRows] = useState("");
    const [shipment, setShipment] = useState([]);

    const [filterData, setFilterData] = useState({
        containerName: "",
        poNum: "",
        orderNo: "",
        order_status: "",
        from: null,
        to: null,
        remove: false,
    });

    const [submittedData, setSubmittedData] = useState({
        containerName: "",
        poNum: "",
        orderNo: "",
        order_status: "",
        from: null,
        to: null,
        submit: false,
    });

    // useEffect(() => {
    //   getOrdersException();
    // }, []);


    useEffect(() => {
        if (
            filterData.containerName === "" &&
            filterData.poNum === "" &&
            filterData.orderNo === "" &&
            filterData.order_status === "" &&
            filterData.from === "" &&
            filterData.to === ""
        ) {
            setSubmittedData({
                ...submittedData,
                submit: false,
            });
        }

        if (filterData.containerName === " ") filterData.containerName = "";
        if (filterData.poNum === " ") filterData.poNum = "";
        if (filterData.orderNo === " ") filterData.orderNo = "";
        if (filterData.order_status === " ") filterData.order_status = "";
        if (filterData.from === " ") filterData.from = "";
        if (filterData.to === " ") filterData.to = "";

        filterData.remove === true && handleFilter();
    }, [filterData]);

    useEffect(() => {
        let currentpolicy = JSON.parse(
            localStorage.getItem("netsuite_container_filter")
        );
        currentpolicy !== null && setFilterData(currentpolicy);

        currentpolicy == null
            ? getOrdersException()
            : currentpolicy.containerName == "" &&
                currentpolicy.poNum == "" &&
                currentpolicy.orderNo == "" &&
                currentpolicy.order_status == "" &&
                currentpolicy.from == "" &&
                currentpolicy.to == "" &&
                currentpolicy.removed == false
                ? getOrdersException()
                : console.log("orders price exception");
    }, []);




    const getOrdersException = () => {
        setLoading(true);
        httpclient
            .get(`temple-webster/netsuite-container-list?pagination=${rowsPerPage}`)
            .then(({ data }) => {
                if (data) {
                    setRows(data.data);
                    setShipment(data.shipmentStatus);
                    setTotal(data.meta.total);
                    setRowsPerPage(parseInt(data.meta.per_page));
                    setPage(data.meta.current_page);
                    setFrom(data.meta.from);
                    setTo(data.meta.to);
                    setLoading(false);
                }
            }).catch(err => {
                if (err.response.status === 401) {
                    localStorage.clear();
                    window.location = "/login";
                }
            })
    };

    const handleView = (row) => {
        setSingleLoading(true);
        setOpenViewDialog(true);
        httpclient
            .get(`temple-webster/netsuite-container-details/${row.ID}`)
            .then(({ data }) => {
                if (data) {
                    setViewDetails(data);
                    setSingleLoading(false);
                }
            })
    };

    const sendDetails = (callback) => {
        if (callback.open === false) {
            setOpenViewDialog(false);
            setViewDetails({});
        }
    };

    const handleRefetch = () => {
        getOrdersException();
    }

    const handleFilter = () => {
        setSubmittedData({
            ...submittedData,

            containerName: filterData.containerName,
            poNum: filterData.poNum,
            orderNo: filterData.orderNo,
            order_status: filterData.order_status,
            from: filterData.from,
            to: filterData.to,
            submit: true,
        });
        filterData.remove = true;
        localStorage.setItem(
            "netsuite_container_filter",
            JSON.stringify(filterData)
        );
        setLoading(true);
        if (
            filterData.containerName ||
            filterData.poNum ||
            filterData.orderNo ||
            filterData.order_status ||
            filterData.fileName ||
            filterData.from ||
            filterData.to
        ) {
            setExportRows("");
            httpclient
                .get(
                    `temple-webster/netsuite-container-list?containerName=${filterData.containerName
                    }&status=${filterData.order_status
                    }&ponNum=${filterData.poNum
                    }&stockCode=${filterData.orderNo
                    }&startDate=${filterData.from || ""}&endDate=${filterData.to || ""
                    }&pagination=${rowsPerPage}&page=${1}`
                )
                .then(({ data }) => {
                    if (data.success) {
                        setRows(data.data);
                        setShipment(data.shipmentStatus);
                        setTotal(data.meta.total);
                        setRowsPerPage(data.meta.per_page);
                        setPage(data.meta.current_page);
                        setFrom(data.meta.from);
                        setTo(data.meta.to);
                        setExportFile(true);
                        setLoading(false);
                    }
                });
        } else {
            getOrdersException();
        }
    };

    const handleExport = () => {
        setLoadingExport(true);
        httpclient
            .get(
                `temple-webster/export-netsuite-container-list?containerName=${filterData.containerName
                }&status=${filterData.order_status
                }&ponNum=${filterData.poNum
                }&stockCode=${filterData.orderNo
                }&startDate=${filterData.from || ""}&endDate=${filterData.to || ""
                }&pagination=${total}`
            )
            .then(({ data }) => {
                if (data.success) {
                    setExportRows(data.download_link);
                    setLoadingExport(false);
                }
            });
    };


    const hadleFilterOpen = () => {
        setFilterOpen((prev) => !prev);
    };

    const handleChangeFilter = (e) => {
        const { name, value } = e.target;
        setFilterData({
            ...filterData,
            [name]: value,
            remove: false,
        });
    };

    const handleRemove = (data) => {
        setExportFile(false);
        setExportRows("");
        if (data === "from") {
            setFilterData({
                ...filterData,
                from: "",
                to: "",
                remove: true,
            });

            setSubmittedData({
                ...submittedData,
                from: "",
                to: "",
            });
        } else {
            setFilterData({
                ...filterData,
                [data]: "",
                remove: true,
            });

            setSubmittedData({
                ...submittedData,
                [data]: "",
            });
        }
    };

    const handleRowCheck = (e, row) => {
        const { checked } = e.target;
        if (checked === true) {
            setRowChecked([...rowChecked, row.orderID]);
        } else {
            let newData = rowChecked.filter((check) => {
                return check !== row.orderID;
            });
            setRowChecked(newData);
        }
    };

    const handleSort = (column) => {
        setDirection((prevDirection) => !prevDirection);
        setCurrentColumn(column);
        submittedData.submit
            ? httpclient
                .get(
                    `temple-webster/netsuite-container-list?containerName=${filterData.containerName
                    }&status=${filterData.order_status
                    }&ponNum=${filterData.poNum
                    }&stockCode=${filterData.orderNo
                    }&startDate=${filterData.from || ""}&endDate=${filterData.to || ""
                    }&sort_by=${column}&direction=${!direction ? "asc" : "desc"
                    }&pagination=${rowsPerPage}&page=${page}`
                )
                .then(({ data }) => {
                    if (data.success === true) {
                        setRows(data.data);
                        setTotal(data.meta.total);
                        setRowsPerPage(parseInt(data.meta.per_page));
                        setPage(data.meta.current_page);
                        setFrom(data.meta.from);
                        setTo(data.meta.to);
                        setLoading(false);
                    }
                })
            : httpclient
                .get(
                    `temple-webster/netsuite-container-list?sort_by=${column}&direction=${!direction ? "asc" : "desc"
                    }&pagination=${rowsPerPage}`
                )
                .then(({ data }) => {
                    if (data.success === true) {
                        setRows(data.data);
                    }
                });
    };

    const handleChangePage = (e, page) => {
        setLoading(true);
        submittedData.submit
            ? httpclient
                .get(
                    `temple-webster/netsuite-container-list?containerName=${filterData.containerName
                    }&status=${filterData.order_status
                    }&ponNum=${filterData.poNum
                    }&stockCode=${filterData.orderNo
                    }&startDate=${filterData.from || ""}&endDate=${filterData.to || ""
                    }&sort_by=${currentColumn}&direction=${direction ? "asc" : "desc"
                    }&pagination=${rowsPerPage}&page=${page}`
                )
                .then(({ data }) => {
                    if (data.success === true) {
                        setRows(data.data);
                        setTotal(data.meta.total);
                        setRowsPerPage(parseInt(data.meta.per_page));
                        setPage(data.meta.current_page);
                        setFrom(data.meta.from);
                        setTo(data.meta.to);
                        setLoading(false);
                    }
                })
            : httpclient
                .get(
                    `temple-webster/netsuite-container-list?direction=${direction ? "asc" : "desc"
                    }&pagination=${rowsPerPage}&page=${page}`
                )
                .then(({ data }) => {
                    if (data.success === true) {
                        setRows(data.data);
                        setTotal(data.meta.total);
                        setRowsPerPage(parseInt(data.meta.per_page));
                        setPage(data.meta.current_page);
                        setFrom(data.meta.from);
                        setTo(data.meta.to);
                        setLoading(false);
                    }
                });
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        // setLoading(true);

        localStorage.setItem("configRowPerPage", event.target.value);

        submittedData.submit
            ? httpclient
                .get(
                    `temple-webster/netsuite-container-list?containerName=${filterData.containerName
                    }&status=${filterData.order_status
                    }&ponNum=${filterData.poNum
                    }&stockCode=${filterData.orderNo
                    }&startDate=${filterData.from || ""}&endDate=${filterData.to || ""
                    }&sort_by=${currentColumn}&direction=${direction ? "asc" : "desc"
                    }&pagination=${+event.target.value}&page=${page}`
                )
                .then(({ data }) => {
                    setLoading(true);
                    if (data.success === true) {
                        setRows(data.data);
                        setTotal(data.meta.total);
                        setRowsPerPage(parseInt(data.meta.per_page));
                        setPage(data.meta.current_page);
                        setFrom(data.meta.from);
                        setTo(data.meta.to);
                        setLoading(false);
                    }
                })
            : httpclient
                .get(
                    `temple-webster/netsuite-container-list?direction=${direction ? "asc" : "desc"
                    }&pagination=${+event.target.value}&page=${1}`
                )
                .then(({ data }) => {
                    setLoading(true);
                    if (data.success === true) {
                        setRows(data.data);
                        setTotal(data.meta.total);
                        setRowsPerPage(parseInt(data.meta.per_page));
                        setFrom(data.meta.from);
                        setTo(data.meta.to);
                        setPage(data.meta.current_page);
                        setLoading(false);
                    }
                });
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };

    useEffect(() => {
        if (!loadingExport && exportRows !== "" && buttonRef.current) {
            console.log("am i here");
            buttonRef.current.click();
        }
    }, [loadingExport, exportRows]);


    //console.log('window',window.location)

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item md={8} xs={12}>
                    <Header>
                        <h1>Inbound Shipment</h1>
                    </Header>
                </Grid>
                <Grid
                    item
                    md={4}
                    xs={12}
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    {exportRows !== "" ? (

                        <Button
                            download
                            href={exportRows}
                            color="primary"
                            variant="contained"
                            style={{ marginRight: "10px" }}
                            ref={buttonRef}
                        >
                            Export <Download style={{ marginLeft: "5px" }} fontSize="small" />
                        </Button>


                    ) : (
                        <Button

                            color="primary"
                            variant="contained"
                            style={{ marginRight: "10px" }}
                            disabled={!exportFile}
                            onClick={handleExport}
                        >
                            {loadingExport ? "Generating Data to Export" : "Export"}{loadingExport ? `...` : <Download style={{ marginLeft: "5px" }} fontSize="small" />}
                        </Button>

                    )}

                    <Button color="primary" variant="contained" onClick={hadleFilterOpen}>
                        Filter <FilterList style={{ marginLeft: "5px" }} fontSize="small" />
                    </Button>
                </Grid>
                {/* Filter */}
                <Grid item xs={12}>
                    <Collapse in={filterOpen}>
                        <Card>
                            <Box p={4}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={4}>
                                        <InputLabel>Status</InputLabel>
                                        <FormControl fullWidth>
                                            <Select
                                                value={filterData.order_status}
                                                // label="Exclusive order_status"
                                                name="order_status"
                                                onChange={handleChangeFilter}
                                            >
                                                <MenuItem value={""}>Select</MenuItem>
                                                {shipment.map((ship) => (
                                                    <MenuItem value={ship.name}>{ship.name}</MenuItem>

                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <InputLabel>Inbound Shipment</InputLabel>
                                        <TextField
                                            name="poNum"
                                            value={filterData.poNum}
                                            onChange={handleChangeFilter}
                                            onKeyDown={e => { if (e.key === "Enter") handleFilter() }}
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <InputLabel>Stock Code</InputLabel>
                                        <TextField
                                            name="orderNo"
                                            value={filterData.orderNo}
                                            onChange={handleChangeFilter}
                                            onKeyDown={e => { if (e.key === "Enter") handleFilter() }}
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <InputLabel>Container Name</InputLabel>
                                        <TextField
                                            name="containerName"
                                            value={filterData.containerName}
                                            onChange={handleChangeFilter}
                                            onKeyDown={e => { if (e.key === "Enter") handleFilter() }}
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={4}>
                                        <InputLabel>Start Date</InputLabel>
                                        <TextField
                                            variant="outlined"
                                            name="from"
                                            type="date"
                                            value={filterData.from}
                                            onChange={(e) => handleChangeFilter(e)}
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={4}>
                                        <InputLabel>End Date</InputLabel>
                                        <TextField
                                            variant="outlined"
                                            name="to"
                                            type="date"
                                            value={filterData.to}
                                            onChange={(e) => handleChangeFilter(e)}
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Box textAlign={"right"}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleFilter}
                                            >
                                                Filter{" "}
                                                <ArrowForward
                                                    fontSize="small"
                                                    style={{ marginLeft: "5px" }}
                                                />
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Card>
                    </Collapse>
                </Grid>

                {submittedData.containerName || submittedData.order_status || submittedData.poNum || submittedData.orderNo || submittedData.from || submittedData.to ? (
                    <Grid item xs={12}>
                        <FilteredBox>
                            <span>Filtered: </span>

                            {submittedData.containerName && (
                                <p>
                                    <span>Container Name: {submittedData.containerName}</span>
                                    <Close
                                        fontSize="small"
                                        onClick={() => handleRemove("containerName")}
                                    />
                                </p>
                            )}
                            {submittedData.poNum && (
                                <p>
                                    <span>Inbound Shipment: {submittedData.poNum}</span>
                                    <Close
                                        fontSize="small"
                                        onClick={() => handleRemove("poNum")}
                                    />
                                </p>
                            )}
                            {submittedData.orderNo && (
                                <p>
                                    <span>Stock Code: {submittedData.orderNo}</span>
                                    <Close
                                        fontSize="small"
                                        onClick={() => handleRemove("orderNo")}
                                    />
                                </p>
                            )}
                            {submittedData.order_status && (
                                <p>
                                    <span>Status: {submittedData.order_status === "Received"
                                        ? "New" : submittedData.order_status}</span>
                                    <Close
                                        fontSize="small"
                                        onClick={() => handleRemove("order_status")}
                                    />
                                </p>
                            )}

                            {(submittedData.from || submittedData.to) && (
                                <p>
                                    <span>
                                        Date Range: {submittedData.from} - {submittedData.to}
                                    </span>
                                    <Close
                                        fontSize="small"
                                        onClick={() => handleRemove("from")}
                                    />
                                </p>
                            )}
                        </FilteredBox>
                    </Grid>
                ) : (
                    <Box></Box>
                )}
                {/* Filter */}

                <Grid item xs={12}>
                    <TWTableComponent
                        columns={columns}
                        rows={rows}
                        sort={true}
                        handleView={handleView}
                        handleRefetch={handleRefetch}
                        handleSort={handleSort}
                        loading={loading}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        handleChangePage={handleChangePage}
                        handleRowCheck={handleRowCheck}
                        rowChecked={rowChecked}
                        //handleAccept={handleAccept}
                        //handleReject={handleReject}
                        direction={direction}
                        currentColumn={currentColumn}
                        page={page}
                        total={total && total}
                        fromTable={from}
                        toTable={to}
                        rowsPerPage={rowsPerPage}
                    />
                </Grid>
            </Grid>

            {openViewDialog && (
                <ViewContainer
                    singleLoading={singleLoading}
                    viewDetails={viewDetails}
                    sendDetails={sendDetails}
                />
            )}

            <Snackbar
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={open}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity={messageState}
                    sx={{ width: "100%" }}
                >
                    {message}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default TWNetSuiteContainer;
