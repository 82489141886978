import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box } from '@mui/material';
import moment from 'moment';
import { Check, Clear } from '@mui/icons-material';
import { httpclient } from '../../utils/httpClient';
import featureServer from '../../utils/featureServer';


export default function BasicTable2(props) {

  const handleDownload = (row) => {
    httpclient
      .get(featureServer ? `https://ubatemp.retailcare.com.au/php/download-example-excel-files/${row.example}` : `https://uba.synccare.com.au/php/download-example-excel-files/${row.example}`, {
        responseType: 'blob',
      })
      .then(({ data }) => {
        if (data) {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${row.example}_example_excel_file.csv`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      })
      .catch((error) => {
        console.error("Error downloading file: ", error);
      });
  };

  return (
    <TableContainer component={Paper} style={{ overflowY: "auto", height: "300px" }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {props.columns.map(col => (
              <TableCell style={{ fontWeight: "700", padding: "10px 5px" }}>{col.name}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        {props.rows.length > 0 ?
          <TableBody>
            {props.rows.map((row, index1) => (
              <TableRow
                key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                {props.columns.map((col, index) => (
                  col.id === "reason" ?
                    <TableCell component="th" scope="row" style={{ width: "150px" }}>
                      {row[col.id]}
                    </TableCell>
                    :
                    <TableCell component="th" scope="row" style={{ padding: "10px 5px" }}>
                      {
                        col.id === "sn" ? (index1 + 1) :
                          col.id === "example" ? <span style={{ color: "blue", cursor :"pointer", textDecoration:"underline" }} onClick={() => handleDownload(row)}>{row.example}</span> :
                            col.id === "log" ? row[col.id].charAt(0).toUpperCase() + row[col.id].slice(1) :
                              col.id === "startDate" ? moment(row.startDate).format("ddd, MMM Do YYYY, h:mm:ss a") :
                                col.id === "endDate" ? moment(row.endDate).format("ddd, MMM Do YYYY, h:mm:ss a") :
                                  col.id === "policyActive" ? ((row[col.id] === "Y" || row[col.id] === "T") ? <Check color="primary" /> : <Clear color="primary" />) :
                                    col.id === "ciBranchName" ? (row.new_system_branch.ciBranchName) :
                                      row[col.id]}
                    </TableCell>

                ))}
              </TableRow>
            ))}
          </TableBody>
          :
          <TableBody>
            <TableRow style={{ position: "relative", width: "100%" }}>
              <Box textAlign="center" style={{ position: "absolute", right: "50%" }} mt={3}>
                No Data Found
              </Box>
            </TableRow>
          </TableBody>
        }
      </Table>
    </TableContainer>
  );
}
