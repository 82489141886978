import { InputLabel, TextField } from "@mui/material";
import React from "react";

const FormTextField = ({
  label,
  rows,
  type,
  name,
  value,
  onChange,
  defaultValue,
  multiline,
  sx,
  disabled,
}) => {
  return (
    <>
      <InputLabel>{label}</InputLabel>
      <TextField
        disabled={disabled}
        multiline={multiline}
        variant="outlined"
        type={type}
        rows={rows}
        inputProps={{ min: 0 }}
        name={name}
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
        fullWidth
        size="small"
        sx={sx}
      />
    </>
  );
};

export default FormTextField;
