import { Checkbox, FormControlLabel } from "@mui/material";
import React from "react";

const generateChecked = (parent, checked, orderParameterLength) => {
  let loopStartIndex, loopLimit;
  parent === "order"
    ? (loopStartIndex = 0)
    : (loopStartIndex = orderParameterLength);
  parent === "order"
    ? (loopLimit = orderParameterLength)
    : (loopLimit = checked.length);
  let allChecked = true;
  for (loopStartIndex; loopStartIndex < loopLimit; loopStartIndex++) {
    allChecked = allChecked && checked[loopStartIndex];
  }
  return allChecked;
};

const generateIntermediateChecks = (parent, checked, orderParameterLength) => {
  let loopStartIndex, loopLimit;
  parent === "order"
    ? (loopStartIndex = 1)
    : (loopStartIndex = orderParameterLength + 1);

  parent === "order"
    ? (loopLimit = orderParameterLength)
    : (loopLimit = checked.length);

  for (loopStartIndex; loopStartIndex < loopLimit; loopStartIndex++) {
    if (checked[loopStartIndex - 1] !== checked[loopStartIndex]) {
      return true;
    }
  }

  // Return false if all elements are equal or if the array has only one element
  return false;
};

const ParentChecked = ({
  label,
  parent,
  checked,
  orderParameterLength,
  handleParentChange,
}) => {
  return (
    <>
      {checked.length > 0 && (
        <FormControlLabel
          label={<span style={{ fontWeight: "bold" }}>{label}</span>}
          control={
            <Checkbox
              checked={generateChecked(parent, checked, orderParameterLength)}
              indeterminate={generateIntermediateChecks(
                parent,
                checked,
                orderParameterLength
              )}
              onChange={(e) => handleParentChange(e, parent)}
            />
          }
        />
      )}
    </>
  );
};

export default ParentChecked;
