import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    styled,
} from "@mui/material";
import { useEffect, useState } from "react";
import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { httpclient } from "../../../../utils/httpClient";


const StyledHeaderTitle = styled(DialogTitle)(({ theme }) => ({
    background: theme.palette.primary.main,
    color: "#fff",
    position: "relative",
    "& button": {
        position: "absolute",
        right: "15px",
        top: "15px",
        color: "#fff",
    },
}));

// const StyledActions = styled(DialogActions)(({theme}) => ({
//   margin:"5px 10px"
// }))

const RejectDialog = (props) => {
   
    const [dialogDetails, setDialogDetails] = useState({
        open: true,
        success: false,
    });
    //console.log('length', props.rowChecked.length)
    const [cancelReason, setCancelReason] = React.useState('');
    const [reasonCode, setReasonCode] = React.useState('');
    const [reasons, setReasons] = React.useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);

    useEffect(() => {
        if (props.rowChecked.length > 0) {
            props.sendToCancel(dialogDetails, cancelReason, reasonCode);
        } else {
            props.sendToCancel(dialogDetails);
        }
    }, [props.rowChecked, dialogDetails, cancelReason, reasonCode]);


    useEffect(() => {
       getReasonCode();
    }, []);

    const getReasonCode = () => {
        
        httpclient(`new-system/order/get-cancel-reasons/${props.viewDetails.companyID}`).then(({ data }) => {
          if (data) {
            setReasons(data.data);
            
          }
        })
      }



    const handleClose = () => {
        setDialogDetails({
            ...dialogDetails,
            open: false,
        });
    };


    // function hasMatchingProducts(props) {
    //     const matchingProducts = props.viewDetails.orderProduct.filter(product => props.rowChecked1.includes(product.orderproductID));

    //     if (matchingProducts.length === 0) {
    //       return false;
    //     }

    //     const firstBranchID = matchingProducts[0].assignToBranch[0].branch.branchID;

    //     for (const product of matchingProducts) {
    //       for (const assignedBranch of product.assignToBranch) {
    //         if (assignedBranch.branch.branchID !== firstBranchID) {
    //           return false;
    //         }
    //       }
    //     }

    //     return true;
    //   }

    function hasMatchingProducts(props) {
        const matchingProducts = props.viewDetails.orderProduct.filter(product => {
            return props.rowChecked1.includes(product.orderproductID) && product.reasonCode === "1";
        });

        if (matchingProducts.length === 0) {
            return false;
        }

        const firstBranchID = matchingProducts[0].assignToBranch[0].branch.branchID;

        for (const product of matchingProducts) {
            for (const assignedBranch of product.assignToBranch) {
                if (assignedBranch.branch.branchID !== firstBranchID) {
                    return false;
                }
            }
        }

        return true;
    }


    const matchingProductsExist = hasMatchingProducts(props);
   

    const filteredProducts = props.viewDetails.orderProduct.filter(product => {
        // Check if reasonCode is equal to 1
        if (product.reasonCode === "1") {
            // Check if orderProductID exists in rowChecked1 array
            return props.rowChecked1.includes(product.orderproductID);
        }
        return false;
    });
   


    const handleYes = () => {
        if (cancelReason && reasonCode) {
            if (
                props.viewDetails.orderProduct.some(
                    (product) => product.reasonCode === "1"
                ) && filteredProducts &&
                props.rowChecked1.length > 0 && hasMatchingProducts(props) === false
            ) {
                // Display window pop-up with the text "Error"
                setDialogOpen(true);
            } else {
                setDialogDetails({
                    ...dialogDetails,
                    open: false,
                    success: true,
                });
                // Pass the selected reason and reasonCode
                props.sendToCancel(dialogDetails, reasonCode, cancelReason);
            }
        }
    };

    const handleYes1 = () => {
        if (
            props.viewDetails.orderProduct.some(
                (product) => product.reasonCode === "1"
            ) && filteredProducts &&
            props.rowChecked1.length > 0 && hasMatchingProducts(props) === false
        ) {
            // Display window pop-up with the text "Error"
            setDialogOpen(true);
        } else {
            setDialogDetails({
                ...dialogDetails,
                open: false,
                success: true,
            });
            // Pass the selected reason and reasonCode
            props.sendToCancel(dialogDetails);
        }
    };


    // console.log("ddddd", dialogDetails );
    // console.log("reason", cancelReason );
    // console.log("ccc", reasonCode );
    const reasons12 = [
        {
            id: 1,
            reason: "Bad Address",
            reasonCode: "CSXB",

        },
        {
            id: 2,
            reason: "Cancelled at Retailer Request",
            reasonCode: "CXSC",

        },
        {
            id: 3,
            reason: "Invalid Ship Instructions",
            reasonCode: "CXSI",

        },
        {
            id: 4,
            reason: "Can't Ship on Time",
            reasonCode: "CXST",

        },
        {
            id: 5,
            reason: "Discontinued Item",
            reasonCode: "CXSD",

        },
        {
            id: 6,
            reason: "Not Enough Stock",
            reasonCode: "CXSN",

        },
        {
            id: 6,
            reason: "Other",
            reasonCode: "CXSO",

        },
        {
            id: 6,
            reason: "Out of Stock",
            reasonCode: "CXSS",

        },
    ]

    const handleChange = (event) => {
        setCancelReason(event.target.value);
        const selectedReason = reasons?.find((reason) => reason.label === event.target.value);
        setReasonCode(selectedReason ? selectedReason.code : '');
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };


    return (
        <div>
            <Dialog
                open={dialogDetails.open}
                onClose={handleClose}
                maxWidth="sm"
                fullWidth
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <StyledHeaderTitle id="alert-dialog-title">
                    Process the orderlines?
                </StyledHeaderTitle>
                <DialogContent>
                    <Box pt={3}>Are you sure you want to process this order?</Box>

                    {props.rowChecked.length > 0 ? (
                        <>
                            <Box pt={3}>Please select the viable reason for rejection.</Box>
                            <Box pt={2} sx={{ minWidth: 240 }}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">required*</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={cancelReason}
                                        label="Reason"
                                        onChange={handleChange}
                                    >
                                        <MenuItem value="">
                                            <em>Select Reason</em>
                                        </MenuItem>
                                        {reasons &&
                                            reasons.map((reason) => (
                                                <MenuItem key={reason.id} value={reason.label}>
                                                    {reason.label}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        </>
                    ) : null}
                </DialogContent>

                <DialogActions styled={{ margin: "5px 10px" }}>
                    <Button onClick={handleClose} color="error" variant="contained" autoFocus>
                        No
                    </Button>
                    {props.rowChecked.length > 0 ? (
                        <Button
                            onClick={handleYes}
                            color="primary"
                            variant="contained"
                            autoFocus
                            disabled={!cancelReason || !reasonCode} // Disable the button when no reason is selected
                        >
                            Yes
                        </Button>
                    ) : (
                        <Button
                            onClick={handleYes1}
                            color="primary"
                            variant="contained"
                            autoFocus

                        >
                            Yes
                        </Button>)
                    }

                </DialogActions>
            </Dialog>
            <>
                <Dialog open={dialogOpen} onClose={handleCloseDialog}>
                    <StyledHeaderTitle>Error</StyledHeaderTitle>
                    <DialogContent>
                        <p>Multiple warehouse despatch for an order is not allowed for this customer.</p>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={handleCloseDialog} color="error">
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        </div>


    );
};

export default RejectDialog;
