import React from "react";

import { dateFormatter } from "../../../../utils/DataGrid/dateFormatter";

import OBDataGrid from "./Table/OBDataGrid";

const fieldHeaderPairs = [
  { field: "orderNo", headerName: "Order No" },
  { field: "orderQty", headerName: "Order Qty" },
  { field: "noOfBoxes", headerName: "Number of Boxes" },
  { field: "branch", headerName: "Location" },
  { field: "orderStatus", headerName: "Order Status" },
  {
    field: "orderDate",
    headerName: "Order Date",
    valueFormatter: (params) => dateFormatter(params),
  },

  // { field: "CUSTOMER_ID", headerName: "Customer Name" },
];

const filterColumn = [
  {
    field: "branch",
    label: "Location",
    component: "AutocompleteField",
    url: "/temple-webster/netsuite-order-list-by-branch",
    overrideDataField: "branchList",
  },
  {
    field: "orderNo",
    label: "Order No",
    component: "TextField",
  },
  {
    field: "orderStatus",
    label: "Order Status",
    component: "AutocompleteField",
    url: "/temple-webster/netsuite-order-list-by-branch",
    overrideDataField: "orderStatus",
  },
  {
    field: "isShippingLabelGenerate",
    label: "Shipping Label PDF Generate",
    component: "SelectField",
  },
  {
    field: "startDate",
    label: "Start Date",
    component: "TextField",
    type: "date",
  },
  {
    field: "endDate",
    label: "End Date",
    component: "TextField",
    type: "date",
  },

  // {
  //   field: "startDate",
  //   label: "Start Date",
  //   component: "TextField",
  //   type: "date",
  // },
  // {
  //   field: "endDate",
  //   label: "End Date",
  //   component: "TextField",
  //   type: "date",
  // },
  // {
  //   field: "CUSTOMER_ID",
  //   label: "CUSTOMER NAME",
  //   component: "TextField",
  // },

  // {
  //   field: "soldToa",
  //   // label: "Sold To",
  //   component: "AutocompleteField",
  //   url: "/current/company-account/all",
  // },
];

const NetsuiteOrderByBranch = () => {
  return (
    <OBDataGrid
      headerTitle="List Orders by Location"
      // exportFeature={false}
      markLabel={true}
      exportFeature={true}
      netsuite={true}
      columns={fieldHeaderPairs}
      filterColumn={filterColumn}
      // children={<Test />}
      pageUrl="/temple-webster/netsuite-order-list-by-branch"
      columnsVisibility={
        {
          // HIST_ID: false,
        }
      }
    />
  );
};

export default NetsuiteOrderByBranch;
