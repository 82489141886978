import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Box,
  IconButton,
  Skeleton,
  Snackbar,
  CircularProgress,
  Tooltip,
  Button,
} from "@mui/material";
import { BeenhereOutlined, Check, Clear, Edit } from "@mui/icons-material";
import moment from "moment";
import DownloadIcon from "@mui/icons-material/Download";
import "./blink.css";
// import EditDialog from "../../Private/Online/Orders/EditDialog";
import EditDialog from "../../../../Online/Orders/EditDialog";
import { useEffect, useState } from "react";
import { httpclient } from "../../../../../utils/httpClient";
import MuiAlert from "@mui/material/Alert";
import { styled } from "@mui/material/styles";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// const TableCell = styled(TableCell)(({ theme }) => ({

//  width: "100px",
//   "& .MuiSelect-select": {
//     padding: "5px",
//   },
//   "& input": {
//     padding: "5px",
//     width: "100px",
//   },
//   "& button": {
//     height: "20px",
//     minWidth: "30px",
//     "& svg": {
//       fontSize: "18px",
//     },
//   },
// }));

export default function PreSellTableComponent(props) {
  //console.log("yyzzz", props)
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [messageState, setMessageState] = useState("");
  const [viewDetails, setViewDetails] = useState("");
  const [keyLoading, setKeyLoading] = useState(false);
  const [keyLoading1, setKeyLoading1] = useState(false);
  // const handleResetPassword = (row) => {
  //   setOpenResetDialog(true);
  //   setViewDetails(row);
  // };

  const sendReset = (call) => {
    if (call.open === false) {
      setShowEditDialog(false);
      setViewDetails({});
    }
    if (call.success === true) {
      setKeyLoading(true);
      httpclient
        .post(`new-system/order/cancel-shippit`, {
          orderID: props.id,
          deliveryFirstName: call.deliveryFirstName,
          deliveryLastName: call.deliveryLastName,
          deliveryPhone: call.deliveryPhone,
          deliveryStreet: call.deliveryStreet,
          deliveryCity: call.deliveryCity,
          deliveryState: call.deliveryState,
          deliveryPostCode: call.deliveryPostCode,
        })
        .then(({ data }) => {
          if (data.success) {
            setOpen(true);
            setMessageState("success");
            setMessage(data.message);
            setTimeout(() => {
              props.handleRefetch();
            }, 3000);
            setViewDetails({});
            setKeyLoading(false);
          } else {
            setOpen(true);
            setMessageState("error");
            setMessage(data.message);
            setKeyLoading(false);
          }
        });
    }
  };

  const handleBookShippit = () => {
    setKeyLoading1(true);
    const shipments = props.viewDetails.filter(
      (shipment) => shipment.manifest_id === null
    );
    const trackingIDs = shipments.map((shipment) => shipment.trackingID);
    httpclient
      .post(`new-system/order/book-order-shipment`, {
        trackingID: trackingIDs,
      })
      .then(({ data }) => {
        if (data.success) {
          setKeyLoading1(false);
          setOpen(true);
          setMessageState("success");
          setMessage(data.message);

          // Order and Product Detail Edit Boxes set to false
          //setProductDetails("");
          //setShowPriceEdit(false);

          //Refetching the data
          setTimeout(() => {
            props.handleRefetch();
          }, 3000);
        } else {
          setKeyLoading1(false);
          setOpen(true);
          setMessageState("error");
          setMessage(data.error);
        }
      })
      .catch((err) => {
        setKeyLoading1(false);
        setOpen(true);
        setMessageState("error");
        setMessage(err.message);
      });
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  //console.log("propssss", props)

  return (
    <TableContainer
      component={Paper}
      style={{ overflowY: "auto", height: "380px" }}
    >
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {props.columns.map((col) => (
              <TableCell
                key={col.name}
                style={{
                  fontWeight: "700",
                  whiteSpace: "nowrap",
                  minWidth: props.isSkuLogistics === 1 ? "260px" : null,
                }}
              >
                {col.name}
                {col.name === "Delivery Details" && (
                  <div style={{ minWidth: "100px" }}>
                    {props.order_status !== "Cancelled" &&
                    props.viewDetails.every(
                      (shipment) => shipment.labelPDF !== null
                    ) &&
                    props.rows[props.rows.length - 1]?.state !== "cancelled" &&
                    props.viewDetails.some(
                      (shipment) => shipment.state !== "processing"
                    ) &&
                    props.viewDetails.some(
                      (shipment) =>
                        shipment.state === "order_placed" ||
                        shipment.state === "despatch_in_progress" ||
                        shipment.state === "ready_for_pickup" ||
                        shipment.state === "PlainLabelCarrier"
                    ) ? (
                      <IconButton
                        onClick={() => {
                          setShowEditDialog(true);
                        }}
                      >
                        {keyLoading ? (
                          <CircularProgress
                            style={{
                              height: "20px",
                              width: "20px",
                              //marginLeft: "10px",
                              position: "relative",
                              //top: "10px",
                            }}
                          />
                        ) : (
                          <Edit
                            sx={{ marginTop: "0px", marginRight: "0px" }}
                            fontSize="small"
                            color="primary"
                          />
                        )}
                      </IconButton>
                    ) : props.viewDetails.some(
                        (shipment) => shipment.state === "processing"
                      ) ? (
                      <Tooltip title="Wait! The process is now currently being executed.">
                        <Edit
                          sx={{
                            marginTop: "0px",
                            marginRight: "0px",
                            opacity: "0.5",
                          }}
                          fontSize="small"
                          color="primary"
                        />
                      </Tooltip>
                    ) : props.order_status === "Cancelled" ? (
                      <Tooltip title="The order has been cancelled">
                        <Edit
                          sx={{
                            marginTop: "0px",
                            marginRight: "0px",
                            opacity: "0.5",
                          }}
                          fontSize="small"
                          color="primary"
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip title="The state of shipment doesnot permit delivery details change.">
                        <Edit
                          sx={{
                            marginTop: "0px",
                            marginRight: "0px",
                            opacity: "0.5",
                          }}
                          fontSize="small"
                          color="primary"
                        />
                      </Tooltip>
                    )}
                  </div>
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        {props.rows.length > 0 ? (
          <TableBody>
            {props.rows.map((row) => {
              var allStockTotal = 0;

              if (row.stock_on_hand && row.stock_on_hand[5]) {
                var newStockArr = Object.values(row.stock_on_hand);
                newStockArr.map((stock, index) => {
                  return (allStockTotal = allStockTotal + stock.actualSOH);
                });
              }
              return (
                <TableRow
                  key={row.name}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    cursor: "pointer",
                  }}
                  // onClick={() => props.handleView(row)}
                >
                  {props.columns.map((col, index) => (
                    <TableCell
                      component="th"
                      scope="row"
                      onClick={() => props.showPreSell(row)}
                    >
                      {col.id === "erpBranchID" ? (
                        row.erpBranch.erpBranchID
                      ) : col.id === "erpBranchName" ? (
                        row.erpBranch.branchName
                      ) : col.id === "stockName" ? (
                        row.stock.ciStockName
                      ) : //col.id === "created_at" ? <span> {moment(row[col.id]).format('ddd, MMM Do YYYY, h:mm:ss a')} </span> :
                      // col.id === "actualSOHOrder" ? <span style={{ color: row.reportType !== "exceptionalOrder" ? 'inherit': 'red' }}> {(allStockTotal)}</span> :
                      col.id === "quantity" ? (
                        <span>{row.quantity}</span>
                      ) : col.id === "unitPrice" ? (
                        <span>{"$" + row.unitPrice}</span>
                      ) : col.id === "actualPrice" ? (
                        <span>{"$" + row.price.ActualPrice}</span>
                      ) : col.id === "reason" ? (
                        row.reportType === "exceptionalOrder" ? (
                          <span style={{ color: "red" }}>{row[col.id]}</span>
                        ) : (
                          <span>{row[col.id]}</span>
                        )
                      ) : col.id === "policyPrice" ? (
                        "$" + row.price_policy_of_stock.price
                      ) : col.id === "active" ? (
                        row.stock.Active === "Y" ? (
                          <Check fontSize="small" color="primary" />
                        ) : (
                          <Clear fontSize="small" color="error" />
                        )
                      ) : col.id === "labelPDF" ? (
                        // row.state === "processing" ||
                        //   row.state ===
                        //   "despatch_in_progress" ? // <Skeleton variant="circular" width={40} height={40} />
                        //   null :
                        row[col.id] !== null && row[col.id] !== "" ? (
                          <IconButton
                            href={row[col.id]}
                            download
                            target="_blank"
                          >
                            <DownloadIcon color="primary" fontSize="medium" />
                          </IconButton>
                        ) : // <Skeleton variant="circular" width={40} height={40} />
                        // <span className="blink">Generating PDF...</span>
                        null
                      ) : col.id === "manifestPDF" ? (
                        // row.state === "processing" ||
                        //   row.state ===
                        //   "despatch_in_progress" ? // <Skeleton variant="circular" width={40} height={40} />
                        //   null :
                        row[col.id] !== null && row[col.id] !== "" ? (
                          <IconButton
                            href={row[col.id]}
                            download
                            target="_blank"
                          >
                            <DownloadIcon color="primary" fontSize="medium" />
                          </IconButton>
                        ) : // <Skeleton variant="circular" width={40} height={40} />
                        // <span className="blink">Generating PDF...</span>
                        null
                      ) : // : col.id === "courier_job_id" ?
                      //   ((row.trackingUrl !== "" && row.trackingUrl !== null) ?

                      //     <a href={row.trackingUrl} download target="_blank">
                      //       {row[col.id]}
                      //     </a>
                      //     :
                      //     row[col.id]
                      //   ) : col.id === "trackingID" ?
                      //   ((row.trackingUrl !== "" && row.trackingUrl !== null) && row.mydealSkuLogistic === 1 ?

                      //     <a href={row.trackingUrl} download target="_blank">
                      //       {row[col.id]}
                      //     </a>
                      //     :
                      //     row[col.id]
                      //   )
                      col.id === "state" ? (
                        row[col.id] === "processing" ? (
                          <span className="blink">Processing</span>
                        ) : row[col.id] === "ready_for_pickup" ? (
                          "Ready For Pickup"
                        ) : row[col.id] ? (
                          row[col.id]
                            .replace(/_/g, " ")
                            .replace(/\b\w/g, (c) => c.toUpperCase())
                        ) : null
                      ) : col.id === "created_at" ? (
                        row[col.id] !== "0000-00-00 00:00:00" ? (
                          row[col.id] !== null ? (
                            moment(row[col.id]).format(
                              "ddd, MMM Do YYYY, h:mm:ss a"
                            )
                          ) : (
                            "-"
                          )
                        ) : (
                          "-"
                        )
                      ) : col.id === "shippitModifiedDate" ? (
                        row.state === "processing" ? null : row[col.id] ? (
                          moment(row[col.id]).format("DD/MM/YYYY")
                        ) : null
                      ) : col.id === "delivery_address" ? (
                        <span>
                          {row.delivery_address}, {row.delivery_suburb},{" "}
                          {row.delivery_state} {row.delivery_postcode}
                        </span>
                      ) : (
                        row[col.id]
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        ) : (
          <TableBody>
            <TableRow style={{ position: "relative", width: "100%" }}>
              <Box
                textAlign="center"
                style={{ position: "absolute", right: "50%" }}
                mt={3}
              >
                No records Available.
              </Box>
            </TableRow>
          </TableBody>
        )}
      </Table>
      {(props.order_status === "Accepted" ||
        props.order_status === "Cancelled") &&
        props.isSkuLogistics !== 1 && (
          <Box style={{ position: "absolute", bottom: "43px", left: "85%" }}>
            {keyLoading1 ? (
              <CircularProgress
                style={{
                  height: "25px",
                  width: "25px",
                  marginLeft: "20px",
                  position: "relative",
                  //top: "10px",
                }}
              />
            ) : (
              <Button
                onClick={() => handleBookShippit()}
                variant="contained"
                color="primary"
                disabled={
                  props.rows[props.rows.length - 1]?.state === "cancelled" ||
                  props.rows[props.rows.length - 1]?.state === "processing" ||
                  props.rows[props.rows.length - 1]?.manifest_id !== null ||
                  (props.rows[props.rows.length - 1]?.manifest_id === null &&
                    props.rows[props.rows.length - 1]?.state === "cancelled")
                }
              >
                Book Shippit Order{" "}
                <BeenhereOutlined
                  fontSize="small"
                  style={{ marginLeft: "3px" }}
                />
              </Button>
            )}
          </Box>
        )}

      {showEditDialog && (
        <EditDialog sendReset={sendReset} viewDetails={props.viewDetails} />
      )}
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={handleCloseSnack}
      >
        <Alert
          onClose={handleCloseSnack}
          severity={messageState}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </TableContainer>
  );
}
