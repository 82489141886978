import { Autocomplete, InputLabel, TextField } from "@mui/material";
import React from "react";

const FormAutocomplete = ({
  label,
  getOptionLabel,
  options,
  name,
  value,
  onChange,
  defaultValue,
}) => {
  return (
    <>
      <InputLabel>{label}</InputLabel>
      <Autocomplete
        disablePortal
        options={options}
        name={name}
        defaultValue={defaultValue}
        inputValue={value}
        getOptionLabel={(option) => option[getOptionLabel]}
        onChange={(e, newValue) => onChange(name, newValue, getOptionLabel)}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{ color: "black" }} // Apply styles to the input
          />
        )}
        size="small"
      />
    </>
  );
};

export default FormAutocomplete;
