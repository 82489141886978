import React from "react";


import { Button, Grid } from "@mui/material";
import TWDataGridTablePage from "../Temple&WebsterTable/TWDataGridTablePage";
import { extractCSVname } from "../../utility/extractCSVname";
import { Download } from "@mui/icons-material";
import { dateTimeFormatter } from "../../../../utils/DataGrid/dateTimeFormatter";

const fieldHeaderPairs = [
  {
    field: "uploadedDate",
    headerName: "Uploaded Date",
    valueFormatter: (params) => dateTimeFormatter(params),
  },
  {
    field: "fileName",
    headerName: "File Name",
    width: 445,
    renderCell: (cellValues) => (
      <>
        <Button href={cellValues.row.filePath}>
          <Download /> {extractCSVname(cellValues.value)}
        </Button>
      </>
    ),
  },
  {
    field: "isProcessed",
    headerName: "Processed ?",
    width: 150,
    renderCell: (cellValues) => (
      
      <>
        {cellValues.row.isProcessed === 0 ? "Yes" : "No"}
          
      </>
    ),
  },

  { field: "totalOrder", headerName: "Total Order" },
  { field: "totalPendingOrder", headerName: "Total Pending Order" },
  { field: "totalProcessOrder", headerName: "Total Processed Order" },
  

  // { field: "CUSTOMER_ID", headerName: "Customer Name" },
];

const filterColumn = [
 
  {
    field: "isProcessed",
    label: "Processed ?",
    component: "SelectField",
  },
  {
    field: "startDate",
    label: "Start Date",
    component: "TextField",
    type: "date",
  },
  {
    field: "endDate",
    label: "End Date",
    component: "TextField",
    type: "date",
  },

  // {
  //   field: "startDate",
  //   label: "Start Date",
  //   component: "TextField",
  //   type: "date",
  // },
  // {
  //   field: "endDate",
  //   label: "End Date",
  //   component: "TextField",
  //   type: "date",
  // },
  // {
  //   field: "CUSTOMER_ID",
  //   label: "CUSTOMER NAME",
  //   component: "TextField",
  // },

  // {
  //   field: "soldToa",
  //   // label: "Sold To",
  //   component: "AutocompleteField",
  //   url: "/current/company-account/all",
  // },
];

const OrderFile = () => {
  return (
    <TWDataGridTablePage
      headerTitle="Order File"
      // exportFeature={false}
      //   markLabel={true}
      //   exportFeature={true}
      //   netsuite={true}
      customIDKey="ID"
      columns={fieldHeaderPairs}
      filterColumn={filterColumn}
      orderFile={true}
      // children={<Test />}
      pageUrl="/temple-webster/order-file"
      columnsVisibility={
        {
          // HIST_ID: false,
        }
      }
    />
  );
};

export default OrderFile;
