import { useLocation } from "react-router-dom";
import AdjustedAllocation from ".";

// const fieldHeaderPairs = [
//   {
//     field: "storeCode",
//     headerName: "Branch",
//     minWidth: 150,
//     tooltip: "Store Code",
//   },
//   {
//     field: "stockCode",
//     headerName: "Style",
//     minWidth: 80,
//     tooltip: "Stock Code",
//   },
//   {
//     field: "qtyAllocated",
//     headerName: "Qty Allocated",
//     minWidth: 110,
//     tooltip: "QtyAllocated (Forecasted Quantity)",
//   },
//   {
//     field: "openingSOH",
//     headerName: "Opening SOH",
//     minWidth: 108,
//     tooltip: "SOH (Quantity)",
//   },
//   {
//     field: "periodSales",
//     headerName: "Period Sales",
//     minWidth: 99,
//     tooltip: "Period Sales ( Forecasted Sales Period )",
//   },
//   {
//     field: "closingStock",
//     headerName: "Closing Stock",
//     minWidth: 105,
//     tooltip: "( Current SOH + Incoming SOH from master files )",
//   },
//   {
//     field: "minLevel",
//     headerName: "Min Level",
//     minWidth: 80,
//     tooltip: "Minimum Stock Level",
//   },
//   {
//     field: "cartonQty",
//     headerName: "Carton Quantity",
//     minWidth: 120,
//     tooltip: "Carton Qty",
//   },
//   {
//     field: "ctnsAllocated",
//     headerName: "Ctns Allocated",
//     minWidth: 110,
//     tooltip: "QtyAllocated / Carton Qty",
//   },
//   {
//     field: "ctnCubic",
//     headerName: "Ctn Cubic",
//     minWidth: 85,
//     tooltip: "CtnCubic",
//   },
//   {
//     field: "totalCubic",
//     headerName: "Total Cubic",
//     minWidth: 90,
//     tooltip: "Ctns Allocated * CtnCubic",
//   },
//   {
//     field: "rawSuggestion",
//     headerName: "Raw Suggestion",
//     minWidth: 130,
//     tooltip: "Raw Suggestion",
//   },
//   {
//     field: "weeklySales",
//     headerName: "Weekly Sales",
//     minWidth: 110,
//     tooltip: "Period Sales / 6",
//   },
//   // {
//   //   field: "weeklySalesExcel",
//   //   headerName: "Hist Weekly Sales",
//   //   minWidth: 110,
//   //   tooltip: "HIST WEEKLY SALES",
//   // },
//   {
//     field: "closingMinusLead",
//     headerName: "Closing Minus Lead",
//     minWidth: 160,
//     tooltip: "Closing Stock - ( 2 * Weekly Sales)",
//   },
//   {
//     field: "differentialPieces",
//     headerName: "Differential Pieces",
//     minWidth: 140,
//     tooltip: "ClosingMinusLead - Min Level",
//   },
//   {
//     field: "differentialCartons",
//     headerName: "Differential Cartons",
//     minWidth: 142,
//     tooltip: "Differential Pieces /  Carton Qty",
//   },
//   //   { field: "", headerName: "TCS Distribution", width: 200 },

//   // { field: "CUSTOMER_ID", headerName: "Customer Name" },
// ];


// const filterColumn = [
//   {
//     field: "storeCode",
//     label: "Branch",
//     component: "AutocompleteField",
//     url: "/replenishment/store-soh",
//     overrideDataField: "branchList",
//   },
//   {
//     field: "stockCode",
//     label: "Style",
//     component: "TextField",
//   },
// ];
const ReplenishmentAllocationsAdjusted = () => {
  const location = useLocation();
 
  return (
    <AdjustedAllocation
      navigateState={location.state}
      forecastPassedUrl="/replenishment/allocation/hist-allocation-report"
    />
  );
};

export default ReplenishmentAllocationsAdjusted;
