import { Box, Card, Collapse, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";

import {
  FilterTextField,
  FilterSelectField,
  FilterAutoCompleteField,
  FilterButton,
  FilterTagSection,
} from ".";

import extractFilterField from "../../utils/FilterForm/extractFilterField";
import displayFilterTags from "../../utils/FilterForm/displayFilterTags";

const FilterForm = ({
  onFilterDataChange,
  filterColumn,
  toogle,
  navigateState,
  orderFile,
}) => {
  const filterDataObject =
    extractFilterField(filterColumn, navigateState) || {};

  const [filterData, setFilterData] = useState(
    //field names
    filterDataObject
  );

  const [submittedData, setSubmittedData] = useState({
    ...filterDataObject,
  });

  const handleChangeFilter = (e) => {
    const { name, value } = e.target;
    setFilterData({
      ...filterData,
      [name]: value,
    });
  };

  const handleChangeAuto = (name, value) => {
    setFilterData((prevFilterData) => ({
      ...prevFilterData,
      [name]: value !== null ? value.id : "",
      [`${name}Name`]: value !== null ? value.name : "",
    }));
  };

  useEffect(() => {
    // Generate the queryString whenever submittedData changes
   
    const queryString = Object.entries(submittedData)
      .map(([key, value]) => {
        // Remove "Name" tag from the key if it ends with "Name"
        const modifiedKey = key.endsWith("Name") ? key.slice(0, -4) : key;
        return `${modifiedKey}=${encodeURIComponent(value)}`;
      })
      .join("&");
    // Pass the queryString to the parent component
    onFilterDataChange(queryString);
  }, [submittedData, onFilterDataChange]);

  const handleFilterButtonClick = () => {
    setSubmittedData((prevData) => ({ ...prevData, ...filterData }));
  };

  const removeTag = (fieldName, component) => {
    setFilterData((prevFilterData) => {
      let updatedFilterData = {
        ...prevFilterData,
        [fieldName]: "",
      };

      if (component === "AutocompleteField") {
        updatedFilterData[`${fieldName}Name`] = "";
      }
      return updatedFilterData;
    });
    setSubmittedData((prevFilterData) => {
      let updatedFilterData = {
        ...prevFilterData,
        [fieldName]: "",
      };
      // FOR ONLINE MODULES THIS DESIGN BUT FOR NOW ONE DONT
      if (component === "AutocompleteField") {
        updatedFilterData[`${fieldName}Name`] = "";
      }
      return updatedFilterData;
    });
  };

  const tags = displayFilterTags(filterColumn, submittedData, removeTag);

  return (
    <>
      <Grid item xs={12}>
        <Collapse in={toogle}>
          <Card>
            <Box p={4}>
              <Grid container spacing={2}>
                {filterColumn.map((filter, index) => {
                  const {
                    field,
                    component,
                    type,
                    url,
                    label,
                    overrideDataField,
                  } = filter;
                  switch (component) {
                    case "TextField":
                      return (
                        <FilterTextField
                          key={field}
                          label={label || field}
                          name={field}
                          value={filterData[field]}
                          type={type}
                          onChange={handleChangeFilter}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") handleFilterButtonClick();
                          }}
                        />
                      );
                    case "SelectField":
                      return (
                        <FilterSelectField
                          key={field}
                          label={label || field}
                          name={field}
                          value={filterData[field]}
                          onChange={handleChangeFilter}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") handleFilterButtonClick();
                          }}
                          orderFile={orderFile}
                        />
                      );
                    case "AutocompleteField":
                      return (
                        <FilterAutoCompleteField
                          key={field}
                          label={label || field}
                          name={field}
                          value={filterData[field + "Name"] || ""}
                          onChangeAuto={handleChangeAuto}
                          onChange={handleChangeFilter}
                          listDataFromUrl={url}
                          overrideDataField={overrideDataField}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") handleFilterButtonClick();
                          }}
                        />
                      );
                    default:
                      return null;
                  }
                })}
                <FilterButton onClick={handleFilterButtonClick} />
              </Grid>
            </Box>
          </Card>
        </Collapse>
      </Grid>

      {/* Filter Tag Section Display */}
      {tags.length > 0 && <FilterTagSection tags={tags} />}
    </>
  );
};

export default FilterForm;
