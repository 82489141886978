import React, { useEffect, useState } from "react";
import TableComponent from "../TableComponent";
import {
  Box,
  Button,
  Card,
  Collapse,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  styled,
  TextField,
  Snackbar,
} from "@mui/material";
import { Add, ArrowForward, Close, FilterList } from "@mui/icons-material";
import { httpclient } from "../../../../utils/httpClient";
import { useNavigate } from "react-router-dom";
import MuiAlert from "@mui/material/Alert";
import DeactivateDialog from "../DeactivateDialog";
import ResetDialog from "../ResetDialog";
import DeleteDialog from "../DeleteDialog";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const login = localStorage.getItem("login");
const loginData = JSON.parse(login);

const columns = [
  { id: "id", name: "ID" },
  { id: "name", name: "Module" },
  { id: "added_date", name: "Added Date" },
  { id: "is_active", name: "Active" },
  //{ id: "is_deleted", name: "Deleted" },
  { id: "updated_date", name: "Updated Date" },
  { id: "modified_by", name: "Modified By" },
  { id: "actions", name: "Actions" },
];


const superOptions = [
  { id: "edit", name: "Edit", action: "handleEdit" },
  { id: "deactivate", name: "Deactivate", action: "handleDeactivate" },
  { id: "delete", name: "Delete", action: "handleDelete" },
];



const FilteredBox = styled(Box)(({ theme }) => ({
  background: "#f9f9f9",
  padding: "5px 10px",
  borderRadius: "5px",
  "& p": {
    margin: "0",
    marginRight: "10px",
    display: "inline-block",
    background: "#dedede",
    borderRadius: "10px",
    padding: "2px 5px",
  },
  "& svg": {
    fontSize: "15px",
    cursor: "pointer",
    position: "relative",
    top: "3px",
    background: theme.palette.primary.dark,
    color: "#fff",
    borderRadius: "50%",
    padding: "2px",
    marginLeft: "2px",
  },
}));

const Header = styled("div")(({ theme }) => ({
  "& h1": {
    color: theme.palette.primary.dark,
    margin: "0",
  },
}));

const AddButton = styled(Button)(({ theme }) => ({
  marginLeft: "10px",
  "& svg": {
    fontSize: "15px",
  },
}));

const configRowPerPage = JSON.parse(localStorage.getItem("configRowPerPage"));



const ListUserModules = () => {
  const navigate = useNavigate();
  const [openResetDialog, setOpenResetDialog] = useState(false);
  const [viewDetails, setViewDetails] = useState({});
  const [openActiveDialog, setOpenActiveDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const [rows, setRows] = useState([]);

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [messageState, setMessageState] = useState("");

  const [loading, setLoading] = useState(false);
  const [direction, setDirection] = useState(false);
  const [currentColumn, setCurrentColumn] = useState("");
  const [page, setPage] = useState(1);
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(
    configRowPerPage && configRowPerPage
      ? configRowPerPage && configRowPerPage
      : 20
  );

  const [rowsPerPage, setRowsPerPage] = useState(
    configRowPerPage && configRowPerPage
      ? configRowPerPage && configRowPerPage
      : 20
  );
  const [total, setTotal] = useState("");
  const [filterOpen, setFilterOpen] = useState(false);


  useEffect(() => {

    getAllModules()

  }, []);

  const getAllModules = () => {
    setLoading(true);
    httpclient.get(`modules`).then(({ data }) => {
      if (data.success) {
        setRows(data.data);
        setTotal(data.meta.total);
        setRowsPerPage(parseInt(data.meta.per_page));
        setPage(data.meta.current_page);
        setFrom(data.meta.from);
        setTo(data.meta.to);
        setLoading(false);
      }
    });
  };


  const hadleAddNew = () => {
    navigate(`/modules/add_module/`);
  };

  const handleEdit = (row) => {
    navigate(`/modules/add_module/${row.id}`);
  };

  const handleDeactivate = (row) => {
    setOpenActiveDialog(true);
    setViewDetails(row);
  };

  const sendDeactivate = (call) => {
    if (call.open === false) {
      setOpenActiveDialog(false);
      setViewDetails({});
    }
    if (call.success === true) {
      httpclient
        .get(`modules/activate-deactivate/${viewDetails.id}`)
        .then(({ data }) => {
          if (data.success) {
            setOpen(true);
            setMessageState("success");
            setMessage(data.message);
            setOpenActiveDialog(false);
            setViewDetails({});
            getAllModules();
          } else {
            setOpen(true);
            setMessageState("error");
            setMessage(data.message);
          }
        });
    }
  };



  const handleDelete = (row) => {
    setOpenDeleteDialog(true);
    setViewDetails(row)
  };

  const sendToDelete = (call) => {
    if (call.open === false) {
      setOpenDeleteDialog(false)
      setViewDetails({})
    }
    if (call.success === true) {
      httpclient
        .delete(`modules/${viewDetails.id}`)
        .then(({ data }) => {
          if (data.success) {
            setOpen(true);
            setMessageState("success");
            setMessage(data.message);
            setOpenDeleteDialog(false);
            setViewDetails({});
            getAllModules();
          } else {
            setOpen(true);
            setMessageState("error");
            setMessage(data.message);
          }
        });
    }
  }

  const currentChange = (value, row) => {
    //console.log("value", value);
    if (value === "Edit") {
      handleEdit(row);
    }
    if (value === "Deactivate") {
      handleDeactivate(row);
    }

    if (value === "Delete") {
      handleDelete(row);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item md={8} xs={12}>
          <Header>
            <h1>List User Modules</h1>
          </Header>
        </Grid>
        <Grid
          item
          md={4}
          xs={12}
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
        >

          <AddButton
            color="secondary"
            variant="contained"
            onClick={hadleAddNew}
          >
            <Add style={{ marginRight: "5px" }} fontSize="small" /> Add Module
          </AddButton>
        </Grid>

        {/* Filter */}

        {/* Filter */}

        <Grid item xs={12}>
          <TableComponent
            columns={columns}
            rows={rows}
            //sort={true}
            //handleSort={handleSort}
            // handleView={handleView}
            // options={loginData.UserLevel === 1 ? superOptions : adminOptions}
            options={superOptions}
            currentChange={currentChange}
            // handleEdit={handleEdit}
            // handleDelete={handleDelete}
            loading={loading}
            //direction={direction}
            currentColumn={currentColumn}
            //handleChangeRowsPerPage={handleChangeRowsPerPage}
            //handleChangePage={handleChangePage}
            page={page}
            total={total && total}
            fromTable={from}
            toTable={to}
            rowsPerPage={rowsPerPage}
          />
        </Grid>
      </Grid>

      {openDeleteDialog && <DeleteDialog sendToDelete={sendToDelete} state="module" />}

      {openActiveDialog && (
        <DeactivateDialog
          viewDetails={viewDetails}
          sendDeactivate={sendDeactivate}
          state="module"
        />
      )}
      {/* {openResetDialog && (
        <ResetDialog viewDetails={viewDetails} sendReset={sendReset} />
      )} */}

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={messageState}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ListUserModules;
