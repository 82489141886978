import { Box, Checkbox, FormControlLabel } from "@mui/material";
import React from "react";

function formatString(inputString) {
  // Split the string by underscores
  const words = inputString.split("_");

  // Capitalize the first letter of each word
  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );

  // Join the words with spaces
  const resultString = capitalizedWords.join(" ");

  return resultString;
}

const ChildChecked = ({
  parameter,
  checked,
  parent,
  orderParameterLength,
  handleChildChange,
}) => {
  return (
    <>
      {checked.length > 0 && (
        <Box sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
          {parameter?.map((key, index) => {
            let checkedIndex = index;
            parent === "order"
              ? (checkedIndex = index)
              : (checkedIndex = index + orderParameterLength);

            return (
              <FormControlLabel
                label={formatString(key)}
                control={
                  <Checkbox
                    checked={checked[checkedIndex]}
                    onChange={(e) => handleChildChange(e, checkedIndex)}
                  />
                }
              />
            );
          })}
        </Box>
      )}
    </>
  );
};

export default ChildChecked;
