import { Box, Card, Grid, styled } from '@mui/material'
import React, { useEffect, useState } from 'react'
import DashboardGrid1 from './DashboardGrid1'
import DashboardGrid2 from './DashboardGrid2'
import DashboardGrid3 from './DashboardGrid3'
import DashboardGrid4 from './DashboardGrid4'
import DashboardGrid5 from './DashboardGrid5'
import DashboardGrid6 from './DashboardGrid6'
// import DashboardGrid7 from './DashboardGrid7'
import DashboardGrid8 from './DashboardGrid8'
import DashboardGrid9 from './DashboardGrid9'
import { httpclient } from '../../utils/httpClient'


const MyCard = styled(Card)(({ theme }) => ({
  padding: "45px",
  "& h3": {
    margin: "0"
  }
}))

const Home = () => {
  const [seconds, setSeconds] = useState(60);
 
  const [resume, setResume] = useState(0);

  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
      setSeconds(60);
    }
  });



  return (
    <div>
      <div>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={4}>
            <DashboardGrid1 />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <DashboardGrid2 />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <DashboardGrid3 />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <DashboardGrid4 />
          </Grid>
          {/* <Grid item xs={12} sm={6} md={4}>
            <DashboardGrid7 />
          </Grid> */}
          <Grid item xs={12} sm={6} md={4}>
            <DashboardGrid8 />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <DashboardGrid6 />
          </Grid>
          <Grid item xs={12} sm={6} md={8}>
            <DashboardGrid5 />
          </Grid>
          {/* {resume === 1 &&
            <Grid item xs={12} sm={6} md={4}>
              <div className="grid-block-timer">
                <h3>Order Process Resuming in:</h3>
                <div className="timer_box">{`${Math.floor(time / 60)}`.padStart(2, 0)}:
                  {`${time % 60}`.padStart(2, 0)}</div>
              </div>
            </Grid>
          } */}
          <Grid item xs={12} sm={6} md={6}>
            <DashboardGrid9 />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <div className="grid-block-timer">
              <h3>Refreshing Dashboard in:</h3>
              <div className="timer_box">00:{String(seconds).padStart(2, '0')}</div>
            </div>
          </Grid>

        </Grid>
      </div>
      <style jsx>{`
        .grid-block-timer {
          border: 0.5px solid #gray;
          box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.07);
          background-color: #fff;
          border-radius: 5px;
          padding: 80px;
        }
      `}</style>
    </div>
  );
};

export default Home;
