
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  TextField,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";

const StyledHeaderTitle = styled(DialogTitle)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: "#fff",
  position: "relative",
  "& button": {
    position: "absolute",
    right: "15px",
    top: "15px",
    color: "#fff",
  },
}));

// const StyledActions = styled(DialogActions)(({theme}) => ({
//   margin:"5px 10px"
// }))

const Dialogue = (props) => {

  const [dialogDetails, setDialogDetails] = useState({
    open: true,
    success: false,
  });

  useEffect(() => {

    props.sendToConfirm(dialogDetails);

  }, [dialogDetails, props]);

  const handleClose = () => {
    setDialogDetails({
      ...dialogDetails,
      open: false,
    });
  };

  const handleYes = () => {
    setDialogDetails({
        ...dialogDetails,
        open: false,
        success: true,
    });
};
  // console.log("ddddd", dialogDetails );
  // console.log("reason", password);


  return (
    <div>
      <Dialog
        open={dialogDetails.open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <StyledHeaderTitle id="alert-dialog-title">
          ReSync?
        </StyledHeaderTitle>
        <DialogContent>
          <Box pt={3} >Are you sure you want to resync the stock customer relation?</Box>
        </DialogContent>
        <DialogActions styled={{ margin: "5px 10px" }}>
          <Button onClick={handleClose} color="error" variant="contained" autoFocus>
            No
          </Button>
          <Button onClick={handleYes} color="primary" variant="contained" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Dialogue;
