export const extractCSVname = (fileName) => {
  // Split the string by spaces
  const parts = fileName?.replace(".csv", "").split(" ");


  // Extract the last element of the array
  const extractedName = parts[parts?.length - 1];

  return extractedName;
};
