import { useLocation } from "react-router-dom";
import DataGridTablePage from "../../../../Components/DataGridTablePage/DataGridTablePage";
const fieldHeaderPairs = [
  {
    field: "storeCode",
    headerName: "Branch",
    width: 150,
    tooltip: "Store Code",
  },
  {
    field: "stockCode",
    headerName: "Style",
    width: 80,
    tooltip: "Stock Code",
  },
  {
    field: "qtyAllocated",
    headerName: "Qty Allocated",
    width: 110,
    tooltip: "QtyAllocated (Forecasted Quantity)",
  },
  {
    field: "openingSoh",
    headerName: "Opening SOH",
    width: 108,
    tooltip: "SOH (Quantity)",
  },
  {
    field: "periodSale",
    headerName: "Period Sales",
    width: 99,
    tooltip: "Period Sales ( Forecasted Sales Period )",
  },
  {
    field: "closingStock",
    headerName: "Closing Stock",
    width: 105,
    tooltip: "( Current SOH + Incoming SOH from master files )",
  },
  {
    field: "minLevel",
    headerName: "Min Level",
    width: 80,
    tooltip: "Minimum Stock Level",
  },
  {
    field: "cartoonQty",
    headerName: "Carton Quantity",
    width: 120,
    tooltip: "Carton Qty",
  },
  {
    field: "ctnsAllocated",
    headerName: "Ctns Allocated",
    width: 110,
    tooltip: "QtyAllocated / Carton Qty",
  },
  {
    field: "ctnCubic",
    headerName: "Ctn Cubic",
    width: 85,
    tooltip: "CtnCubic",
  },
  {
    field: "totalCubic",
    headerName: "Total Cubic",
    width: 90,
    tooltip: "Ctns Allocated * CtnCubic",
  },
  {
    field: "rawSuggestion",
    headerName: "Raw Suggestion",
    width: 130,
    tooltip: "Raw Suggestion",
  },
  {
    field: "weeklySale",
    headerName: "Weekly Sales",
    width: 110,
    tooltip: "Period Sales / 6",
  },
  {
    field: "closingMinus",
    headerName: "Closing Minus Lead",
    width: 160,
    tooltip: "Closing Stock - ( 2 * Weekly Sales)",
  },
  {
    field: "differentialPieces",
    headerName: "Differential Pieces",
    width: 140,
    tooltip: "ClosingMinusLead - Min Level",
  },
  {
    field: "differentialCartons",
    headerName: "Differential Cartons",
    width: 140,
    tooltip: "Differential Pieces /  Carton Qty",
  },
  //   { field: "", headerName: "TCS Distribution", width: 200 },

  // { field: "CUSTOMER_ID", headerName: "Customer Name" },
];
const filterColumn = [
  {
    field: "storeCode",
    label: "Branch",
    component: "AutocompleteField",
    url: "/replenishment/store-soh",
    overrideDataField: "branchList",
  },
  {
    field: "stockCode",
    label: "Style",
    component: "TextField",
  },
];
const ReplenishmentAllocationsStandard = () => {
  const location = useLocation();
  return (
    <DataGridTablePage
      tempTableOff={true}
      navigateState={location.state}
      headerTitle="List Replenishment Allocations Standard"
      exportFeature={true}
      columns={fieldHeaderPairs}
      filterColumn={filterColumn}
      exportFileLink={true}
      pageUrl="/replenishment/allocation/details-standard"
      columnsVisibility={
        {
          // HIST_ID: false,
        }
      }
      forecastPassedUrl="/replenishment/allocation/hist-allocation-report"
    />
  );
};

export default ReplenishmentAllocationsStandard;
