import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  MenuItem,
  Portal,
  Snackbar,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  gridClasses,
  GridCsvExportMenuItem,
  GridToolbarExportContainer,
  gridFilteredSortedRowIdsSelector,
  gridVisibleColumnFieldsSelector,
  useGridApiContext,
} from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { FilterList } from "@mui/icons-material";
import useApiCall from "../../../../../hooks/useApiCall";

import { colDataExtendAttributes } from "../../../../../utils/DataGrid/colDataExtendAttributes";
import { dateFormatter } from "../../../../../utils/DataGrid/dateFormatter";
import { CSVLink } from 'react-csv';
import useGetCall from "../../../../../hooks/useGetCall";
import FilterForm from "../../../../../Components/FilterForm/FilterForm";
import ExcelMenu from "../../../../../Components/ExcelMenu/ExcelMenu";
import { httpclient } from "../../../../../utils/httpClient";


const formatStockCodes = (stockCodesString) => {
  // Split the string into an array based on newline character
  const stockCodesArray = stockCodesString.split("\n");

  // Join the array elements using commas
  const formattedStockCodes = stockCodesArray.join(",");

  return formattedStockCodes;
};

const OBDataGrid = ({
  //checkboxSelection = false,
  checkboxSelection,
  customIDKey,
  columns,
  filterColumn,
  headerTitle,
  exportFeature,
  pageUrl,
  exportFileLink,
  columnsVisibility,
  children,
  dataChanged,
  hideMenu,
  navigateState,
  forecastPassedUrl,
  tempTableOff,
  markLabel,
  netsuite,
}) => {
  const [open, setOpen] = useState(false);
  const [loadingLabel, setLoadingLabel] = useState(false);
  const [openLabel, setOpenLabel] = useState(false);
  const [message, setMessage] = useState("");
  const [messageState, setMessageState] = useState("");

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [toogleFilterForm, setToogleFilterForm] = useState(false);
  const [toogleChildren, setToogleChildren] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [passRowParams, setPassRowParams] = useState({});
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 50,
  });

  const [sortModel, setSortModel] = useState([
    {
      field: "",
      sort: "",
    },
  ]);

  const [filterData, setFilterData] = useState("");
  const [copiedString, setCopiedString] = useState("");
  const [label, setLabel] = useState("");
  const [copied, setCopied] = useState(false);


  const { loading, error, data, pageInfo } = useApiCall(
    `${pageUrl}?pagination=${paginationModel.pageSize}&page=${paginationModel.page + 1
    }&sort_by=${sortModel[0]?.field || ""}&direction=${sortModel[0]?.sort || ""
    }&${filterData}`,
    "GET",
    [sortModel, paginationModel, filterData, dataChanged, refetch === true],
    //check this dependencies pagination working only after sorting model changed
  );

  // const { loading: loading1, data: data1 } = useApiCall(
  //   pageInfo && pageInfo.total !== undefined ? `${pageUrl}?pagination=${pageInfo.total}&${filterData}` : `${pageUrl}`,
  //   "GET",
  //   [pageInfo]
  // );

  const defaultHistoricalSalesStart = "2022-05-01";
  const defaultHistoricalSalesEnd = "2023-05-30";
  const defaultSupplierLeadTimeInWeeks = 4;
  const defaultDefaultDemand = "HIST";
  const defaultStockCodes = "";

  let apiCallUrl;

  if (navigateState?.filterData) {
    const {
      supplierLeadTime,
      custLeadTimeDays,
      stock_codes,
      customerID,
      coverageBuffer,
      cycleFrequency,
      defaultDemand,
      defaultForecast,
      smoothing,
      recentSalesWeeks,
      useSalesDataSince,
      historicalSalesStart,
      salesExcludeMonths,
      historicalSalesEnd,
      globalAllocRamping,
      globalSales,
      meanTrimming,
      otherSettings,
      currentProductOnly,
      dataRetrieveOnly,
      ignoreSupplier,
      standardAllocations,
      adjustedAllocations,
      allocateToMSLOnly,
    } = navigateState?.filterData;
    let defaultDemandDependentParameters;

    if (defaultDemand === "RECENT") {
      defaultDemandDependentParameters = `?recentSalesWeek=${recentSalesWeeks}`;
    }
    if (defaultDemand === "HIST") {
      defaultDemandDependentParameters = `?historicalSalesStart=${historicalSalesStart}&historicalSalesEnd=${historicalSalesEnd}`;
    } else {
      defaultDemandDependentParameters = `?`;
      // `?salesExcludeMonths=${salesExcludeMonths}`;
    }
    apiCallUrl = `${forecastPassedUrl}${defaultDemandDependentParameters}&supplierLeadTime=${supplierLeadTime}&defaultDemand=${defaultDemand}&stock_codes=${formatStockCodes(
      stock_codes
    )}`;
  } else {
    apiCallUrl = `${forecastPassedUrl}?historicalSalesStart=${defaultHistoricalSalesStart}&historicalSalesEnd=${defaultHistoricalSalesEnd}&supplierLeadTime=${defaultSupplierLeadTimeInWeeks}&defaultDemand=${defaultDefaultDemand}`;
  }

  //   useEffect(() => {
  //     console.log("NAVIGATE FILTER DATA", navigateState?.filterData);
  //   }, [navigateState]);
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
    setOpenLabel(false);
  };
  const {
    loading: downloadForecastLoading,
    error: downloadForecastError,
    data: downloadForecastData,
    message: downloadForecastMessage,
  } = useGetCall(apiCallUrl, forecastPassedUrl, [navigateState]);

  const [rowCountState, setRowCountState] = React.useState(
    pageInfo?.total || 0
  );

  const handleFilterDataChange = (newFilterData) => {
    setFilterData(newFilterData);
  };

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      pageInfo?.total !== undefined ? pageInfo?.total : prevRowCountState
    );
  }, [pageInfo?.total, setRowCountState]);

  const handleSortModelChange = (model) => {
    if (JSON.stringify(model) !== JSON.stringify(sortModel)) {
      setSortModel(model);
    }
  };
  const handleCellClick = (param, event) => {
    event.stopPropagation();
  };
  const handleMarkAsLabelRecieved = () => {
    setLoadingLabel(true);
    httpclient
      .post(netsuite ? `temple-webster/netsuite-order-mark-as-label-received` : `temple-webster/order-mark-as-label-received`, {
        orders_no: label
      })
      .then(({ data }) => {
        if (data.success) {
          setOpenLabel(true);
          setMessageState("success");
          setMessage(data.message);
          setLoadingLabel(false);
          setTimeout(() => {
            setLabel("");
            setRefetch(true);
          }, 1000);

        } else {
          setLoadingLabel(false);
          setOpenLabel(true);
          setMessageState("error");
          setMessage(data.error || data.message);
        }
      });
  }

  const customToolBar = () => {
    const GridToolbarExport = ({ csvOptions, printOptions, ...other }) => (
      <GridToolbarExportContainer {...other}>
        <GridCsvExportMenuItem options={csvOptions} />
        <ExportAllMenuItem />
        <ExportFilterMenuItem filterData={filterData} />
      </GridToolbarExportContainer>
    );




    return (
      <>
        <Portal container={() => document.getElementById("filter-panel")}>
          <GridToolbarExport
            csvOptions={{
              fileName: headerTitle + String(dateFormatter(Date.now())),
            }}
            type="Button"
            color="primary"
            variant="contained"
            sx={{ padding: "6.5px 16px", ml: 1 }}
          />
        </Portal>
      </>
    );
  };

  // const customToolBar = () => {
  //   return (
  //     <>
  //       {/* {exportFeature && (
  //         <Portal container={() => document.getElementById("filter-panel")}>
  //           {!exportFileLink && (
  //             <GridToolbarExport
  //               csvOptions={{
  //                 fileName: headerTitle + String(dateFormatter(Date.now())),
  //               }}
  //               type="Button"
  //               color="primary"
  //               variant="contained"
  //               sx={{ padding: "6.5px 16px" }}
  //             />
  //           )}
  //         </Portal>
  //       )} */}
  //       {exportFeature && (
  //         <Portal container={() => document.getElementById("filter-panel")}>
  //           {!exportFileLink && (
  //             <>
  //               <Box
  //                 display="inline-flex"
  //                 alignItems="center"
  //                 justifyContent="center"
  //                 width="120px"
  //                 height="36px"
  //                 marginLeft={"5px"}
  //                 backgroundColor={loading1  ? "#cccccc" : "#9d182d"} // Change background color when disabled
  //                 borderRadius="4px"
  //                 disabled={loading1  }
  //               >
  //                 {loading1  ? (
  //                   <span
  //                     style={{ textDecoration: 'none', color: "#ffffff", fontSize: "14px" }}
  //                   >
  //                     EXPORT CSV
  //                   </span>

  //                 ) : (
  //                   <CSVLink
  //                     data={data1}
  //                     filename={headerTitle + String(dateFormatter(Date.now())) + ".csv"}
  //                     //className="btn btn-primary"
  //                     target="_blank"
  //                     style={{ textDecoration: 'none', color: "#ffffff", fontSize: "14px" }}
  //                   >
  //                     EXPORT CSV
  //                   </CSVLink>
  //                 )}
  //               </Box>

  //             </>

  //           )}
  //         </Portal>
  //       )}
  //     </>
  //   );
  // };

  return (
    <>
      {!error && (
        <>
          <Grid container spacing={2} sx={{ width: "100%" }}>
            <Grid item md={6} xs={12}>
              <h1>{headerTitle}</h1>
              <Typography fontWeight={"bold"}>
                {navigateState?.fileName
                  ? `File Name: ${navigateState?.fileName}`
                  : ""}
              </Typography>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
            >
              <Box id="filter-panel">
                {/* <Button
                  color="primary"
                  variant="contained"
                  target="_blank"
                  disabled={loading}
                  onClick={() => {
                    let copiedString = data
                      .map((order) => order.orderNo)
                      .join("\n");

                    navigator.clipboard
                      .writeText(copiedString)
                      .then(() => {
                        setOpenSnackbar(true);
                        setOpen(true);
                      })
                      .catch((msg) => console.log(msg));
                  }}
                >
                  Copy Order Numbers
                </Button> */}
                {markLabel &&
                  <Button
                    sx={{ marginRight: "5px" }}
                    color="primary"
                    variant="contained"
                    onClick={() => handleMarkAsLabelRecieved()}
                    disabled={label === "" || !copied}
                    style={{ marginLeft: "5px" }}
                  >
                    Mark as Label Received

                  </Button>
                }

                <Button

                  color="primary"
                  variant="contained"
                  target="_blank"
                  disabled={loading}
                  onClick={() => {
                    let copiedString = "";
                    let copiedString1 = "";
                    setRefetch(false);
                    if (rowSelectionModel.length > 0) {
                      const selectedOrderNumbers = data
                        .filter((order) => rowSelectionModel.includes(order.id))
                        .map((order) => order.orderNo);

                      copiedString = selectedOrderNumbers.join(",\n");
                      copiedString1 = selectedOrderNumbers.join(",");
                      setCopiedString(copiedString);
                      setLabel(copiedString1);
                    } else {
                      copiedString = data
                        .map((order) => order.orderNo)
                        .join(",\n");
                      setCopiedString(copiedString);
                    }
                    navigator.clipboard

                      .writeText(copiedString)
                      .then(() => {
                        setOpenSnackbar(true);
                        setOpen(true);
                        setCopied(true);
                      })
                      .catch((msg) => console.log(msg));
                  }}
                >
                  Copy Order No
                </Button>

                <Snackbar
                  open={openSnackbar}
                  autoHideDuration={3000} // Adjust the duration as needed
                  onClose={handleCloseSnackbar}
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                >
                  <Alert onClose={handleCloseSnackbar} severity="success">
                    Order numbers copied to clipboard!
                  </Alert>
                </Snackbar>
                <Snackbar
                  open={openLabel}
                  autoHideDuration={3000} // Adjust the duration as needed
                  onClose={handleCloseSnackbar}
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                >
                  <Alert onClose={handleCloseSnackbar} severity={messageState}>
                    {message}
                  </Alert>
                </Snackbar>
                <Dialog
                  open={open}
                  onClose={() => setOpen(false)}
                  scroll="paper"
                  aria-labelledby="scroll-dialog-title"
                  aria-describedby="scroll-dialog-description"
                  fullWidth={true}
                  maxWidth="sm"
                >
                  <DialogTitle id="scroll-dialog-title">
                    Copied PO Orders:
                  </DialogTitle>
                  <DialogContent dividers={true}>
                    <DialogContentText>
                      <TextareaAutosize
                        aria-label="order-numbers"
                        rowsMin={3} // Minimum number of rows to display
                        placeholder="Order numbers"
                        // value={data
                        //   .map((order, index) => order.orderNo)
                        //   .join("\n")}
                        value={copiedString}
                        readOnly
                        style={{ width: "100%", fontSize: "1.3rem" }}
                      />
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setOpen(false)}>Close</Button>
                  </DialogActions>
                </Dialog>
              </Box>

              {!!filterColumn && (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => setToogleFilterForm((prev) => !prev)}
                  style={{ marginLeft: "5px" }}
                >
                  Filter
                  <FilterList fontSize="small" />
                </Button>
              )}
            </Grid>
            <Grid item xs={12}>
              {!!filterColumn && (
                <FilterForm
                  toogle={toogleFilterForm}
                  navigateState={navigateState}
                  filterColumn={filterColumn}
                  onFilterDataChange={handleFilterDataChange}
                />
              )}

              {navigateState?.startDate && (
                <ExcelMenu hideMenu={hideMenu} navigateState={navigateState} />
              )}
            </Grid>

            <Grid item xs={12}>

              {!tempTableOff && (
                <div style={{ height: 600, width: '100%', overflowX: 'auto' }}>
                  <DataGrid
                    //this optiona
                    slots={{
                      toolbar: customToolBar,
                    }}
                    //this changes and optional
                    columnVisibilityModel={columnsVisibility}
                    //this changes and mandatory
                    // getRowId={
                    //   customIDKey ? (row) => row[customIDKey] : () => uuidv4()
                    // }

                    rows={data}
                    onRowClick={
                      children
                        ? (params) => {
                          setToogleChildren((prev) => !prev);
                          setPassRowParams(params);
                          // console.log("ROW CLICKED", params.row);
                        }
                        : null
                      // () => console.log("CLICKED")
                    }
                    rowCount={rowCountState}
                    columns={colDataExtendAttributes(columns)}
                    loading={loading}
                    disableColumnMenu
                    autoHeight={true}
                    pageSizeOptions={[20, 50, 70, 100]}
                    paginationMode="server"
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    checkboxSelection={true}
                    // onCellClick={handleCellClick}
                    sortingMode="server"
                    sortModel={sortModel}
                    onSortModelChange={handleSortModelChange}
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                      setRowSelectionModel(newRowSelectionModel);
                      setCopied(false);
                    }}
                    rowSelectionModel={rowSelectionModel}
                    sx={{
                      '& .MuiDataGrid-checkboxInput': {
                        color: 'purple !important', // Change this to your default color
                      },
                    
                        [`.${gridClasses.main}`]: {
                          overflow: "unset"
                        },
                        [`.${gridClasses.columnHeaders}`]: {
                          position: "sticky",
                          top: 0,
                          zIndex: 1
                        }
                    

                    }}
                  />
                </div>
              )}

            </Grid>

            {toogleChildren &&
              React.cloneElement(children, {
                setToogleChildren: setToogleChildren,
                parameter: passRowParams,
              })}
          </Grid>
        </>
      )}

      {error && console.log("error message", error)}
    </>
  );
};

export default OBDataGrid;

const getJson = (apiRef) => {
  // Select rows and columns
  const filteredSortedRowIds = gridFilteredSortedRowIdsSelector(apiRef);
  const visibleColumnsField = gridVisibleColumnFieldsSelector(apiRef);

  // Format the data. Here we only keep the value
  const data = filteredSortedRowIds.map((id) => {
    const row = {};
    visibleColumnsField.forEach((field) => {
      row[field] = apiRef.current.getCellParams(id, field).value;
    });
    return row;
  });

  // Stringify with some indentation
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/stringify#parameters
  // return JSON.stringify(data, null, 2);

  console.log(
    "TESTNETD ",
    apiRef.current,
    filteredSortedRowIds,
    visibleColumnsField,
    data
  );
};

const exportBlob = (blob, filename) => {
  // Save the blob in a json file
  const url = URL.createObjectURL(blob);

  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  a.click();

  setTimeout(() => {
    URL.revokeObjectURL(url);
  });
};

function ExportAllMenuItem(props) {
  const apiRef = useGridApiContext();
  const [exportAllLoading, setExportAllLoading] = useState(false);
  const [exportAllData, setExportAllData] = useState(null);
  const [exportAllError, setExportAllError] = useState("");

  getJson(apiRef);

  const { hideMenu } = props;

  const getCsv = (fetchedData) => {
    let csvContent = `orderNo,  branch, orderStatus, orderDate\n`;

    // Add data rows
    fetchedData.forEach((item) => {
      csvContent += `${item.orderNo}, ${item.branch}, ${item.orderStatus}, ${item.orderDate}\n`;
    });

    return csvContent;
  };

  return (
    <MenuItem
      onClick={() => {
        setExportAllLoading(true);

        httpclient
          .get("/temple-webster/order-list-by-branch?pagination=all")
          .then(({ data }) => {
            if (data.success) {
              setExportAllLoading(false);

              setExportAllData(data.data);
              setExportAllError(data.message);
              let csvContent = `orderNo,  branch, orderStatus, orderDate\n`;
              let orderNoOnly = "";

              // Add data rows
              data.data.forEach((item) => {
                orderNoOnly += `${item.orderNo}\n`;
                csvContent += `${item.orderNo}, ${item.branch}, ${item.orderStatus}, ${item.orderDate}\n`;
              });
              const blob = new Blob([csvContent], {
                type: "text/csv",
              });
              exportBlob(blob, "All_Data.csv");

              navigator.clipboard
                .writeText(orderNoOnly)
                .then(() => {
                  //  setOpenSnackbar(true);
                  //  setOpen(true);
                })
                .catch((msg) => console.log(msg));
              hideMenu?.();
            }
          })
          .catch((err) => {
            setExportAllLoading(false);
            if (err.response && err.response.status === 401) {
              localStorage.clear();
              window.location = "/login";
            } else {
              setExportAllError("An error occurred while fetching data.");
            }
          });

        // const jsonString = getJson(apiRef);
        // const blob = new Blob([jsonString], {
        //   type: "text/json",
        // });
        // exportBlob(blob, "DataGrid_demo.json");

        // const csvContent = getCsv(exportAllData);
        // const blob = new Blob([csvContent], {
        //   type: "text/csv",
        // });
        // exportBlob(blob, "DataGrid_demo.csv");

        // Hide the export menu after the export
        console.log("ALL DATA DISPLAYED.");
        // hideMenu?.();
      }}
    >
      {exportAllLoading
        ? "Exporting All Data..."
        : "Export All & Copy Order No"}
    </MenuItem>
  );
}




function ExportFilterMenuItem(props) {
  const apiRef = useGridApiContext();
  const [exportFilLoading, setExportFilLoading] = useState(false);
  const [exportAllData, setExportAllData] = useState(null);
  const [exportAllError, setExportAllError] = useState("");

  getJson(apiRef);

  const { hideMenu } = props;

  const getCsv = (fetchedData) => {
    let csvContent = `orderNo,  branch, orderStatus, orderDate\n`;

    // Add data rows
    fetchedData.forEach((item) => {
      csvContent += `${item.orderNo}, ${item.branch}, ${item.orderStatus}, ${item.orderDate}\n`;
    });

    return csvContent;
  };

  return (
    <MenuItem
      onClick={() => {
        setExportFilLoading(true);

        httpclient
          .get(`/temple-webster/order-list-by-branch?pagination=all&${props.filterData}`)
          .then(({ data }) => {
            if (data.success) {
              setExportFilLoading(false);

              setExportAllData(data.data);
              setExportAllError(data.message);
              let csvContent = `orderNo,  branch, orderStatus, orderDate\n`;
              let orderNoOnly = "";

              // Add data rows
              data.data.forEach((item) => {
                orderNoOnly += `${item.orderNo}\n`;
                csvContent += `${item.orderNo}, ${item.branch}, ${item.orderStatus}, ${item.orderDate}\n`;
              });
              const blob = new Blob([csvContent], {
                type: "text/csv",
              });
              exportBlob(blob, "Filtered_Data.csv");

              navigator.clipboard
                .writeText(orderNoOnly)
                .then(() => {
                  //  setOpenSnackbar(true);
                  //  setOpen(true);
                })
                .catch((msg) => console.log(msg));
              hideMenu?.();
            }
          })
          .catch((err) => {
            setExportFilLoading(false);
            if (err.response && err.response.status === 401) {
              localStorage.clear();
              window.location = "/login";
            } else {
              setExportAllError("An error occurred while fetching data.");
            }
          });

        // const jsonString = getJson(apiRef);
        // const blob = new Blob([jsonString], {
        //   type: "text/json",
        // });
        // exportBlob(blob, "DataGrid_demo.json");

        // const csvContent = getCsv(exportAllData);
        // const blob = new Blob([csvContent], {
        //   type: "text/csv",
        // });
        // exportBlob(blob, "DataGrid_demo.csv");

        // Hide the export menu after the export
        console.log("Fil DATA DISPLAYED.");
        // hideMenu?.();
      }}
    >
      {exportFilLoading
        ? "Exporting Filtered Data..."
        : "Export Filter & Copy Order No"}
    </MenuItem>
  );
}