import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Portal,
  Snackbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGrid, GridToolbarExport } from "@mui/x-data-grid";
import React, { useEffect, useState, useRef } from "react";
import { v4 as uuidv4 } from "uuid";

import { CloudUpload, Download, FilterList } from "@mui/icons-material";
import useApiCall from "../../hooks/useApiCall";
import FilterForm from "../FilterForm/FilterForm";
import { colDataExtendAttributes } from "../../utils/DataGrid/colDataExtendAttributes";
import { dateFormatter } from "../../utils/DataGrid/dateFormatter";
import ExcelMenu from "../ExcelMenu/ExcelMenu";
import { httpclient } from "../../../src/utils/httpClient";
import useGetCall from "../../hooks/useGetCall";
import { CSVLink } from 'react-csv';
import BasicTable2 from "../BasicTable2";
const formatStockCodes = (stockCodesString) => {
  // Split the string into an array based on newline character
  const stockCodesArray = stockCodesString.split("\n");

  // Join the array elements using commas
  const formattedStockCodes = stockCodesArray.join(",");

  return formattedStockCodes;
};



const NamingConvension = (props) => {

  const [dialogDetails, setDialogDetails] = useState({
    open: true,
    success: false,
  });


  const handleClose = () => {
    props.setOpenNamingConvension(false)
  };

 

  return (
    <div>
      <Dialog
        open={dialogDetails.open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          File Naming Convention
        </DialogTitle>
        <DialogContent>
          {/* <Box pt={3} >Are you sure you want to cancel this order? </Box> */}
          <Box pt={3} >
          <BasicTable2 rows={props.namingRows} columns={props.namingColumns}/>
          </Box>

        </DialogContent>
        <DialogActions styled={{ margin: "5px 10px" }}>
          <Button onClick={handleClose} color="primary" variant="contained" autoFocus>
            Close
          </Button>
          

        </DialogActions>
      </Dialog>
    </div>
  );
};

const DataGridTablePage = ({
  checkboxSelection = false,
  customIDKey,
  columns,
  filterColumn,
  headerTitle,
  exportFeature,
  pageUrl,
  exportFileLink,
  columnsVisibility,
  children,
  dataChanged,
  hideMenu,
  navigateState,
  forecastPassedUrl,
  tempTableOff,
  uploadFeature,
  uploadFile,
  uploadFileLink,
  dateFilter,
  text,
}) => {
  const [copiedString, sestCopiedString] = useState("");
  const [open, setOpen] = useState(false);
  const [openNamingConvension, setOpenNamingConvension] = useState(false);
  const [message, setMessage] = useState("Upload Successful.");
  const [toogleFilterForm, setToogleFilterForm] = useState(false);
  const [toogleChildren, setToogleChildren] = useState(false);
  const [passRowParams, setPassRowParams] = useState({});
  const [uploadLoading, setUploadLoading] = useState(null);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 20,
  });
  const [file, setFile] = useState("");
  const [sortModel, setSortModel] = useState([
    {
      field: "",
      sort: "",
    },
  ]);

  const [filterData, setFilterData] = useState("");
  const [filterDataSales, setFilterDataSales] = useState({});
  const [empty, setEmpty] = useState(false);
  const [files, setFiles] = useState([]);
  const [fileCount, setFileCount] = useState(0);

  const fileInputRef = useRef(null);

  const handleFileSelectionButtonClick = () => {
    fileInputRef.current.click(); // Clicking the file input to open file selection dialog
  };

  const openToolTip = () => {
    setOpenNamingConvension(true); // Clicking the file input to open file selection dialog
  };

  const namingColumns = [
    { id: "type", name: "Type" },
    { id: "mustHave", name: "Must Have" },
    { id: "example", name: "Example" }
  ];
  
  const namingRows = [
    { type: "MSL Level", mustHave: "StoreStockMasterxxxx.csv", example: "StoreStockMaster-2024-02-24.csv" },
    { type: "Store SOH", mustHave: "StoreSOHxxxx.csv", example: "StoreSOH-2024-03-25.csv" },
    { type: "Store Sales", mustHave: "StoreSalesxxxx.csv", example: "StoreSales-2024-04-25.csv"  },
    { type: "Store Grades", mustHave: "StoreGradesxxxx.csv", example: "StoreGrades-2024-05-25.csv"  },
    { type: "Pipeline", mustHave: "Pipelinexxxx.csv", example: "Pipeline2024-09-08.csv"  }
  ];
  


  const { loading: loading, error: error, data: data, pageInfo: pageInfo } = useApiCall(
    `${pageUrl}?pagination=${paginationModel.pageSize}&page=${paginationModel.page + 1
    }&sort_by=${sortModel[0]?.field || ""}&direction=${sortModel[0]?.sort || ""
    }&${filterData}`,
    "GET",

    [sortModel, paginationModel, filterData, dataChanged]
    //check this dependencies pagination working only after sorting model changed
  );
  useEffect(() => {
    if (dateFilter !== undefined) {
      const pairs = filterData?.split('&');

      pairs.forEach(pair => {

        const [key, value] = pair.split('=');

        filterDataSales[key] = value;
      });

      // const allEmpty = Object.values(filterDataSales).every(value => value === '' || value === undefined);
      const allEmpty = filterDataSales.startDate === '' || filterDataSales.endDate === '';
      setEmpty(allEmpty);
    }
  }, [filterData, dateFilter !== undefined]);




  const { loading: loading1, data: data1 } = useApiCall(
    pageInfo && pageInfo.total !== undefined ? `${pageUrl}?pagination=${pageInfo.total}&${filterData}` : `${pageUrl}`,
    "GET",
    [pageInfo]
  );


  // Call fetchData whenever pageInfo changes


  const defaultHistoricalSalesStart = "2022-05-01";
  const defaultHistoricalSalesEnd = "2023-05-30";
  const defaultSupplierLeadTimeInWeeks = 4;
  const defaultDefaultDemand = "HIST";
  const defaultStockCodes = "";

  let apiCallUrl;

  if (navigateState?.filterData) {
    const {
      supplierLeadTime,
      custLeadTimeDays,
      stock_codes,
      customerID,
      coverageBuffer,
      cycleFrequency,
      defaultDemand,
      defaultForecast,
      smoothing,
      recentSalesWeeks,
      useSalesDataSince,
      historicalSalesStart,
      salesExcludeMonths,
      historicalSalesEnd,
      globalAllocRamping,
      globalSales,
      meanTrimming,
      otherSettings,
      currentProductOnly,
      dataRetrieveOnly,
      ignoreSupplier,
      standardAllocations,
      adjustedAllocations,
      allocateToMSLOnly,
    } = navigateState?.filterData;
    let defaultDemandDependentParameters;

    if (defaultDemand === "RECENT") {
      defaultDemandDependentParameters = `?recentSalesWeek=${recentSalesWeeks}`;
    }
    if (defaultDemand === "HIST") {
      defaultDemandDependentParameters = `?historicalSalesStart=${historicalSalesStart}&historicalSalesEnd=${historicalSalesEnd}`;
    } else {
      defaultDemandDependentParameters = `?`;
      // `?salesExcludeMonths=${salesExcludeMonths}`;
    }
    apiCallUrl = `${forecastPassedUrl}${defaultDemandDependentParameters}&supplierLeadTime=${supplierLeadTime}&defaultDemand=${defaultDemand}&stock_codes=${formatStockCodes(
      stock_codes
    )}`;
  } else {
    apiCallUrl = `${forecastPassedUrl}?historicalSalesStart=${defaultHistoricalSalesStart}&historicalSalesEnd=${defaultHistoricalSalesEnd}&supplierLeadTime=${defaultSupplierLeadTimeInWeeks}&defaultDemand=${defaultDefaultDemand}`;
  }

  useEffect(() => {
    console.log("NAVIGATE FILTER DATA", navigateState?.filterData);
  }, [navigateState]);

  const {
    loading: downloadForecastLoading,
    error: downloadForecastError,
    data: downloadForecastData,
    message: downloadForecastMessage,
  } = useGetCall(apiCallUrl, forecastPassedUrl, [navigateState]);



  const [rowCountState, setRowCountState] = React.useState(
    pageInfo?.total || 0
  );

  const handleFilterDataChange = (newFilterData) => {
    setFilterData(newFilterData);
  };

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;

    // Check if a file is selected
    if (!selectedFiles || selectedFiles.length === 0) {
      return;
    }

    // Set the selected file in the state (assuming you have a state variable named 'file')
    setFile(selectedFiles[0]);

    // Check if the selected file is a PDF
    const fileName = selectedFiles[0];

    const formData = new FormData();
    formData.append("file", fileName); // Use 'file' instead of 'selectedFiles'
    setUploadLoading(true);
    httpclient
      .post("/replenishment/importPipeline", formData)
      .then(({ data }) => {
        if (data.success === true) {
          setUploadLoading(false);
          setMessage("Upload Successful");
          setFile("");
          setOpen(true);
        } else {
          setUploadLoading(false);
          // Handle error, e.g., show a message to the user
          setMessage("Error uploading file");
          // : " + data.message);
          setOpen(true);
          setFile("");
        }
      })
      .catch((error) => {
        setUploadLoading(false);
        // Handle HTTP error
        setMessage("Upload Failed.");
        alert("An error occurred while uploading the file.");
      });
    console.log("FILE UPLOADED", fileName);
  };
  

  const handleFileChangeMultiple = (event) => {
   
    setFiles(event.target.files); // Update state with selected files
    setFileCount(event.target.files.length);
  };
 
  
  const handleSubmit = () => {

    if (!files || files.length === 0) {
      return; // No files selected, do nothing
    }
  
    const formData = new FormData();
    // Append each file to the formData object
    for (let i = 0; i < files.length; i++) {
      formData.append('file[]', files[i]);
    }
    setUploadLoading(true);
    httpclient
      .post("replenishment/importAllExcelFiles", formData)
      .then(({ data }) => {
        if (data.success === true) {
          setUploadLoading(false);
          setMessage("Upload Successful");
          setOpen(true);
        } else {
          setUploadLoading(false);
          // Handle error, e.g., show a message to the user
          setMessage("Error uploading file: " + data.message);
          setOpen(true);
        }
      })
      .catch((error) => {
        setUploadLoading(false);
        // Handle HTTP error
        setMessage("Upload Failed.");
        alert("An error occurred while uploading the file.");
      });
    console.log("FILE UPLOADED", file);
  };



useEffect(() => {
  setRowCountState((prevRowCountState) =>
    pageInfo?.total !== undefined ? pageInfo?.total : prevRowCountState
  );
}, [pageInfo?.total, setRowCountState]);

const handleSortModelChange = (model) => {
  if (JSON.stringify(model) !== JSON.stringify(sortModel)) {
    setSortModel(model);
  }
};
const handleCellClick = (param, event) => {
  event.stopPropagation();
};

const customToolBar = () => {
  return (
    <>


      {/* {exportFeature && (
          <Portal container={() => document.getElementById("filter-panel")}>
            {!exportFileLink && (
              <GridToolbarExport
                csvOptions={{
                  fileName: headerTitle + String(dateFormatter(Date.now())),
                }}
                type="Button"
                color="primary"
                variant="contained"
                sx={{ padding: "6.5px 16px" }}
              />
            )}
          </Portal>
        )} */}
      {exportFeature && (
        <Portal container={() => document.getElementById("filter-panel")}>
          {!exportFileLink && (
            <>

              {(!loading1 && data1.length > 0) ? (
                <CSVLink data={data1} filename={headerTitle + String(dateFormatter(Date.now())) + ".csv"}>
                  <Button
                    color="primary"
                    variant="contained"
                    style={{ marginRight: "2px" }}
                  >
                    Export <Download style={{ marginLeft: "5px" }} fontSize="small" />
                  </Button>
                </CSVLink>
              ) : (
                <Button
                  color="primary"
                  variant="contained"
                  style={{ marginRight: "2px" }}
                  disabled={loading}
                //onClick={handleExport}
                //ref={buttonRef} // Assign the ref to the button
                >
                  {/* {loadingExport ? "Generating Data to Export" : "Create Export"}{loadingExport ? `...` : <Download style={{ marginLeft: "5px" }} fontSize="small" />} */}
                  Export {loading1 ?
                    <CircularProgress
                      style={{
                        marginLeft: "5px",
                        height: "25px",
                        width: "25px",
                        color: "#f5f5f5"
                      }} fontSize="small" />
                    :
                    <Download style={{ marginLeft: "5px" }} fontSize="small" />}
                </Button>
              )}

            </>

          )}
        </Portal>
      )}
    </>
  );
};

const customToolBarExp = () => {
  return (
    <>
      {uploadFeature && (
        <Portal container={() => document.getElementById("filter-panel")}>
          {!uploadFileLink && (
            <>
              <Button
                component="label"
                variant="contained"
                startIcon={<CloudUpload />}
                sx={{ marginLeft: "5px" }}
              >
                {uploadLoading ? "Uploading ..." : "Upload CSV"}
                <input
                  type="file"
                  accept=".csv"
                  style={{
                    clip: "rect(0 0 0 0)",
                    clipPath: "inset(50%)",
                    height: 1,
                    overflow: "hidden",
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    whiteSpace: "nowrap",
                    width: 1,
                  }}
                  // multiple
                  onChange={handleFileChange} // Add the change event handler
                />
              </Button>
            </>
          )}
        </Portal>
      )}
    </>
  );
};

// const customToolBarUpload = () => {
//   return (
//     <>
//       {uploadFeature && uploadFile && (
//         <Portal container={() => document.getElementById("filter-panel")}>
//           {uploadFile && (
//             <>
//               <Button
//                 component="label"
//                 variant="contained"
//                 startIcon={<CloudUpload />
//               }
//               onClick={handleFileSelectionButtonClick}
//               >
//                 {uploadLoading ? "Uploading ..." : "Upload CSV"}
//                 <input
//                   type="file"
//                   accept=".csv"
//                   style={{
//                     clip: "rect(0 0 0 0)",
//                     clipPath: "inset(50%)",
//                     height: 1,
//                     overflow: "hidden",
//                     position: "absolute",
//                     bottom: 0,
//                     left: 0,
//                     whiteSpace: "nowrap",
//                     width: 1,
//                     display:'none'
//                   }}
//                   ref={fileInputRef}
//                   multiple
//                   onChange={handleFileChangeMultiple} // Add the change event handler
//                 />
//               </Button>
//             </>
//           )}
//         </Portal>
//       )}
//     </>
//   );
// };

return (
  <>
    {/* maxWidth={false}> */}
    {!error && (
      <>
        <Grid container spacing={2} sx={{ width: "100%" }}>
          <Grid item md={6} xs={12}>
            <h1>{headerTitle}</h1>
            <Typography fontWeight={"bold"}>
              {navigateState?.fileName
                ? `File Name: ${navigateState?.fileName}`
                : ""}
            </Typography>
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
          >
            {uploadFeature && (
              <Box id="filter-panel">
                {uploadFileLink && (
                  <Button
                    color="primary"
                    variant="contained"
                    target="_blank"
                    disabled={{}}
                    href={{}}
                  // onClick={() => console.log(downloadForecastData)}
                  >
                    Upload CSV
                  </Button>
                )}
              </Box>
            )}
            {uploadFile && (
              <Box id="filter-panel">

                <Button
                  component="label"
                  variant="contained"
                  sx={{marginRight:"10px"}}

                >
                  Select Multiple files to upload({fileCount})
                  <input
                    type="file"
                    accept=".csv"
                    style={{
                      clip: "rect(0 0 0 0)",
                      clipPath: "inset(50%)",
                      height: 1,
                      overflow: "hidden",
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      whiteSpace: "nowrap",
                      width: 1,
                    }}
                    //ref={fileInputRef}
                    multiple
                    onChange={handleFileChangeMultiple} // Add the change event handler
                  />
                </Button>
                <Button
                  component="label"
                  variant="contained"
                  startIcon={<CloudUpload />
                  }
                  //disabled={file.length < 1}
                  onClick={handleSubmit}

                >
                   {uploadLoading ? "Uploading ..." : "Upload CSV"}
                  {/*<input
                    type="file"
                    accept=".csv"
                    style={{
                      clip: "rect(0 0 0 0)",
                      clipPath: "inset(50%)",
                      height: 1,
                      overflow: "hidden",
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      whiteSpace: "nowrap",
                      width: 1,
                    }}
                    //ref={fileInputRef}
                    multiple
                    onChange={handleFileChangeMultiple} // Add the change event handler 
                  />*/}
                </Button>
              </Box>
            )}
            {exportFeature && (
              <Box id="filter-panel">
                {exportFileLink && (
                  <Button
                    color="primary"
                    variant="contained"
                    target="_blank"
                    disabled={downloadForecastLoading}
                    href={downloadForecastData}
                  // onClick={() => console.log(downloadForecastData)}
                  >
                    Download CSV
                  </Button>
                )}
              </Box>
            )}
            {!!filterColumn && (
              <Button
                color="primary"
                variant="contained"
                onClick={() => setToogleFilterForm((prev) => !prev)}
                style={{ marginLeft: "5px" }}
              >
                Filter
                <FilterList fontSize="small" />
              </Button>
            )}
          </Grid>
          <Grid item xs={12}>
            {!!filterColumn && (
              <FilterForm
                toogle={toogleFilterForm}
                navigateState={navigateState}
                filterColumn={filterColumn}
                onFilterDataChange={handleFilterDataChange}
              />
            )}

            {navigateState?.startDate && (
              <ExcelMenu hideMenu={hideMenu} navigateState={navigateState} />
            )}
          </Grid>
          <Grid item md={12} xs={12}>
            {text &&
              // <div style={{ display: "flex", flexDirection: "column", lineHeight: "1.0", fontFamily: "Poppins", alignItems: "flex-end" }}>
              //   <small>Reminder! MSL LEVEL MUST BE StoreStockMaster and .csv, store soh must be StoreSOH.csv, store sales must be StoreSales and store grade must be StoreGrading</small>
              //   &nbsp;&nbsp;
                
              // </div>
              <div style={{ display: "flex", flexDirection: "column", lineHeight: "1.0", fontFamily: "Poppins", alignItems: "flex-end" }}>
              <Button
                color="primary"
                variant="contained"
                onClick={() => openToolTip()}
                style={{ marginLeft: "5px" }}
              >
                File Naming Convention
                
              </Button>
              </div>
            }
          </Grid>

          <Grid item xs={12}>
            {!tempTableOff && (
              <DataGrid
                //this optional
                // slots={{
                //   toolbar: customToolBar,
                // }}
                slots={{
                  toolbar: () => (
                    <>
                      <Portal container={() => document.getElementById("filter-panel")}>
                        {/* Include customToolBar */}

                        {customToolBar()}
                        {/* Include customToolBarExp */}
                        {customToolBarExp()}
                      </Portal>
                    </>
                  ),
                }}
                //this changes and optional
                columnVisibilityModel={columnsVisibility}
                //this changes and mandatory
                getRowId={
                  customIDKey ? (row) => row[customIDKey] : () => uuidv4()
                }
                onClipboardCopy={(str) => {
                  const coppiedString = str
                    .split("\n")
                    .map((line) => line.split("\t")[0])
                    .toString()
                    .replaceAll(",", "\n");
                  sestCopiedString(coppiedString);
                }}
                rows={data}
                onRowClick={
                  children
                    ? (params) => {
                      setToogleChildren((prev) => !prev);
                      setPassRowParams(params);
                      // console.log("ROW CLICKED", params.row);
                    }
                    : null
                  // () => console.log("CLICKED")
                }
                rowCount={rowCountState}
                columns={colDataExtendAttributes(columns)}
                loading={loading}
                disableColumnMenu
                autoHeight={true}
                pageSizeOptions={[20, 50, 70, 100]}
                paginationMode="server"
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                checkboxSelection={checkboxSelection}
                // onCellClick={handleCellClick}
                sortingMode="server"
                sortModel={sortModel}
                onSortModelChange={handleSortModelChange}
              />
            )}
          </Grid>

          {toogleChildren &&
            React.cloneElement(children, {
              setToogleChildren: setToogleChildren,
              parameter: passRowParams,
            })}
             {openNamingConvension && 
             <NamingConvension
             namingColumns = {namingColumns}
             namingRows = {namingRows}
             setOpenNamingConvension={setOpenNamingConvension}
              />
             }
        </Grid>
        <Snackbar
          open={open}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={(event, reason) => {
            if (reason === "clickaway") {
              return;
            }
            setOpen(false);
          }}
          message={message}
        />
      </>
    )}

    {error && console.log("error message", error)}
  </>
);
};

export default DataGridTablePage;
