import React from "react";
import { dateFormatter } from "../../../utils/DataGrid/dateFormatter";
import DataGridTablePage from "../../../Components/DataGridTablePage/DataGridTablePage";
import { useLocation } from "react-router-dom";

const fieldHeaderPairs = [
  { field: "TRAN_ID" },
  { field: "ORDER_NO", headerName: "Number" },
  {
    field: "ETA",
    headerName: "REVTA",
    valueFormatter: (params) => dateFormatter(params),
  },
  { field: "STORE_CODE", headerName: "Branch" },
  { field: "STOCK_CODE", headerName: "Style" },

  { field: "ORDER_QTY", headerName: "Order Quantity" },
  {
    field: "updatedDate",
    headerName: "Updated Date",
    valueFormatter: (params) => dateFormatter(params),
  },
  // { field: "CUSTOMER_ID", headerName: "Customer Name" },
];

const filterColumn = [
  {
    field: "STORE_CODE",
    label: "Branch",
    component: "AutocompleteField",
    url: "/replenishment/pipeline",
    overrideDataField: "branchList",
  },
  {
    field: "STOCK_CODE",
    label: "Style",
    component: "TextField",
  },
  {
    field: "startDate",
    label: "Start Date",
    component: "TextField",
    type: "date",
  },
  {
    field: "endDate",
    label: "End Date",
    component: "TextField",
    type: "date",
  },
  // {
  //   field: "soldToa",
  //   // label: "Sold To",
  //   component: "AutocompleteField",
  //   url: "/current/company-account/all",
  // },
];

const ReplenishmentPipeline = () => {
  const location = useLocation();
  return (
    
    <DataGridTablePage
    headerTitle="List Pipeline"
    hideMenu={2}
    exportFeature={true}
    uploadFeature={true}
    navigateState={location.state}
    customIDKey="TRAN_ID"
    columns={fieldHeaderPairs}
    filterColumn={filterColumn}
    pageUrl="/replenishment/pipeline"
    columnsVisibility={{
      TRAN_ID: false,
    }}
  />
  );
};

export default ReplenishmentPipeline;
