import { Check, Clear, Close, KeyboardArrowLeft } from "@mui/icons-material";
import {
    AppBar,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Skeleton,
    styled,
    useTheme,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import BasicTableView1 from "../../../../Components/BasicTableView1";



const StyledHeaderTitle = styled(DialogTitle)(({ theme }) => ({
    background: theme.palette.primary.main,
    color: "#fff",
    position: "relative",
    "& button": {
        position: "absolute",
        right: "15px",
        top: "15px",
        color: "#fff",
    },
}));

const FlexContent = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    fontSize: "17px",
    marginBottom: "10px",
    alignItems: "flex-start",
}));

const FlexInnerTitle = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    minWidth: "280px",
    maxWidth: "320px",
    fontWeight: "600",
}));

const Values = styled("div")(({ theme }) => ({
    marginLeft: "15px",
    fontWeight: "500",
    color: theme.palette.primary.dark,
    "& input": {
        padding: "10px",
        width: "150px",
    },
    "& .MuiSelect-select": {
        padding: "10px",
    },
    "& button": {
        padding: "11px !important",
        minWidth: "40px",
    },
}));


const AppBarTabs = styled(AppBar)(({ theme }) => ({
    background: "#fff",
    color: theme.palette.primary.dark,
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        "aria-controls": `full-width-tabpanel-${index}`,
    };
}

const ViewMoreDetailsOrder = (props) => {


    const theme = useTheme();
    const [value, setValue] = useState(0);

    const [dialogDetails, setDialogDetails] = useState({
        open: true,
    });


    useEffect(() => {
        props.sendDetails(dialogDetails);

    }, [props, dialogDetails]);


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleClose = () => {
        setDialogDetails({
            ...dialogDetails,
            open: false,
        });
    };


    const ParameterDisplay = ({ title, value, isDate = false }) => (
        <FlexContent>
            <FlexInnerTitle>
                <span>{title}</span> <span> : </span>
            </FlexInnerTitle>
            <Values>
                {/* {isDate && value !== "0000-00-00 00:00:00" && value !== ""
                    ? moment(value).format("ddd, MMM Do YYYY, hh:mm:ss A")
                    : value || "-"} */}
                {value || "-"}
            </Values>
        </FlexContent>
    );

    const parameters = [
        { key: 'orderType', title: 'Order Type' },
        { key: 'orderNumber', title: 'Order Number' },
        { key: 'orderDate', title: 'Order Date', isDate: true },
        { key: 'netsuiteOrderNumber', title: 'NetSuite Order Number' },
        { key: 'netsuiteOrderNumberGenerateDate', title: 'NetSuite Order Number Generate Date', isDate: true },
        { key: 'totalOrderProduct', title: 'Total Order Product' },
        { key: 'totalPrice', title: 'Total Price' },
        { key: 'assignedBranch', title: 'Assigned Branch' },
        { key: 'isLimboOrder', title: 'Limbo Order?' },
        { key: 'isPreSalesOrder', title: 'Pre-Sales Order?' },

    ];

    const parameters1 = [

        { key: 'isPlainLabelOrder', title: 'Plain Label Order?' },

    ]


    if ((props.viewDetails.orderType === 'MYDEAL' || props.viewDetails.orderType === 'Bunnings') && props.viewDetails.isPlainLabelOrder === 'No') {

        parameters1.push(
            { key: 'isPushToShippit', title: 'Push to Shippit?' },
            { key: 'isLabelPdfGenerated', title: 'Label PDF Generate?' },
            { key: 'isMainfestGenerated', title: 'Manifest Generate?' },
        )
    } else if (props.viewDetails.orderType === 'DSCO') {
        parameters1.push(
            { key: 'isPushToShippit', title: 'Push to Shippit?' },
            { key: 'isLabelPdfGenerated', title: 'Label PDF Generate?' },
            { key: 'isMainfestGenerated', title: 'Manifest Generate?' },
        )

    } else if (props.viewDetails.orderType === 'TW') {
        parameters1.push(
            { key: 'isPushToShippit', title: 'Push to Shippit?' },
            { key: 'isLabelPdfGenerated', title: 'Label PDF Generate?' },
            { key: 'isMainfestGenerated', title: 'Manifest Generate?' },
        )

    }

    parameters1.push(

        { key: 'isInvoiceReadyForNetsuite', title: 'Invoice Ready for Netsuite?' },
        { key: 'isDespatchReportGenerated', title: 'Despatch Report Generate?' },
        { key: 'isDespatchReportEmailSent', title: 'Despatch Report Email Sent?' },
        { key: 'isInvoiceGeneratedFromNetsuite', title: 'Invoice Generated from Netsuite?' },
        { key: 'isPushTrackingToMarketplace', title: 'Push Tracking to Marketplace?' },
        { key: 'isMarkAsShippedToMarketplace', title: 'Mark As Shipped To Marketplace?' },
        { key: 'isInvoiceSentToMarketplace', title: 'Invoice Sent To Marketplace?' }

    )

    const parameters2 = [];

    if (props.viewDetails.isPlainLabelOrder === 'Yes') {
        parameters2.push(
            { key: '-', title: '-' }
        );
    } else if (props.viewDetails.isPlainLabelOrder === 'No') {
        parameters2.push(
            { key: '-', title: '-' }
        );
    }

    if ((props.viewDetails.orderType === 'MYDEAL' || props.viewDetails.orderType === 'Bunnings') && props.viewDetails.isPlainLabelOrder === 'No') {

        parameters2.push(
            { key: 'pushToShippitDate', title: 'Push to Shippit Date' },
            { key: 'labelPdfGeneratedDate', title: 'Label PDF Generated Date', isDate: true },
            { key: 'mainfestGeneratedDate', title: 'Manifest Generated Date', isDate: true },
        )
    } else if (props.viewDetails.orderType === 'DSCO') {
        parameters2.push(
            { key: 'pushToShippitDate', title: 'Push to Shippit Date' },
            { key: 'labelPdfGeneratedDate', title: 'Label PDF Generated Date', isDate: true },
            { key: 'mainfestGeneratedDate', title: 'Manifest Generated Date', isDate: true },
        )

    } else if (props.viewDetails.orderType === 'TW') {
        parameters2.push(
            { key: 'pushToShippitDate', title: 'Push to Shippit Date' },
            { key: 'labelPdfGeneratedDate', title: 'Label PDF Generated Date', isDate: true },
            { key: 'mainfestGeneratedDate', title: 'Manifest Generated Date', isDate: true },
        )

    }

    parameters2.push(

        { key: 'invoiceReadyForNetsuiteDate', title: 'Invoice Ready for Netsuite Date', isDate: true },
        { key: 'despatchReportgeneratedDate', title: 'Despatch Report Generated Date', isDate: true },
        { key: 'despatchReportEmailSentDate', title: 'Despatch Report Email Sent Date', isDate: true },
        { key: 'invoiceGeneratedFromNetsuiteDate', title: 'Invoice Generated From NetSuite Date', isDate: true },


    )

    if (props.viewDetails && (props.viewDetails.orderType === 'MYDEAL' || props.viewDetails.orderType === "Bunnings")) {
        if (props.viewDetails.isPushTrackingToMarketplace === 'No') {
            parameters2.push(
                { key: 'toBeTrackingDate', title: 'To Be Tracking Date' }
            );
        } else if (props.viewDetails.isPushTrackingToMarketplace === 'Yes') {
            parameters2.push(
                { key: 'pushTrackingMarketplaceDate', title: 'Push Tracking Marketplace Date' }
            );
        }

    }


    if (props.viewDetails && props.viewDetails.orderType === "Bunnings") {
        if (props.viewDetails.isMarkAsShippedToMarketplace === 'Yes') {
            parameters2.push(
                { key: 'markAsShippedToMarketplaceDate', title: 'Mark As Shipped To Marketplace Date' }
            );
        } else if (props.viewDetails.isMarkAsShippedToMarketplace === 'No') {
            parameters2.push(
                { key: 'toBeShippedDate', title: 'To Be Shipped Date' }
            );
        }
    }
    if (props.viewDetails && (props.viewDetails.orderType === "DSCO")) {

        parameters2.push(
            { key: 'markAsShippedToMarketplaceDate', title: 'Mark As Shipped To Marketplace Date' }
        );

    }

    parameters2.push(


        { key: 'invoiceSentToMarketplaceDate', title: 'Invoice Sent To Marketplace Date', isDate: true },


    )

    if (!(props.viewDetails && props.viewDetails.orderType === 'TW' && props.viewDetails.isShipmentUpdateOrder === 'No')) {
        parameters1.push(

            { key: 'isShipmentUpdateOrder', title: 'Shipment Update Order?' },

            { key: 'isTrackingGenerated', title: 'Tracking Generated?' },

            { key: 'isTrackingPushToFtp', title: 'Tracking Push to FTP?' },

        );
    }

    if (props.viewDetails && props.viewDetails.orderType === 'TW' && props.viewDetails.isPreSalesOrder === "Yes") {
        parameters1.push(
            { key: 'isPreSalesRecited', title: 'Pre-Sales Recited?' },
        )

    }

    if (!(props.viewDetails && props.viewDetails.orderType === 'TW' && props.viewDetails.isShipmentUpdateOrder === 'No')) {
        parameters2.push(

            { key: 'shipmentUpdateDate', title: 'Shipment Update Date', isDate: true },

            { key: 'trackingGeneratedDate', title: 'Tracking Generated Date', isDate: true },

            { key: 'trackingPushToFtpDate', title: 'Tracking Push to FTP Date', isDate: true }
        );
    }
    if (props.viewDetails && props.viewDetails.orderType === 'TW' && props.viewDetails.isPreSalesOrder === "Yes") {
        parameters2.push(
            { key: 'preSalesRecitedDate', title: 'Pre-Sales Recited Date', isDate: true },
        )

    }


    return (
        <div>
            <Dialog
                open={dialogDetails.open}
                onClose={handleClose}
                maxWidth="l"
                fullWidth
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <StyledHeaderTitle id="alert-dialog-title">
                    <div>{`View Order Details (${props.viewDetails?.netsuiteOrderNumber || "-"
                        })`}</div>
                    <IconButton onClick={handleClose}>
                        <Close />
                    </IconButton>
                </StyledHeaderTitle>
                {props.singleLoading ? (
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Box p={3} sx={{ width: "100%" }}>
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                </Box>
                            </Grid>


                        </Grid>
                    </DialogContent>
                ) : (
                    <DialogContent style={{ margin: "0", padding: "0" }}>
                        <AppBarTabs position="static">
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                indicatorColor="secondary"
                                textColor="inherit"
                                variant="fullWidth"
                                aria-label="full width tabs example"
                            >
                                <Tab label="Order Details" {...a11yProps(0)} />


                            </Tabs>
                        </AppBarTabs>

                        <TabPanel value={value} index={0} dir={theme.direction}>

                            <Box p={3}>
                                <Grid container spacing={2}>
                                    {/* Left Side */}
                                    <Grid item xs={12} md={4}>
                                        {parameters.map(({ key, title, isDate }) => (
                                            props.viewDetails && props.viewDetails[key] !== undefined && (
                                                <ParameterDisplay key={key} title={title} value={key === "totalPrice" ? `$${props.viewDetails[key]}` : props.viewDetails[key]} isDate={isDate} />
                                            )
                                        ))}
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        {parameters1.map(({ key, title, isDate }) => (
                                            props.viewDetails && props.viewDetails[key] !== undefined && (
                                                <ParameterDisplay key={key} title={title} value={props.viewDetails[key]} isDate={isDate} />
                                            )
                                        ))}
                                    </Grid>

                                    <Grid item xs={12} md={5}>
                                        {parameters2.map(({ key, title, isDate }) => (
                                            key === "-" ?
                                                <FlexContent>
                                                    <FlexInnerTitle>
                                                        <span>{title}</span> <span>  </span>
                                                    </FlexInnerTitle>
                                                    <Values>


                                                    </Values>
                                                </FlexContent>
                                                :
                                                props.viewDetails && props.viewDetails[key] !== undefined && (
                                                    <ParameterDisplay key={key} title={title} value={props.viewDetails[key]} isDate={isDate} />
                                                )
                                        ))}
                                    </Grid>
                                </Grid>
                            </Box>

                        </TabPanel>


                    </DialogContent>
                )}
                <DialogActions>
                    <Button onClick={handleClose} variant="outlined" color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ViewMoreDetailsOrder;
