import React, { useState } from "react";
import DataGridTablePage from "../../../Components/DataGridTablePage/DataGridTablePage";

import moment from "moment";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Grid,
  Snackbar,
  TextField,
} from "@mui/material";
import { httpclient } from "../../../utils/httpClient";
import { FilterList } from "@mui/icons-material";

const Holiday = () => {
  var loginData = localStorage.getItem("login");
  var loginValue = JSON.parse(loginData);

  const fieldHeaderPairs = [
    {
      field: "date",
      headerName: "Holiday",
      width: 200,
      valueFormatter: (params) =>
        moment(params.value, "DD/MM/YYYY").format("ddd, MMM Do YYYY"),
    },
    { field: "description", headerName: "Occasion", width: 320 },

    { field: "userID", width: 150 },
    {
      field: "id",
      headerName: "",
      width: 180,

      renderCell: (params) => (
        <>
          <Grid width={"100%"}>
            {/* {params.value} */}
            <Button
              variant="outlined"
              size="small"
              style={{ marginLeft: 0 }}
              tabIndex={params.hasFocus ? 0 : -1}
              onClick={() => editHolidayDataFill(params.value)}
            >
              Edit
            </Button>
            <Button
              variant="outlined"
              size="small"
              style={{ marginLeft: 16 }}
              tabIndex={params.hasFocus ? 0 : -1}
              onClick={() => deleteSingleHoliday(params.value)}
            >
              Delete
            </Button>
          </Grid>
        </>
      ),
    },
  ];

  const [open, setOpen] = useState(false);
  const [formValue, setFormValue] = useState({
    date: "",
    description: "",
    bulkData: "",
  });
  const [loading, setLoading] = useState(false);
  const [bulkLoading, setBulkLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const [message, setMessage] = useState("");
  const [messageState, setMessageState] = useState("");
  const [isDataChanged, setIsDataChanged] = useState(1);
  const [editId, setEditId] = useState(null);

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const deleteSingleHoliday = (id) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this order?"
    );

    if (confirmed) {
      httpclient
        .delete(`setting/delete/holiday-calendar/${id}`)
        .then(({ data }) => {
          if (data.success) {
            setOpen(true);
            setMessageState("success");
            setMessage(data.message);
            setIsDataChanged((prev) => (prev === 1 ? 0 : 1));
          } else {
            setOpen(true);
            setMessageState("error");
            setMessage(data.error);
          }
        });
    }
  };

  const handleChangeFormData = (e) => {
    const { name, value } = e.target;

    setFormValue({
      ...formValue,
      [name]: value,
    });
    if (name === "bulkData") {
      const lines = value.split("\n");
      const linePattern = /^\d{2}\/\d{2}\/\d{4} - .*$/;
      const isValid = lines.every((line) => linePattern.test(line));
      setValidationError(!isValid);
      if (value === "") setValidationError(false);
    }
  };

  const editHolidayDataFill = (id) => {
    setEditLoading(true);

    httpclient.get(`setting/edit/holiday-calendar/${id}`).then(({ data }) => {
      if (data.success) {
        const { description, date } = data.data;
        const parsedDate = moment(date, "DD/MM/YYYY");

        // Format the date in the desired format
        const formattedDate = parsedDate.format("YYYY-MM-DD");

        setEditLoading(false);
        setFormValue((prev) => ({
          ...prev,
          description: description,
          date: formattedDate,
        }));
        setEditId(id);
      } else {
        setEditLoading(false);
      }
    });
  };


  const handleBulkHoliday = () => {
    setBulkLoading(true);
    httpclient
      .post(`setting/bulk/holiday-calendar`, {
        holidays: formValue.bulkData,
        userID: loginValue.userID,
      })
      .then(({ data }) => {
        if (data.success) {
          setOpen(true);
          setMessageState("success");
          setMessage(data.message);
          setBulkLoading(false);
          setIsDataChanged((prev) => (prev === 1 ? 0 : 1));
          setFormValue((prev) => ({
            ...prev,
            bulkData: "",
          }));
        } else {
          setBulkLoading(false);
          setOpen(true);
          setMessageState("error");
          setMessage(data.error);
        }
      });
  };

  const handleAddEditHoliday = () => {
    setLoading(true);
    // Determine the API endpoint based on whether you're adding or updating
    const apiEndpoint = editId
      ? `setting/update/holiday-calendar/${editId}`
      : `setting/holiday-calendar`;

    // Create dynamic data object
    const requestData = {
      date: moment(formValue.date).format("DD/MM/YYYY"),
      description: formValue.description || "-",
      // Include userID only if id is not provided
      ...(editId ? {} : { userID: loginValue.userID }),
    };
    httpclient.post(apiEndpoint, requestData).then(({ data }) => {
      if (data.success) {
        setOpen(true);
        setMessageState("success");
        setMessage(data.message);
        setLoading(false);
        setEditId(null);
        setIsDataChanged((prev) => (prev === 1 ? 0 : 1));
        setFormValue((prev) => ({
          ...prev,
          date: "",
          description: "",
        }));
      } else {
        setLoading(false);
        setOpen(true);
        setMessageState("error");
        setMessage(data.error);
      }
    });
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <DataGridTablePage
            dataChanged={isDataChanged}
            headerTitle="Holidays"
            exportFeature={false}
            customIDKey="id"
            columns={fieldHeaderPairs}
            pageUrl="setting/get-holiday/calendar"
            columnsVisibility={{
              // id: false,
              userID: false,
            }}
            onCellClick={null}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <h1>{editId ? "Edit Holiday" : "Add Holiday"}</h1>
          <Box mt={5} p={2} style={{ background: "#fff" }}>
            <TextField
              variant="outlined"
              type="date"
              name="date"
              placeholder={"DD/MM/YYYY"}
              value={formValue.date}
              onChange={handleChangeFormData}
              //fullWidth
              sx={{ marginBottom: "8px", marginRight: "15px", minWidth: "40%" }}
            />
            <TextField
              style={{ minWidth: "57%" }}
              value={formValue.description}
              onChange={handleChangeFormData}
              name="description"
              id="outlined-basic"
              label="Enter holiday description"
              variant="outlined"
            />
            <Box pt={2} pb={0} textAlign={"right"}>
              {loading ? (
                <CircularProgress
                  style={{
                    height: "30px",
                    width: "25px",
                    position: "relative",
                    top: "0px",
                  }}
                />
              ) : (
                <>
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={
                      formValue.description === "" || formValue.date === ""
                    }
                    onClick={handleAddEditHoliday}
                    style={{ marginTop: "10px" }}
                  >
                    {editId ? "Update" : "Add"}
                  </Button>
                  {editId && (
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        setEditId(null);
                        setFormValue({
                          description: "",
                          date: "",
                        });
                      }}
                      style={{ marginLeft: "10px", marginTop: "10px" }}
                    >
                      Go to Add
                    </Button>
                  )}
                </>
              )}
            </Box>
          </Box>

          <Grid item md={12}>
            <Box p={2} mt={8} style={{ background: "#fff" }}>
              <h3>Bulk Add Here</h3>
              <TextField
                id="standard-multiline-static"
                label="Add Holiday and Occasion (DD/MM/YYYY - Holiday Description)"
                multiline
                rows={4}
                placeholder="DD/MM/YYYY - Enter Holiday Description Here"
                variant="standard"
                error={validationError}
                value={formValue.bulkData}
                name="bulkData"
                onChange={handleChangeFormData}
                helperText={
                  validationError
                    ? "Invalid format. Please use DD/MM/YYYY - Holiday Description."
                    : ""
                }
                sx={{ width: "100%" }}
              />

              <Box pt={2} pb={0} textAlign={"right"}>
                {bulkLoading ? (
                  <CircularProgress
                    style={{
                      height: "30px",
                      width: "25px",
                      position: "relative",
                      top: "0px",
                    }}
                  />
                ) : (
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={validationError || formValue.bulkData === ""}
                    onClick={handleBulkHoliday}
                  >
                    BULK ADD
                  </Button>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={handleCloseSnack}
      >
        <Alert
          onClose={handleCloseSnack}
          severity={messageState}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Holiday;
