import React, { useEffect, useState } from "react";

import { httpclient } from "../../../utils/httpClient";
import { CircularProgress, styled, Snackbar, Grid, Typography, Accordion, AccordionSummary, AccordionDetails, FormControlLabel, Checkbox, TextField, Button } from "@mui/material";
import LoadingScreen from "../../../Components/LoadingScreen";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const FlexContent = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  fontSize: "17px",
  marginBottom: "10px",
  alignItems: "flex-start",
}));

const FlexInnerTitle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  minWidth: "280px",
  maxWidth: "320px",
  //fontWeight: "600",
}));

const Values = styled("div")(({ theme }) => ({
  marginRight: "15px",
  fontWeight: "500",
  color: theme.palette.primary.dark,
  "& input": {
    padding: "10px",
    width: "100px",
  },
  "& .MuiSelect-select": {
    padding: "10px",
  },
  "& button": {
    padding: "11px !important",
    minWidth: "40px",
  },
}));

const PL = () => {
  const [syncLoading, setSyncLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [syncList, setSyncList] = useState([]);
  const [newSyncList, setNewSyncList] = useState([]);
  const [expandedAccordion, setExpandedAccordion] = useState(null);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [messageState, setMessageState] = useState("");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    httpclient.get(`warehouse-setting`).then(({ data }) => {
      if (data.success) {
        setSyncLoading(false);
        setSyncList(data.data);
      } else {
        setSyncLoading(false);
      }
    });
  };

  useEffect(() => {
    if (syncList.length > 0) {
      setNewSyncList(syncList)
    }
  }, [syncList]);


  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? panel : null);
  };

  const handleSettingChange = (branchIndex, settingIndex, newValue) => {
    const newSyncLists = [...newSyncList];
    newSyncLists[branchIndex].settings[settingIndex].value = newValue;
    setNewSyncList(newSyncLists);
  };

  const renderSettingField = (setting, branchIndex, settingIndex) => {
    switch (setting.type) {
      case 'checkbox':
        return (
          <FormControlLabel
            control={
              <Checkbox
                checked={setting.value === '1'}
                onChange={(e) => handleSettingChange(branchIndex, settingIndex, e.target.checked ? '1' : '0')}
              />
            }
            label={setting.setting_name}
          />
        );
      case 'text':
        return (
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FlexContent>
                <Values>
                  <TextField
                    value={setting.value}
                    variant="standard"
                    fullWidth
                    onChange={(e) => handleSettingChange(branchIndex, settingIndex, e.target.value)}
                  />
                </Values>
                <FlexInnerTitle>
                  <span>{setting.setting_name}</span>
                </FlexInnerTitle>
              </FlexContent>
            </Grid>
          </Grid>
        );

      default:
        return null;
    }
  };

  const handleSubmit = () => {
    setLoading(true);
    httpclient.post(`warehouse-setting`, newSyncList).then(({ data }) => {
      if (data.success) {
        setLoading(false);
        setOpen(true);
        setMessage(data.message);
        setMessageState("success");
        fetchData();
        setExpandedAccordion(null);

      } else {
        setLoading(false);
        setOpen(true);
        setMessage(data.error || data.message);
        setMessageState("error");
      }
    });
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };


  return (
    <>
      {syncLoading ? (
        <LoadingScreen message="fetching Data ..." />
      ) : (
        <Grid container spacing={2}>
          {newSyncList.map((sync, branchIndex) => (
            <Grid item xs={12} key={branchIndex}>
              <Accordion
                expanded={expandedAccordion === `panel${branchIndex}`}
                onChange={handleChangeAccordion(`panel${branchIndex}`)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${branchIndex}-content`}
                  id={`panel${branchIndex}-header`}
                >
                  <Typography style={{ fontWeight: 'bold' }}>{sync.branchName}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {sync.settings.map((setting, settingIndex) => (
                    <div key={settingIndex}>
                      {renderSettingField(setting, branchIndex, settingIndex)}
                    </div>
                  ))}
                </AccordionDetails>
              </Accordion>
            </Grid>
          ))}
          <Grid item xs={12} sx={{ textAlign: "right", pt: 2, m: 2 }}>
            {loading ? (
              <CircularProgress
                style={{
                  height: "20px",
                  width: "20px",
                  position: "relative",
                }}
              />
            ) : (
              <Button variant="contained" color="primary" onClick={handleSubmit}>
                Submit
              </Button>
            )}
          </Grid>
        </Grid>
      )}
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={handleCloseSnack}
      >
        <Alert
          onClose={handleCloseSnack}
          severity={messageState}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </>


  );
};

export default PL;
