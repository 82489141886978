//import axios from 'axios';

// let httpclient = axios.create({
//   baseURL: 'https://ubatemp.retailcare.com.au/php/api/',
//   //baseURL: 'http://192.168.1.88/api/',
//   headers: {
//     'Access-Control-Allow-Origin': '*',
//     'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
//     'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
//     // 'Content-Type': "application/json",
//     Authorization: "Bearer " + tokenData,
//   },
// });

// export default httpclient;

import axios from "axios";
import GlobalURL from "./global";

var tokenData = localStorage.getItem("token");
var token = JSON.parse(tokenData);

// let localhttpclient = axios.create({
//   //baseURL:'http://localhost/v2.1/php/',
//   baseURL: GlobalURL[0].url,
//   headers: {
//     "Access-Control-Allow-Origin": "*",
//     "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
//     "Access-Control-Allow-Headers":
//       "Origin, Content-Type, X-Auth-Token, X-CSRFToken, CSRF ",
//     // 'CSRF': csrfToken.content,
//     Authorization: "Bearer " + token,
//   },
// });

// let livehttpclient = axios.create({
//   baseURL: GlobalURL[0].url,
//   headers: {
//     "Access-Control-Allow-Origin": "*",
//     "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
//     "Access-Control-Allow-Headers":
//       "Origin, Content-Type, X-Auth-Token, X-CSRFToken, CSRF",
//     // 'CSRF' : csrfToken.content,
//     Authorization: "Bearer " + token,
//   },
// });
export const httpclient = axios.create({
  baseURL: GlobalURL[0].url,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
    "Access-Control-Allow-Headers":
      "Origin, Content-Type, X-Auth-Token, X-CSRFToken, CSRF",
    // 'CSRF' : csrfToken.content,
    Authorization: "Bearer " + token,
  },
});

//   window.location.hostname === "localhost" ? localhttpclient : livehttpclient;
