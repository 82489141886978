import React, { useEffect, useState } from "react";
import { ArrowForward, Close, FilterList } from "@mui/icons-material";
import TableComponent from "../TableComponent";
import { httpclient } from "../../../../../utils/httpClient";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Collapse,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  styled,
  TextField,
} from "@mui/material";


const columns = [
  // { id: "sn", name: "SN"},
  { id: "stockID", name: "Stock ID" },
  { id: "marketplaceID", name: "Marketplace ID" },
  //{ id: "sellPriceNumber", name: "Sell Price Number" }, 
  { id: "pendingProcess", name: "Pending?" },
];

const FilteredBox = styled(Box)(({ theme }) => ({
  background: "#f9f9f9",
  padding: "5px 10px",
  borderRadius: "5px",
  "& p": {
    margin: "0",
    marginRight: "10px",
    display: "inline-block",
    background: "#dedede",
    borderRadius: "10px",
    padding: "2px 5px",
  },
  "& svg": {
    fontSize: "15px",
    cursor: "pointer",
    position: "relative",
    top: "3px",
    background: theme.palette.primary.dark,
    color: "#fff",
    borderRadius: "50%",
    padding: "2px",
    marginLeft: "2px",
  },
}));

const Header = styled("div")(({ theme }) => ({
  "& h1": {
    color: theme.palette.primary.dark,
    margin: "0",
  },
}));



const ZeroPrice = () => {

  const [rows, setRows] = useState([]);

  const [loading, setLoading] = useState(false);
  
 


  useEffect(() => {
    
    getNewSystemStocks()
 
  }, []);

  const getNewSystemStocks = () => {
    setLoading(true);

    httpclient
      .get(`/new-system/zero-price`)
      .then(({ data }) => {
        if (data.success) {
          setRows(data.data);
         
          setLoading(false);
        }
      }).catch(err => {
        if(err.response.status === 401){
          localStorage.clear();
          window.location = "/login";
        }
      })
  };
 

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item md={8} xs={12}>
          <Header>
            <h1>List Zero Price Stock</h1>
          </Header>
        </Grid>
        <Grid
          item
          md={4}
          xs={12}
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
        >
         
        </Grid>

        <Grid item xs={12}>
          <TableComponent
            columns={columns}
            rows={rows}
            loading={loading} 
            page={" "}
            total={" "}
            fromTable={" "}
            toTable={" "}          
          />
        </Grid>
      </Grid>

     
    </div>
  );
};

export default ZeroPrice;
