import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Box,
  Button,
  IconButton,
  Skeleton,
  TableFooter,
  TablePagination,
  useTheme,
} from "@mui/material";
import moment from "moment/moment";
import {
  Check,
  Clear,
  KeyboardArrowDown,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardArrowUp,
  UnfoldMore,
} from "@mui/icons-material";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.light,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  "& svg": {
    position: "relative",
    top: "5px",
  },
  "&:last-child": {
    // paddingRight: 64,
    "& svg": {
      // display: 'none',
      // color: theme.palette.primary.dark
    },
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onPageChange } = props;
  const theme = useTheme();

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  return (
    <div style={{ flexShrink: "0" }}>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 1}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage)}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default function TableComponent(props) {
  // const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(+event.target.value);
  //   setPage(0);
  // };

  // console.log("props", props);

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              {props.columns.map((col, index) =>
                props.sort ? (
                  ((index === (props.columns.length)) || index === 0 || index === props.columns.length - 1) ?
                    <StyledTableCell>
                      {col.name}
                      {/* {props.currentColumn === col.id ? <span style={{ fontWeight:"700" }}>{col.name}</span> : col.name}
                     {props.currentColumn === col.id ? props.direction ? <KeyboardArrowUp fontSize="small" /> : <KeyboardArrowDown fontSize="small" /> : <UnfoldMore fontSize="small" />}   */}
                    </StyledTableCell>
                    :
                    index === 0 ? (
                      <StyledTableCell onClick={() => props.handleSort(col.id)}>
                        {props.currentColumn === col.id ? (
                          <span style={{ fontWeight: "700" }}>{col.name}</span>
                        ) : (
                          col.name
                        )}
                        {props.currentColumn === col.id ? (
                          props.direction ? (
                            <KeyboardArrowUp fontSize="small" />
                          ) : (
                            <KeyboardArrowDown fontSize="small" />
                          )
                        ) : (
                          <UnfoldMore fontSize="small" />
                        )}
                      </StyledTableCell>
                    ) : (
                      <StyledTableCell
                        align=""
                        onClick={() => props.handleSort(col.id)}
                      >
                        {props.currentColumn === col.id ? (
                          <span style={{ fontWeight: "700" }}>{col.name}</span>
                        ) : (
                          col.name
                        )}
                        {props.currentColumn === col.id ? (
                          props.direction ? (
                            <KeyboardArrowUp fontSize="small" />
                          ) : (
                            <KeyboardArrowDown fontSize="small" />
                          )
                        ) : (
                          <UnfoldMore fontSize="small" />
                        )}
                      </StyledTableCell>
                    )
                ) : index === 0 ? (
                  <StyledTableCell>{col.name}</StyledTableCell>
                ) : (
                  <StyledTableCell align="">{col.name}</StyledTableCell>
                )
              )}
            </TableRow>
          </TableHead>
          {props.loading ?
            <TableBody sx={{ position: "relative", height: "465px", overflow: "hidden" }}>
              <Box p={3} sx={{ position: "absolute", width: "100%" }} >
                <Skeleton height={60} />
                <Skeleton height={60} />
                <Skeleton height={60} />
                <Skeleton height={60} />
                <Skeleton height={60} />
                <Skeleton height={60} />
                <Skeleton height={60} />
              </Box>
            </TableBody>
            :
            <TableBody>
              {
                props.rows.length > 0 ?
                  props.rows
                    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index1) => {
                      const stateObject = {
                        ciStockCode: row.ciStockCode,
                        stockID: row.stockID,
                      };

                      return (
                        <StyledTableRow
                          key={Math.random()}
                          //onClick={() => props.handleView(row.stockID)}
                          style={{ cursor: "pointer" }}
                        >
                          {props.columns.map((col, index) =>
                            // index === 0 ? (
                            (col.id === "sn") ? (
                              <StyledTableCell align="" style={{ zIndex: "1" }}  >

                                {Number(props.fromTable) + index1}

                              </StyledTableCell>
                            )
                              :
                              col.id === "image" ? (
                                index === 0 ? (
                                  <StyledTableCell component="th" scope="row">
                                    <img
                                      src={row.image}
                                      style={{
                                        width: "100px",
                                        height: "100px",
                                        objectFit: "cover",
                                      }}
                                      alt={row.firstname}
                                    />
                                  </StyledTableCell>
                                ) : (
                                  <StyledTableCell align="right">
                                    <img
                                      src={row.image}
                                      style={{
                                        width: "100px",
                                        height: "100px",
                                        objectFit: "cover",
                                      }}
                                      alt={row.firstname}
                                    />
                                  </StyledTableCell>
                                )
                              ) : index === 0 ? (
                                <StyledTableCell component="th" scope="row">
                                  {row[col.id]}
                                </StyledTableCell>
                              ) : col.id === "description" ||
                                col.id === "shortDescription" ||
                                col.id === "text" ? (
                                <StyledTableCell align="" sx={{ width: "500px" }}>
                                  {(
                                    row[col.id].substring(0, 100) +
                                    (row[col.id].length > 100 ? "..." : " ")
                                  ).replaceAll(/<[^>]+>/g, "")}
                                </StyledTableCell>
                              ) : (
                                <StyledTableCell align="">
                                  {(col.id === "averageCost" || col.id === "supplierCost") ?
                                    <>${row[col.id]}</>
                                    :
                                    (col.id === "weight" || col.id === "netWeight") ?
                                      <>{row[col.id]}kg</>
                                      :
                                      col.id === "active" ?
                                        row[col.id] === "Y" ? <Check color="primary" /> : <Clear color="primary" />
                                        :
                                        col.id === "Active" ?
                                          row[col.id] === "Y" ? <Check color="primary" /> : <Clear color="primary" />
                                          :
                                          col.id === "exclusivePending" ?
                                            row[col.id] === 1 ? <Check color="primary" /> : <Clear color="primary" />
                                            :
                                            col.id === "pricePending" ?
                                              row[col.id] === 1 ? <Check color="primary" /> : <Clear color="primary" />
                                              :
                                              col.id === "companyID" ?
                                              row[col.id] === 16 ? "MyDeal.com.au - E-COM (AUS) Pty Ltd" : "-"
                                              :
                                              col.id === "pendingProcess" ?
                                                row[col.id] === 1 ? <Check color="primary" /> : <Clear color="primary" />
                                                :
                                                col.id === "stockGroupID1" ? row.stockGroupID1[0] ? row.stockGroupID1[0].name : "-"
                                                  :
                                                  col.id === "stockGroupID2" ? row.stockGroupID2[0] ? row.stockGroupID2[0].name : "-"
                                                    :
                                                    col.id === "stockExclusiveToClient" ? (row[col.id] === "" ? "-" : (row[col.id] === "N/A" ? "-" : row[col.id]))
                                                      :
                                                      col.id === "lastModified" ?
                                                        moment(row[col.id]).format("ddd, MMM Do YYYY, h:mm:ss a")
                                                        :
                                                        col.id === "ciLastModifiedDateTime" ?
                                                          moment(row[col.id]).format("ddd, MMM Do YYYY, h:mm:ss a")
                                                          : col.id === "pendingProcess" ? (
                                                            row[col.id] === 1 ? (
                                                              <Check fontSize="small" color="primary" />
                                                            ) : (
                                                              <Clear fontSize="small" color="error" />
                                                            )
                                                          ) : col.id === "action" ? (
                                                            <>
                                                              <Link to={`/stock/new_system_stock`} state={stateObject}>
                                                                <Button color="primary" variant="contained">
                                                                  Move To Stock
                                                                </Button>
                                                              </Link>
                                                            </>
                                                          ) :
                                                            (row[col.id] || "-")}
                                </StyledTableCell>
                              )
                          )}
                        </StyledTableRow>
                      )
                    })
                  :
                  <TableRow sx={{ position: "relative", height: "50px" }}>
                    <TableCell sx={{ position: "absolute", right: "50%", borderBottom: "none" }}>
                      No Exceptions Found
                    </TableCell>
                  </TableRow>
              }
            </TableBody>
          }
          {props.footer !== false && (
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[20, 50, 70, 100]}
                  rowsPerPage={props.rowsPerPage}
                  page={props.page}
                  count={props.total && props.total}
                  SelectProps={{
                    native: true,
                  }}
                  labelDisplayedRows={() =>
                    `${props.fromTable !== null ? props.fromTable : "0"} - ${props.toTable !== null ? props.toTable : "0"
                    } to ${props.total}`
                  }
                  onPageChange={props.handleChangePage}
                  onRowsPerPageChange={props.handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
      {/* {props.footer !== false && (
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={props.rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )} */}
    </Paper>
  );
}
