import { Close } from "@mui/icons-material";
import React from "react";

const FilterTag = ({ label, tag, onClick }) => {
  return (
    <p>
      <span>
        {label}: {tag}
      </span>
      <Close fontSize="small" onClick={onClick} />
    </p>
  );
};

export default FilterTag;
