
import React, { useEffect, useState } from "react";
import {
 
  Skeleton

} from "@mui/material";
import { httpclient } from "../../../utils/httpClient";

const title = [

  { id: "cancelled", name: "Cancelled" },
  { id: "despatch_in_progress", name: "Despatch In Progress" },
  { id: "not-found", name: "Not Found" },
  { id: "ready_for_pickup", name: "Ready For Pickup" },
  
  
  
];

const DashboardGrid8 = () => {
    const [loading, setLoading] = useState(false);

    const [productStatus, setProductStatus] = useState({});


    useEffect(() => {
      fetchData();
      const intervalId = setInterval(fetchData, 60 * 1000); // Fetch data every 60 seconds
      return () => clearInterval(intervalId); // Cleanup the interval on component unmount
    }, []);
  
    const fetchData = () => {
      setLoading(true);
      httpclient
        .get(`realtime-status/shipit-order-status`)
        .then(({ data }) => {
          if (data.success) {
            setProductStatus(data.data);
            setLoading(false);
          } else {
            console.log("Error!");
          }
        });
    };
    //console.log("productstatus", productStatus)


    const renderContent = () => {
      if (loading) {
        return (
          <div className="grid-block-content">
            {title.map((item) => (
              <>
                <div className="grid-item title">{item.name}</div>
                <div className="grid-item content">
                  <Skeleton variant="text" />
                </div>
              </>
            ))}
          </div>
        );
      }
      return (
        <div className="grid-block-content">
                {title.map((item) => {
                    let totalOrder= "";
                    for (let state in productStatus) {
                        const shippit = productStatus[state];
                        if (shippit.state === item.id) {
                            totalOrder = shippit.totalOrder;
                            break;
                        }
                    }
                    return (
                        <React.Fragment key={item.id}>
                            <div className="grid-item title">{item.name}</div>
                            <div className="grid-item content">{totalOrder || "0"}</div>
                        </React.Fragment>
                    );
                })}
            </div>
      );
    };

  

  return (
    <div className="grid-block">
      <div className="grid-block-header">
      Shippit Order Status
      </div>
         
      {renderContent()}
      
    </div>
  );
}

export default DashboardGrid8;
