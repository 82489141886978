
import React, { useEffect, useState } from "react";
import { Skeleton } from "@mui/material";
import { httpclient } from "../../../utils/httpClient";
import moment from "moment/moment";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Tooltip from '@mui/material/Tooltip';

const theme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#3f51b5',
          fontSize: 12,
          fontFamily: "sans-serif !important",
          fontWeight: "bold",

        },
        arrow: {
          color: '#3f51b5',
        },

      },
    },
  },
});


// const title = [
//   { id: "stockLastSyncedDate", name: "Stock Last Synced Date" },
//   { id: "SOHLastSyncedDate", name: "SOH Last Synced Date" },
//   { id: "orderLastSyncedDate", name: "Order Last Synced Date" },
// ];



const title = [
  { id: "BookOrderFromShippit", name: "Book Order From Shippit"},
  { id: "CancelOrderToDsco", name: "Cancel Order To DSCO"},
  { id: "ChangeDeliveryAddress", name: "Change Delivery Address"},
  { id: "MailCsvToBranches", name: "Mail CSV To Branches"},
  { id: "PullOrderFromDSCO", name: "Pull Order From DSCO"},
  { id: "pullInvoiceFromExo", name: "Pull Invoice From Exo" },
  { id: "pushInvoiceToDsco", name: "Push Invoice To DSCO"},
  { id: "pushOrderToExo", name: "Push Order to Exo"},
  { id: "pushShippingDetailToDsco", name: "Push Shipping Detail To DSCO"}
];

const DashboardGrid9 = () => {
  const [loading, setLoading] = useState(false);
  const [productStatus, setProductStatus] = useState({});

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(fetchData, 60 * 1000); // Fetch data every 60 seconds
    return () => clearInterval(intervalId); // Cleanup the interval on component unmount
  }, []);

  const fetchData = () => {
    setLoading(true);
    httpclient
      .get(`realtime-status/crone-job`)
      .then(({ data }) => {
        if (data.success) {
          setProductStatus(data.data);
          setLoading(false);
        } else {
          console.log("Error!");
        }
      });
  };

  const renderContent = () => {
    if (loading) {
      return (
        <div className="grid-block-content">
          {title.map((item) => (
            <>
              <div className="grid-item title">{item.name}</div>
              <div className="grid-item content">
                <Skeleton variant="text" />
              </div>
            </>
          ))}
        </div>
      );
    }
    return (


      <div className="grid-block-content">
        {title.map((item) => (
          <>
            <div className="grid-item title">{item.name}</div>
            <div className="grid-item content">{(productStatus[item.id])}</div>
          </>
        ))}
      </div>
    );
  };
  return (
    <div className="grid-block">
      <div className="grid-block-header">Crone Execution time</div>
      {renderContent()}
    </div>
  );
};

export default DashboardGrid9;
