let GlobalURL;

let LocalGlobalURL = [
  {
    //url: "https://ubatemp.retailcare.com.au/php/api/",

    // temp server from Charles bro
    //url: "http://192.168.1.68:8000/api/",
    //url: "http://192.168.1.89:8000/api/",
    url: "http://192.168.1.91:8000/api/",
    // videourl: 'http://staging.franchise.care/uploaded/videofile/',
    // paypalid: 'sb',
    // paypaltype: 'sandbox',
    version: "1.0",
  },
];

let LiveGlobalURL = [
  {
    url: "https://uba.synccare.com.au/php/api/",
    // videourl: 'https://tfg.franchise.care/uploaded/videofile/',
    // paypalid: 'AZ1XjgwM4m-dg7L4OlQMf6jOdzq1H_6IDKLBVf7mh7VqIgJPsYSIKN03iC5LO2XtfSoLUac9NF5R0x8v',
    // paypaltype: 'live',
    version: "1.0",
  },
];

let StagingGlobalURL = [
  {
    url: "https://ubatemp.retailcare.com.au/php/api/",
    // videourl: 'http://staging.franchise.care/uploaded/videofile/',
    // paypalid: 'sb',
    // paypaltype: 'sandbox',
    version: "1.0",
  },
];


export default GlobalURL =
  window.location.hostname === "uba.synccare.com.au"
    ? LiveGlobalURL
    : window.location.hostname === "ubatemp.retailcare.com.au"
    ? StagingGlobalURL
    : LiveGlobalURL;
