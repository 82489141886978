import { Close } from "@mui/icons-material";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";

const StyledHeaderTitle = styled(DialogTitle)(({ theme }) => ({
    background: theme.palette.primary.main,
    color: "#fff",
    position: "relative",
    "& button": {
        position: "absolute",
        right: "15px",
        top: "15px",
        color: "#fff",
    },
}));


const PauseDialog = (props) => {
    const [dialogDetails, setDialogDetails] = useState({
        open: true,
        success: false,
    });


    useEffect(() => {
        props.sendToPause(dialogDetails);
    }, [dialogDetails]);

    const handleClose = () => {
        setDialogDetails({
            ...dialogDetails,
            open: false,
        });
    };

    const handleYes = () => {
        setDialogDetails({
            ...dialogDetails,
            open: false,
            success: true,
        });
    };


    return (
        <div>
            <Dialog
                open={dialogDetails.open}
                onClose={handleClose}
                maxWidth="sm"
                fullWidth
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <StyledHeaderTitle id="alert-dialog-title">
                    Pause Order
                    <IconButton onClick={handleClose}>
                        <Close />
                    </IconButton>
                </StyledHeaderTitle>
                <DialogContent>
                    <Box pt={3}>
                        Are you sure you want to pause the order assign process?
                        {/* <strong> Are you sure you want to assign this branch? </strong> */}
                    </Box>
                    <Box pt={3}>
                        <span><small><i>Note : Automatically resume the order assign process after 30 minutes. You can also resume it manually at any time.</i></small></span>
                        {/* <strong> Are you sure you want to assign this branch? </strong> */}
                    </Box>

                </DialogContent>
                <DialogActions sx={{ margin: "5px 10px", justifyContent: "right" }}>
                    <Button onClick={handleClose} color="error" variant="contained" autoFocus>
                        NO
                    </Button>
                    <Button onClick={handleYes} color="primary" variant="outlined" autoFocus>
                        Yes
                    </Button>
                </DialogActions>

            </Dialog>
        </div>
    );
};

export default PauseDialog;
