import { Close } from "@mui/icons-material";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    styled,
    TextField,
    Grid,
    MenuItem,
    FormControl,
    Select,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";




const StyledHeaderTitle = styled(DialogTitle)(({ theme }) => ({
    background: theme.palette.primary.main,
    color: "#fff",
    position: "relative",
    "& button": {
        position: "absolute",
        right: "15px",
        top: "15px",
        color: "#fff",
    },
}));

const FlexContent = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    fontSize: "17px",
    marginBottom: "10px",
    alignItems: "flex-start",
}));

const FlexInnerTitle = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    minWidth: "210px",
    maxWidth: "320px",
    fontWeight: "600",
}));

const Values = styled("div")(({ theme }) => ({
    marginLeft: "15px",
    fontWeight: "500",

    color: theme.palette.primary.dark,
    "& input": {
        padding: "10px",
        width: "250px",
    },
    "& .MuiSelect-select": {
        padding: "10px",
    },
    "& button": {
        padding: "11px !important",
        minWidth: "40px",
    },
}));

const states = [
    {
        id: 1,
        name: "NSW"

    },
    {
        id: 2,
        name: "VIC"

    },
    {
        id: 3,
        name: "QLD"

    },
    {
        id: 4,
        name: "WA"

    },
    {
        id: 5,
        name: "SA"

    },
    {
        id: 6,
        name: "TAS"

    },
    {
        id: 7,
        name: "NT"

    },
    {
        id: 8,
        name: "ACT"

    },

];

//const API_KEY = 'AIzaSyBWTHo6phFt7OZNhgT3wP901adD4ycob48';

const EditDialog = (props) => {

    // const [address, setAddress] = useState('VIC');
    // const handleChange = (e) => {
    //     setAddress(e.target.value);
    // };

    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     // Make API request to geocode the address here
    //     // You can use the `address` state and `API_KEY` constant

    //     // Example API request
    //     const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${API_KEY}`;

    //     // Perform the API request using fetch or your preferred library
    //     fetch(apiUrl)
    //         .then((response) => response.json())
    //         .then((data) => {
    //             // Handle the response data here
    //             // You can access the geocoded coordinates from `data.results`
    //             console.log("geo-data",data);
    //         })
    //         .catch((error) => {
    //             // Handle any errors that occurred during the API request
    //             console.error(error);
    //         });
    // };

    // const autoCompleteRef = useRef();
    // const inputRef = useRef();
    // const options = {
    //     componentRestrictions: { country: "ng" },
    //     fields: ["address_components", "geometry", "icon", "name"],
    //     types: ["establishment"]
    // };

    // const handlePlaceSelect = () => {
    //     const selectedPlace = autoCompleteRef.current.getPlace();
    //     console.log(selectedPlace);
    //   };

    //   useEffect(() => {
    //     if (window.google) {
    //       autoCompleteRef.current = new window.google.maps.places.Autocomplete(
    //         inputRef.current,
    //         options
    //       );

    //       autoCompleteRef.current.addListener('place_changed', handlePlaceSelect);
    //     }
    //   }, []);

    const [dialogDetails, setDialogDetails] = useState({
        open: true,
        success: false,

        deliveryFirstName: (props.viewDetails && props.viewDetails[0].deliveryDetail.deliveryFirstName) || "",
        deliveryLastName: (props.viewDetails && props.viewDetails[0].deliveryDetail.deliveryLastName) || "",
        deliveryPhone: (props.viewDetails && props.viewDetails[0].deliveryDetail.deliveryPhone) || "",
        deliveryStreet: (props.viewDetails && props.viewDetails[0].deliveryDetail.deliveryStreet) || "",
        deliveryCity: (props.viewDetails && props.viewDetails[0].deliveryDetail.deliveryCity) || "",
        deliveryPostCode: (props.viewDetails && props.viewDetails[0].deliveryDetail.deliveryPostCode) || "",
        deliveryState: (props.viewDetails && props.viewDetails[0].deliveryDetail.deliveryState) || "",

    });

    const handleFirstNameChange = (event) => {
        setDialogDetails({
            ...dialogDetails,
            deliveryFirstName: event.target.value,


        });

    };
    const handleLastNameChange = (event) => {
        setDialogDetails({
            ...dialogDetails,
            deliveryLastName: event.target.value,

        });

    };
    const handlePhoneChange = (event) => {
        setDialogDetails({
            ...dialogDetails,
            deliveryPhone: event.target.value,

        });

    };

    const handleDeliveryAddressChange = (event) => {
        setDialogDetails({
            ...dialogDetails,
            deliveryStreet: event.target.value,


        });

    };
    const handleDeliveryAddressChange1 = (event) => {
        setDialogDetails({
            ...dialogDetails,

            deliveryCity: event.target.value,


        });

    };
    const handleDeliveryAddressChange2 = (event) => {
        setDialogDetails({
            ...dialogDetails,

            deliveryPostCode: event.target.value,


        });

    };
    // const handleDeliveryAddressChange3 = (event) => {
    //     setDialogDetails({
    //         ...dialogDetails,

    //         deliveryState: event.target.value,

    //     });

    // };

    const handleDeliveryAddressChange3 = (event) => {
        const selectedState = event.target.value;

        setDialogDetails((prevDialogDetails) => ({
            ...prevDialogDetails,
            deliveryState: selectedState,
        }));
    };

    useEffect(() => {
        props.sendReset(dialogDetails);
    }, [dialogDetails]);

    const handleClose = () => {
        setDialogDetails({
            ...dialogDetails,
            open: false,
        });
    };

    const handleYes = () => {
        const confirmed = window.confirm("Are you sure you want to edit?");
        if (confirmed) {
            setDialogDetails({
                ...dialogDetails,
                open: false,
                success: true,
            });
        } else {

            // User canceled the edit action
            // Add any necessary code for cancellation
        }
    };
    ////////////////////////////////////////////////////////////////////////////////////////////////////



    return (
        <div>


            <Dialog
                open={dialogDetails.open}
                onClose={handleClose}
                maxWidth="sm"
                fullWidth
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <StyledHeaderTitle id="alert-dialog-title">
                    Edit Delivery Details

                    <IconButton onClick={handleClose}>
                        <Close />
                    </IconButton>
                </StyledHeaderTitle>
                <DialogContent>
                    <Box p={3} sx={{ width: "100%" }}>
                        <Grid container spacing={2}>

                            <Grid item xs={12} md={8}>

                                <FlexContent>

                                    <FlexInnerTitle>
                                        <span>First Name</span> <span> : </span>
                                    </FlexInnerTitle>

                                    <Values>
                                        <TextField
                                            id="deliveryFirstName"
                                            label=""
                                            variant="standard"
                                            value={dialogDetails.deliveryFirstName}
                                            onChange={handleFirstNameChange}
                                        />

                                    </Values>
                                </FlexContent>
                                <FlexContent>

                                    <FlexInnerTitle>
                                        <span>Last Name</span> <span> : </span>
                                    </FlexInnerTitle>

                                    <Values>
                                        <TextField
                                            id="deliveryLastName"
                                            label=""
                                            variant="standard"
                                            value={dialogDetails.deliveryLastName}
                                            onChange={handleLastNameChange}
                                        />

                                    </Values>
                                </FlexContent>
                                <FlexContent>

                                    <FlexInnerTitle>
                                        <span>Phone</span> <span> : </span>
                                    </FlexInnerTitle>

                                    <Values>
                                        <TextField
                                            id="deliveryPhone"
                                            label=""
                                            variant="standard"
                                            value={dialogDetails.deliveryPhone}
                                            onChange={handlePhoneChange}
                                        />

                                    </Values>
                                </FlexContent>

                                <FlexContent>

                                    <FlexInnerTitle>
                                        <span>Address</span> <span> : </span>
                                    </FlexInnerTitle>

                                    <Values>
                                        <TextField
                                            id="deliveryStreet"
                                            label=""
                                            variant="standard"
                                            value={dialogDetails.deliveryStreet}
                                            onChange={handleDeliveryAddressChange}
                                        />

                                    </Values>
                                </FlexContent>

                                <FlexContent>

                                    <FlexInnerTitle>
                                        <span>City</span> <span> : </span>
                                    </FlexInnerTitle>

                                    <Values>
                                        <TextField
                                            id="deliveryCity"
                                            label=""
                                            variant="standard"
                                            value={dialogDetails.deliveryCity}
                                            onChange={handleDeliveryAddressChange1}
                                        />


                                    </Values>
                                </FlexContent>
                                <FlexContent>

                                    <FlexInnerTitle>
                                        <span>State</span> <span> : </span>
                                    </FlexInnerTitle>

                                    <Values>

                                        <FormControl
                                            style={{ width: "260px", marginBottom: "10px" }}
                                        >
                                            <Select
                                                variant="standard"
                                                name="deliveryState"
                                                value={dialogDetails.deliveryState}
                                                onChange={handleDeliveryAddressChange3}
                                            >
                                                <MenuItem value={""}>
                                                    <em>Select state</em>
                                                </MenuItem>
                                                {states.length > 0 &&
                                                    states.map((state) => (
                                                        <MenuItem
                                                            value={state.name}
                                                            key={state.id}
                                                        >
                                                            {state.name}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>


                                    </Values>
                                </FlexContent>

                                <FlexContent>

                                    <FlexInnerTitle>
                                        <span>Post Code</span> <span> : </span>
                                    </FlexInnerTitle>

                                    <Values>
                                        <TextField
                                            id="deliveryPostCode"
                                            label=""
                                            variant="standard"
                                            value={dialogDetails.deliveryPostCode}
                                            onChange={handleDeliveryAddressChange2}
                                        />


                                    </Values>
                                </FlexContent>



                            </Grid>
                        </Grid>
                    </Box>



                </DialogContent>

                {/* <div>
                <label>enter address :</label>
                <input ref={inputRef} />
            </div> */}
                <DialogActions sx={{ margin: "5px 10px", display: "flex", alignItems: "center" }}>
                    <Button size="small" onClick={handleClose} color="primary" variant="outlined" autoFocus>
                        Cancel
                    </Button>
                    {/* <Button  color="primary" variant="outlined" autoFocus>
                        Edit
                    </Button> */}
                    <Button size="small"
                        variant="contained"

                        onClick={handleYes}>
                        Edit
                    </Button>
                </DialogActions>
                {/* <form onSubmit={handleSubmit}>
                    <label>
                        Delivery Address:
                        <input type="text" value={address} onChange={handleChange} />
                    </label>
                    <button type="submit">Geocode Address</button>
                </form>
 */}

            </Dialog>

        </div>
    );
};

export default EditDialog;
