import React, { useEffect, useState } from "react";
import { ArrowForward, Close, FilterList } from "@mui/icons-material";
import TableComponent from "../TableComponent";
import { httpclient } from "../../../../utils/httpClient";
import {
  Box,
  Button,
  Card,
  Collapse,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  styled,
  TextField,
} from "@mui/material";
import ViewPolicyDialog from "../ViewPolicyDialog";
// import ViewBranchDialog from "../ViewBranchDialog";

const columns = [
  { id: "policyID", name: "Policy ID" },
  { id: "policyReference", name: "Policy Reference" },
  { id: "policyCustomerReferenceNote", name: "Note" },
  { id: "startDate", name: "Start Date" },
  { id: "endDate", name: "End Date" },
  { id: "lastModified", name: "Last Modified" },
  { id: "policyActive", name: "Active" },
  // { id: "actions", name: "Actions" },
];

const FilteredBox = styled(Box)(({ theme }) => ({
  background: "#f9f9f9",
  padding: "5px 10px",
  borderRadius: "5px",
  "& p": {
    margin: "0",
    marginRight: "10px",
    display: "inline-block",
    background: "#dedede",
    borderRadius: "10px",
    padding: "2px 5px",
  },
  "& svg": {
    fontSize: "15px",
    cursor: "pointer",
    position: "relative",
    top: "3px",
    background: theme.palette.primary.dark,
    color: "#fff",
    borderRadius: "50%",
    padding: "2px",
    marginLeft: "2px",
  },
}));

const Header = styled("div")(({ theme }) => ({
  "& h1": {
    color: theme.palette.primary.dark,
    margin: "0",
  },
}));

const configRowPerPage = JSON.parse(localStorage.getItem("configRowPerPage"));

const CurrentPricePolicy = () => {
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [viewDetails, setViewDetails] = useState({});

  const [rows, setRows] = useState([]);

  const [loading, setLoading] = useState(false);
  const [singleLoading, setSingleLoading] = useState(false);
  const [direction, setDirection] = useState(false);
  const [currentColumn, setCurrentColumn] = useState("");
  const [page, setPage] = useState(1);
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(
    configRowPerPage && configRowPerPage
      ? configRowPerPage && configRowPerPage
      : 20
  );

  const [rowsPerPage, setRowsPerPage] = useState(
    configRowPerPage && configRowPerPage
      ? configRowPerPage && configRowPerPage
      : 20
  );
  const [total, setTotal] = useState("");
  const [filterOpen, setFilterOpen] = useState(false);

  const [filterData, setFilterData] = useState({

    policyID: "",
    policyReference: "",
    startDate: "",
    endDate: "",
    active: "",
    remove: false,
  });

  const [submittedData, setSubmittedData] = useState({
    policyID: "",
    policyReference: "",
    startDate: "",
    endDate: "",
    active: "",
    submit: false,
  });

  // useEffect(() => {
  //   getCurrentPolicy();
  // }, []);

  useEffect(() => {
    if (
      filterData.policyID === "" &&
      filterData.policyReference === "" &&
      filterData.startDate === "" &&
      filterData.endDate === "" &&
      filterData.active === ""
    ) {
      setSubmittedData({
        ...submittedData,
        submit: false,
      });
    }
    if (filterData.policyID === " ") filterData.policyID = "";
    if (filterData.policyReference === " ") filterData.policyReference = "";
    if (filterData.startDate === " ") filterData.startDate = "";
    if (filterData.endDate === " ") filterData.endDate = "";
    if (filterData.active === " ") filterData.active = "";

    filterData.remove === true && handleFilter();
  }, [filterData]);

  useEffect(() => {
    let currentpolicy = JSON.parse(
      localStorage.getItem("current_pricepolicy_filter")
    );
    currentpolicy !== null && setFilterData(currentpolicy);

    currentpolicy == null
      ? getCurrentPolicy()
      : currentpolicy.branchCode == "" &&
        currentpolicy.branchName == "" &&
        currentpolicy.inboundBranch == "" &&
        currentpolicy.pendingProcess == "" &&
        currentpolicy.active == "" &&
        currentpolicy.removed == false
        ? getCurrentPolicy()
        : console.log("current price policy!");
  }, []);

  const getCurrentPolicy = () => {
    setLoading(true);
    httpclient.get(`/current/price-policy?pagination=${rowsPerPage}`).then(({ data }) => {
      if (data.success) {
        setRows(data.data);
        setTotal(data.meta.total);
        setRowsPerPage(parseInt(data.meta.per_page));
        setPage(data.meta.current_page);
        setFrom(data.meta.from);
        setTo(data.meta.to);
        setLoading(false);
      }
    }).catch(err => {
      if(err.response.status === 401){
        localStorage.clear();
        window.location = "/login";
      }
    })
  };

  const handleView = (row) => {
    setSingleLoading(true);
    setOpenViewDialog(true);
    httpclient.get(`current/price-policy/${row.policyID}`).then(({ data }) => {
      if (data.success) {
        setViewDetails(data.data);
        setSingleLoading(false);
      }
    }).catch(err => {
      if(err.response.status === 401){
        localStorage.clear();
        window.location = "/login";
      }
    })
  };

  const sendDetails = (callback) => {
    if (callback.open === false) {
      setOpenViewDialog(false);
      setViewDetails({});
    }
  };

  const handleFilter = () => {
    setSubmittedData({
      ...submittedData,
      policyID: filterData.policyID,
      policyReference: filterData.policyReference,
      startDate: filterData.startDate,
      endDate: filterData.endDate,
      active: filterData.active,
      submit: true,
    });
    filterData.remove = true;
    localStorage.setItem(
      "current_pricepolicy_filter",
      JSON.stringify(filterData)
    );
    setLoading(true);
    if (
      filterData.policyID ||
      filterData.policyReference ||
      filterData.startDate ||
      filterData.endDate ||
      filterData.active
    ) {
      httpclient
        .get(
          `current/price-policy?policyID=${filterData.policyID
          }&policyReference=${filterData.policyReference
          }&startDate=${filterData.startDate}&endDate=${filterData.endDate
          }&policyActive=${filterData.active
          }&pagination=${rowsPerPage}&page=${1}`
        )
        .then(({ data }) => {
          if (data.success) {
            setRows(data.data);
            setTotal(data.meta.total);
            setRowsPerPage(data.meta.per_page);
            setPage(data.meta.current_page);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        });
    } else {
      getCurrentPolicy();
    }
  };

  const hadleFilterOpen = () => {
    setFilterOpen((prev) => !prev);
  };

  const handleChangeFilter = (e) => {
    const { name, value } = e.target;
    setFilterData({
      ...filterData,
      [name]: value,
      remove: false,
    });
  };

  const handleRemove = (data) => {
    setFilterData({
      ...filterData,
      [data]: "",
      remove: true,
    });

    setSubmittedData({
      ...submittedData,
      [data]: "",
    });
  };

  const handleSort = (column) => {
    setDirection((prevDirection) => !prevDirection);
    setCurrentColumn(column);
    submittedData.submit
      ? httpclient
        .get(
          `current/price-policy?policyID=${filterData.policyID
          }&policyReference=${filterData.policyReference}&startDate=${filterData.startDate}&endDate=${filterData.endDate}&policyActive=${filterData.active}&sort_by=${column}&direction=${!direction ? "asc" : "desc"
          }&pagination=${rowsPerPage}&page=${page}`
        )
        .then(({ data }) => {
          if (data.success === true) {
            setRows(data.data);
            setTotal(data.meta.total);
            setRowsPerPage(parseInt(data.meta.per_page));
            setPage(data.meta.current_page);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        })
      :
      httpclient
        .get(
          `current/price-policy?sort_by=${column}&direction=${!direction ? "asc" : "desc"
          }&pagination=${rowsPerPage}`
        )
        .then(({ data }) => {
          if (data.success === true) {
            setRows(data.data);
          }
        });
  };

  const handleChangePage = (e, page) => {
    setLoading(true);
    submittedData.submit
      ? httpclient
        .get(
          `current/price-policy?policyID=${filterData.policyID
          }&policyReference=${filterData.policyReference}&startDate=${filterData.startDate}&endDate=${filterData.endDate}&policyActive=${filterData.active}&sort_by=${currentColumn}&direction=${direction ? "asc" : "desc"
          }&pagination=${rowsPerPage}&page=${page}`
        )
        .then(({ data }) => {
          if (data.success === true) {
            setRows(data.data);
            setTotal(data.meta.total);
            setRowsPerPage(parseInt(data.meta.per_page));
            setPage(data.meta.current_page);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        })
      : httpclient
        .get(
          `current/price-policy?direction=${direction ? "asc" : "desc"
          }&pagination=${rowsPerPage}&page=${page}`
        )
        .then(({ data }) => {
          if (data.success === true) {
            setRows(data.data);
            setTotal(data.meta.total);
            setRowsPerPage(parseInt(data.meta.per_page));
            setPage(data.meta.current_page);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    // setLoading(true);

    localStorage.setItem("configRowPerPage", event.target.value);

    submittedData.submit
      ? httpclient
        .get(
          `current/price-policy?policyID=${filterData.policyID
          }&policyReference=${filterData.policyReference
          }&startDate=${filterData.startDate}&endDate=${filterData.endDate
          }&policyActive=${filterData.active}&sort_by=${currentColumn}&direction=${direction ? "asc" : "desc"
          }&pagination=${+event.target
            .value}&page=${page}`
        )
        .then(({ data }) => {
          setLoading(true);
          if (data.success === true) {
            setRows(data.data);
            setTotal(data.meta.total);
            setRowsPerPage(parseInt(data.meta.per_page));
            setPage(data.meta.current_page);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        })
      : httpclient
        .get(
          `current/price-policy?direction=${direction ? "asc" : "desc"
          }&pagination=${+event.target.value}&page=${1}`
        )
        .then(({ data }) => {
          setLoading(true);
          if (data.success === true) {
            setRows(data.data);
            setTotal(data.meta.total);
            setRowsPerPage(parseInt(data.meta.per_page));
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setPage(data.meta.current_page);
            setLoading(false);
          }
        });
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item md={8} xs={12}>
          <Header>
            <h1>List Current Price Policy</h1>
          </Header>
        </Grid>
        <Grid
          item
          md={4}
          xs={12}
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Button color="primary" variant="contained" onClick={hadleFilterOpen}>
            Filter <FilterList style={{ marginLeft: "5px" }} fontSize="small" />
          </Button>
        </Grid>

        {/* Filter */}
        <Grid item xs={12}>
          <Collapse in={filterOpen}>
            <Card>
              <Box p={4}>

                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <InputLabel>Policy ID</InputLabel>
                    <TextField
                      variant="outlined"
                      name="policyID"
                      value={filterData.policyID}
                      onChange={handleChangeFilter}
                      onKeyDown={e => {if(e.key === "Enter") handleFilter()}}
                      fullWidth
                    />
                  </Grid>
                  
                    <Grid item xs={12} md={4}>
                      <InputLabel>Policy Reference</InputLabel>
                      <TextField
                        variant="outlined"
                        name="policyReference"
                        value={filterData.policyReference}
                        onChange={handleChangeFilter}
                        onKeyDown={e => {if(e.key === "Enter") handleFilter()}}
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <InputLabel>Active</InputLabel>
                      <FormControl fullWidth>
                        <Select
                          value={filterData.active}
                          // label="Exclusive Pending"
                          name="active"
                          onChange={handleChangeFilter}
                        >
                          <MenuItem value={""}>Select</MenuItem>
                          <MenuItem value={"Y"}>Yes</MenuItem>
                          <MenuItem value={"N"}>No</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <InputLabel>Start Date</InputLabel>
                      <TextField
                        variant="outlined"
                        name="startDate"
                        type="date"
                        value={filterData.startDate}
                        onChange={(e) => handleChangeFilter(e)}
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <InputLabel>End Date</InputLabel>
                      <TextField
                        variant="outlined"
                        name="endDate"
                        type="date"
                        value={filterData.endDate}
                        onChange={(e) => handleChangeFilter(e)}
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Box textAlign={"right"}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleFilter}
                        >
                          Filter{" "}
                          <ArrowForward
                            fontSize="small"
                            style={{ marginLeft: "5px" }}
                          />
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                
              </Box>
            </Card>
          </Collapse>
        </Grid>

        {submittedData.policyID ||
        submittedData.policyReference ||
          submittedData.startDate ||
          submittedData.endDate ||
          submittedData.active ? (
          <Grid item xs={12}>
            <FilteredBox>
              <span>Filtered: </span>

              {submittedData.policyID && (
                <p>
                  <span>Policy ID: {submittedData.policyID}</span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("policyID")}
                  />
                </p>
              )}
              {submittedData.policyReference && (
                <p>
                  <span>Policy Reference: {submittedData.policyReference}</span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("policyReference")}
                  />
                </p>
              )}
              {submittedData.startDate && (
                <p>
                  <span>Start Date: {submittedData.startDate}</span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("startDate")}
                  />
                </p>
              )}

              {submittedData.endDate && (
                <p>
                  <span>
                    End Date:{" "}
                    {submittedData.endDate}
                  </span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("endDate")}
                  />
                </p>
              )}

              {submittedData.active && (
                <p>
                  <span>
                    Active: {submittedData.active === "Y" ? "Yes" : "No"}
                  </span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("active")}
                  />
                </p>
              )}
            </FilteredBox>
          </Grid>
        ) : (
          <Box></Box>
        )}
        {/* Filter */}

        <Grid item xs={12}>
          <TableComponent
            columns={columns}
            rows={rows}
            sort={true}
            handleView={handleView}
            handleSort={handleSort}
            loading={loading}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleChangePage={handleChangePage}
            direction={direction}
            currentColumn={currentColumn}
            page={page}
            total={total && total}
            fromTable={from}
            toTable={to}
            rowsPerPage={rowsPerPage}
          />
        </Grid>
      </Grid>

      {openViewDialog && (
        <ViewPolicyDialog singleLoading={singleLoading} viewDetails={viewDetails} sendDetails={sendDetails} />
      )}
    </div>
  );
};

export default CurrentPricePolicy;
