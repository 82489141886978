// import { Check, Clear, Close, KeyboardArrowLeft } from "@mui/icons-material";
// import {
//     AppBar,
//     Box,
//     Button,
//     Dialog,
//     DialogActions,
//     DialogContent,
//     DialogTitle,
//     Grid,
//     IconButton,
//     Skeleton,
//     styled,
//     useTheme,
// } from "@mui/material";
// import moment from "moment";
// import React, { useEffect, useState } from "react";
// import Tabs from "@mui/material/Tabs";
// import Tab from "@mui/material/Tab";
// import Typography from "@mui/material/Typography";
// import PropTypes from "prop-types";
// import BasicTableView1 from "../../../../Components/BasicTableView1";



// const StyledHeaderTitle = styled(DialogTitle)(({ theme }) => ({
//     background: theme.palette.primary.main,
//     color: "#fff",
//     position: "relative",
//     "& button": {
//         position: "absolute",
//         right: "15px",
//         top: "15px",
//         color: "#fff",
//     },
// }));

// const FlexContent = styled("div")(({ theme }) => ({
//     display: "flex",
//     flexDirection: "row",
//     fontSize: "17px",
//     marginBottom: "10px",
//     alignItems: "flex-start",
// }));

// const FlexInnerTitle = styled("div")(({ theme }) => ({
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "space-between",
//     minWidth: "280px",
//     maxWidth: "320px",
//     fontWeight: "600",
// }));

// const Values = styled("div")(({ theme }) => ({
//     marginLeft: "15px",
//     fontWeight: "500",
//     color: theme.palette.primary.dark,
//     "& input": {
//         padding: "10px",
//         width: "150px",
//     },
//     "& .MuiSelect-select": {
//         padding: "10px",
//     },
//     "& button": {
//         padding: "11px !important",
//         minWidth: "40px",
//     },
// }));


// const AppBarTabs = styled(AppBar)(({ theme }) => ({
//     background: "#fff",
//     color: theme.palette.primary.dark,
// }));

// function TabPanel(props) {
//     const { children, value, index, ...other } = props;

//     return (
//         <div
//             role="tabpanel"
//             hidden={value !== index}
//             id={`full-width-tabpanel-${index}`}
//             aria-labelledby={`full-width-tab-${index}`}
//             {...other}
//         >
//             {value === index && (
//                 <Box sx={{ p: 3 }}>
//                     <Typography>{children}</Typography>
//                 </Box>
//             )}
//         </div>
//     );
// }

// TabPanel.propTypes = {
//     children: PropTypes.node,
//     index: PropTypes.number.isRequired,
//     value: PropTypes.number.isRequired,
// };

// function a11yProps(index) {
//     return {
//         id: `full-width-tab-${index}`,
//         "aria-controls": `full-width-tabpanel-${index}`,
//     };
// }

// const ViewDetail = (props) => {

//     const theme = useTheme();
//     const [value, setValue] = useState(0);

//     const [dialogDetails, setDialogDetails] = useState({
//         open: true,
//     });
//     const [productDetails, setProductDetails] = useState("");


//     useEffect(() => {
//         props.sendDetails(dialogDetails);

//     }, [props, dialogDetails]);


//     const handleChange = (event, newValue) => {
//         setValue(newValue);
//     };

//     const handleClose1 = () => {
//         setDialogDetails({
//             ...dialogDetails,
//             open: false,
//         });
//     };

//     const handleView = (row) => {
//         setProductDetails(row);
//     };

//     const handleBack = () => {
//         setProductDetails("");
//     };



//     return (
//         <div>
//             <Dialog
//                 open={dialogDetails.open}
//                 onClose={handleClose1}
//                 maxWidth="xl"
//                 fullWidth
//                 aria-labelledby="alert-dialog-title"
//                 aria-describedby="alert-dialog-description"
//             >
//                 <StyledHeaderTitle id="alert-dialog-title">
//                     <div>{`View Marketplace Workflow Details (${props.viewDetails[0]?.customerID || "-"
//                         })`}</div>
//                     <IconButton onClick={handleClose1}>
//                         <Close />
//                     </IconButton>
//                 </StyledHeaderTitle>
//                 {props.singleLoading ? (
//                     <DialogContent>
//                         <Grid container spacing={2}>
//                             <Grid item xs={12}>
//                                 <Box p={3} sx={{ width: "100%" }}>
//                                     <Skeleton height={40} />
//                                     <Skeleton height={40} />
//                                     <Skeleton height={40} />
//                                     <Skeleton height={40} />
//                                     <Skeleton height={40} />
//                                     <Skeleton height={40} />
//                                     <Skeleton height={40} />
//                                     <Skeleton height={40} />
//                                     <Skeleton height={40} />
//                                 </Box>
//                             </Grid>


//                         </Grid>
//                     </DialogContent>
//                 ) : (
//                     <DialogContent style={{ margin: "0", padding: "0" }}>



//                     </DialogContent>
//                 )}
//                 <DialogActions>
//                     <Button onClick={handleClose1} variant="outlined" color="primary">
//                         Close
//                     </Button>
//                 </DialogActions>
//             </Dialog>
//         </div>
//     );
// };

// export default ViewDetail;

//----------------------------------------------------------------------------------------------------------------

import React, { useEffect, useState, useRef } from "react";
import {
    ArrowForward,
    Close,
    Download,
    FilterList,
} from "@mui/icons-material";

import { httpclient } from "../../../../utils/httpClient";
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    Card,
    Collapse,
    Grid,
    InputLabel,
    styled,
    TextField,
    Snackbar,
    Select,
    MenuItem,
    FormControl,
    Autocomplete,
    useTheme,
    Skeleton,
    IconButton
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { useLocation, useNavigate } from "react-router";
import { CSVLink } from "react-csv";
import { dateFormatter } from "../../../../utils/DataGrid/dateFormatter";
import TWTableComponent from "../../../Online/TempleAndWebster/Temple&WebsterTable/TWTableComponent";
import ViewMoreDetails from "./ViewMoreDetails";
// import ViewDetail from "./ViewDetail";


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const StyledHeaderTitle = styled(DialogTitle)(({ theme }) => ({
    background: theme.palette.primary.main,
    color: "#fff",
    position: "relative",
    "& button": {
        position: "absolute",
        right: "15px",
        top: "15px",
        color: "#fff",
    },
}));

const columns = [
    { id: "code", name: "" },
    { id: "name", name: "Name" },
    { id: "statusInfo", name: "Status" },
    
    { id: "lastUpdated", name: "Last Updated" },
    { id: "lastError", name: "Last Error" },
    { id: "lastRun", name: "Last Run" },
    { id: "schedule", name: "Schedule" },
    { id: "action", name: "Action" },
   
];

const FilteredBox = styled(Box)(({ theme }) => ({
    background: "#f9f9f9",
    padding: "5px 10px",
    borderRadius: "5px",
    "& p": {
        margin: "0",
        marginRight: "10px",
        display: "inline-block",
        background: "#dedede",
        borderRadius: "10px",
        padding: "2px 5px",
    },
    "& svg": {
        fontSize: "15px",
        cursor: "pointer",
        position: "relative",
        top: "3px",
        background: theme.palette.primary.dark,
        color: "#fff",
        borderRadius: "50%",
        padding: "2px",
        marginLeft: "2px",
    },
}));

const Header = styled("div")(({ theme }) => ({
    "& h1": {
        color: theme.palette.primary.dark,
        margin: "0",
    },
}));

const configRowPerPage = JSON.parse(localStorage.getItem("configRowPerPage"));

const ViewDetail = (props) => {
    const theme = useTheme();
    const [value, setValue] = useState(0);

    const [dialogDetails, setDialogDetails] = useState({
        open: true,
    });
    const [productDetails, setProductDetails] = useState("");


    useEffect(() => {
        props.sendDetails(dialogDetails);

    }, [props, dialogDetails]);


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleClose1 = () => {
        setDialogDetails({
            ...dialogDetails,
            open: false,
        });
    };

    // const handleView = (row) => {
    //     setProductDetails(row);
    // };

    const handleBack = () => {
        setProductDetails("");
    };


    const navigate = useNavigate();
    const buttonRef = useRef(null);
    const [openViewDialog, setOpenViewDialog] = useState(false);
    const [viewDetails, setViewDetails] = useState({});

    const [rows, setRows] = useState([]);
    const [rowChecked, setRowChecked] = useState([]);

    const [loading, setLoading] = useState(false);
    const [loadingExport, setLoadingExport] = useState(false);
    const [singleLoading, setSingleLoading] = useState(false);
    const [direction, setDirection] = useState(false);
    const [currentColumn, setCurrentColumn] = useState("");
    const [page, setPage] = useState(1);
    const [from, setFrom] = useState(1);
    const [to, setTo] = useState(
        configRowPerPage && configRowPerPage
            ? configRowPerPage && configRowPerPage
            : 20
    );

    const [rowsPerPage, setRowsPerPage] = useState(
        configRowPerPage && configRowPerPage
            ? configRowPerPage && configRowPerPage
            : 20
    );
    const [total, setTotal] = useState("");
    const [filterOpen, setFilterOpen] = useState(false);

    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [messageState, setMessageState] = useState("");
    const [exportFile, setExportFile] = useState(false);
    const [exportRows, setExportRows] = useState("");
    const [shipment, setShipment] = useState([]);

    const [filterData, setFilterData] = useState({
        orderType: "",
        orderNumber: "",
        netsuiteOrderNumber: "",
        order_status: "",
        order_status_name: "",
        from: null,
        to: null,
        remove: false,
    });

    const [submittedData, setSubmittedData] = useState({
        orderType: "",
        orderNumber: "",
        netsuiteOrderNumber: "",
        order_status: "",
        order_status_name: "",
        from: null,
        to: null,
        submit: false,
    });

    // useEffect(() => {
    //   getOrdersException();
    // }, []);


    useEffect(() => {
        if (
            filterData.orderType === "" &&
            filterData.orderNumber === "" &&
            filterData.netsuiteOrderNumber === "" &&
            filterData.order_status === "" &&
            filterData.order_status_name === "" &&
            filterData.from === "" &&
            filterData.to === ""
        ) {
            setSubmittedData({
                ...submittedData,
                submit: false,
            });
        }

        if (filterData.orderType === " ") filterData.orderType = "";
        if (filterData.orderNumber === " ") filterData.orderNumber = "";
        if (filterData.netsuiteOrderNumber === " ") filterData.netsuiteOrderNumber = "";
        if (filterData.order_status === " ") filterData.order_status = "";
        if (filterData.order_status_name === " ") filterData.order_status_name = "";
        if (filterData.from === " ") filterData.from = "";
        if (filterData.to === " ") filterData.to = "";

        filterData.remove === true && handleFilter();
    }, [filterData]);

    useEffect(() => {
        let currentpolicy = JSON.parse(
            localStorage.getItem("workplace_marketplace_filter")
        );
        currentpolicy !== null && setFilterData(currentpolicy);

        currentpolicy == null
            ? getOrdersException()
            : currentpolicy.orderType == "" &&
                currentpolicy.orderNumber == "" &&
                currentpolicy.netsuiteOrderNumber == "" &&
                currentpolicy.order_status == "" &&
                currentpolicy.order_status_name == "" &&
                currentpolicy.from == "" &&
                currentpolicy.to == "" &&
                currentpolicy.removed == false
                ? getOrdersException()
                : console.log("orders price exception");
    }, []);




    const getOrdersException = () => {
        setLoading(true);
        httpclient
            .get(`workflow/${props.ID}/workFlowDetails?pagination=${rowsPerPage}`)
            .then(({ data }) => {
                if (data) {
                    setRows(data.data);
                    setShipment(data.customOrderStatusArray);
                    setTotal(data.meta.total);
                    setRowsPerPage(parseInt(data.meta.per_page));
                    setPage(data.meta.current_page);
                    setFrom(data.meta.from);
                    setTo(data.meta.to);
                    setLoading(false);
                }
            }).catch(err => {
                if (err.response.status === 401) {
                    localStorage.clear();
                    window.location = "/login";
                }
            })
    };

    const handleView = (row) => {
        setSingleLoading(true);
        setOpenViewDialog(true);
        httpclient
            .get(`workflow/${props.ID}/${row.code}/functionDetails`)
            .then(({ data }) => {
                if (data) {
                    setViewDetails(data.data);
                    setSingleLoading(false);
                }
            })
    };

    const sendDetails = (callback) => {
        if (callback.open === false) {
            setOpenViewDialog(false);
            setViewDetails({});
        }
    };

    const handleRefetch = () => {
        getOrdersException();
    }



    const handleChangeException = (value) => {
        setFilterData({
            ...filterData,
            order_status: value !== null ? value.id : "",
            order_status_name: value !== null ? value.name : "",
            remove: false,
        });
    };

    const handleFilter = () => {
        setSubmittedData({
            ...submittedData,

            orderType: filterData.orderType,
            orderNumber: filterData.orderNumber,
            netsuiteOrderNumber: filterData.netsuiteOrderNumber,
            order_status: filterData.order_status,
            order_status_name: filterData.order_status_name,
            from: filterData.from,
            to: filterData.to,
            submit: true,
        });
        filterData.remove = true;
        localStorage.setItem(
            "workplace_marketplace_filter",
            JSON.stringify(filterData)
        );
        setLoading(true);
        if (
            filterData.orderType ||
            filterData.orderNumber ||
            filterData.netsuiteOrderNumber ||
            filterData.order_status ||
            filterData.order_status_name ||
            filterData.fileName ||
            filterData.from ||
            filterData.to
        ) {
            setExportRows("");
            httpclient
                .get(
                    `workflow/${props.ID}/workFlowDetails?orderType=${filterData.orderType
                    }&customOrderStatus=${filterData.order_status
                    }&orderNumber=${filterData.orderNumber
                    }&netsuiteOrderNumber=${filterData.netsuiteOrderNumber
                    }&startDate=${filterData.from || ""}&endDate=${filterData.to || ""
                    }&pagination=${rowsPerPage}&page=${1}`
                )
                .then(({ data }) => {
                    if (data.success) {
                        setRows(data.data);
                        setShipment(data.customOrderStatusArray);
                        setTotal(data.meta.total);
                        setRowsPerPage(data.meta.per_page);
                        setPage(data.meta.current_page);
                        setFrom(data.meta.from);
                        setTo(data.meta.to);
                        setExportFile(true);
                        setLoading(false);
                    }
                });
        } else {
            getOrdersException();
        }
    };

    const handleExport = () => {
        setLoadingExport(true);
        httpclient
            .get(
                `temple-webster/export-netsuite-container-list?orderType=${filterData.orderType
                }&customOrderStatus=${filterData.order_status
                }&orderNumber=${filterData.orderNumber
                }&netsuiteOrderNumber=${filterData.netsuiteOrderNumber
                }&startDate=${filterData.from || ""}&endDate=${filterData.to || ""
                }&pagination=${total}`
            )
            .then(({ data }) => {
                if (data.success) {
                    setExportRows(data.download_link);
                    setLoadingExport(false);
                }
            });
    };


    const hadleFilterOpen = () => {
        setFilterOpen((prev) => !prev);
    };

    const handleChangeFilter = (e) => {
        const { name, value } = e.target;
        if (name === "order_status_name") {
            setFilterData({
                ...filterData,
                order_status_name: value,
                remove: false,
            });
        } else {
            setFilterData({
                ...filterData,
                [name]: value,
                remove: false,
            });
        }
    };

    const handleRemove = (data) => {
        setExportFile(false);
        setExportRows("");
        if (data === "order_status") {
            filterData.order_status_name = "";
            submittedData.order_status_name = "";
        }
        if (data === "from") {
            setFilterData({
                ...filterData,
                from: "",
                to: "",
                remove: true,
            });

            setSubmittedData({
                ...submittedData,
                from: "",
                to: "",
            });
        } else {
            setFilterData({
                ...filterData,
                [data]: "",
                remove: true,
            });

            setSubmittedData({
                ...submittedData,
                [data]: "",
            });
        }
    };

    const handleRowCheck = (e, row) => {
        const { checked } = e.target;
        if (checked === true) {
            setRowChecked([...rowChecked, row.orderID]);
        } else {
            let newData = rowChecked.filter((check) => {
                return check !== row.orderID;
            });
            setRowChecked(newData);
        }
    };

    const handleSort = (column) => {
        setDirection((prevDirection) => !prevDirection);
        setCurrentColumn(column);
        submittedData.submit
            ? httpclient
                .get(
                    `workflow/${props.ID}/workFlowDetails?orderType=${filterData.orderType
                    }&customOrderStatus=${filterData.order_status
                    }&orderNumber=${filterData.orderNumber
                    }&netsuiteOrderNumber=${filterData.netsuiteOrderNumber
                    }&startDate=${filterData.from || ""}&endDate=${filterData.to || ""
                    }&sort_by=${column}&direction=${!direction ? "asc" : "desc"
                    }&pagination=${rowsPerPage}&page=${page}`
                )
                .then(({ data }) => {
                    if (data.success === true) {
                        setRows(data.data);
                        setTotal(data.meta.total);
                        setRowsPerPage(parseInt(data.meta.per_page));
                        setPage(data.meta.current_page);
                        setFrom(data.meta.from);
                        setTo(data.meta.to);
                        setLoading(false);
                    }
                })
            : httpclient
                .get(
                    `workflow/${props.ID}/workFlowDetails?sort_by=${column}&direction=${!direction ? "asc" : "desc"
                    }&pagination=${rowsPerPage}`
                )
                .then(({ data }) => {
                    if (data.success === true) {
                        setRows(data.data);
                    }
                });
    };

    const handleChangePage = (e, page) => {
        setLoading(true);
        submittedData.submit
            ? httpclient
                .get(
                    `workflow/${props.ID}/workFlowDetails?orderType=${filterData.orderType
                    }&customOrderStatus=${filterData.order_status
                    }&orderNumber=${filterData.orderNumber
                    }&netsuiteOrderNumber=${filterData.netsuiteOrderNumber
                    }&startDate=${filterData.from || ""}&endDate=${filterData.to || ""
                    }&sort_by=${currentColumn}&direction=${direction ? "asc" : "desc"
                    }&pagination=${rowsPerPage}&page=${page}`
                )
                .then(({ data }) => {
                    if (data.success === true) {
                        setRows(data.data);
                        setTotal(data.meta.total);
                        setRowsPerPage(parseInt(data.meta.per_page));
                        setPage(data.meta.current_page);
                        setFrom(data.meta.from);
                        setTo(data.meta.to);
                        setLoading(false);
                    }
                })
            : httpclient
                .get(
                    `workflow/${props.ID}/workFlowDetails?direction=${direction ? "asc" : "desc"
                    }&pagination=${rowsPerPage}&page=${page}`
                )
                .then(({ data }) => {
                    if (data.success === true) {
                        setRows(data.data);
                        setTotal(data.meta.total);
                        setRowsPerPage(parseInt(data.meta.per_page));
                        setPage(data.meta.current_page);
                        setFrom(data.meta.from);
                        setTo(data.meta.to);
                        setLoading(false);
                    }
                });
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        // setLoading(true);

        localStorage.setItem("configRowPerPage", event.target.value);

        submittedData.submit
            ? httpclient
                .get(
                    `workflow/${props.ID}/workFlowDetails?orderType=${filterData.orderType
                    }&customOrderStatus=${filterData.order_status
                    }&orderNumber=${filterData.orderNumber
                    }&netsuiteOrderNumber=${filterData.netsuiteOrderNumber
                    }&startDate=${filterData.from || ""}&endDate=${filterData.to || ""
                    }&sort_by=${currentColumn}&direction=${direction ? "asc" : "desc"
                    }&pagination=${+event.target.value}&page=${page}`
                )
                .then(({ data }) => {
                    setLoading(true);
                    if (data.success === true) {
                        setRows(data.data);
                        setTotal(data.meta.total);
                        setRowsPerPage(parseInt(data.meta.per_page));
                        setPage(data.meta.current_page);
                        setFrom(data.meta.from);
                        setTo(data.meta.to);
                        setLoading(false);
                    }
                })
            : httpclient
                .get(
                    `workflow/${props.ID}/workFlowDetails?direction=${direction ? "asc" : "desc"
                    }&pagination=${+event.target.value}&page=${1}`
                )
                .then(({ data }) => {
                    setLoading(true);
                    if (data.success === true) {
                        setRows(data.data);
                        setTotal(data.meta.total);
                        setRowsPerPage(parseInt(data.meta.per_page));
                        setFrom(data.meta.from);
                        setTo(data.meta.to);
                        setPage(data.meta.current_page);
                        setLoading(false);
                    }
                });
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };

    useEffect(() => {
        if (!loadingExport && exportRows !== "" && buttonRef.current) {
            console.log("am i here");
            buttonRef.current.click();
        }
    }, [loadingExport, exportRows]);


    //console.log('window',window.location)

    return (
        <div>

            <Dialog
                open={dialogDetails.open}
                onClose={handleClose1}
                maxWidth="l"
                fullWidth
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <StyledHeaderTitle id="alert-dialog-title">
                    <div>{`View Marketplace Workflow Details (${props.customerName || "-"
                        })`}</div>
                    <IconButton onClick={handleClose1}>
                        <Close />
                    </IconButton>
                </StyledHeaderTitle>
                {props.singleLoading ? (
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Box p={3} sx={{ width: "100%" }}>
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                </Box>
                            </Grid>


                        </Grid>
                    </DialogContent>
                ) : (
                    <DialogContent style={{ margin: "0", padding: "0" }}>
                        <Grid container spacing={2}>
                        <Box p={4} sx={{ width: "100%" }}>
                            <Grid item md={8} xs={12}>
                                {/* <Header>
                                    <h1>Marketplace Orders</h1>
                                </Header> */}
                            </Grid>
                            <Grid
                                item
                                md={4}
                                xs={12}
                                display="flex"
                                alignItems="center"
                                justifyContent="flex-end"
                            >
                                {/* {exportRows !== "" ? (

                        <Button
                            download
                            href={exportRows}
                            color="primary"
                            variant="contained"
                            style={{ marginRight: "10px" }}
                            ref={buttonRef}
                        >
                            Export <Download style={{ marginLeft: "5px" }} fontSize="small" />
                        </Button>


                    ) : (
                        <Button

                            color="primary"
                            variant="contained"
                            style={{ marginRight: "10px" }}
                            disabled={!exportFile}
                            onClick={handleExport}
                        >
                            {loadingExport ? "Generating Data to Export" : "Export"}{loadingExport ? `...` : <Download style={{ marginLeft: "5px" }} fontSize="small" />}
                        </Button>

                    )} */}

                                {/* <Button color="primary" variant="contained" onClick={hadleFilterOpen}>
                                    Filter <FilterList style={{ marginLeft: "5px" }} fontSize="small" />
                                </Button> */}
                            </Grid>
                            {/* Filter */}
                            <Grid item xs={12}>
                                <Collapse in={filterOpen}>
                                    <Card>
                                        <Box p={4}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} md={4}>
                                                    <InputLabel>Status</InputLabel>


                                                    <Autocomplete
                                                        disablePortal
                                                        id="soldto_name1"
                                                        options={shipment}
                                                        onChange={(event, newValue) => {
                                                            handleChangeException(newValue);
                                                        }}
                                                        inputValue={filterData.order_status_name}
                                                        getOptionLabel={(option) => option.name}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                onChange={handleChangeFilter}
                                                                onKeyDown={(e) => {
                                                                    if (e.key === "Enter") handleFilter();
                                                                }}
                                                                value={filterData.order_status}
                                                                variant="outlined"
                                                                name="order_status"
                                                            // label="Company"
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <InputLabel>Order Number</InputLabel>
                                                    <TextField
                                                        name="orderNumber"
                                                        value={filterData.orderNumber}
                                                        onChange={handleChangeFilter}
                                                        onKeyDown={e => { if (e.key === "Enter") handleFilter() }}
                                                        variant="outlined"
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <InputLabel>NetSuite Order Number</InputLabel>
                                                    <TextField
                                                        name="netsuiteOrderNumber"
                                                        value={filterData.netsuiteOrderNumber}
                                                        onChange={handleChangeFilter}
                                                        onKeyDown={e => { if (e.key === "Enter") handleFilter() }}
                                                        variant="outlined"
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <InputLabel>Order Type</InputLabel>
                                                    <FormControl fullWidth>
                                                        <Select
                                                            value={filterData.orderType}

                                                            name="orderType"
                                                            onChange={handleChangeFilter}
                                                        >
                                                            <MenuItem value={""}>Select</MenuItem>
                                                            <MenuItem value={"Bunnings"}>Bunnings</MenuItem>
                                                            <MenuItem value={"MYDEAL"}>MYDEAL</MenuItem>
                                                            <MenuItem value={"DSCO"}>DSCO</MenuItem>



                                                        </Select>
                                                    </FormControl>

                                                </Grid>

                                                <Grid item xs={12} md={4}>
                                                    <InputLabel>Start Date</InputLabel>
                                                    <TextField
                                                        variant="outlined"
                                                        name="from"
                                                        type="date"
                                                        value={filterData.from}
                                                        onChange={(e) => handleChangeFilter(e)}
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </Grid>

                                                <Grid item xs={12} md={4}>
                                                    <InputLabel>End Date</InputLabel>
                                                    <TextField
                                                        variant="outlined"
                                                        name="to"
                                                        type="date"
                                                        value={filterData.to}
                                                        onChange={(e) => handleChangeFilter(e)}
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Box textAlign={"right"}>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={handleFilter}
                                                        >
                                                            Filter{" "}
                                                            <ArrowForward
                                                                fontSize="small"
                                                                style={{ marginLeft: "5px" }}
                                                            />
                                                        </Button>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Card>
                                </Collapse>
                            </Grid>

                            {submittedData.orderType || submittedData.order_status_name || submittedData.orderNumber || submittedData.netsuiteOrderNumber || submittedData.from || submittedData.to ? (
                                <Grid item xs={12}>
                                    <FilteredBox>
                                        <span>Filtered: </span>

                                        {submittedData.orderType && (
                                            <p>
                                                <span>Order Type: {submittedData.orderType}</span>
                                                <Close
                                                    fontSize="small"
                                                    onClick={() => handleRemove("orderType")}
                                                />
                                            </p>
                                        )}
                                        {submittedData.orderNumber && (
                                            <p>
                                                <span>Order Number: {submittedData.orderNumber}</span>
                                                <Close
                                                    fontSize="small"
                                                    onClick={() => handleRemove("orderNumber")}
                                                />
                                            </p>
                                        )}
                                        {submittedData.netsuiteOrderNumber && (
                                            <p>
                                                <span>NetSuite Order Number: {submittedData.netsuiteOrderNumber}</span>
                                                <Close
                                                    fontSize="small"
                                                    onClick={() => handleRemove("netsuiteOrderNumber")}
                                                />
                                            </p>
                                        )}
                                        {submittedData.order_status_name && (
                                            <p>
                                                <span>Status: {submittedData.order_status_name} </span>
                                                <Close
                                                    fontSize="small"
                                                    onClick={() => handleRemove("order_status")}
                                                />
                                            </p>
                                        )}

                                        {(submittedData.from || submittedData.to) && (
                                            <p>
                                                <span>
                                                    Date Range: {submittedData.from} - {submittedData.to}
                                                </span>
                                                <Close
                                                    fontSize="small"
                                                    onClick={() => handleRemove("from")}
                                                />
                                            </p>
                                        )}
                                    </FilteredBox>
                                </Grid>
                            ) : (
                                <Box></Box>
                            )}
                            {/* Filter */}

                            <Grid item xs={12}>
                                <TWTableComponent
                                    columns={columns}
                                    rows={rows}
                                    sort={true}
                                    view={true}
                                    handleView={handleView}
                                    handleRefetch={handleRefetch}
                                    handleSort={handleSort}
                                    loading={loading}
                                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                                    handleChangePage={handleChangePage}
                                    handleRowCheck={handleRowCheck}
                                    rowChecked={rowChecked}
                                    //handleAccept={handleAccept}
                                    //handleReject={handleReject}
                                    direction={direction}
                                    currentColumn={currentColumn}
                                    page={page}
                                    total={total && total}
                                    fromTable={from}
                                    toTable={to}
                                    rowsPerPage={rowsPerPage}
                                />
                            </Grid>
                            </Box>
                        </Grid>

                        {openViewDialog && (
                            <ViewMoreDetails
                                singleLoading={singleLoading}
                                viewDetails={viewDetails}
                                sendDetails={sendDetails}
                            />
                        )}

                        <Snackbar
                            autoHideDuration={3000}
                            anchorOrigin={{ vertical: "top", horizontal: "right" }}
                            open={open}
                            onClose={handleClose}
                        >
                            <Alert
                                onClose={handleClose}
                                severity={messageState}
                                sx={{ width: "100%" }}
                            >
                                {message}
                            </Alert>
                        </Snackbar>
                    </DialogContent>
                )}
                <DialogActions>
                    <Button onClick={handleClose1} variant="outlined" color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>

    );
};

export default ViewDetail;
