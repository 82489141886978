import { CircularProgress, Grid, Typography } from "@mui/material";
const LoadingScreen = ({ message }) => {
  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{
          pl: 2,
          pt: 3,
          mt: 1,
          ml: 0.2,
          background: "white",
          height: "100vh",
          display: "flex", // Added to enable flex container
          flexDirection: "column",
          alignItems: "center", // Center content vertically
          justifyContent: "center",
        }}
      >
        <CircularProgress sx={{ mb: 1 }} />
        <Typography sx={{ fontWeight: "bold" }}>
          {`${message || "Loading..."}`}
        </Typography>
      </Grid>
    </>
  );
};

export default LoadingScreen;
