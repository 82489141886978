import React, { useState } from "react";
import { dateFormatter } from "../../../../utils/DataGrid/dateFormatter";
import { extractCSVname } from "../../utility/extractCSVname.js";
import { Button, Grid, Snackbar } from "@mui/material";
import { Download } from "@mui/icons-material";
import TWDataGridTablePage from "../Temple&WebsterTable/TWDataGridTablePage.js";
import { httpclient } from "../../../../utils/httpClient.js";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const WaitingOrder = () => {

    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [messageState, setMessageState] = useState("");
    const [loading, setLoading] = useState(false);
    const [refetchSOH, setRefetchSOH] = useState(false);

    const fieldHeaderPairs = [
        {
            field: "Id"
        },
        {
            field: "newSystemOrderID",
            headerName: "PO Number",
        },
        {
            field: "order_status",
            headerName: "Order Status",
        },
        {
            field: "customer_name",
            headerName: "Customer Name",
        },
        {
            field: "total_items",
            headerName: "Total Items",
            width: 120,
        },
        {
            field: "total_amount",
            headerName: "Total Amount",
            width: 120,
        },
        {
            field: "order_created",
            headerName: "Order Created Date",
            width: 160,
            valueFormatter: (params) => dateFormatter(params),
        },
        {
            field: "order_import_date",
            headerName: "Order Imported Date",
            width: 160,
            valueFormatter: (params) => dateFormatter(params),
        },

        {
            field: "last_status_updated_date",
            headerName: "Status Last Updated Date",
            width: 160,
            valueFormatter: (params) => dateFormatter(params),
        },
    ];

    const filterColumn = [
        //   {
        // field: "STORE_CODE",
        // label: "Branch",
        // component: "TextField",
        // component: "AutocompleteField",
        // url: "/replenishment/pipeline",
        // overrideDataField: "branchList",
        //   },

        {
            field: "orderStartDate",
            label: "Order From",
            component: "TextField",
            type: "date",
        },
        {
            field: "orderEndDate",
            label: "Order To",
            component: "TextField",
            type: "date",
        },
        {
            field: "importStartDate",
            label: "Import From",
            component: "TextField",
            type: "date",
        },
        {
            field: "importEndDate",
            label: "Import To",
            component: "TextField",
            type: "date",
        },
        // {
        //   field: "soldToa",
        //   // label: "Sold To",
        //   component: "AutocompleteField",
        //   url: "/current/company-account/all",
        // },
    ];

    const handleCloseSnack = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };


    return (
        <>
            <TWDataGridTablePage
                headerTitle="List Waiting Orders"
                customIDKey="Id"
                loading={loading}
                columns={fieldHeaderPairs}
                filterColumn={filterColumn}
                pageUrl="temple-webster/waiting-orders-list"
                columnsVisibility={{
                    Id: false,
                }}
            />
            <Snackbar
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={open}
                onClose={handleCloseSnack}
            >
                <Alert
                    onClose={handleCloseSnack}
                    severity={messageState}
                    sx={{ width: "100%" }}
                >
                    {message}
                </Alert>
            </Snackbar>
        </>
    );
};

export default WaitingOrder;
