import React, { useEffect, useState, useRef } from "react";
import { ArrowForward, Close, Download, FilterList } from "@mui/icons-material";
import TableComponent from "../TableComponent";
import { httpclient } from "../../../../utils/httpClient";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Collapse,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  styled,
  TextField,
} from "@mui/material";
import ViewDetailsSystemDialog from "../ViewDetailsSystemDialog.js";
import { useLocation, useNavigate } from "react-router";
import { CSVLink } from "react-csv";
import { dateFormatter } from "../../../../utils/DataGrid/dateFormatter.js";

const columns = [
  { id: "stockID", name: "Stock ID" },
  { id: "ciStockCode", name: "Stock Code" },
  { id: "ciStockName", name: "Stock Name" },
  { id: "ciStockBrand", name: "Stock Brand" },
  { id: "stockGroupID1", name: "Group 1" },
  { id: "stockGroupID2", name: "Group 2" },
  { id: "stockExclusiveToClient", name: "Exclusive To" },
  // { id: "pendingProcess", name: "Pending Process" },
  { id: "Active", name: "Active" },
  { id: "ciLastModifiedDateTime", name: "Last Modified" },
  // { id: "actions", name: "Actions" },
];

const FilteredBox = styled(Box)(({ theme }) => ({
  background: "#f9f9f9",
  padding: "5px 10px",
  borderRadius: "5px",
  "& p": {
    margin: "0",
    marginRight: "10px",
    display: "inline-block",
    background: "#dedede",
    borderRadius: "10px",
    padding: "2px 5px",
  },
  "& svg": {
    fontSize: "15px",
    cursor: "pointer",
    position: "relative",
    top: "3px",
    background: theme.palette.primary.dark,
    color: "#fff",
    borderRadius: "50%",
    padding: "2px",
    marginLeft: "2px",
  },
}));

const Header = styled("div")(({ theme }) => ({
  "& h1": {
    color: theme.palette.primary.dark,
    margin: "0",
  },
}));

const configRowPerPage = JSON.parse(localStorage.getItem("configRowPerPage"));

const NewSystemStock = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const buttonRef = useRef(null);
  const [loadingExport, setLoadingExport] = useState(false);
  const [exportFile, setExportFile] = useState(false);
  const [exportRows, setExportRows] = useState([]);


  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [viewDetails, setViewDetails] = useState({});
  const [singleLoading, setSingleLoading] = useState(false);
  const [rows, setRows] = useState([]);

  const [loading, setLoading] = useState(false);
  const [direction, setDirection] = useState(false);
  const [currentColumn, setCurrentColumn] = useState("");
  const [page, setPage] = useState(1);
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(
    configRowPerPage && configRowPerPage
      ? configRowPerPage && configRowPerPage
      : 20
  );

  const [rowsPerPage, setRowsPerPage] = useState(
    configRowPerPage && configRowPerPage
      ? configRowPerPage && configRowPerPage
      : 20
  );
  const [total, setTotal] = useState("");
  const [filterOpen, setFilterOpen] = useState(false);
  const [groupList1, setGroupList1] = useState([]);
  const [groupList2, setGroupList2] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [brandList, setBrandList] = useState([]);

  const [filterData, setFilterData] = useState({
    stockName: "",
    stockCode: "",
    brandID: "",
    brandName: "",
    group1: "",
    group1Name: "",
    group2: "",
    group2Name: "",
    pendingProcess: "",
    exclusiveTo: "",
    exclusiveName: "",
    soldTo: '',
    soldToName: '',
    active: "",
    lastModified: "",
    isEmptyLogisticClass: "",
    remove: false,
  });

  const [submittedData, setSubmittedData] = useState({
    stockName: "",
    stockCode: "",
    brandID: "",
    brandName: "",
    group1: "",
    group1Name: "",
    group2: "",
    group2Name: "",
    pendingProcess: "",
    exclusiveTo: "",
    exclusiveName: "",
    soldTo: '',
    soldToName: '',
    active: "",
    lastModified: "",
    isEmptyLogisticClass: "",
    submit: false,
  });

  useEffect(() => {
    // getNewSystemStocks();
    getGroup1();
    getGroup2();
    getAllCompany();
    getAllBrands();
  }, []);



  useEffect(() => {
    if (
      filterData.stockCode === "" &&
      filterData.stockName === "" &&
      filterData.brandName === "" &&
      filterData.brandID === "" &&
      filterData.group1 === "" &&
      filterData.group1Name === "" &&
      filterData.group2 === "" &&
      filterData.group2Name === "" &&
      filterData.pendingProcess === "" &&
      filterData.exclusiveTo === "" &&
      filterData.soldTo === "" &&
      filterData.soldToName === "" &&
      filterData.exclusiveName === "" &&
      filterData.active === "" &&
      filterData.isEmptyLogisticClass === ""
    ) {
      setSubmittedData({
        ...submittedData,
        submit: false,
      });
    }
    if (filterData.stockCode === " ") filterData.stockCode = "";
    if (filterData.stockName === " ") filterData.stockName = "";
    if (filterData.brandID === " ") filterData.brandID = "";
    if (filterData.brandName === " ") filterData.brandName = "";
    if (filterData.group1 === " ") filterData.group1 = "";
    if (filterData.group1Name === " ") filterData.group1Name = "";
    if (filterData.group2 === " ") filterData.group2 = "";
    if (filterData.group2Name === " ") filterData.group2Name = "";
    if (filterData.pendingProcess === " ") filterData.pendingProcess = "";
    if (filterData.exclusiveTo === " ") filterData.exclusiveTo = "";
    if (filterData.soldTo === " ") filterData.soldTo = "";
    if (filterData.soldToName === " ") filterData.soldToName = "";
    if (filterData.exclusiveName === " ") filterData.exclusiveName = "";
    if (filterData.active === " ") filterData.active = "";
    if (filterData.isEmptyLogisticClass === " ") filterData.isEmptyLogisticClass = "";

    filterData.remove === true && handleFilter();
  }, [filterData]);

  useEffect(() => {
    let systemStockStorage = JSON.parse(
      localStorage.getItem("new_system_stock_filter")
    );
    systemStockStorage !== null && setFilterData(systemStockStorage);

    systemStockStorage == null
      ? getNewSystemStocks()
      : systemStockStorage.stockCode == "" &&
        systemStockStorage.stockName == "" &&
        systemStockStorage.brandID == "" &&
        systemStockStorage.brandName == "" &&
        systemStockStorage.group1 == "" &&
        systemStockStorage.group1Name == "" &&
        systemStockStorage.group2 == "" &&
        systemStockStorage.group2Name == "" &&
        systemStockStorage.exclusiveTo == "" &&
        systemStockStorage.exclusiveName == "" &&
        systemStockStorage.soldTo == "" &&
        systemStockStorage.soldToName == "" &&
        systemStockStorage.pendingProcess == "" &&
        systemStockStorage.active == "" &&
        systemStockStorage.isEmptyLogisticClass == "" &&
        systemStockStorage.removed == false
        ? getNewSystemStocks()
        : console.log("new system stocks!");
  }, []);


  useEffect(() => {

    if (location.state === "empty" || location.state === "nonEmpty") {
      filterData.isEmptyLogisticClass = location.state === "empty" ? "1" : location.state === "nonEmpty" ? "0" : location.state;
      filterData.soldToName = location.state === "empty" ? "Bunnings Group Ltd T/A Bunnings Marketplace" : location.state === "nonEmpty" ? "Bunnings Group Ltd T/A Bunnings Marketplace" : location.state;
      filterData.soldTo = location.state === "empty" ? 28 : location.state === "nonEmpty" ? 28 : location.state;
      setTimeout(() => {
        handleFilter();
        navigate("#", { replace: true });
      }, 1500);
    } else if (location.state?.ciStockCode) {
      filterData.stockCode = location.state?.ciStockCode
      setTimeout(() => {
        handleFilter();
        navigate("#", { replace: true });
        handleView(location.state?.stockID);
      }, 2000);
    }
    setTimeout(() => {
      navigate("#", { replace: true });
    }, 1500);
  }, [location.state]);


  const getNewSystemStocks = () => {
    setLoading(true);

    httpclient
      .get(`/new-system/stock-detail?pagination=${rowsPerPage}`)
      .then(({ data }) => {
        if (data.success) {
          setRows(data.data);
          setTotal(data.meta.total);
          setRowsPerPage(parseInt(data.meta.per_page));
          setPage(data.meta.current_page);
          setFrom(data.meta.from);
          setTo(data.meta.to);
          setLoading(false);
        }
      }).catch(err => {
        if (err.response.status === 401) {
          localStorage.clear();
          window.location = "/login";
        }
      })
  };

  const getGroup1 = () => {
    httpclient.get("/new-system/stock-group1/all").then(({ data }) => {
      if (data.success) {
        setGroupList1(data.data);
      }
    }).catch(err => {
      if (err.response.status === 401) {
        localStorage.clear();
        window.location = "/login";
      }
    })
  };

  const getGroup2 = () => {
    httpclient.get("/new-system/stock-group2/all").then(({ data }) => {
      if (data.success) {
        setGroupList2(data.data);
      }
    }).catch(err => {
      if (err.response.status === 401) {
        localStorage.clear();
        window.location = "/login";
      }
    })
  };

  const getAllCompany = () => {
    httpclient.get("/current/company-account/all").then(({ data }) => {
      if (data.success) {
        setCompanyList(data.data);
      }
    }).catch(err => {
      if (err.response.status === 401) {
        localStorage.clear();
        window.location = "/login";
      }
    })
  };

  const getAllBrands = () => {
    httpclient.get("/current/brand/all").then(({ data }) => {
      if (data.success) {
        setBrandList(data.data);
      }
    }).catch(err => {
      if (err.response.status === 401) {
        localStorage.clear();
        window.location = "/login";
      }
    })
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "group1") {
      setFilterData({
        ...filterData,
        group1Name: value,
        remove: false,
      });
    }
    if (name === "group2") {
      setFilterData({
        ...filterData,
        group2Name: value,
        remove: false,
      });
    }
    if (name === "exclusiveTo") {
      setFilterData({
        ...filterData,
        exclusiveName: value,
        remove: false,
      });
    }
    if (name === "brandID") {
      setFilterData({
        ...filterData,
        brandName: value,
        remove: false,
      });
    }
    if (name === "soldTo") {
      setFilterData({
        ...filterData,
        soldToName: value,
        remove: false,
      });
    }
  };

  const handleChangeGroup1 = (value) => {
    setFilterData({
      ...filterData,
      group1: value !== null ? value.id : "",
      group1Name: value !== null ? value.name : "",
      remove: false,
    });
  };

  const handleChangeGroup2 = (value) => {
    setFilterData({
      ...filterData,
      group2: value !== null ? value.id : "",
      group2Name: value !== null ? value.name : "",
      remove: false,
    });
  };

  const handleChangeExclusive = (value) => {
    setFilterData({
      ...filterData,
      exclusiveTo: value !== null ? value.id : "",
      exclusiveName: value !== null ? value.name : "",
      remove: false,
    });
  };

  const handleChangeBrand = (value) => {
    setFilterData({
      ...filterData,
      brandID: value !== null ? value.brandID : "",
      brandName: value !== null ? value.brandName : "",
      remove: false,
    });
  };

  const handleChangeSoldTo = (value) => {
    setFilterData({
      ...filterData,
      soldTo: value !== null ? value.id : "",
      soldToName: value !== null ? value.name : "",
      remove: false,
    });
  };

  // Filter

  const hadleFilterOpen = () => {
    setFilterOpen((prev) => !prev);
  };

  const handleChangeFilter = (e) => {
    const { name, value } = e.target;
    setFilterData({
      ...filterData,
      [name]: value,
      remove: false,
    });
  };

  const handleFilter = () => {
    setSubmittedData({
      ...submittedData,
      stockName: filterData.stockName,
      stockCode: filterData.stockCode,
      brandName: filterData.brandName,
      brandID: filterData.brandID,
      group1: filterData.group1,
      group2: filterData.group2,
      group1Name: filterData.group1Name,
      group2Name: filterData.group2Name,
      pendingProcess: filterData.pendingProcess,
      exclusiveTo: filterData.exclusiveTo,
      exclusiveName: filterData.exclusiveName,
      soldTo: filterData.soldTo,
      soldToName: filterData.soldToName,
      active: filterData.active,
      isEmptyLogisticClass: filterData.isEmptyLogisticClass,
      submit: true,
    });
    filterData.remove = true;
    localStorage.setItem("new_system_stock_filter", JSON.stringify(filterData));
    setLoading(true);
    if (
      filterData.stockCode ||
      filterData.stockName ||
      filterData.brandID ||
      filterData.group1 ||
      filterData.group2 ||
      filterData.pendingProcess ||
      filterData.exclusiveName ||
      filterData.soldTo ||
      filterData.soldToName ||
      filterData.active ||
      filterData.isEmptyLogisticClass
    ) {
      setExportRows([]);
      httpclient
        .get(
          `new-system/stock-detail?ciStockCode=${filterData.stockCode
          }&ciStockName=${filterData.stockName}&ciStockBrand=${filterData.brandName}&stockGroupID1=${filterData.group1}&stockGroupID2=${filterData.group2
          }&stockExclusiveToClient=${filterData.exclusiveTo}&soldTo=${filterData.soldTo}&pendingProcess=${filterData.pendingProcess
          }&Active=${filterData.active}&isEmptyLogisticClass=${filterData.isEmptyLogisticClass}&pagination=${rowsPerPage}&page=${1}`
        )
        .then(({ data }) => {
          if (data.success) {
            setRows(data.data);
            setTotal(data.meta.total);
            setRowsPerPage(data.meta.per_page);
            setPage(data.meta.current_page);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setExportFile(true);
            setLoading(false);
          }
        });
    } else {
      getNewSystemStocks();
    }
  };

  const handleExport = () => {
    setLoadingExport(true);
    httpclient
      .get(
        `new-system/stock-detail?ciStockCode=${filterData.stockCode
        }&ciStockName=${filterData.stockName}&ciStockBrand=${filterData.brandName}&stockGroupID1=${filterData.group1}&stockGroupID2=${filterData.group2
        }&stockExclusiveToClient=${filterData.exclusiveTo}&soldTo=${filterData.soldTo}&pendingProcess=${filterData.pendingProcess
        }&Active=${filterData.active}&isEmptyLogisticClass=${filterData.isEmptyLogisticClass}&pagination=${total}`
      )
      .then(({ data }) => {
        if (data.success) {
          setExportRows(data.data);          
          setLoadingExport(false);
        }
      });
  };

  const handleRemove = (data) => {
    setExportFile(false);
    setExportRows([]);
    if (data === "group1") {
      filterData.group1Name = "";
      submittedData.group1Name = "";
    }
    if (data === "group2") {
      filterData.group2Name = "";
      submittedData.group2Name = "";
    }
    if (data === "exclusiveTo") {
      filterData.exclusiveName = "";
      submittedData.exclusiveName = "";
    }
    if (data === "brandID") {
      filterData.brandName = "";
      submittedData.brandName = "";
    }
    if (data === "soldTo") {
      filterData.soldToName = "";
      submittedData.soldToName = "";
    }
    setFilterData({
      ...filterData,
      [data]: "",
      remove: true,
    });
    setSubmittedData({
      ...submittedData,
      [data]: "",
    });
  };
  // Filter

  const handleSort = (column) => {
    setDirection((prevDirection) => !prevDirection);
    setCurrentColumn(column);
    submittedData.submit
      ? httpclient
        .get(
          `new-system/stock-detail?ciStockCode=${filterData.stockCode
          }&ciStockName=${filterData.stockName}&ciStockBrand=${filterData.brandName
          }&stockGroupID1=${filterData.group1}&stockGroupID2=${filterData.group2
          }&soldTo=${filterData.soldTo}&pendingProcess=${filterData.pendingProcess
          }&stockExclusiveToClient=${filterData.exclusiveTo}&Active=${filterData.active
          }&isEmptyLogisticClass=${filterData.isEmptyLogisticClass}&sort_by=${column}&direction=${!direction ? "asc" : "desc"
          }&pagination=${rowsPerPage}&page=${page}`
        )
        .then(({ data }) => {
          if (data.success === true) {
            setRows(data.data);
            setTotal(data.meta.total);
            setRowsPerPage(parseInt(data.meta.per_page));
            setPage(data.meta.current_page);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        })
      : httpclient
        .get(
          `new-system/stock-detail?sort_by=${column}&direction=${!direction ? "asc" : "desc"
          }&pagination=${rowsPerPage}`
        )
        .then(({ data }) => {
          if (data.success === true) {
            setRows(data.data);
          }
        });
  };

  const handleView = (id) => {
    
    setSingleLoading(true);
    setOpenViewDialog(true);
    httpclient.get(`/new-system/stock-detail/${id}`).then(({ data }) => {
      if (data.success) {
        setViewDetails(data.data);
        setSingleLoading(false);
      }
    });
  };

  const sendDetails = (callback) => {
    if (callback.open === false) {
      setOpenViewDialog(false);
      setViewDetails({});
    }
    if (callback.refetch === true) {
      handleView(callback.stockID);
      setTimeout(() => {
        getNewSystemStocks();
      }, 1000);
    }
  };

  const handleChangePage = (e, page) => {
    setLoading(true);
    submittedData.submit
      ? httpclient
        .get(
          `new-system/stock-detail?ciStockCode=${filterData.stockCode}&ciStockName=${filterData.stockName}&ciStockBrand=${filterData.brandName}&stockGroupID1=${filterData.group1}&stockGroupID2=${filterData.group2}&pendingProcess=${filterData.pendingProcess}&soldTo=${filterData.soldTo}&stockExclusiveToClient=${filterData.exclusiveTo}&Active=${filterData.active}&isEmptyLogisticClass=${filterData.isEmptyLogisticClass}&sort_by=${currentColumn}&direction=${direction ? 'asc' : 'desc'}&pagination=${rowsPerPage}&page=${page}`
        )
        .then(({ data }) => {
          if (data.success === true) {
            setRows(data.data);
            setTotal(data.meta.total);
            setRowsPerPage(parseInt(data.meta.per_page));
            setPage(data.meta.current_page);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        })
      : httpclient
        .get(
          `new-system/stock-detail?direction=${direction ? "asc" : "desc"
          }&pagination=${rowsPerPage}&page=${page}`
        )
        .then(({ data }) => {
          if (data.success === true) {
            setRows(data.data);
            setTotal(data.meta.total);
            setRowsPerPage(parseInt(data.meta.per_page));
            setPage(data.meta.current_page);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    // setLoading(true);

    localStorage.setItem("configRowPerPage", event.target.value);

    submittedData.submit
      ? httpclient
        .get(
          `new-system/stock-detail?ciStockCode=${filterData.stockCode
          }&ciStockName=${filterData.stockName}&ciStockBrand=${filterData.brandName
          }&stockGroupID1=${filterData.group1}&stockGroupID2=${filterData.group2
          }&soldTo=${filterData.soldTo}&pendingProcess=${filterData.pendingProcess
          }&stockExclusiveToClient=${filterData.exclusiveTo}&Active=${filterData.active
          }&isEmptyLogisticClass=${filterData.isEmptyLogisticClass}&sort_by=${currentColumn}&direction=${direction ? 'asc' : 'desc'}&pagination=${+event.target.value}&page=${page}`
        )
        .then(({ data }) => {
          setLoading(true);
          if (data.success === true) {
            setRows(data.data);
            setTotal(data.meta.total);
            setRowsPerPage(parseInt(data.meta.per_page));
            setPage(data.meta.current_page);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        })
      : httpclient
        .get(
          `new-system/stock-detail?direction=${direction ? "asc" : "desc"
          }&pagination=${+event.target.value}&page=${1}`
        )
        .then(({ data }) => {
          setLoading(true);
          if (data.success === true) {
            setRows(data.data);
            setTotal(data.meta.total);
            setRowsPerPage(parseInt(data.meta.per_page));
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setPage(data.meta.current_page);
            setLoading(false);
          }
        });
  };

  useEffect(() => {
    if (!loadingExport && exportRows.length > 0 && buttonRef.current) {
      buttonRef.current.click();
    }
  }, [loadingExport, exportRows]);

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item md={8} xs={12}>
          <Header>
            <h1>List New System Stock</h1>
          </Header>
        </Grid>
        <Grid
          item
          md={4}
          xs={12}
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
        >

          {exportRows.length > 0 ? (
            <CSVLink data={exportRows} filename={"List New System Stock" + String(dateFormatter(Date.now())) + ".csv"}>

              <Button

                color="primary"
                variant="contained"
                style={{ marginRight: "10px" }}
                ref={buttonRef}
              >
                Export <Download style={{ marginLeft: "5px" }} fontSize="small" />
              </Button>

            </CSVLink>
          ) : (
            <Button

              color="primary"
              variant="contained"
              style={{ marginRight: "10px" }}
              disabled={!exportFile}
              onClick={handleExport}
            >
              {loadingExport ? "Generating Data to Export" : "Export"}{loadingExport ? `...` : <Download style={{ marginLeft: "5px" }} fontSize="small" />}
            </Button>

          )}
          <Button color="primary" variant="contained" onClick={hadleFilterOpen}>
            Filter <FilterList style={{ marginLeft: "5px" }} fontSize="small" />
          </Button>
        </Grid>

        {/* Filter */}
        <Grid item xs={12}>
          <Collapse in={filterOpen}>
            <Card>
              <Box p={4}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <InputLabel>Stock Name</InputLabel>
                    <TextField
                      variant="outlined"
                      name="stockName"
                      value={filterData.stockName}
                      onChange={handleChangeFilter}
                      onKeyDown={e => { if (e.key === "Enter") handleFilter() }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <InputLabel>Stock Code</InputLabel>
                    <TextField
                      variant="outlined"
                      name="stockCode"
                      value={filterData.stockCode}
                      onChange={handleChangeFilter}
                      onKeyDown={e => { if (e.key === "Enter") handleFilter() }}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <InputLabel>Brand Name</InputLabel>
                    <Autocomplete
                      disablePortal
                      id="brand_name"
                      options={brandList}
                      onChange={(event, newValue) => {
                        handleChangeBrand(newValue);
                      }}
                      inputValue={filterData.brandName}
                      getOptionLabel={(option) => option.brandName}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          onChange={handleChange}
                          onKeyDown={e => { if (e.key === "Enter") handleFilter() }}
                          value={filterData.brandName}
                          variant="outlined"
                          name="brandID"
                        // label="Company"
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <InputLabel>New System Stock Group 1</InputLabel>
                    <Autocomplete
                      disablePortal
                      id="newgroup1_name"
                      options={groupList1}
                      onChange={(event, newValue) => {
                        handleChangeGroup1(newValue);
                      }}
                      inputValue={filterData.group1Name}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          onChange={handleChange}
                          onKeyDown={e => { if (e.key === "Enter") handleFilter() }}
                          value={filterData.group1Name}
                          variant="outlined"
                          name="group1"
                        // label="Company"
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <InputLabel>New System Stock Group 2</InputLabel>
                    <Autocomplete
                      disablePortal
                      id="newgroup2_name"
                      options={groupList2}
                      onChange={(event, newValue) => {
                        handleChangeGroup2(newValue);
                      }}
                      inputValue={filterData.group2Name}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          onChange={handleChange}
                          onKeyDown={e => { if (e.key === "Enter") handleFilter() }}
                          value={filterData.group2Name}
                          variant="outlined"
                          name="group2"
                        // label="Company"
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <InputLabel>Exclusive To</InputLabel>
                    <Autocomplete
                      disablePortal
                      id="exclusive_name"
                      options={companyList}
                      onChange={(event, newValue) => {
                        handleChangeExclusive(newValue);
                      }}
                      inputValue={filterData.exclusiveName}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          onChange={handleChange}
                          onKeyDown={e => { if (e.key === "Enter") handleFilter() }}
                          value={filterData.exclusiveName}
                          variant="outlined"
                          name="exclusiveTo"
                        // label="Company"
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <InputLabel>Sold To</InputLabel>
                    <Autocomplete
                      disablePortal
                      id="soldto_name"
                      options={companyList}
                      onChange={(event, newValue) => {
                        handleChangeSoldTo(newValue);
                      }}
                      inputValue={filterData.soldToName}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          onChange={handleChange}
                          onKeyDown={e => { if (e.key === "Enter") handleFilter() }}
                          value={filterData.soldToName}
                          variant="outlined"
                          name="soldTo"
                        // label="Company"
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <InputLabel>Pending Process</InputLabel>
                    <FormControl fullWidth>
                      <Select
                        value={filterData.pendingProcess}
                        // label="Exclusive Pending"
                        name="pendingProcess"
                        onChange={handleChangeFilter}
                      >
                        <MenuItem value={""}>Select</MenuItem>
                        <MenuItem value={"1"}>Yes</MenuItem>
                        <MenuItem value={"0"}>No</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <InputLabel>Shipping Class</InputLabel>
                    <FormControl fullWidth>
                      <Select
                        value={filterData.isEmptyLogisticClass}
                        name="isEmptyLogisticClass"
                        // label="Active"
                        onChange={handleChangeFilter}
                      >
                        <MenuItem value={""}>Select</MenuItem>
                        <MenuItem value={"1"}>Empty</MenuItem>
                        <MenuItem value={"0"}>Not Empty</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>


                  <Grid item xs={12} md={4}>
                    <InputLabel>Active</InputLabel>
                    <FormControl fullWidth>
                      <Select
                        value={filterData.active}
                        name="active"
                        // label="Active"
                        onChange={handleChangeFilter}
                      >
                        <MenuItem value={""}>Select</MenuItem>
                        <MenuItem value={"Y"}>Yes</MenuItem>
                        <MenuItem value={"N"}>No</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <Box textAlign={"right"}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleFilter}
                      >
                        Filter{" "}
                        <ArrowForward
                          fontSize="small"
                          style={{ marginLeft: "5px" }}
                        />
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </Collapse>
        </Grid>

        {submittedData.stockCode ||
          submittedData.stockName ||
          submittedData.brandID ||
          submittedData.group1Name ||
          submittedData.group2Name ||
          submittedData.pendingProcess ||
          submittedData.soldToName ||
          submittedData.exclusiveName ||
          submittedData.active ||
          submittedData.isEmptyLogisticClass
          ? (
            <Grid item xs={12}>
              <FilteredBox>
                <span>Filtered: </span>
                {submittedData.stockCode && (
                  <p>
                    <span>Stock Code: {submittedData.stockCode}</span>
                    <Close
                      fontSize="small"
                      onClick={() => handleRemove("stockCode")}
                    />
                  </p>
                )}
                {submittedData.stockName && (
                  <p>
                    <span>Stock Name: {submittedData.stockName}</span>
                    <Close
                      fontSize="small"
                      onClick={() => handleRemove("stockName")}
                    />
                  </p>
                )}
                {submittedData.exclusiveName && (
                  <p>
                    <span>Excluisve to: {submittedData.exclusiveName}</span>
                    <Close
                      fontSize="small"
                      onClick={() => handleRemove("exclusiveTo")}
                    />
                  </p>
                )}
                {submittedData.soldToName && (
                  <p>
                    <span>Sold to: {submittedData.soldToName}</span>
                    <Close
                      fontSize="small"
                      onClick={() => handleRemove("soldTo")}
                    />
                  </p>
                )}
                {submittedData.brandID && (
                  <p>
                    <span>Brand Name: {submittedData.brandName}</span>
                    <Close
                      fontSize="small"
                      onClick={() => handleRemove("brandID")}
                    />
                  </p>
                )}
                {submittedData.group1 && (
                  <p>
                    <span>Group 1: {submittedData.group1Name}</span>
                    <Close
                      fontSize="small"
                      onClick={() => handleRemove("group1")}
                    />
                  </p>
                )}

                {submittedData.group2 && (
                  <p>
                    <span>Group 2: {submittedData.group2Name}</span>
                    <Close
                      fontSize="small"
                      onClick={() => handleRemove("group2")}
                    />
                  </p>
                )}
                {submittedData.pricePending && (
                  <p>
                    <span>
                      Price Pending:{" "}
                      {submittedData.pricePending === "1" ? "Yes" : "No"}
                    </span>
                    <Close
                      fontSize="small"
                      onClick={() => handleRemove("pricePending")}
                    />
                  </p>
                )}
                {submittedData.pendingProcess && (
                  <p>
                    <span>
                      Pending Process:{" "}
                      {submittedData.pendingProcess === "1" ? "Yes" : "No"}
                    </span>
                    <Close
                      fontSize="small"
                      onClick={() => handleRemove("pendingProcess")}
                    />
                  </p>
                )}
                {submittedData.active && (
                  <p>
                    <span>
                      Active: {submittedData.active === "Y" ? "Yes" : "No"}
                    </span>
                    <Close
                      fontSize="small"
                      onClick={() => handleRemove("active")}
                    />
                  </p>
                )}
                {submittedData.isEmptyLogisticClass && (
                  <p>
                    <span>
                      Shipping Class: {submittedData.isEmptyLogisticClass === "1" ?
                        "Empty" : "Not Empty"}
                    </span>
                    <Close
                      fontSize="small"
                      onClick={() => handleRemove("isEmptyLogisticClass")}
                    />
                  </p>
                )}
              </FilteredBox>
            </Grid>
          ) : (
            <Box></Box>
          )}
        {/* Filter */}

        <Grid item xs={12}>
          <TableComponent
            columns={columns}
            rows={rows}
            sort={true}
            handleSort={handleSort}
            handleView={handleView}
            loading={loading}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleChangePage={handleChangePage}
            direction={direction}
            currentColumn={currentColumn}
            page={page}
            total={total && total}
            fromTable={from}
            toTable={to}
            rowsPerPage={rowsPerPage}
          />
        </Grid>
      </Grid>

      {openViewDialog && (
        <ViewDetailsSystemDialog
          singleLoading={singleLoading}
          viewDetails={viewDetails}
          sendDetails={sendDetails}
        />
      )}
    </div>
  );
};

export default NewSystemStock;
