import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Box,
  IconButton,
  Skeleton,
  CircularProgress,
  Snackbar,
  Tooltip,
  Button,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Radio,
  Checkbox,
} from "@mui/material";
import { red, blue } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import {
  ArrowBack,
  Check,
  Clear,
  Done,
  Edit,
  BookmarkBorder,
  Bookmark,
  PanoramaFishEye,
  Lens,
  TaskAlt,
  HighlightOff,
  HourglassEmptyOutlined,
  WifiProtectedSetup,
} from "@mui/icons-material";
import MuiAlert from "@mui/material/Alert";
import moment from "moment";
import DownloadIcon from "@mui/icons-material/Download";
import "../BasicTableView/blink.css";
import React, { useEffect, useState } from "react";
import { httpclient } from "../../utils/httpClient";
import AssignedDialog from "../../Private/Online/Orders/AssignedDialog";
import RejectDialog from "../../Private/Online/Orders/RejectDialog";
import StatusDialog from "../../Private/Online/Orders/StatusDialog";
import { withStyles } from "@mui/styles";
import CancelProductDialog from "../../Private/Online/Orders/CancelProductDialog";
import ProcessDialog from "../../Private/Online/Orders/ProcessDialog";
import ReProcessDialog from "../../Private/Online/Orders/ReProcessDialog";
import featureServer from "../../utils/featureServer";

// const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  "& .MuiSelect-select": {
    padding: "5px",
  },
  "& input": {
    padding: "5px",
    width: "100px",
  },
  "& button": {
    height: "20px",
    minWidth: "30px",
    "& svg": {
      fontSize: "18px",
    },
  },
}));

export default function BasicTableView(props) {
  console.log("online", props)
  const [orderBranch, setOrderBranch] = useState({
    orderPrice: "",
    invoicedPrice: "",
    orderQuantity: "",
  });
  const [productDetails, setProductDetails] = useState("");
  //const [checkedRows, setCheckedRows] = useState([]);
  var [rowCheckedID, setRowCheckedID] = useState([]);
  var [selectedChecked, setSelectedChecked] = useState([]);

  var [rowCheckedID2, setRowCheckedID2] = useState([]);
  var [selectedChecked2, setSelectedChecked2] = useState([]);
  const [allRows, setAllRows] = useState([]);

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [messageState, setMessageState] = useState("");

  const [keyLoading, setKeyLoading] = useState(false);
  const [keyLoading1, setKeyLoading1] = useState(false);
  const [keyLoadingRe, setKeyLoadingRe] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);

  const [showBranchEdit, setShowBranchEdit] = useState("");
  const [lineBranchName, setLineBranchName] = useState("");
  const [branchLoading, setBranchLoading] = useState(false);
  const [currentBranchID, setCurrentBranchID] = useState("");
  const [orderProductID, setOrderProductID] = useState("");
  const [openRejectDialog, setOpenRejectDialog] = useState(false);
  const [showPriceEdit, setShowPriceEdit] = useState("");
  const [lineInvoicePrice, setLineInvoicePrice] = useState("");
  const [invoiceLoading, setInvoiceLoading] = useState(false);
  const [currentInvoiceID, setCurrentInvoiceID] = useState("");
  const [acceptStatus, setAcceptStatus] = useState("");
  const [showAssignBranchDialog, setShowAssignBranchDialog] = useState(false);
  const [openStatusDialog, setOpenStatusDialog] = useState(false);
  const [openReProcessDialog, setOpenReProcessDialog] = useState(false);
  const [invoiceStatus, setInvoiceStatus] = useState(
    props.viewDetails.invoiceStatus || ""
  );

  const [buttonLoader, setButtonLoader] = useState(false);
  const [dialogDetails, setDialogDetails] = useState({
    open: true,
    refetch: false,
    orderID: "",
  });
  const [openCancelProductDialog, setOpenCancelProductDialog] = useState(false);
  const [checkState, setCheckState] = useState([]);
  const [rowValue, setRowValue] = useState(null);
  const [filter, setFilter] = useState(null);
  const [openProcessDialog, setOpenProcessDialog] = useState(false);

  // useEffect(() => {
  //   setAllRows(props && props.rows);
  // },[])

  // console.log("props.reportType", props.reportType)
  // console.log("props.orderStatus", props.orderStatus)

  // useEffect(() => {
  //   var notExceptional = [];
  //   var exceptional = [];

  //   props.rows.map(row => {
  //     if(row.reportType === "exceptionalOrder"){
  //       exceptional.push(row.orderproductID);
  //       selectedChecked2[row.orderproductID] = true;

  //       // setSelectedChecked2(selectedChecked2);
  //     }else{
  //       notExceptional.push(row.orderproductID);
  //       selectedChecked[row.orderproductID] = true;

  //       // setSelectedChecked(selectedChecked);
  //     }
  //   })
  //   setRowCheckedID2(exceptional);
  //   setRowCheckedID(notExceptional);

  // },[props.rows])

  useEffect(() => {
    var notExceptional = [];
    var exceptional = [];

    props &&
      props.rows.map((row) => {
        if (row.reportType === "exceptionalOrder") {
          exceptional.push(row.orderproductID);
          selectedChecked2[row.orderproductID] = true;

          // setSelectedChecked2(selectedChecked2);
        } else {
          notExceptional.push(row.orderproductID);
          selectedChecked[row.orderproductID] = true;

          // setSelectedChecked(selectedChecked);
        }
      });

    setRowCheckedID2(exceptional);
    setRowCheckedID(notExceptional);
  }, []);

  const changeInvoiceStatus = (id) => {
    setKeyLoading(true);
    httpclient
      .post(`new-system/order-product/update-invoice/${props.id}`, {
        //id: [props.viewDetails.newSystemOrderID],
        invoiceStatus: invoiceStatus,
      })
      .then(({ data }) => {
        if (data.success) {
          setKeyLoading(false);
          setOpen(true);

          setMessageState("success");
          setMessage(data.message);
          setProductDetails("");
          setTimeout(() => {
            props.handleRefetch();
          }, 2000);
        } else {
          setKeyLoading(false);
          setOpen(true);
          setMessageState("error");
          setMessage(data.error);
        }
      });
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleChangeLineBranch = (row) => {
    setProductDetails(row);

    var checkConfirm = false;
    row.stock_on_hand.map((stock) => {
      if (stock.new_system_branch.eID === lineBranchName) {
        if (Number(row.quantity) > stock.actualSOH) {
          return (checkConfirm = true);
        }
      }
    });

    if (checkConfirm === true) {
      setShowAssignBranchDialog(true);
    } else {
      setBranchLoading(true);
      httpclient
        .post(`new-system/order-product/reassign-order/${currentBranchID}`, {
          branchID: lineBranchName,
        })
        .then(({ data }) => {
          if (data.success) {
            setBranchLoading(false);
            setOpen(true);
            setMessageState("success");
            setMessage(data.message);
            setProductDetails("");

            // Order and Product Detail Edit Boxes set to false
            setCurrentBranchID("");
            setShowBranchEdit(false);
            setShowPriceEdit(false);
            setTimeout(() => {
              props.handleRefetch();
            }, 2000);
          } else {
            setCurrentBranchID("");
            setProductDetails("");
            setBranchLoading(false);
            setOpen(true);
            setMessageState("error");
            setMessage(data.error);
          }
        })
        .catch((err) => {
          console.log("catch err", err);
          setCurrentBranchID("");
          setProductDetails("");
          setBranchLoading(false);
          setOpen(true);
          setMessageState("error");
          setMessage(err.message);
        });
    }
  };

  const sendAssignBranch = (call) => {
    if (call.open === false) {
      setShowAssignBranchDialog(false);
      setProductDetails("");
    }
    if (call.success === true) {
      setBranchLoading(true);
      httpclient
        .post(
          `new-system/order-product/reassign-order/${productDetails.orderproductID}`,
          {
            branchID: lineBranchName,
          }
        )
        .then(({ data }) => {
          if (data.success) {
            setBranchLoading(false);
            setOpen(true);
            setMessageState("success");
            setMessage(data.message);

            // Order and Product Detail Edit Boxes set to false
            props.setValue(1);
            setProductDetails("");
            setCurrentBranchID("");
            setShowBranchEdit(false);
            setShowPriceEdit(false);

            setTimeout(() => {
              props.handleRefetch();
            }, 2000);
          } else {
            setCurrentBranchID("");
            setBranchLoading(false);
            setOpen(true);
            setMessageState("error");
            setMessage(data.error);
          }
        })
        .catch((err) => {
          setCurrentBranchID("");
          setProductDetails("");
          setBranchLoading(false);
          setOpen(true);
          setMessageState("error");
          setMessage(err.message);
        });
    }
  };

  const handleChangeLinePrice = (row) => {
    setInvoiceLoading(true);
    httpclient
      .post(
        `new-system/order-product/update-invoice-price/${row.newSystemOrderID}`,
        {
          price: lineInvoicePrice,
          productID: row.orderproductID,
        }
      )
      .then(({ data }) => {
        if (data.success) {
          setInvoiceLoading(false);
          setOpen(true);
          setMessageState("success");
          setMessage(data.message);

          // Order and Product Detail Edit Boxes set to false
          setProductDetails("");
          setShowPriceEdit(false);

          //Refetching the data
          setTimeout(() => {
            props.handleRefetch();
          }, 2000);
        } else {
          setInvoiceLoading(false);
          setOpen(true);
          setMessageState("error");
          setMessage(data.error);
        }
      })
      .catch((err) => {
        setInvoiceLoading(false);
        setOpen(true);
        setMessageState("error");
        setMessage(err.message);
      });
  };

  const handleRefetch = () => {
    setDialogDetails({
      open: true,
      refetch: true,
      orderID: props.viewDetails.orderID,
    });
    setTimeout(() => {
      setDialogDetails({
        open: true,
        refetch: false,
        orderID: "",
      });
    }, 100);
  };

  const handleAccept = (row) => {
    setAcceptStatus("accept");
    setOrderProductID(row.orderproductID);
    setOpenStatusDialog(true);
  };
  const handleReject = (row) => {
    setAcceptStatus("reject");
    setOrderProductID(row.orderproductID);
    setOpenStatusDialog(true);
  };

  const sendChangeOrder = (call, reason, reasonCode) => {
    if (call.open === false) {
      setAcceptStatus("");
      setOpenStatusDialog(false);
    }
    if (call.success === true) {
      setButtonLoader(true);
      httpclient
        .post("temple-webster/update-order-product-status", {
          orderproductID: orderProductID,
          order_product_status: acceptStatus === "accept" ? "Accepted" : "Rejected",
          reason:reason,
          reasonCode:reasonCode
        })
        .then(({ data }) => {
          if (data.success) {
            setOpen(true);
            setMessageState("success");
            setMessage(data.message);
            //Accept and Reject setting back to initail state
            setAcceptStatus("");
            setOrderProductID("");
            setButtonLoader(false);
            //Refetch the data
            props.handleRefetch();
          } else {
            setOpen(true);
            setMessageState("error");
            setMessage(data.error === "" ? data.message : data.error);
            setButtonLoader(false);
            setAcceptStatus("");
            setOrderProductID("");
          }
        })
        .catch((err) => {
          setOpen(true);
          setMessageState("error");
          setMessage("Error: Server error");
          setButtonLoader(false);
          setAcceptStatus("");
          setOrderProductID("");
        });
    }
  };

  const handleRowCheck = (event, row) => {
    const { checked } = event.target;
    selectedChecked[row.orderproductID] = checked;
    selectedChecked2[row.orderproductID] = false;

    if (checked === true) {
      setRowCheckedID([...rowCheckedID, row.orderproductID]);

      var oldIds = rowCheckedID2.filter((id) => id != row.orderproductID);
      setRowCheckedID2(oldIds);
    } else {
      var newIds = rowCheckedID.filter((id) => id != row.orderproductID);
      setRowCheckedID(newIds);
    }
  };

  const handleRowCheck2 = (event, row) => {
    const { checked } = event.target;
    selectedChecked2[row.orderproductID] = checked;
    selectedChecked[row.orderproductID] = false;

    if (checked === true) {
      setRowCheckedID2([...rowCheckedID2, row.orderproductID]);

      var oldIds = rowCheckedID.filter((id) => id != row.orderproductID);
      setRowCheckedID(oldIds);
    } else {
      var newIds = rowCheckedID2.filter((id) => id != row.orderproductID);
      setRowCheckedID2(newIds);
    }
  };

  // console.log('row check id11111111111', rowCheckedID);
  // console.log('row 22222', rowCheckedID2);

  const handeUpdateStatus = () => {
    setOpenRejectDialog(true);
  };
  const handleReProcess = () => {
    setOpenReProcessDialog(true);
  };

  const sendToCancel = (call, reason, reasonCode) => {
    //console.log("zzzz", dialogDetails, reason, reasonCode)
    if (call.open === false) {
      setOpenRejectDialog(false);
    }
    if (call.success === true) {
      // console.log('unique', uniqueIds);
      let uniqueIds = [...new Set(rowCheckedID2)];
      let currentOrderID = [props.viewDetails.orderID];
      const status =
        props.rows.length === uniqueIds.length ? "reject" : "accept";
      setKeyLoading(true);

      const apipoint =

        props.viewDetails.orderType === "Bunnings" && status === "accept"
          ? "/new-system/order/bunnings-accept"
          : "/new-system/order/update-status";


      httpclient
        .post(apipoint, {
          orderID: currentOrderID,
          companyID: [props.viewDetails.companyID],
          status:
            props.rows.length === uniqueIds.length ? "Rejected" : "Accepted",
          cancelOrderProductIds: uniqueIds,
          reason: reason,
          reasonCode: reasonCode,
        })
        .then(({ data }) => {
          if (data.success) {
            setOpen(true);
            setMessageState("success");
            setMessage(data.message);
            setKeyLoading(false);
            // Order and Product Detail Edit Boxes set to false
            setProductDetails("");
            //Refetching the data
            setTimeout(() => {
              props.handleRefetch();
            }, 2000);
          } else {
            setKeyLoading(false);
            setOpen(true);
            setMessageState("error");
            setMessage(data.error);
          }
        })
        .catch((err) => {
          setKeyLoading(false);
          setOpen(true);
          setMessageState("error");
          setMessage(err.message);
        });
    }
  };

  const handleCancelProductDialogue = (row) => {
    setOpenCancelProductDialog(true);
    setRowValue(row);
  };
  const sendToCancelProduct = (call, reason, reasonCode, row) => {
    if (call.open === false) {
      setOpenCancelProductDialog(false);
    }
    if (call.success === true) {
      setKeyLoading(true);

      httpclient
        .post(`new-system/order/cancel-order-item`, {
          orderID: props.viewDetails.newSystemOrderID,
          productID: [row.orderproductID],
          reason: reason,
          reasonCode: reasonCode,
          lineItemCancel: true,
        })
        .then(({ data }) => {
          if (data.success) {
            setOpen(true);
            setMessageState("success");
            //setOpenActiveDialog(false);
            setMessage(data.message);
            setKeyLoading(false);
            //Refetching the data
            //handleRefetch();
            setProductDetails("");
            setTimeout(() => {
              props.handleRefetch();
            }, 2000);
          } else {
            setKeyLoading(false);
            setOpen(true);
            setMessageState("error");
            setMessage(data.error);
          }
        });
    }
  };
  // console.log('ids', rowCheckedID);
  // console.log('check val', selectedChecked);

  // console.log('row is', props.rows)

  const handleManualProcess = () => {
    setOpenProcessDialog(true);
  };

  const sendToProcess = (call) => {
    if (call.open === false) {
      setOpenProcessDialog(false);
    }
    if (call.success === true) {
      setKeyLoading1(true);
      httpclient
        .post(`new-system/order/ignore-order`, {
          orderID: [props.viewDetails.orderID],
        })
        .then(({ data }) => {
          if (data.success) {
            setOpen(true);
            setMessageState("success");
            //setOpenActiveDialog(false);
            setMessage(data.message);
            setKeyLoading1(false);
            //Refetching the data
            setProductDetails("");
            setTimeout(() => {
              props.handleRefetch();
            }, 2000);

            //setProductDetails("");
          } else {
            setKeyLoading1(false);
            setOpen(true);
            setMessageState("error");
            setMessage(data.error);
          }
        });
    }
  };

  const sendToReProcess = (call) => {
    if (call.open === false) {
      setOpenReProcessDialog(false);
    }
    if (call.success === true) {
      setKeyLoadingRe(true);
      const apipoint =

        `temple-webster/order-reassign/${props.viewDetails.newSystemOrderID}`;
      httpclient
        .post(apipoint)
        .then(({ data }) => {
          if (data.success) {
            setOpen(true);
            setMessageState("success");
            //setOpenActiveDialog(false);
            setMessage(data.message);
            setKeyLoadingRe(false);
            //Refetching the data
            setProductDetails("");
            setTimeout(() => {
              props.handleRefetch();
            }, 2000);

            //setProductDetails("");
          } else {
            setKeyLoadingRe(false);
            setOpen(true);
            setMessageState("error");
            setMessage(data.error);
          }
        });
    }
  };
  // console.log("------------", props.viewDetails.newSystemOrderID)
  //console.log("------------", productDetails.orderproductID)
  //console.log("props >>>", props.viewDetails.assignToBranch.length);

  function accept(props) {
    if (
      props.viewDetails.company.multiLoc_desp_per_po === "0" &&
      props.viewDetails.orderProduct.some(
        (product) => product.assignToBranch.length > 1
      )
    ) {
      return true;
    } else {
      return false;
    }
  }
  function validstate(props) {
    if (
      props.shippitDetails[props.shippitDetails.length - 1]?.state ===
      "order_placed" ||
      props.shippitDetails[props.shippitDetails.length - 1]?.state ===
      "despatch_in_progress" ||
      props.shippitDetails[props.shippitDetails.length - 1]?.state ===
      "ready_for_pickup" ||
      props.shippitDetails[props.shippitDetails.length - 1]?.state ===
      "PlainLabelCarrier"
    ) {
      return true;
    } else {
      return false;
    }
  }

  
  return (
    <TableContainer
      component={Paper}
      style={{ overflowY: "auto", height: "380px" }}
    >
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <>
          {/* {props.orderStatus === "Accepted" ? (
            <span className="group">
              <caption>Ready to be Invoiced? </caption> <span className="no">No</span>
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    sx={{ m: 1 }}
                    checked={(invoiceStatus === "ready" || invoiceStatus === "invoiced" || invoiceStatus === "change-price-invoiced") ? true : false}
                    disabled={(invoiceStatus === "invoiced" || invoiceStatus === "change-price-invoiced" || disabledButton) ? true : false}
                    value={invoiceStatus}
                    onChange={(event) => {
                      const status = event.target.checked ? "ready" : "pending"; // Set the status based on switch state
                      setInvoiceStatus(status);
                      //changeInvoiceStatus(productDetails.orderproductID, status); // Call the API with the selected status
                    }}
                  />
                }
                label="Yes"
              />

              {keyLoading ? (
                <CircularProgress
                  style={{
                    height: "25px",
                    width: "25px",
                    marginLeft: "10px",
                    position: "relative",
                    top: "10px",
                  }}
                />
              ) : (
                <>
                  <Button variant="contained"

                    color="primary"
                    onClick={() => {
                      if (invoiceStatus === "ready" && !disabledButton) { // Check whether the button has not been clicked yet
                        setDisabledButton(true); // Set the disabledButton state variable to true
                        changeInvoiceStatus(productDetails.orderproductID);
                      }
                    }}

                    disableElevation
                    disabled={disabledButton || invoiceStatus === "invoiced" || invoiceStatus === "change-price-invoiced"
                      ||
                      (props.viewDetails.invoiceStatus === "ready" &&
                        invoiceStatus === props.viewDetails.invoiceStatus)
                    }
                  // Disable the button when invoiceStatus is "invoiced"
                  >
                    Accept
                  </Button>
                </>
              )}


            </span>

          ) :
            // !(props.orderStatus === "Accepted" ||
            //   props.orderStatus === "Rejected" ||
            //   props.orderStatus === "Cancelled") &&
            //   props.reportType !== "exceptionalOrder" ?
            //   (<span className="group">
            //     <Button
            //       color="primary"
            //       size="small"
            //       variant="contained"
            //       onClick={() => handleAccept()}
            //       disabled={buttonLoader}
            //     >
            //       Accept Order{" "}
            //       <Check
            //         fontSize="small"
            //         style={{ marginLeft: "5px", top: "0" }}
            //       />
            //     </Button>
            //     <Button
            //       color="error"
            //       size="small"
            //       variant="contained"
            //       onClick={() => handleReject()}
            //       style={{ marginLeft: "10px" }}
            //       disabled={buttonLoader}
            //     >
            //       Reject Order{" "}
            //       <Clear
            //         fontSize="small"
            //         style={{ marginLeft: "5px", top: "0" }}
            //       />
            //     </Button>
            //     {buttonLoader && (
            //       <CircularProgress
            //         style={{
            //           marginLeft: "10px",
            //           position: "relative",
            //           top: "5px",
            //           width: "25px",
            //           height: "25px",
            //         }}
            //       />
            //     )}
            //   </span>)
            //   :
             null

          } */}
        </>

        <TableHead>
          <TableRow>
            {props.columns.map((col) => (
              <TableCell style={{ fontWeight: "700", whiteSpace: "nowrap" }}>
                {/* whiteSpace: "nowrap"  */}
                {col.name}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {props.rows.length > 0 ? (
          <TableBody>
            {props.rows.map((row) => {
              var allStockTotal = 0;
              var branchNames = "";
              var branchNamesNetsuite = "";
              if (row.stock_on_hand && row.stock_on_hand[5]) {
                var newStockArr = Object.values(row.stock_on_hand);
                newStockArr.map((stock, index) => {
                  return (allStockTotal = allStockTotal + stock.actualSOH);
                });
              }

              branchNames =
                row.assignToBranch.length > 0 &&
                row.assignToBranch?.map(
                  (branch, index) =>
                    branch.branch?.ciBranchName +
                    (row.assignToBranch.length === index + 1 ? "" : ", ")
                );

              branchNamesNetsuite =
                row.assignToBranch.length > 0 &&
                row.assignToBranch?.map(
                  (branch, index) =>
                    branch.branch?.branchName +
                    (row.assignToBranch.length === index + 1 ? "" : ", ")
                );

              return (
                <TableRow
                  key={row.name}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    cursor: "pointer",
                    backgroundColor: row.is_tw_product_process === 2 ? "#ff9693" : "",
                  }}
                
                >
                  {props.columns.map((col, index) =>
                    col.id === "assignedBranch" ? (
                      <StyledTableCell component="th" scope="row">
                        {!showBranchEdit ? (
                          <span>
                            {branchNames}
                            {!(
                              props.orderStatus === "Accepted" ||
                              props.orderStatus === "Pre-Sales" ||
                              props.orderStatus === "Rejected" ||
                              props.orderStatus === "Cancelled" ||
                              props.orderStatus === "Ignored"
                            ) ? (
                              <IconButton
                              disabled
                              // disabled={row.is_tw_product_process === 2}
                                onClick={() => {
                                  setShowBranchEdit(true);
                                  setShowPriceEdit(false);
                                  setCurrentBranchID(row.orderproductID);
                                  setLineBranchName(
                                    row.assignToBranch[0].branch.eID
                                  );
                                }}
                              >
                                <Edit fontSize="small" color="primary" />
                              </IconButton>
                            ) : null}
                          </span>
                        ) : row.orderproductID !== currentBranchID ? (
                          <span>
                            {branchNames}
                            <IconButton
                            disabled
                            // disabled={row.is_tw_product_process === 2}
                              onClick={() => {
                                setShowBranchEdit(true);
                                setShowPriceEdit(false);

                                setCurrentBranchID(row.orderproductID);
                                setLineBranchName(
                                  row.assignToBranch[0].branch.eID
                                );
                              }}
                            >
                              <Edit fontSize="small" color="primary" />
                            </IconButton>
                          </span>
                        ) : (
                          <Box display={"flex"} flexDirection="row">
                            <FormControl
                              style={{ width: "150px", marginBottom: "10px" }}
                            >
                              <Select
                                value={lineBranchName}
                                onChange={(e) =>
                                  setLineBranchName(e.target.value)
                                }
                                name="lineBranchName"
                              >
                                <MenuItem value={""}>
                                  <em>Select Branch</em>
                                </MenuItem>
                                {row.stock_on_hand.length > 0 &&
                                  row.stock_on_hand.map((stock) => (
                                    <MenuItem
                                      value={stock.new_system_branch.eID}
                                      key={stock.new_system_branch.eID}
                                    >
                                      {stock.new_system_branch.ciBranchName}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>

                            {branchLoading ? (
                              <CircularProgress
                                style={{
                                  height: "25px",
                                  width: "25px",
                                  marginLeft: "10px",
                                  position: "relative",
                                  top: "10px",
                                }}
                              />
                            ) : (
                              <Box display={"flex"} flexDirection="column">
                                <Tooltip title="Back">
                                  <Button
                                    variant="contained"
                                    size="small"
                                    color="primary"
                                    onClick={() => setShowBranchEdit(false)}
                                    sx={{
                                      marginLeft: "10px",
                                      marginBottom: "5px",
                                    }}
                                  >
                                    <ArrowBack fontSize="small" />
                                  </Button>
                                </Tooltip>
                                <Tooltip title="Change Branch">
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    onClick={() => handleChangeLineBranch(row)}
                                    sx={{ marginLeft: "10px" }}
                                  >
                                    <Done fontSize="small" />
                                  </Button>
                                </Tooltip>
                              </Box>
                            )}
                          </Box>
                        )}
                      </StyledTableCell>
                    ) :
                      col.id === "assignedBranchNetsuite" ? (
                        <StyledTableCell component="th" scope="row">
                          {!showBranchEdit ? (
                            <span>
                              {branchNamesNetsuite}
                              {!(
                                props.orderStatus === "Accepted" ||
                                props.orderStatus === "Pre-Sales" ||
                                props.orderStatus === "Rejected" ||
                                props.orderStatus === "Cancelled" ||
                                props.orderStatus === "Ignored"
                              ) ? (
                                <IconButton
                                disabled
                                  onClick={() => {
                                    setShowBranchEdit(true);
                                    setShowPriceEdit(false);
                                    setCurrentBranchID(row.orderproductID);
                                    setLineBranchName(
                                      row.assignToBranch[0].branch.erpBranchID
                                    );
                                  }}
                                >
                                  <Edit fontSize="small" color="primary" />
                                </IconButton>
                              ) : null}
                            </span>
                          ) : row.orderproductID !== currentBranchID ? (
                            <span>
                              {branchNamesNetsuite}
                              <IconButton
                              disabled
                                onClick={() => {
                                  setShowBranchEdit(true);
                                  setShowPriceEdit(false);

                                  setCurrentBranchID(row.orderproductID);
                                  setLineBranchName(
                                    row.assignToBranch[0].branch.erpBranchID
                                  );
                                }}
                              >
                                <Edit fontSize="small" color="primary" />
                              </IconButton>
                            </span>
                          ) : (
                            <Box display={"flex"} flexDirection="row">
                              <FormControl
                                style={{ width: "150px", marginBottom: "10px" }}
                              >
                                <Select
                                  value={lineBranchName}
                                  onChange={(e) =>
                                    setLineBranchName(e.target.value)
                                  }
                                  name="lineBranchName"
                                >
                                  <MenuItem value={""}>
                                    <em>Select Branch</em>
                                  </MenuItem>
                                  {row.stock_on_hand.length > 0 &&
                                    row.stock_on_hand.map((stock) => (
                                      <MenuItem
                                        value={stock.new_system_branch.erpBranchID}
                                        key={stock.new_system_branch.erpBranchID}
                                      >
                                        {stock.new_system_branch.branchName}
                                      </MenuItem>
                                    ))}
                                </Select>
                              </FormControl>

                              {branchLoading ? (
                                <CircularProgress
                                  style={{
                                    height: "25px",
                                    width: "25px",
                                    marginLeft: "10px",
                                    position: "relative",
                                    top: "10px",
                                  }}
                                />
                              ) : (
                                <Box display={"flex"} flexDirection="column">
                                  <Tooltip title="Back">
                                    <Button
                                      variant="contained"
                                      size="small"
                                      color="primary"
                                      onClick={() => setShowBranchEdit(false)}
                                      sx={{
                                        marginLeft: "10px",
                                        marginBottom: "5px",
                                      }}
                                    >
                                      <ArrowBack fontSize="small" />
                                    </Button>
                                  </Tooltip>
                                  <Tooltip title="Change Branch">
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      size="small"
                                      onClick={() => handleChangeLineBranch(row)}
                                      sx={{ marginLeft: "10px" }}
                                    >
                                      <Done fontSize="small" />
                                    </Button>
                                  </Tooltip>
                                </Box>
                              )}
                            </Box>
                          )}
                        </StyledTableCell>
                      ) : col.id === "cancel" ? (
                        <StyledTableCell align="left" style={{ zIndex: "1" }}>
                          {row.status === "accepted" ||
                            row.status === "Accepted" ? (
                            // keyLoading ? (
                            //   <CircularProgress
                            //     style={{
                            //       height: "25px",
                            //       width: "25px",
                            //       marginLeft: "10px",
                            //       position: "relative",
                            //       top: "10px",
                            //     }}
                            //   />
                            // ) : (
                            <>
                              <Button
                                variant="contained"
                                color="error"
                                onClick={() => handleCancelProductDialogue(row)}
                                sx={{ padding: "5px" }}
                                // disabled={productDetails.status === "cancelled"}
                                disabled={keyLoading || !validstate(props)}
                              >
                                cancel
                                <HighlightOff
                                  fontSize="small"
                                  style={{ marginLeft: "3px" }}
                                />
                              </Button>
                            </>
                          ) : // )
                            null}
                        </StyledTableCell>
                      )
                        : col.id === "confirm" ? (
                         
                          <StyledTableCell align="left" style={{ zIndex: "1" }}>
                             {row.is_tw_product_process === 1 ? (
                                  <Tooltip title="This order line is accepted">
                                    <TaskAlt style={{ marginLeft: "25px", top: "0" }} fontSize="small" color="success" />
                                  </Tooltip>
                                ) : row.is_tw_product_process === 2 ? (
                                  <Tooltip title="This order line is cancelled">
                                    <HighlightOff style={{ marginLeft: "25px", top: "0" }} fontSize="small" color="error" />
                                  </Tooltip>
                                )
                          : row.is_tw_product_process === 0 && (
                            <>
                            <IconButton
                            disabled
                              color="success"
                              size="medium"
                              variant="contained"
                              onClick={() => handleAccept(row)}
                              // disabled={
                              //   (featureServer && props.viewDetails.exoOrderNumber === null) ||
                              //   (!(
                              //     row.reasonCode === "3" ||
                              //     row.reasonCode === "4" ||
                              //     row.reasonCode === "5" ||
                              //     row.reasonCode === "12"
                              //   ) &&
                              //     row.reasonCode !== "1" &&
                              //     row.reportType === "exceptionalOrder") ||
                              //     accept(props)
                              //     ? true
                              //     : false
                              // }
                            >
                              
                              <Check
                                fontSize="medium"
                                style={{ marginLeft: "5px", top: "0" }}
                              />
                            </IconButton>

                            <IconButton
                            disabled
                              color="error"
                              size="medium"
                              variant="contained"
                              onClick={() => handleReject(row)}
                              style={{ marginLeft: "10px" }}
                              // disabled={
                              //   (featureServer && props.viewDetails.exoOrderNumber === null)
                              // }
                            >
                             
                              <Clear
                                fontSize="medium"
                                style={{ marginLeft: "5px", top: "0" }}
                              />
                            </IconButton>
                            </>
                          )}

                          </StyledTableCell>
                          
                        ) : col.id === "checkColumn" ? (
                          <StyledTableCell align="left" style={{ zIndex: "1" }}>
                            {props.orderStatus === "Accepted" ||
                              props.orderStatus === "Pre-Sales" ||
                              props.orderStatus === "Rejected" ||
                              props.orderStatus === "Cancelled" ||
                              props.reportType !== "exceptionalOrder" ? (
                              ""
                            ) : (
                              <Radio
                                onChange={(e) => handleRowCheck(e, row)}
                                value={
                                  selectedChecked[row.orderproductID] ? true : false
                                }
                                checked={
                                  selectedChecked[row.orderproductID] ? true : false
                                }
                                disabled={
                                  (!(
                                    row.reasonCode === "3" ||
                                    row.reasonCode === "4" ||
                                    row.reasonCode === "5" ||
                                    row.reasonCode === "12"
                                  ) &&
                                    row.reasonCode !== "1" &&
                                    row.reportType === "exceptionalOrder") ||
                                    accept(props)
                                    ? true
                                    : false
                                }
                                sx={{
                                  color: blue[800],
                                  "&.Mui-checked": {
                                    color: blue[600],
                                  },
                                }}
                              />
                              //   <Checkbox
                              //   onChange={(e) => handleRowCheck(e, row)}
                              //   value = {selectedChecked[row.orderproductID] || (row.reportType !== "exceptionalOrder")}
                              //   checked={selectedChecked[row.orderproductID] || (row.reportType !== "exceptionalOrder")}

                              //   sx={{
                              //     color: blue[800],
                              //     '&.Mui-checked': {
                              //       color: blue[800],
                              //     },
                              //   }}

                              // />
                            )}
                          </StyledTableCell>
                        ) : col.id === "checkColumn1" ? (
                          <StyledTableCell align="left" style={{ zIndex: "1" }}>
                            {props.orderStatus === "Accepted" ||
                              props.orderStatus === "Pre-Sales" ||
                              props.orderStatus === "Rejected" ||
                              props.orderStatus === "Cancelled" ||
                              props.reportType !== "exceptionalOrder" ? (
                              ""
                            ) : (
                              // <Checkbox
                              //   onChange={(e) => handleRowCheck2(e, row)}
                              //   value = {selectedChecked2[row.orderproductID] || (row.reportType !== "exceptionalOrder")}
                              //   checked={selectedChecked2[row.orderproductID] || (row.reportType !== "exceptionalOrder")}
                              //   sx={{
                              //     color: red[800],
                              //     '&.Mui-checked': {
                              //       color: red[800],
                              //     },
                              //   }}
                              // />

                              <Radio
                                onChange={(e) => handleRowCheck2(e, row)}
                                value={
                                  selectedChecked2[row.orderproductID]
                                    ? true
                                    : false
                                }
                                checked={
                                  selectedChecked2[row.orderproductID]
                                    ? true
                                    : false
                                }
                                disabled={
                                  !(
                                    row.reasonCode === "3" ||
                                    row.reasonCode === "4" ||
                                    row.reasonCode === "5" ||
                                    row.reasonCode === "12"
                                  ) &&
                                    row.reasonCode !== "1" &&
                                    row.reportType === "exceptionalOrder"
                                    ? true
                                    : false
                                }
                                sx={{
                                  color: red[800],
                                  "&.Mui-checked": {
                                    color: red[600],
                                  },
                                }}
                              />
                            )}
                          </StyledTableCell>
                        ) : col.id === "invoicedPrice" ? (
                          <StyledTableCell component="th" scope="row">
                            {!showPriceEdit ? (
                              <span>
                                ${row.invoicedPrice}
                                {props.invoiceStatus === "pending" &&
                                  props.orderStatus !== "Cancelled" &&
                                  props.orderStatus !== "Ignored" ? (
                                  <IconButton
                                  disabled
                                  // disabled={row.is_tw_product_process === 2}
                                    onClick={() => {
                                      setShowPriceEdit(true);
                                      setShowBranchEdit(false);
                                      setCurrentInvoiceID(row.orderproductID);
                                      setLineInvoicePrice(row.invoicedPrice);
                                    }}
                                  >
                                    <Edit fontSize="small" color="primary" />
                                  </IconButton>
                                ) : null}
                              </span>
                            ) : row.orderproductID !== currentInvoiceID ? (
                              <span>
                                ${row.invoicedPrice}
                                <IconButton
                                disabled
                                // disabled={row.is_tw_product_process === 2}
                                  onClick={() => {
                                    setShowPriceEdit(true);
                                    setShowBranchEdit(false);
                                    setCurrentInvoiceID(row.orderproductID);
                                    setLineInvoicePrice(row.invoicedPrice);
                                  }}
                                >
                                  <Edit fontSize="small" color="primary" />
                                </IconButton>
                              </span>
                            ) : (
                              <Box display={"flex"} flexDirection="row">
                                <TextField
                                  name="lineInvoicePrice"
                                  variant="outlined"
                                  value={lineInvoicePrice}
                                  onChange={(e) =>
                                    setLineInvoicePrice(e.target.value)
                                  }
                                />
                                {invoiceLoading ? (
                                  <CircularProgress
                                    style={{
                                      height: "25px",
                                      width: "25px",
                                      marginLeft: "10px",
                                      position: "relative",
                                      top: "10px",
                                    }}
                                  />
                                ) : (
                                  <Box display={"flex"} flexDirection="column">
                                    <Tooltip title="Back">
                                      <Button
                                        variant="contained"
                                        size="small"
                                        color="primary"
                                        onClick={() => setShowPriceEdit(false)}
                                        sx={{
                                          marginLeft: "10px",
                                          marginBottom: "5px",
                                        }}
                                      >
                                        <ArrowBack fontSize="small" />
                                      </Button>
                                    </Tooltip>
                                    <Tooltip title="Change Invoice Price">
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        onClick={() => handleChangeLinePrice(row)}
                                        sx={{ marginLeft: "10px" }}
                                      >
                                        <Done fontSize="small" />
                                      </Button>
                                    </Tooltip>
                                  </Box>
                                )}
                              </Box>
                            )}
                          </StyledTableCell>
                        ) : (
                          <TableCell
                            component="th"
                            scope="row"
                            onClick={() => props.handleView(row)}
                          >
                            {col.id === "erpBranchID" ? (
                              row.erpBranch.erpBranchID
                            ) : col.id === "erpBranchName" ? (
                              row.erpBranch.branchName
                            ) : col.id === "stockName" ? (
                              row.stock.ciStockName
                            ) : col.id === "stockNameNetsuite" ? (
                              row.stock.stockNameDefault
                            ) : //col.id === "created_at" ? <span> {moment(row[col.id]).format('ddd, MMM Do YYYY, h:mm:ss a')} </span> :

                              col.id === "quantity" ? (
                                <span>{row.quantity}</span>
                              ) : col.id === "unitPrice" ? (
                                <span>{"$" + row.unitPrice}</span>
                              ) : col.id === "actualPrice" ? (
                                <span>{"$" + row.price.ActualPrice}</span>
                              ) : col.id === "bestPrice" ? (
                                <span>{"$" + row[col.id]}</span>
                              ) : col.id === "shippingFee" ? (
                                <span>
                                  {props.viewDetails.orderType === "TW"
                                    ? "-"
                                    : `$ ${row[col.id]}`}
                                </span>
                              ) : col.id === "reason" ? (
                                row.reportType === "exceptionalOrder" ? (
                                  <span style={{ color: "red" }}>{row[col.id]}</span>
                                ) : (!branchNames || !branchNamesNetsuite) ? (
                                  <span style={{ color: "red" }}>
                                    {
                                      "The product has not been assigned to any branch."
                                    }
                                  </span>
                                ) : (
                                  <span>{row[col.id]}</span>
                                )
                              ) : col.id === "policyPrice" ? (
                                "$" + row.price_policy_of_stock.price
                              ) : col.id === "active" ? (
                                row.stock.Active === "Y" ? (
                                  <Check fontSize="small" color="primary" />
                                ) : (
                                  <Clear fontSize="small" color="error" />
                                )
                              ) : col.id === "status" ? (
                                row.status === "accepted" ||
                                  row.status === "Accepted" ? (
                                  <Tooltip title="This order line is accepted">
                                    <TaskAlt fontSize="small" color="success" />
                                  </Tooltip>
                                ) : row.status === "cancelled" ? (
                                  <Tooltip title="This order line is cancelled">
                                    <HighlightOff fontSize="small" color="error" />
                                  </Tooltip>
                                ) : (
                                  <Tooltip title="This order line is rejected">
                                    <HighlightOff fontSize="small" color="error" />
                                  </Tooltip>
                                )
                              ) : col.id === "labelPDF" ? (
                                row.state === "processing" ||
                                  row.state === "dispatch_in_progress" ? null : row[ // <Skeleton variant="circular" width={40} height={40} />
                                    col.id
                                  ] ? (
                                  <IconButton
                                    href={row[col.id]}
                                    download
                                    target="_blank"
                                  >
                                    <DownloadIcon color="primary" fontSize="medium" />
                                  </IconButton>
                                ) : (
                                  // <Skeleton variant="circular" width={40} height={40} />
                                  <span className="blink">Generating PDF...</span>
                                )
                              ) : col.id === "state" ? (
                                row[col.id] === "processing" ? (
                                  <span className="blink">Processing</span>
                                ) : row[col.id] === "dispatch_in_progress" ? (
                                  <span className="blink">Dispatch In Progress</span>
                                ) : row[col.id] === "ready_for_pickup" ? (
                                  "Ready For Pickup"
                                ) : row[col.id] ? (
                                  row[col.id]
                                    .replace(/_/g, " ")
                                    .replace(/\b\w/g, (c) => c.toUpperCase())
                                ) : null
                              ) : col.id === "created_at" ? (
                                row[col.id] !== "0000-00-00 00:00:00" ? (
                                  row[col.id] !== null ? (
                                    moment(row[col.id]).format(
                                      "ddd, MMM Do YYYY, h:mm:ss a"
                                    )
                                  ) : (
                                    "-"
                                  )
                                ) : (
                                  "-"
                                )
                              ) : col.id === "shippitModifiedDate" ? (
                                row.state === "processing" ? null : row[col.id] ? (
                                  moment(row[col.id]).format(
                                    "ddd, MMM Do YYYY, h:mm:ss a"
                                  )
                                ) : null
                              ) : col.id === "delivery_address" ? (
                                `${row.delivery_address}, ${row.delivery_suburb}, ${row.delivery_state} ${row.delivery_postcode}`
                              ) : (
                                row[col.id]
                              )}
                          </TableCell>
                        )
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        ) : (
          <TableBody>
            <TableRow style={{ position: "relative", width: "100%" }}>
              <Box
                textAlign="center"
                style={{ position: "absolute", right: "50%" }}
                mt={3}
              >
                No records Available.
              </Box>
            </TableRow>
          </TableBody>
        )}
      </Table>

      {props.orderStatus === "Accepted" ||
        props.orderStatus === "Pre-Sales" ||
        props.orderStatus === "Rejected" ||
        props.orderStatus === "Cancelled" ||
        props.orderStatus === "Ignored" ||
        props.viewDetails.orderType === "TW" ||
        props.reportType !== "exceptionalOrder" ? (
        ""
      ) : (
        <Box style={{ position: "absolute", bottom: "40px" }}>
          {keyLoading ? (
            <CircularProgress
              style={{
                height: "25px",
                width: "25px",
                marginLeft: "20px",

                position: "relative",
                //top: "10px",
              }}
            />
          ) : (
            <Button
              onClick={handeUpdateStatus}
              variant="contained"
              color="primary"
              disabled
              // disabled={(featureServer && props.viewDetails.exoOrderNumber === null)}
            >
              Process Order
            </Button>
          )}
        </Box>
      )}

      {props.viewDetails.orderType === "TW" && props.orderStatus !== "Cancelled" && props.orderStatus !== "Accepted"  && props.orderStatus !== "Pre-Sales" &&
        // (
        //   props.viewDetails.orderProduct.some((or) => or.reasonCode === "11") ||
        //   props.viewDetails.orderProduct.some((or) => or.reasonCode === "13")
        // ) 
       
        props.reportType === "exceptionalOrder" ?
        (
          <Box style={{ position: "absolute", bottom: "40px", left: "46%" }}>
            {keyLoading ? (
              <CircularProgress
                style={{
                  height: "25px",
                  width: "25px",
                  marginLeft: "20px",

                  position: "relative",
                  //top: "10px",
                }}
              />
            ) : (
              <Button
                onClick={handleReProcess}
                variant="contained"
                color="primary"
                disabled
                // disabled={(featureServer && props.viewDetails.exoOrderNumber === null)}
              >
                <WifiProtectedSetup
                  fontSize="small"
                  style={{ marginRight: "5px" }}
                />
                Recheck Order Flow
              </Button>
            )}
          </Box>
        ) : ("")}

      {props.orderStatus === "Received" && (
        <Box style={{ position: "absolute", bottom: "43px", left: "86%" }}>
          {keyLoading1 ? (
            <CircularProgress
              style={{
                height: "25px",
                width: "25px",
                marginLeft: "20px",

                position: "relative",
                //top: "10px",
              }}
            />
          ) : (
            <Button
            disabled
              onClick={handleManualProcess}
              variant="contained"
              color="primary"
            >
              <HourglassEmptyOutlined
                fontSize="small"
                style={{ marginRight: "5px" }}
              />
              Manual Process
            </Button>
          )}
        </Box>
      )}

      {openProcessDialog && (
        <ProcessDialog
          sendToProcess={sendToProcess}
          viewDetails={props.viewDetails}
        />
      )}
      {openReProcessDialog && (
        <ReProcessDialog
          sendToProcess={sendToReProcess}
          viewDetails={props.viewDetails}
        />
      )}
      {openCancelProductDialog && (
        <CancelProductDialog
          sendToCancelProduct={sendToCancelProduct}
          viewDetails={props.viewDetails}
          row={rowValue} // Pass the row value as a prop
        />
      )}

      {openRejectDialog && (
        <RejectDialog
          sendToCancel={sendToCancel}
          viewDetails={props.viewDetails}
          rowChecked={[...new Set(rowCheckedID2)]}
          rowChecked1={[...new Set(rowCheckedID)]}
        />
      )}

      {openStatusDialog && (
        <StatusDialog
          sendChangeOrder={sendChangeOrder}
          status={acceptStatus}
          viewDetails={props.viewDetails}
        //selectedChecked={[]}
        />
      )}

      {showAssignBranchDialog && (
        <AssignedDialog sendAssignBranch={sendAssignBranch} />
      )}

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={handleCloseSnack}
      >
        <Alert
          onClose={handleCloseSnack}
          severity={messageState}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </TableContainer>
  );
}
