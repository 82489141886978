import { Button, Grid } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import PATH from "../../Routing/PATHS";

const ExcelMenu = ({ navigateState, hideMenu }) => {
  const navigate = useNavigate();
  const { startDate, fileName } = navigateState;
  return (
    <Grid item xs={12}>
      {hideMenu !== 1 && (
        <Button
          variant="text"
          sx={{ margin: 0, marginLeft: "16px", padding: 0 }}
          onClick={() => {
            navigate(PATH.REPLENISHMENT_HISTORY_SOH, {
              state: {
                startDate: startDate,
                fileName: fileName,
              },
            });
          }}
        >
          Open SOH History
        </Button>
      )}
      {hideMenu !== 2 && (
        <Button
          variant="text"
          sx={{ margin: 0, marginLeft: "16px", padding: 0 }}
          onClick={() => {
            navigate(PATH.REPLENISHMENT_PIPELINE, {
              state: {
                startDate: startDate,
                fileName: fileName,
              },
            });
          }}
        >
          Open Pipeline
        </Button>
      )}
      {hideMenu !== 3 && (
        <Button
          variant="text"
          sx={{ margin: 0, marginLeft: "16px", padding: 0 }}
          onClick={() => {
            navigate(PATH.REPLENISHMENT_STORESOH, {
              state: {
                startDate: startDate,
                fileName: fileName,
              },
            });
          }}
        >
          Open Store SOH
        </Button>
      )}
      {hideMenu !== 4 && (
        <Button
          variant="text"
          sx={{ margin: 0, marginLeft: "16px", padding: 0 }}
          onClick={() => {
            navigate(PATH.REPLENISHMENT_STORESALES, {
              state: {
                startDate: startDate,
                fileName: fileName,
              },
            });
          }}
        >
          Open Store Sales
        </Button>
      )}
      {hideMenu !== 5 && (
        <Button
          variant="text"
          sx={{ margin: 0, marginLeft: "16px", padding: 0 }}
          onClick={() => {
            navigate(PATH.REPLENISHMENT_MSLLEVEL, {
              state: {
                startDate: startDate,
                fileName: fileName,
              },
            });
          }}
        >
          Open MSL Level
        </Button>
      )}
    </Grid>
  );
};

export default ExcelMenu;
