import { CloudUpload, Download } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import React from "react";

import { dateFormatter } from "../../../../utils/DataGrid/dateFormatter";
import UploadOrderTable from "./UploadOrderTable";

const fieldHeaderPairs = [
  { field: "ID" },
  {
    field: "uploadedDate",
    headerName: "Uploaded Date",

    valueFormatter: (params) => dateFormatter(params),
  },
  {
    field: "fileName",
    headerName: "File Name",
  },
  {
    field: "filePath",
    headerName: "Download File",

    renderCell: (cellValues) => (
      <>
        <Button href={cellValues.row.filePath}>
          <Download />
        </Button>
      </>
    ),
  },
];

const filterColumn = [
  {
    field: "startDate",
    label: "Start Date",
    component: "TextField",
    type: "date",
  },
  {
    field: "endDate",
    label: "End Date",
    component: "TextField",
    type: "date",
  },
];

const NetSuiteUpload = () => {
  return (
    <>
      <Box p={3}>
        <UploadOrderTable
          headerTitle="List Upload CSV"
          uploadFeature={true}
          netsuite={true}
          customIDKey="ID"
          columns={fieldHeaderPairs}
          filterColumn={filterColumn}
          pageUrl="/temple-webster/netsuite-order-file"
        />
      </Box>
    </>
  );
};

export default NetSuiteUpload;
