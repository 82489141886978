import { RemoveRedEye } from "@mui/icons-material";
import {
    Box,
    Button,
    Card,
    Checkbox,
    CircularProgress,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    Snackbar,
    styled,
    TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { httpclient } from "../../../../utils/httpClient";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Header = styled("div")(({ theme }) => ({
    "& h1": {
        color: theme.palette.primary.dark,
    },
}));

const RootCard = styled(Card)(({ theme }) => ({
    padding: "40px",
    [theme.breakpoints.down("md")]: {
        padding: "20px",
    },
}));

const PasswordBox = styled(Box)(({ theme }) => ({
    position: "relative",
    "& svg": {
        cursor: "pointer",
        position: "absolute",
        right: "18px",
        top: "18px",
        color: "#999",
    },
}));

const AddUserModule = (props) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    var loginData = localStorage.getItem("login");
    var loginValue = JSON.parse(loginData);

    const [moduleDetails, setModuleDetails] = useState({
        name: "",
        modified_by: loginValue.userName,
        is_active: false,

    });

    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [messageState, setMessageState] = useState("");
    const [loading, setLoading] = useState(false);

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    useEffect(() => {

        getSingleModule();

    }, []);


    //console.log('modulelist', moduleList);
    //console.log('loc', location);
    // const handleChange1 = (event) => {
    //   const {
    //     target: { value },
    //   } = event;
    //   setUserModule(
    //     // On autofill we get a stringified value.
    //     typeof value === 'string' ? value.split(',') : value,
    //   );
    // };

    const getSingleModule = () => {
        id &&
            httpclient.get(`modules/${id}`).then(({ data }) => {
                if (data.success) {
                    //console.log("user-success", data);
                    setModuleDetails({
                        name: data.data.name,
                        modified_by: moduleDetails.modified_by,
                        is_active: data.data.is_active === 1 ? true : false,
                    });
                }
            });
    };

    //console.log("user dteails", moduleDetails);

    // const handleChange = (e) => {
    //   const { name, value } = e.target;
    //   setModuleDetails({
    //     ...moduleDetails,
    //     [name]: value,
    //   });
    // };
    const handleChange = (e) => {
        //console.log("changed", e )
        const { name, value } = e.target;



        setModuleDetails({
            ...moduleDetails,
            [name]: value,
        });


    };

    const handleCheck = (e) => {
        const { name, checked } = e.target;
        setModuleDetails({
            ...moduleDetails,
            [name]: checked,
        });
    };

    const handleBack = () => {
        navigate(-1);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        !id
            ? httpclient
                .post("modules", {
                    name: moduleDetails.name,
                    modified_by: moduleDetails.modified_by,
                    is_active: moduleDetails.is_active === true ? 1 : 0,

                })
                .then(({ data }) => {
                    if (data.success) {
                        setOpen(true);
                        setMessageState("success");
                        setMessage(data.message);
                        setLoading(false);
                        setTimeout(() => {
                            navigate("/modules");
                        }, [1500]);
                    } else {
                        setLoading(false);
                        setOpen(true);
                        setMessageState("error");
                        setMessage(typeof (data.error) === "string" ? data.error : data.error[0]);
                    }
                })
            :

            httpclient
                .post(`modules/${id}`, {
                    name: moduleDetails.name,
                    modified_by: moduleDetails.modified_by,
                    is_active: moduleDetails.is_active === true ? 1 : 0,
                })
                .then(({ data }) => {
                    if (data.success) {
                        setOpen(true);
                        setMessageState("success");
                        setMessage(data.message);
                        setLoading(false);
                        setTimeout(() => {
                            navigate("/modules");
                        }, [1500]);
                    } else {
                        setLoading(false);
                        setOpen(true);
                        setMessageState("error");
                        setMessage(typeof (data.error) === "string" ? data.error : data.error[0]);
                    }
                });
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };
    console.log("user-details", moduleDetails);
    return (
        <div>
            <Header>
                <h1>{id ? "Edit Module" : "Add Module"}</h1>
            </Header>
            <RootCard>
                <form onSubmit={(e) => handleSubmit(e)}>
                    <Grid container spacing={2}>
                        {id &&
                        <Grid item xs={12} md={12}>
                            <TextField
                                variant="outlined"
                                name="modified_by"
                                type="text"
                                label={id ? "Modified By" : "Added By"}
                                value={moduleDetails.modified_by}
                                onChange={(e) => handleChange(e)}
                                sx={{ width: "100%" }}
                                required
                                disabled
                            />
                        </Grid>
                       } 

                        <Grid item xs={12} md={12}>
                            <TextField
                                variant="outlined"
                                name="name"
                                type="text"
                                label="Module Name"
                                value={moduleDetails.name}
                                onChange={(e) => handleChange(e)}
                                sx={{ width: "100%" }}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <FormControl>
                                <FormControlLabel
                                    control={<Checkbox value={moduleDetails.is_active} checked={moduleDetails.is_active ? true : false} onChange={handleCheck} name="is_active" />}
                                    label="Module Active ?"
                                // labelPlacement="top"
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <Box textAlign={"right"}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleBack}
                                    sx={{ marginRight: "10px" }}
                                >
                                    Back
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                // onClick={handleSubmit}
                                >
                                    Submit
                                </Button>
                                {loading && (
                                    <CircularProgress size={"small"} style={{ width: "25px", height: '25px', marginLeft: "10px", position: "relative", top: "10px" }} />
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </form>
            </RootCard>

            <Snackbar
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={open}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity={messageState}
                    sx={{ width: "100%" }}
                >
                    {message}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default AddUserModule;
