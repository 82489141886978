
import React, { useEffect, useState } from "react";
import { Skeleton } from "@mui/material";
import { httpclient } from "../../../utils/httpClient";

const title = [
  { id: "total_stocks", name: "Total Stocks" },
  { id: "sync_pending", name: "Sync Pending" },
  { id: "sync_completed", name: "Sync Completed" },
  { id: "synced_today", name: "Synced Today" },
  { id: "synced_yesterday", name: "Synced Yesterday" },
  { id: "synced_7days", name: "Synced 7 days" },
];

const DashboardGrid1 = () => {
  const [loading, setLoading] = useState(true);
  const [productStatus, setProductStatus] = useState({});

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(fetchData, 60 * 1000); // Fetch data every 60 seconds
    return () => clearInterval(intervalId); // Cleanup the interval on component unmount
  }, []);


  const fetchData = () => {
    setLoading(true);
    httpclient
      .get(`realtime-status/current-product`)
      .then(({ data }) => {
        if (data.success) {
          setProductStatus(data.data);
          setLoading(false);
        } else {
          console.log("Error!");
        }
      }).catch(err => {
        if(err.response.status === 401){
          localStorage.clear();
          window.location = "/login";
        }
      })
  };

  const renderContent = () => {
    if (loading) {
      return (
        <div className="grid-block-content">
          {title.map((item) => (
            <>
              <div className="grid-item title">{item.name}</div>
              <div className="grid-item content">
                <Skeleton variant="text" />
              </div>
            </>
          ))}
        </div>
      );
    }

    return (
      <div className="grid-block-content">
        {title.map((item) => (
          <>
            <div className="grid-item title">{item.name}</div>
            <div className="grid-item content">{productStatus[item.id]}</div>
          </>
        ))}
      </div>
    );
  };

  return (

    <div className="grid-block">
      <div className="grid-block-header">
      NetSuite → SyncCare
      </div>
      {renderContent()}
      <style jsx>{`
        .grid-block {
          border: 0.5px solid #gray;
          box-shadow:0px 5px 20px 0px rgba(0,0,0,0.07);
          padding: 0px;
          background-color: #fff;
          border-radius: 5px;
          
         
        }

        .grid-block-header {
          font-size: 18px;
          font-weight: bold;
          padding:10px;
          margin-top: 0.3px;
          background-color: ${window.location.hostname === 'ubatemp.retailcare.com.au' ? "#9D182D" : "#3f51b5"} ;
          color: #fff;
          border-radius: 3px;        
          height: 100%;
          min-height:70px;
          display: flex; 
          align-items: center;
        }

        .grid-block-content {
          display: grid;
          width: 100%;
          grid-template-columns: 1fr auto;
          gap: 1px solid #ccc;
          font-family: "Trebechut" sans serief;
          font-size: 14px;
          font-weight: bold;
          
        }
        .grid-block-content-traffic {
          display: grid;
          width: 100%;
          grid-template-columns: 1fr 1fr 1fr auto;
          gap: 1px solid #ccc;
          font-family: "Trebechut" sans-serif;
          font-size: 14px;
          font-weight: bold;
          
        }
        

        .grid-item.title {
          border-right: 1px solid #f1f1f1;
          border-bottom: 1px solid #f1f1f1;
          background-color: #ffffff;
          padding: 16px;
        }
        .grid-item.content {
          border-bottom: 1px solid #f1f1f1;
          background-color: #ffffff;
          padding: 16px;
          min-width: 120px;
        }
        .grid-item.content1 {
          border-bottom: 1px solid #f1f1f1;
          background-color: #ffffff;
          padding: 16px;
          min-width: 120px;
        }
        .grid-item.content-traffic {
          border-bottom: 1px solid #f1f1f1;
          border-left: 1px solid #f1f1f1;
          background-color: #ffffff;
          padding: 14px;
          min-width: 120px;
         
        }

        
      `}</style>
    </div>
  );
};

export default DashboardGrid1;

