import React, { useEffect, useState, useRef } from "react";
import {
  ArrowForward,
  Check,
  Clear,
  Close,
  Download,
  FilterList,
} from "@mui/icons-material";
import TableComponent from "../TableComponent";
import { httpclient } from "../../../../utils/httpClient";
import {
  Box,
  Button,
  Card,
  Collapse,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  styled,
  TextField,
  Snackbar,
  Autocomplete,
} from "@mui/material";
import ViewOrderDialog from "../ViewOrderDialog";
import MuiAlert from "@mui/material/Alert";
import StatusDialog from "../StatusDialog";
import BackdropLoader from "../../../../Components/BackdropLoader";
import { useLocation, useNavigate } from "react-router";
import { CSVLink } from "react-csv";
import { dateFormatter } from "../../../../utils/DataGrid/dateFormatter";

//import { useLocation } from "react-router-dom";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// order_created = DSCO order created date
// lastUpdate = DSCO last update
// consumerOrderDate = Consumer order date

const columns = [
  { id: "checkColumn", name: " " },
  // { id: "newSystemOrderID", name: "DSCO Order Id" },
  { id: "newSystemOrderNumber", name: "PO Number" },
  { id: "netsuiteOrderNumber", name: "Netsuite Number" },
  { id: "company", name: "Customer Name" },
  { id: "billToName", name: "Consumer Name" },
  { id: "total_items", name: "Total Items" },
  { id: "orderTotalAmount", name: "Order Total" },
  { id: "order_created", name: "Order Date" },
  { id: "expectedDeliveryDate", name: "Despatch Date" },
  { id: "reportType", name: "Exception? " },
  //{ id: "lastUpdate", name: "DSCO Last Update" },
  // { id: "consumerOrderDate", name: "Consumer order date" },
  // { id: "shippitTrackingCode", name: "Tracking" },
  { id: "fullfillment_status", name: "Ready to fulfill" },
  { id: "invoiceStatus", name: "Invoice?" },
  { id: "shipment", name: "Shipment" },
  { id: "actions", name: "Order Confirmation" },
];

const FilteredBox = styled(Box)(({ theme }) => ({
  background: "#f9f9f9",
  padding: "5px 10px",
  borderRadius: "5px",
  "& p": {
    margin: "3px 0",
    marginRight: "10px",
    display: "inline-block",
    background: "#dedede",
    borderRadius: "10px",
    padding: "2px 5px",
  },
  "& svg": {
    fontSize: "15px",
    cursor: "pointer",
    position: "relative",
    top: "3px",
    background: theme.palette.primary.dark,
    color: "#fff",
    borderRadius: "50%",
    padding: "2px",
    marginLeft: "2px",
  },
}));

const Header = styled("div")(({ theme }) => ({
  "& h1": {
    color: theme.palette.primary.dark,
    margin: "0",
  },
}));

const configRowPerPage = JSON.parse(localStorage.getItem("configRowPerPage"));

const ListOrders = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const buttonRef = useRef(null);

  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [viewDetails, setViewDetails] = useState({});
  const [openStatusDialog, setOpenStatusDialog] = useState(false);
  const [customStatus, setCustomStatus] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [exceptionStatus, setExceptionStatus] = useState([]);
  const [rows, setRows] = useState([]);
  const [exportRows, setExportRows] = useState("");
  const [rowChecked, setRowChecked] = useState([]);
  const [company, setCompany] = useState([]);
  const [selectedChecked, setSelectedChecked] = useState([]);
  const [orderChecked, setOrderChecked] = useState([]);

  const [status, setStatus] = useState("");
  const [selected, setSelected] = useState([]);
  const [selected1, setSelected1] = useState([]);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [backdropLoader, setBackdropLoader] = useState(false);

  const [loading, setLoading] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [singleLoading, setSingleLoading] = useState(false);
  const [direction, setDirection] = useState(false);
  const [currentColumn, setCurrentColumn] = useState("");
  const [page, setPage] = useState(1);
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(
    configRowPerPage && configRowPerPage
      ? configRowPerPage && configRowPerPage
      : 20
  );

  const [rowsPerPage, setRowsPerPage] = useState(
    configRowPerPage && configRowPerPage
      ? configRowPerPage && configRowPerPage
      : 20
  );
  const [total, setTotal] = useState("");
  const [filterOpen, setFilterOpen] = useState(false);

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [messageState, setMessageState] = useState("");
  const [companyList, setCompanyList] = useState([]);
  const [exportFile, setExportFile] = useState(false);
  const [filterData, setFilterData] = useState({
    exceptionalOrder: "",
    newSystemOrderNumber: "",
    phoneNumber: "",
    shipment: "",
    order_status: "",
    invoiceStatus: "",
    customerName: "",
    soldTo: "",
    soldToName: "",
    customStatus: "",
    customStatusName: "",
    branchList: "",
    branchListName: "",
    exceptionStatus: "",
    exceptionStatusName: "",
    stockCode: "",
    startDate: "",
    endDate: "",
    despatchByStartDate: "",
    despatchByEndDate: "",
    netsuiteOrderNumber: "",
    isShippingLabelGenerate: "",
    remove: false,
  });

  const [submittedData, setSubmittedData] = useState({
    exceptionalOrder: "",
    newSystemOrderNumber: "",
    phoneNumber: "",
    shipment: "",
    order_status: "",
    customStatus: "",
    customStatusName: "",
    branchList: "",
    branchListName: "",
    exceptionStatus: "",
    exceptionStatusName: "",
    invoiceStatus: "",
    customerName: "",
    soldTo: "",
    soldToName: "",
    stockCode: "",
    netsuiteOrderNumber: "",
    startDate: "",
    endDate: "",
    despatchByStartDate: "",
    despatchByEndDate: "",
    isShippingLabelGenerate: "",
    submit: false,
  });
  let orderID = JSON.parse(localStorage.getItem("orderID"));

  useEffect(() => {
    getAllCompany();
  }, []);

  useEffect(() => {
    if (
      filterData.exceptionalOrder === "" &&
      filterData.newSystemOrderNumber === "" &&
      filterData.phoneNumber === "" &&
      filterData.order_status === "" &&
      filterData.customStatus === "" &&
      filterData.customStatusName === "" &&
      filterData.branchList === "" &&
      filterData.branchListName === "" &&
      filterData.exceptionStatus === "" &&
      filterData.exceptionStatusName === "" &&
      filterData.shipment === "" &&
      filterData.invoiceStatus === "" &&
      filterData.despatchByStartDate === "" &&
      filterData.despatchByEndDate === "" &&
      filterData.customerName === "" &&
      filterData.soldTo === "" &&
      filterData.soldToName === "" &&
      filterData.stockCode === "" &&
      filterData.startDate === "" &&
      filterData.endDate === "" &&
      filterData.isShippingLabelGenerate === "" &&
      filterData.netsuiteOrderNumber === ""
    ) {
      setSubmittedData({
        ...submittedData,
        submit: false,
      });
    }
    if (filterData.exceptionalOrder === " ") filterData.exceptionalOrder = "";
    if (filterData.newSystemOrderNumber === " ")
      filterData.newSystemOrderNumber = "";
    if (filterData.phoneNumber === " ") filterData.phoneNumber = "";
    if (filterData.startDate === " ") filterData.startDate = "";
    if (filterData.endDate === " ") filterData.endDate = "";
    if (filterData.despatchByStartDate === " ")
      filterData.despatchByStartDate = "";
    if (filterData.despatchByEndDate === " ") filterData.despatchByEndDate = "";
    if (filterData.customerName === " ") filterData.customerName = "";
    if (filterData.soldTo === " ") filterData.soldTo = "";
    if (filterData.soldToName === " ") filterData.soldToName = "";
    if (filterData.stockCode === " ") filterData.stockCode = "";
    if (filterData.shipment === " ") filterData.shipment = "";
    if (filterData.order_status === " ") filterData.order_status = "";
    if (filterData.customStatus === " ") filterData.customStatus = "";
    if (filterData.customStatusName === " ") filterData.customStatusName = "";
    if (filterData.branchList === " ") filterData.branchList = "";
    if (filterData.branchListName === " ") filterData.branchListName = "";
    if (filterData.exceptionStatus === " ") filterData.exceptionStatus = "";
    if (filterData.exceptionStatusName === " ") filterData.exceptionStatusName = "";
    if (filterData.invoiceStatus === " ") filterData.invoiceStatus = "";
    if (filterData.netsuiteOrderNumber === " ") filterData.netsuiteOrderNumber = "";
    if (filterData.isShippingLabelGenerate === " ") filterData.isShippingLabelGenerate = "";
 
    filterData.remove === true && handleFilter();
  }, [filterData]);

  useEffect(() => {
    let currentpolicy = JSON.parse(localStorage.getItem("orderlist1_filter"));
    currentpolicy !== null && setFilterData(currentpolicy);
    
    currentpolicy == null
      ? getOrders()
      : currentpolicy.exceptionalOrder == "" &&
        currentpolicy.newSystemOrderNumber == "" &&
        currentpolicy.phoneNumber == "" &&
        currentpolicy.order_status == "" &&
        currentpolicy.customStatus == "" &&
        currentpolicy.customStatusName == "" &&
        currentpolicy.branchList == "" &&
        currentpolicy.branchListName == "" &&
        currentpolicy.exceptionStatus == "" &&
        currentpolicy.exceptionStatusName == "" &&
        currentpolicy.invoiceStatus == "" &&
        currentpolicy.shipment == "" &&
        currentpolicy.startDate == "" &&
        currentpolicy.endDate == "" &&
        currentpolicy.despatchByStartDate == "" &&
        currentpolicy.despatchByEndDate == "" &&
        currentpolicy.stockCode == "" &&
        currentpolicy.soldTo == "" &&
        currentpolicy.soldToName == "" &&
        currentpolicy.customerName == "" &&
        currentpolicy.isShippingLabelGenerate == "" &&
        currentpolicy.netsuiteOrderNumber == "" &&
        currentpolicy.removed == false
        ? getOrders()
        : console.log("orders");
  }, []);

  useEffect(() => {
    if (
      location.state === "Accepted" ||
      location.state === "Rejected" ||
      location.state === "New" ||
      location.state === "Cancelled" ||
      location.state === "Manually"
    ) {
      filterData.order_status =
        location.state === "New"
          ? "Received"
          : location.state === "Manually"
            ? "Ignored"
            : location.state;
      setTimeout(() => {
        handleFilter();
        navigate("#", { replace: true });
      }, 1500);
    } else if (location.state === "Delivered") {
      filterData.shipment =
        location.state === "Delivered" ? "Shipped" : location.state;
      setTimeout(() => {
        handleFilter();
        navigate("#", { replace: true });
      }, 1500);
    } else if (location.state === "Invoiced") {
      filterData.invoiceStatus =
        location.state === "Invoiced" ? "invoiced" : location.state;
      setTimeout(() => {
        handleFilter();
        navigate("#", { replace: true });
      }, 1500);
    }
    setTimeout(() => {
      navigate("#", { replace: true });
    }, 1500);
  }, [
    location.state === "Accepted" ||
    location.state === "Rejected" ||
    location.state === "New" ||
    location.state === "Cancelled" ||
    location.state === "Delivered" ||
    location.state === "Invoiced" ||
    location.state === "Manually",
  ]);

  const getOrders = () => {
    setLoading(true);
    httpclient
      .get(`new-system/netsuite/exceptional-report?pagination=${rowsPerPage}&page=${page}`)
      .then(({ data }) => {
        if (data) {
          setRows(data.data);
          setCustomStatus(data.customOrderStatus);
          setBranchList(data.branchList);
          setExceptionStatus(data.exceptionStatus);
          setTotal(data.meta.total);
          setRowsPerPage(parseInt(data.meta.per_page));
          setPage(data.meta.current_page);
          setFrom(data.meta.from);
          setTo(data.meta.to);
          setLoading(false);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.clear();
          window.location = "/login";
        }
      });
  };

  const getAllCompany = () => {
    httpclient
      .get(`new-system/netsuite/company-account/all`)
      .then(({ data }) => {
        if (data.success) {
          setCompanyList(data.data);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.clear();
          window.location = "/login";
        }
      });
  };

  const handleView = (row) => {
    setSingleLoading(true);
    setOpenViewDialog(true);
    httpclient
      .get(`new-system/netsuite/exceptional-report/${row.orderID || row}`)
      .then(({ data }) => {
        if (data) {
          setViewDetails(data.data);
          setSingleLoading(false);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.clear();
          window.location = "/login";
        }
      });
  };

  useEffect(() => {
    if (orderID) {
      handleView(orderID);
      setTimeout(() => {
        localStorage.removeItem("orderID");
        localStorage.removeItem("view");
      }, 3000);
    }
  }, [orderID]);

  const sendDetails = (callback) => {
    if (callback.open === false) {
      setOpenViewDialog(false);
      setViewDetails({});
    }
    if (callback.refetch === true) {
      handleView(callback.orderID);
      setTimeout(() => {
        handleFilter();
      }, 1000);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "soldTo") {
      setFilterData({
        ...filterData,
        soldToName: value,
        remove: false,
      });
    }
    if (name === "customStatus") {
      setFilterData({
        ...filterData,
        customStatusName: value,
        remove: false,
      });
    }
    if (name === "branchList") {
      setFilterData({
        ...filterData,
        branchListName: value,
        remove: false,
      });
    }
    if (name === "exceptionStatus") {
      setFilterData({
        ...filterData,
        exceptionStatusName: value,
        remove: false,
      });
    }
  };

  const handleChangeSoldTo = (value) => {
    setFilterData({
      ...filterData,
      soldTo: value !== null ? value.id : "",
      soldToName: value !== null ? value.name : "",
      remove: false,
    });
  };

  const handleChangeCustom = (value) => {
    setFilterData({
      ...filterData,
      customStatus: value !== null ? value.id : "",
      customStatusName: value !== null ? value.name : "",
      remove: false,
    });
  };

  const handleChangeBranch = (value) => {
    setFilterData({
      ...filterData,
      branchList: value !== null ? value.id : "",
      branchListName: value !== null ? value.name : "",
      remove: false,
    });
  };

  const handleChangeException = (value) => {
    setFilterData({
      ...filterData,
      exceptionStatus: value !== null ? value.id : "",
      exceptionStatusName: value !== null ? value.name : "",
      remove: false,
    });
  };

  const handleFilter = () => {
    setSubmittedData({
      ...submittedData,
      exceptionalOrder: filterData.exceptionalOrder,
      newSystemOrderNumber: filterData.newSystemOrderNumber,
      phoneNumber: filterData.phoneNumber,
      order_status: filterData.order_status,
      customStatus: filterData.customStatus,
      customStatusName: filterData.customStatusName,
      branchList: filterData.branchList,
      branchListName: filterData.branchListName,
      exceptionStatus: filterData.exceptionStatus,
      exceptionStatusName: filterData.exceptionStatusName,
      invoiceStatus: filterData.invoiceStatus,
      shipment: filterData.shipment,
      startDate: filterData.startDate,
      endDate: filterData.endDate,
      despatchByStartDate: filterData.despatchByStartDate,
      despatchByEndDate: filterData.despatchByEndDate,
      customerName: filterData.customerName,
      stockCode: filterData.stockCode,
      soldTo: filterData.soldTo,
      soldToName: filterData.soldToName,
      netsuiteOrderNumber: filterData.netsuiteOrderNumber,
      isShippingLabelGenerate: filterData.isShippingLabelGenerate,
      submit: true,
    });

    filterData.remove = true;

    // const orderlistFilterNew = {
    //   ...filterData,
    //   order_status: "Recieved", // Set order_status to "new" for orderlist_filter_new
    // };
    localStorage.setItem("orderlist1_filter", JSON.stringify(filterData));
    // localStorage.setItem("orderlist_filter_new", JSON.stringify(orderlistFilterNew)); // Save orderlist_filter_new in localStorage

    setLoading(true);
    if (
      filterData.exceptionalOrder ||
      filterData.newSystemOrderNumber ||
      filterData.phoneNumber ||
      filterData.order_status ||
      filterData.customStatus ||
      filterData.customStatusName ||
      filterData.branchList ||
      filterData.branchListName ||
      filterData.exceptionStatus ||
      filterData.exceptionStatusName ||
      filterData.invoiceStatus ||
      filterData.shipment ||
      filterData.startDate ||
      filterData.endDate ||
      filterData.despatchByStartDate ||
      filterData.despatchByEndDate ||
      filterData.stockCode ||
      filterData.soldTo ||
      filterData.soldToName ||
      filterData.customerName ||
      filterData.isShippingLabelGenerate ||
      filterData.netsuiteOrderNumber
    ) {

      setExportRows("");
      httpclient
        .get(
          `new-system/netsuite/exceptional-report?reportType=${filterData.exceptionStatus
          }&newSystemOrderNumber=${filterData.newSystemOrderNumber
          }&order_status=${filterData.order_status}&branchID=${filterData.branchList}&custom_order_status=${filterData.customStatus}&isShippingLabelGenerate=${filterData.isShippingLabelGenerate}&shipment=${filterData.shipment
          }&billToName=${filterData.customerName}&soldTo=${filterData.soldTo
          }&phoneNumber=${filterData.phoneNumber}&stockCode=${filterData.stockCode
          }&invoiceStatus=${filterData.invoiceStatus}&startDate=${filterData.startDate || ""
          }&endDate=${filterData.endDate || ""}&despatchByStartDate=${filterData.despatchByStartDate || ""
          }&despatchByEndDate=${filterData.despatchByEndDate || ""
          }&netsuiteOrderNumber=${filterData.netsuiteOrderNumber || ""
          }&pagination=${rowsPerPage}&page=${page}`
        )
        .then(({ data }) => {
          if (data.success) {
            setRows(data.data);
            setCustomStatus(data.customOrderStatus)
            setBranchList(data.branchList);
            setExceptionStatus(data.exceptionStatus)
            setTotal(data.meta.total);
            setRowsPerPage(data.meta.per_page);
            setPage(data.meta.current_page);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setExportFile(true);
            // setTimeout(() => {
            //   handleExport(data.meta.total);
            // }, 500);
            setLoading(false);
          }
        });
    } else {
      getOrders();
    }
  };

  const handleExport = () => {
    setLoadingExport(true);
    httpclient
      .get(
        `new-system/netsuite/exceptional-report/filter-order-export?reportType=${filterData.exceptionStatus
        }&newSystemOrderNumber=${filterData.newSystemOrderNumber
        }&order_status=${filterData.order_status}&branchID=${filterData.branchList}&custom_order_status=${filterData.customStatus}&isShippingLabelGenerate=${filterData.isShippingLabelGenerate}&shipment=${filterData.shipment
        }&billToName=${filterData.customerName}&soldTo=${filterData.soldTo
        }&phoneNumber=${filterData.phoneNumber}&stockCode=${filterData.stockCode
        }&invoiceStatus=${filterData.invoiceStatus}&startDate=${filterData.startDate || ""
        }&endDate=${filterData.endDate || ""}&despatchByStartDate=${filterData.despatchByStartDate || ""
        }&despatchByEndDate=${filterData.despatchByEndDate || ""
        }&netsuiteOrderNumber=${filterData.netsuiteOrderNumber || ""
        }&pagination=${total}`
      )
      .then(({ data }) => {
        if (data.success) {
          setExportRows(data.download_link);
          setLoadingExport(false);
        }
      });
  };

  const hadleFilterOpen = () => {
    setFilterOpen((prev) => !prev);
  };

  const handleChangeFilter = (e) => {
    const { name, value } = e.target;
    setFilterData({
      ...filterData,
      [name]: value,
      remove: false,
    });
  };
  // console.log('filter data', filterData);

  const handleRemove = (data) => {
    setExportFile(false);
    setExportRows("");
    if (data === "soldTo") {
      filterData.soldToName = "";
      submittedData.soldToName = "";
    }
    if (data === "customStatus") {
      filterData.customStatusName = "";
      submittedData.customStatusName = "";
    }
    if (data === "branchList") {
      filterData.branchListName = "";
      submittedData.branchListName = "";
    }
    if (data === "exceptionStatus") {
      filterData.exceptionStatusName = "";
      submittedData.exceptionStatusName = "";
    }
    if (data === "startDate") {
      setFilterData({
        ...filterData,
        startDate: "",
        endDate: "",
        remove: true,
      });
      setSubmittedData({
        ...submittedData,
        startDate: "",
        endDate: "",
      });
    } else if (data === "despatchByStartDate") {
      setFilterData({
        ...filterData,
        despatchByStartDate: "",
        despatchByEndDate: "",
        remove: true,
      });
      setSubmittedData({
        ...submittedData,
        despatchByStartDate: "",
        despatchByEndDate: "",
      });
    } else {
      setFilterData({
        ...filterData,
        [data]: "",
        remove: true,
      });

      setSubmittedData({
        ...submittedData,
        [data]: "",
      });
    }
  };

  function accept(row) {
    if (
      row.company.multiLoc_desp_per_po === "0" &&
      row.orderProduct.some((product) => product.assignToBranch.length > 1)
    ) {
      return true;
    } else {
      return false;
    }
  }

  const handleRowCheck = (e, row) => {
    console.log("row", row);
    var checkIfNotSame = false;
    selectedChecked.map((check) => {
      if (
        row.reportType !== check.reportType ||
        row.orderType !== check.orderType
      ) {
        return (checkIfNotSame = true);
      } else {
        return (checkIfNotSame = false);
      }
    });

    if (checkIfNotSame === true) {
      setButtonLoader(true);
      setTimeout(() => {
        alert("Please select only one type of exception order");
        setButtonLoader(false);
      }, 500);
    } else {
      setButtonLoader(false);
      const { checked } = e.target;
      if (checked === true) {
        setRowChecked([...rowChecked, row.orderID]);
        setCompany([...company, row.company.erpAccountCustomerID]);
        setSelectedChecked([...selectedChecked, row]);
        setOrderChecked([
          ...orderChecked,
          !row.orderProduct.some(
            (product) =>
              product.reasonCode === "3" ||
              product.reasonCode === "4" ||
              product.reasonCode === "5" ||
              product.reasonCode === "12" ||
              product.reasonCode === ""
          ) || accept(row),
        ]);
      } else {
        let newData = rowChecked.filter((check) => {
          return check !== row.orderID;
        });

        let newArr = selectedChecked.filter((select) => {
          return select.orderID !== row.orderID;
        });

        let newOrder = orderChecked.filter((order, index) => {
          return index !== rowChecked.indexOf(row.orderID);
        });
        let newArr1 = company.filter((order, index) => {
          return index !== rowChecked.indexOf(row.orderID);
        });

        // Update the 'company' state with the unique companies
        setCompany(newArr1);
        setRowChecked(newData);
        setSelectedChecked(newArr);
        setOrderChecked(newOrder);
      }
    }
  };

  // console.log("row-checked", rowChecked)
  // console.log("selected-checked", selectedChecked)
  // console.log("selected", selected)
  // console.log("company", company)

  const handleAccept = (row) => {
    let currID = [row.orderID];
    let company = [row.company.erpAccountCustomerID];
    setViewDetails(row);
    setSelected(currID);
    setSelected1(company);
    setStatus("accept");
    setOpenStatusDialog(true);
  };

  const handleReject = (row) => {
    let currID = [row.orderID];
    let company = [row.company.erpAccountCustomerID];
    setViewDetails(row);
    setSelected(currID);
    setSelected1(company);
    setStatus("reject");
    setOpenStatusDialog(true);
  };

  const handleAcceptArray = () => {
    setOpenStatusDialog(true);
    setStatus("accept");
  };

  const handleRejectArray = () => {
    setOpenStatusDialog(true);
    setStatus("reject");
  };

  // const sendChangeOrder = (call) => {
  //   if (call.open === false) {
  //     setRowChecked([]);
  //     setSelectedChecked([]);
  //     setStatus("");
  //     setOpenStatusDialog(false);
  //     setViewDetails({});
  //   }
  //   if (call.success === true) {
  //     setButtonLoader(true);
  //     setBackdropLoader(true);
  //     httpclient
  //       .post("/new-system/netsuite/order/update-status", {
  //         orderID: selected.length > 0 ? selected : rowChecked,
  //         status: status === "accept" ? "Accepted" : "Rejected",
  //         fullfillment_status: status === "reject" ? "0" : call.fullfillment,
  //       })
  //       .then(({ data }) => {
  //         if (data.success) {
  //           setButtonLoader(false);
  //           setBackdropLoader(false);
  //           setOpen(true);
  //           setMessageState("success");
  //           setMessage(data.message);
  //           setRowChecked([]);
  //           setSelected([]);
  //           setSelectedChecked([]);
  //           setStatus("");
  //           setViewDetails({});
  //           setTimeout(() => {
  //             getOrders();
  //           }, [1500]);
  //         } else {
  //           setButtonLoader(false);
  //           setBackdropLoader(false);
  //           setOpen(true);
  //           setMessageState("error");
  //           setMessage(data.error);
  //           setRowChecked([]);
  //           setSelectedChecked([]);
  //           setStatus("");
  //           setViewDetails({});
  //         }
  //       })
  //       .catch((err) => {
  //         setButtonLoader(false);
  //         setBackdropLoader(false);
  //         setOpen(true);
  //         setMessageState("error");
  //         setMessage(err.message);
  //         setRowChecked([]);
  //         setSelectedChecked([]);
  //         setStatus("");
  //         setViewDetails({});
  //       });
  //   }
  // };

  const sendChangeOrder = (call, reason, reasonCode) => {
    if (call.open === false) {
      setRowChecked([]);
      setSelectedChecked([]);
      setStatus("");
      setOpenStatusDialog(false);
      setViewDetails({});
    }
    if (call.success === true) {
      setButtonLoader(true);
      setBackdropLoader(true);
      const apipoint =
        // (viewDetails.orderType === "Bunnings" && status === "accept") ||
        // selectedChecked.some(
        //   (row) => row.orderType === "Bunnings" && status === "accept"
        // )
        //   ? "/new-system/netsuite/order/bunnings-accept"
        //   : 
        "/new-system/netsuite/order/update-status";
      httpclient
        .post(apipoint, {
          orderID: selected.length > 0 ? selected : rowChecked,
          companyID: selected1.length > 0 ? selected1 : [...new Set(company)],
          status: status === "accept" ? "Accepted" : "Rejected",
          reason: reason,
          reasonCode: reasonCode,
          fullfillment_status: status === "reject" ? "0" : call.fullfillment,
        })
        .then(({ data }) => {
          if (data.success) {
            setButtonLoader(false);
            setBackdropLoader(false);
            setOpen(true);
            setMessageState("success");
            setMessage(data.message);
            setRowChecked([]);
            setSelected([]);
            setSelected1([]);
            setSelectedChecked([]);
            setStatus("");
            setViewDetails({});
            setTimeout(() => {
              handleFilter();
            }, 1500);
          } else {
            setButtonLoader(false);
            setBackdropLoader(false);
            setOpen(true);
            setMessageState("error");
            setMessage(data.error);
            setRowChecked([]);
            setSelectedChecked([]);
            setStatus("");
            setViewDetails({});
          }
        })
        .catch((err) => {
          setButtonLoader(false);
          setBackdropLoader(false);
          setOpen(true);
          setMessageState("error");
          setMessage(err.message);
          setRowChecked([]);
          setSelectedChecked([]);
          setStatus("");
          setViewDetails({});
        });
    }
  };

  const handleSort = (column) => {
    setDirection((prevDirection) => !prevDirection);
    setCurrentColumn(column);
    submittedData.submit
      ? httpclient
        .get(
          `new-system/netsuite/exceptional-report?reportType=${filterData.exceptionStatus
          }&newSystemOrderNumber=${filterData.newSystemOrderNumber
          }&phoneNumber=${filterData.phoneNumber}&isShippingLabelGenerate=${filterData.isShippingLabelGenerate}&order_status=${filterData.order_status
          }&branchID=${filterData.branchList}&custom_order_status=${filterData.customStatus}&shipment=${filterData.shipment}&billToName=${filterData.customerName
          }&soldTo=${filterData.soldTo}&stockCode=${filterData.stockCode
          }&invoiceStatus=${filterData.invoiceStatus}&startDate=${filterData.startDate || ""
          }&endDate=${filterData.endDate || ""}&despatchByStartDate=${filterData.despatchByStartDate || ""
          }&despatchByEndDate=${filterData.despatchByEndDate || ""
          }&netsuiteOrderNumber=${filterData.netsuiteOrderNumber || ""
          }&sort_by=${column}&direction=${!direction ? "asc" : "desc"
          }&pagination=${rowsPerPage}&page=${page}`
        )
        .then(({ data }) => {
          if (data.success === true) {
            setRows(data.data);
            setTotal(data.meta.total);
            setRowsPerPage(parseInt(data.meta.per_page));
            setPage(data.meta.current_page);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        })
      : httpclient
        .get(
          `new-system/netsuite/exceptional-report?sort_by=${column}&direction=${!direction ? "asc" : "desc"
          }&pagination=${rowsPerPage}&page=${page}`
        )
        .then(({ data }) => {
          if (data.success === true) {
            setRows(data.data);
          }
        });
  };

  const handleChangePage = (e, page) => {
    setLoading(true);
    submittedData.submit
      ? httpclient
        .get(
          `new-system/netsuite/exceptional-report?reportType=${filterData.exceptionStatus
          }&newSystemOrderNumber=${filterData.newSystemOrderNumber
          }&phoneNumber=${filterData.phoneNumber}&isShippingLabelGenerate=${filterData.isShippingLabelGenerate}&order_status=${filterData.order_status
          }&branchID=${filterData.branchList}&custom_order_status=${filterData.customStatus}&shipment=${filterData.shipment}&billToName=${filterData.customerName
          }&soldTo=${filterData.soldTo}&stockCode=${filterData.stockCode
          }&invoiceStatus=${filterData.invoiceStatus}&startDate=${filterData.startDate || ""
          }&endDate=${filterData.endDate || ""}&despatchByStartDate=${filterData.despatchByStartDate || ""
          }&despatchByEndDate=${filterData.despatchByEndDate || ""
          }&netsuiteOrderNumber=${filterData.netsuiteOrderNumber || ""
          }&sort_by=${currentColumn}&direction=${direction ? "asc" : "desc"
          }&pagination=${rowsPerPage}&page=${page}`
        )
        .then(({ data }) => {
          if (data.success === true) {
            setRows(data.data);
            setTotal(data.meta.total);
            setRowsPerPage(parseInt(data.meta.per_page));
            setPage(data.meta.current_page);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        })
      : httpclient
        .get(
          `new-system/netsuite/exceptional-report?direction=${direction ? "asc" : "desc"
          }&pagination=${rowsPerPage}&page=${page}`
        )
        .then(({ data }) => {
          if (data.success === true) {
            setRows(data.data);
            setTotal(data.meta.total);
            setRowsPerPage(parseInt(data.meta.per_page));
            setPage(data.meta.current_page);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    // setLoading(true);

    localStorage.setItem("configRowPerPage", event.target.value);

    submittedData.submit
      ? httpclient
        .get(
          `new-system/netsuite/exceptional-report?reportType=${filterData.exceptionStatus
          }
          &newSystemOrderNumber=${filterData.newSystemOrderNumber
          }&phoneNumber=${filterData.phoneNumber}&isShippingLabelGenerate=${filterData.isShippingLabelGenerate}&order_status=${filterData.order_status
          }&branchID=${filterData.branchList}&custom_order_status=${filterData.customStatus}&billToName=${filterData.customerName}&soldTo=${filterData.soldTo
          }&stockCode=${filterData.stockCode}&shipment=${filterData.shipment
          }&invoiceStatus=${filterData.invoiceStatus}&startDate=${filterData.startDate || ""
          }&startDate=${filterData.startDate || ""}&despatchByStartDate=${filterData.despatchByStartDate || ""
          }&despatchByEndDate=${filterData.despatchByEndDate || ""
          }&netsuiteOrderNumber=${filterData.netsuiteOrderNumber || ""
          }&sort_by=${currentColumn}&direction=${direction ? "asc" : "desc"
          }&pagination=${+event.target.value}&page=${page}`
        )
        .then(({ data }) => {
          setLoading(true);
          if (data.success === true) {
            setRows(data.data);
            setTotal(data.meta.total);
            setRowsPerPage(parseInt(data.meta.per_page));
            setPage(data.meta.current_page);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        })
      : httpclient
        .get(
          `new-system/netsuite/exceptional-report?direction=${direction ? "asc" : "desc"
          }&pagination=${+event.target.value}&page=${page}`
        )
        .then(({ data }) => {
          setLoading(true);
          if (data.success === true) {
            setRows(data.data);
            setTotal(data.meta.total);
            setRowsPerPage(parseInt(data.meta.per_page));
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setPage(data.meta.current_page);
            setLoading(false);
          }
        });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    if (!loadingExport && exportRows !== "" && buttonRef.current) {
      buttonRef.current.click();
    }
  }, [loadingExport, exportRows]);

  //console.log("company-list", companyList);
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item md={8} xs={12}>
          <Header>
            <h1>All Orders (NetSuite)</h1>
          </Header>
        </Grid>
        <Grid
          item
          md={4}
          xs={12}
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
        >
          {exportRows !== "" ? (

            <Button
              download
              href={exportRows}
              color="primary"
              variant="contained"
              style={{ marginRight: "10px" }}
              ref={buttonRef}
            >
              Export <Download style={{ marginLeft: "5px" }} fontSize="small" />
            </Button>


          ) : (
            <Button

              color="primary"
              variant="contained"
              style={{ marginRight: "10px" }}
              disabled={!exportFile}
              onClick={handleExport}
            >
              {loadingExport ? "Generating Data to Export" : "Export"}{loadingExport ? `...` : <Download style={{ marginLeft: "5px" }} fontSize="small" />}
            </Button>

          )}
          <Button color="primary" variant="contained" onClick={hadleFilterOpen}>
            Filter <FilterList style={{ marginLeft: "5px" }} fontSize="small" />
          </Button>
        </Grid>

        {/* Filter */}
        <Grid item xs={12}>
          <Collapse in={filterOpen}>
            <Card>
              <Box p={4}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <InputLabel>Exception Status</InputLabel>
                    {/* <FormControl fullWidth>
                      <Select
                        value={filterData.exceptionalOrder}
                        // label="Exclusive exceptionalOrder"
                        name="exceptionalOrder"
                        onChange={handleChangeFilter}
                      >
                        <MenuItem value={""}>Select</MenuItem>
                        <MenuItem value={"exceptionalOrder"}>Yes</MenuItem>
                        <MenuItem value={"readyToProcessOrder"}>No</MenuItem>
                      </Select>
                    </FormControl> */}
                    <Autocomplete
                      disablePortal
                      id="soldto_name1"
                      options={exceptionStatus}
                      onChange={(event, newValue) => {
                        handleChangeException(newValue);
                      }}
                      inputValue={filterData.exceptionStatusName}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          onChange={handleChange}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") handleFilter();
                          }}
                          value={filterData.exceptionStatusName}
                          variant="outlined"
                          name="exceptionStatus"
                        // label="Company"
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <InputLabel>NetSuite Order Number</InputLabel>
                    <TextField
                      name="netsuiteOrderNumber"
                      value={filterData.netsuiteOrderNumber}
                      onChange={handleChangeFilter}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") handleFilter();
                      }}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>

                  {/* <Grid item xs={12} md={4}>
                    <InputLabel>DSCO Order ID</InputLabel>
                    <TextField
                      name="newSystemOrderID"
                      value={filterData.newSystemOrderID}
                      onChange={handleChangeFilter}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid> */}

                  <Grid item xs={12} md={4}>
                    <InputLabel>PO Number</InputLabel>
                    <TextField
                      name="newSystemOrderNumber"
                      value={filterData.newSystemOrderNumber}
                      onChange={handleChangeFilter}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") handleFilter();
                      }}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <InputLabel>Stock Code</InputLabel>
                    <TextField
                      name="stockCode"
                      value={filterData.stockCode}
                      onChange={handleChangeFilter}
                      onKeyDown={e => { if (e.key === "Enter") handleFilter() }}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <InputLabel>Consumer Name</InputLabel>
                    <TextField
                      name="customerName"
                      value={filterData.customerName}
                      onChange={handleChangeFilter}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") handleFilter();
                      }}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <InputLabel>Customer Name</InputLabel>
                    <Autocomplete
                      disablePortal
                      id="soldto_name"
                      options={companyList}
                      onChange={(event, newValue) => {
                        handleChangeSoldTo(newValue);
                      }}
                      inputValue={filterData.soldToName}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          onChange={handleChange}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") handleFilter();
                          }}
                          value={filterData.soldToName}
                          variant="outlined"
                          name="soldTo"
                        // label="Company"
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <InputLabel>Phone Number</InputLabel>
                    <TextField
                      name="phoneNumber"
                      value={filterData.phoneNumber}
                      onChange={handleChangeFilter}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") handleFilter();
                      }}
                      variant="outlined"
                      type="number"
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <InputLabel>Order Status</InputLabel>
                    <FormControl fullWidth>
                      <Select
                        value={filterData.order_status}
                        // label="Exclusive order_status"
                        name="order_status"
                        onChange={handleChangeFilter}
                      >
                        <MenuItem value={""}>Select</MenuItem>
                        <MenuItem value={"Accepted"}>Accepted</MenuItem>
                        <MenuItem value={"Rejected"}>Rejected</MenuItem>
                        <MenuItem value={"Ignored"}>
                          Manually Processed
                        </MenuItem>
                        <MenuItem value={"Received"}>New</MenuItem>
                        <MenuItem value={"Cancelled"}>Cancelled</MenuItem>
                        <MenuItem value={"Pre-Sales"}>Pre-Sales</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <InputLabel>Invoice Status</InputLabel>
                    <FormControl fullWidth>
                      <Select
                        value={filterData.invoiceStatus}
                        // label="Exclusive order_status"
                        name="invoiceStatus"
                        onChange={handleChangeFilter}
                      >
                        <MenuItem value={""}>Select</MenuItem>
                        <MenuItem value={"pending"}>Not Invoiced</MenuItem>
                        <MenuItem value={"ready"}>Generating Invoice</MenuItem>
                        <MenuItem value={"invoiced"}>Invoiced</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <InputLabel>Shipment Status</InputLabel>
                    <FormControl fullWidth>
                      <Select
                        value={filterData.shipment}
                        // label="Exclusive exceptionalOrder"
                        name="shipment"
                        onChange={handleChangeFilter}
                      >
                        <MenuItem value={""}>Select</MenuItem>
                        <MenuItem value={"Shipped"}>Shipped</MenuItem>
                        <MenuItem value={"Pending"}>Pending</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <InputLabel>Start Date</InputLabel>
                    <TextField
                      variant="outlined"
                      name="startDate"
                      type="date"
                      value={filterData.startDate}
                      onChange={(e) => handleChangeFilter(e)}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <InputLabel>End Date</InputLabel>
                    <TextField
                      variant="outlined"
                      name="endDate"
                      type="date"
                      value={filterData.endDate}
                      onChange={(e) => handleChangeFilter(e)}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <InputLabel>Despatch by Start Date</InputLabel>
                    <TextField
                      variant="outlined"
                      name="despatchByStartDate"
                      type="date"
                      value={filterData.despatchByStartDate}
                      onChange={(e) => handleChangeFilter(e)}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <InputLabel>Despatch by End Date</InputLabel>
                    <TextField
                      variant="outlined"
                      name="despatchByEndDate"
                      type="date"
                      value={filterData.despatchByEndDate}
                      onChange={(e) => handleChangeFilter(e)}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <InputLabel>Shipping Label Generated?</InputLabel>
                    <FormControl fullWidth>
                      <Select
                        value={filterData.isShippingLabelGenerate}
                        // label="Exclusive exceptionalOrder"
                        name="isShippingLabelGenerate"
                        onChange={handleChangeFilter}
                      >
                        <MenuItem value={""}>Select</MenuItem>
                        <MenuItem value={"1"}>Yes</MenuItem>
                        <MenuItem value={"0"}>No</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    {/* <InputLabel>Custom Order Status</InputLabel>
                    <FormControl fullWidth>
                      <Select
                        value={filterData.customStatus}
                        // label="Exclusive order_status"
                        name="customStatus"
                        onChange={handleChangeFilter}
                      >
                        <MenuItem value={""}>Select</MenuItem>
                        {customStatus && customStatus.length > 0 && customStatus.map((cust) => (
                        <MenuItem value={cust.id}>{cust.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl> */}
                    <InputLabel>Custom Order Status</InputLabel>

                    <Autocomplete
                      disablePortal
                      id="soldto_name1"
                      options={customStatus}
                      onChange={(event, newValue) => {
                        handleChangeCustom(newValue);
                      }}
                      inputValue={filterData.customStatusName}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          onChange={handleChange}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") handleFilter();
                          }}
                          value={filterData.customStatusName}
                          variant="outlined"
                          name="customStatus"
                        // label="Company"
                        />
                      )}
                    />
                  </Grid>

                  {/* <Grid item xs={12} md={4}>
                    <InputLabel>Branch</InputLabel>

                    <Autocomplete
                      disablePortal
                      id="soldto_name1"
                      options={branchList}
                      onChange={(event, newValue) => {
                        handleChangeBranch(newValue);
                      }}
                      inputValue={filterData.branchListName}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          onChange={handleChange}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") handleFilter();
                          }}
                          value={filterData.branchListName}
                          variant="outlined"
                          name="branchList"
                        />
                      )}
                    />
                  </Grid> */}

                  {/* <Grid item xs={12} md={4}>
                    <InputLabel>Pending</InputLabel>
                    <FormControl fullWidth>
                      <Select
                        value={filterData.pending}
                        // label="Exclusive Pending"
                        name="pending"
                        onChange={handleChangeFilter}
                      >
                        <MenuItem value={""}>Select</MenuItem>
                        <MenuItem value={"Y"}>Yes</MenuItem>
                        <MenuItem value={"N"}>No</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <InputLabel>Order Type</InputLabel>
                    <FormControl fullWidth>
                      <Select
                        value={filterData.orderType}
                        // label="Exclusive orderType"
                        name="orderType"
                        onChange={handleChangeFilter}
                      >
                        <MenuItem value={""}>Select</MenuItem>
                        <MenuItem value={"Disco"}>Disco</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <InputLabel>Order Status</InputLabel>
                    <FormControl fullWidth>
                      <Select
                        value={filterData.pending}
                        // label="Exclusive Pending"
                        name="pending"
                        onChange={handleChangeFilter}
                      >
                        <MenuItem value={""}>Select</MenuItem>
                        <MenuItem value={"created"}>Created</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid> */}

                  <Grid item xs={12}>
                    <Box textAlign={"right"}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleFilter}
                      >
                        Filter{" "}
                        <ArrowForward
                          fontSize="small"
                          style={{ marginLeft: "5px" }}
                        />
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </Collapse>
        </Grid>

        {submittedData.exceptionalOrder ||
          submittedData.newSystemOrderNumber ||
          submittedData.order_status ||
          submittedData.customStatusName ||
          submittedData.branchListName ||
          submittedData.exceptionStatusName ||
          submittedData.shipment ||
          submittedData.invoiceStatus ||
          submittedData.phoneNumber ||
          submittedData.customerName ||
          submittedData.soldToName ||
          submittedData.stockCode ||
          submittedData.despatchByStartDate ||
          submittedData.despatchByEndDate ||
          submittedData.startDate ||
          submittedData.netsuiteOrderNumber ||
          submittedData.isShippingLabelGenerate ||
          submittedData.endDate ? (
          <Grid item xs={12}>
            <FilteredBox>
              <span>Filtered: </span>
              {submittedData.exceptionStatusName && (
                <p>
                  <span>
                    Exception Status:{" "}
                    {submittedData.exceptionStatusName}
                  </span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("exceptionStatus")}
                  />
                </p>
              )}

              {submittedData.isShippingLabelGenerate && (
                <p>
                  <span>
                    Shipping Label Generate:{" "}
                    {submittedData.isShippingLabelGenerate === "0"
                      ? "No"
                      : "Yes"}
                  </span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("isShippingLabelGenerate")}
                  />
                </p>
              )}

              {submittedData.newSystemOrderNumber && (
                <p>
                  <span>PO Number: {submittedData.newSystemOrderNumber}</span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("newSystemOrderNumber")}
                  />
                </p>
              )}
              {submittedData.netsuiteOrderNumber && (
                <p>
                  <span>NetSuite Order Number: {submittedData.netsuiteOrderNumber}</span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("netsuiteOrderNumber")}
                  />
                </p>
              )}
              {submittedData.order_status && (
                <p>
                  <span>
                    Order Status:
                    {submittedData.order_status === "Received"
                      ? "New"
                      : submittedData.order_status === "Ignored"
                        ? "Manually Processed"
                        : submittedData.order_status}
                  </span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("order_status")}
                  />
                </p>
              )}
              {/* 
              {submittedData.invoiceStatus && (
                <p>
                  <span>Invoice Status: {submittedData.invoiceStatus}</span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("invoiceStatus")}
                  />
                </p>
              )} */}

              {submittedData.invoiceStatus &&
                submittedData.invoiceStatus === "pending" ? (
                <p>
                  <span>Invoice Status: Not Invoiced</span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("invoiceStatus")}
                  />
                </p>
              ) : submittedData.invoiceStatus &&
                submittedData.invoiceStatus === "ready" ? (
                <p>
                  <span>Invoice Status: Generating Invoice</span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("invoiceStatus")}
                  />
                </p>
              ) : submittedData.invoiceStatus &&
                submittedData.invoiceStatus === "invoiced" ? (
                <p>
                  <span>Invoice Status: Invoiced</span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("invoiceStatus")}
                  />
                </p>
              ) : null}
              {(submittedData.startDate || submittedData.endDate) && (
                <p>
                  <span>
                    Date Range: {submittedData.startDate} -{" "}
                    {submittedData.endDate}
                  </span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("startDate")}
                  />
                </p>
              )}

              {/* {submittedData.endDate && (
                <p>
                  <span>End Date: {submittedData.endDate}</span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("endDate")}
                  />
                </p>
              )} */}

              {(submittedData.despatchByStartDate ||
                submittedData.despatchByEndDate) && (
                  <p>
                    <span>
                      Despatch date range: {submittedData.despatchByStartDate} -{" "}
                      {submittedData.despatchByEndDate}
                    </span>
                    <Close
                      fontSize="small"
                      onClick={() => handleRemove("despatchByStartDate")}
                    />
                  </p>
                )}
              {submittedData.phoneNumber && (
                <p>
                  <span>Phone Number: {submittedData.phoneNumber}</span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("phoneNumber")}
                  />
                </p>
              )}

              {submittedData.customerName && (
                <p>
                  <span>Consumer Name: {submittedData.customerName}</span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("customerName")}
                  />
                </p>
              )}
              {submittedData.soldToName && (
                <p>
                  <span>Customer Name: {submittedData.soldToName}</span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("soldTo")}
                  />
                </p>
              )}

              {submittedData.stockCode && (
                <p>
                  <span>Stock Code: {submittedData.stockCode}</span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("stockCode")}
                  />
                </p>
              )}
              {submittedData.shipment && (
                <p>
                  <span>Shipment: {submittedData.shipment}</span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("shipment")}
                  />
                </p>
              )}
              {submittedData.customStatusName && (
                <p>
                  <span>Custom Status: {submittedData.customStatusName}</span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("customStatus")}
                  />
                </p>
              )}
              {submittedData.branchListName && (
                <p>
                  <span>Branch: {submittedData.branchListName}</span>
                  <Close
                    fontSize="small"
                    onClick={() => handleRemove("branchList")}
                  />
                </p>
              )}
            </FilteredBox>
          </Grid>
        ) : (
          <Box></Box>
        )}
        {/* Filter */}

        {/* Bulk Accept and Reject */}
        <Grid item xs={12}>
          <Box textAlign={"right"}>
            {rowChecked.length > 0 && (
              <span style={{ marginRight: "10px" }}>
                {rowChecked.length} rows selected.
              </span>
            )}
            <Button
              color="primary"
              size="small"
              variant="contained"
              onClick={() => handleAcceptArray()}
              disabled={
                rowChecked.length > 0 && !orderChecked.includes(true) && selectedChecked.every((sel) => sel.netsuiteOrderNumber !== null)
                  ? buttonLoader
                    ? true
                    : false
                  : true
              }
            >
              Accept{" "}
              <Check fontSize="small" style={{ marginLeft: "5px", top: "0" }} />
            </Button>

            <Button
              color="error"
              size="small"
              variant="contained"
              onClick={() => handleRejectArray()}
              style={{ marginLeft: "10px" }}
              disabled={
                rowChecked.length > 0 && selectedChecked.every((sel) => sel.netsuiteOrderNumber !== null) ? (buttonLoader ? true : false) : true
              }
            >
              Reject{" "}
              <Clear fontSize="small" style={{ marginLeft: "5px", top: "0" }} />
            </Button>
          </Box>
        </Grid>
        {/* Bulk Accept and Reject */}

        <Grid item xs={12}>
          <TableComponent
            columns={columns}
            rows={rows}
            sort={true}
            handleView={handleView}
            handleSort={handleSort}
            loading={loading}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleChangePage={handleChangePage}
            handleRowCheck={handleRowCheck}
            rowChecked={rowChecked}
            handleAccept={handleAccept}
            handleReject={handleReject}
            buttonLoader={buttonLoader}
            direction={direction}
            currentColumn={currentColumn}
            page={page}
            total={total && total}
            fromTable={from}
            toTable={to}
            rowsPerPage={rowsPerPage}
            filterData={filterData}
          />
        </Grid>
      </Grid>

      {openViewDialog && (
        <ViewOrderDialog
          singleLoading={singleLoading}
          viewDetails={viewDetails}
          sendDetails={sendDetails}
        />
      )}

      {openStatusDialog && (
        <StatusDialog
          sendChangeOrder={sendChangeOrder}
          status={status}
          selectedChecked={selectedChecked}
          viewDetails={viewDetails}
        />
      )}

      {backdropLoader && <BackdropLoader />}

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={messageState}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ListOrders;
