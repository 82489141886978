import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Grid,
  Snackbar,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { httpclient } from "../../../../utils/httpClient";
import { extractCompanySettings } from "../extractCompanySettings";
import ParentChecked from "./checkBoxForm/ParentChecked";
import ChildChecked from "./checkBoxForm/ChildChecked";

var loginData = localStorage.getItem("login");
var loginValue = JSON.parse(loginData);

const SyncSettings = ({ label, companyId, fetchedSyncSettingsDetails }) => {
  const [checked, setChecked] = useState([]);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [saveChangesLoading, setSaveChangesLoading] = useState(null);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");

  const companySpecificParameters = extractCompanySettings(
    fetchedSyncSettingsDetails,
    companyId
  );

  const orderParameters = Object.keys(companySpecificParameters?.order || []);
  const productParameters = Object.keys(
    companySpecificParameters?.product || []
  );

  const orderParameterChecks = orderParameters.map(
    (key) => !!Number(`${companySpecificParameters?.order[key]}`)
  );
  const productParameterChecks = productParameters.map(
    (key) => !!Number(`${companySpecificParameters?.product[key]}`)
  );

  useEffect(() => {
    setChecked([...orderParameterChecks, ...productParameterChecks]);
  }, [fetchedSyncSettingsDetails]);

  const handleParentChange = (event, type) => {
    if (type === "order")
      setChecked([
        ...Array.from(
          { length: orderParameters.length },
          () => event.target.checked
        ),
        ...productParameters.map((_, i) => checked[orderParameters.length + i]),
      ]);
    else if (type === "product")
      setChecked([
        ...checked.slice(0, orderParameters.length),
        ...Array.from(
          { length: productParameters.length },
          () => event.target.checked
        ),
      ]);
  };

  const handleChildChange = (event, index) => {
    setChecked((prevState) => {
      const newChecked = [...prevState];
      newChecked[index] = event.target.checked;
      return newChecked;
    });
  };

  const handleSaveSync = (companySpecificParameters, companyId) => {
    setSaveChangesLoading(true);

    const orderParameters = Object.keys(companySpecificParameters?.order || []);
    const productParameters = Object.keys(
      companySpecificParameters?.product || []
    );
    const postParameter = {
      companyID: companyId,
      settings: [
        ...orderParameters.map((item, index) => ({
          key: item,
          value: checked[index],
        })),
        ...productParameters.map((item, index) => ({
          key: item,
          value: checked[orderParameters.length + index],
        })),
      ],

      updated_by: `${loginValue.firstName} ${loginValue.lastName}`,
      //   order_import: checked[0],
      //   order_push_to_exo: checked[1],
      //   order_to_shippit: checked[2],
      //   order_accept_reject: checked[3],
      //   product_update: checked[4],
      //   product_upload: checked[5],
    };

    httpclient
      .post("/update-web-routing-setting", postParameter)
      .then(({ data }) => {
        if (data.success) {
          setSaveChangesLoading(false);
          setData(data.data);
          setMessage(data.message);
        }
      })
      .catch((err) => {
        setSaveChangesLoading(false);
        if (err.response && err.response.status === 401) {
          localStorage.clear();
          window.location = "/login";
        } else {
          setError("An error occurred while fetching data.");
        }
      });
  };

  useEffect(() => {
    if (message) {
      setOpen(true);
    }
  }, [data]);

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      {(productParameters.length > 0 || orderParameters.length > 0) && (
        <>
          <Grid
            container
            spacing={2}
            sx={{ pl: 2, pt: 3, mt: 1, ml: 0.2, background: "white" }}
          >
            {/* <Grid item xs={12}>
              <h1>{label || "Pass Label Please"}</h1>
            </Grid> */}

            {orderParameters.length > 0 && (
              <Grid item xs={12} sm={6}>
                <ParentChecked
                  label="Order"
                  parent="order"
                  checked={checked}
                  orderParameterLength={orderParameters.length}
                  handleParentChange={handleParentChange}
                />
                <ChildChecked
                  parameter={orderParameters}
                  checked={checked}
                  parent="order"
                  orderParameterLength={orderParameters.length}
                  handleChildChange={handleChildChange}
                />
              </Grid>
            )}
            {productParameters.length > 0 && (
              <Grid item xs={12} sm={6}>
                <ParentChecked
                  label="Product"
                  parent="product"
                  checked={checked}
                  orderParameterLength={orderParameters.length}
                  handleParentChange={handleParentChange}
                />
                <ChildChecked
                  parameter={productParameters}
                  checked={checked}
                  parent="product"
                  orderParameterLength={orderParameters.length}
                  handleChildChange={handleChildChange}
                />
              </Grid>
            )}
            <Grid item xs={12} sx={{ textAlign: "right", pt: 2, m: 2 }}>
              <Button
                color="primary"
                variant="contained"
                onClick={() =>
                  handleSaveSync(companySpecificParameters, companyId)
                }
              >
                {saveChangesLoading ? (
                  <>
                    Applying
                    <CircularProgress
                      sx={{ color: "white", ml: 1 }}
                      size={15}
                    />
                  </>
                ) : (
                  "Apply Changes"
                )}
              </Button>
            </Grid>
          </Grid>
          <Snackbar
            autoHideDuration={2000}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={open}
            onClose={handleCloseSnack}
          >
            <Alert onClose={handleCloseSnack} severity="success">
              {message}
            </Alert>
          </Snackbar>
        </>
      )}
    </>
  );
};

export default SyncSettings;
