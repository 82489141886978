import React from "react";
import MiniDrawer from "../Components/Drawer";
import { Navigate } from "react-router-dom";
import PATH from "./PATHS";
import Login from "../Public/Login";
const ProtectedRoute = () => {
  return localStorage.getItem("token") ? (
    <MiniDrawer />
  ) : (
    // <Login />
    <Navigate to={PATH.LOGIN} />
  );
};

export default ProtectedRoute;
