import React from "react";

import { extractCSVname } from "../../utility/extractCSVname.js";
import { Button, Grid } from "@mui/material";
import { Download } from "@mui/icons-material";
import TWDataGridTablePage from "../Temple&WebsterTable/TWDataGridTablePage.js";
import TWOrderTestingTable from "../Temple&WebsterTable/TWOrderTestingTable.js";
import { dateTimeFormatter } from "../../../../utils/DataGrid/dateTimeFormatter.js";

const fieldHeaderPairs = [
  {
    field: "createdAt",
    headerName: "CSV Generated Date",
    width: 260,
    valueFormatter: (params) => dateTimeFormatter(params),
  },
  { field: "ID" },
  {
    field: "sku_logistics",
    headerName: "SKU Logistics",
    width: 200,
    renderCell: (cellValues) => {
      if (cellValues.row.branch === "sku_logistics") {
        // If branch matches submit3pl, render the download button
        return (
          <>
            <Button href={cellValues.row.fileLink}>
              <Download />
            </Button>
          </>
        );
      } else {
        // If branch does not match submit3pl, render "-"
        return "-";
      }
    }
  },
  {
    field: "acr_sydney",
    headerName: "ACR Sydney",
    width: 200,
    renderCell: (cellValues) => {
      if (cellValues.row.branch === "acr_sydney") {
        // If branch matches submit3pl, render the download button
        return (
          <>
            <Button href={cellValues.row.fileLink}>
              <Download />
            </Button>
          </>
        );
      } else {
        // If branch does not match submit3pl, render "-"
        return "-";
      }
    }
  },
 
  {
    field: "submit3pl",
    headerName: "Summit 3PL",
    width: 200,
    renderCell: (cellValues) => {
      if (cellValues.row.branch === "submit3pl") {
        // If branch matches submit3pl, render the download button
        return (
          <>
            <Button href={cellValues.row.fileLink}>
              <Download />
              {/* {extractCSVname(cellValues.row.file)} */}
            </Button>
          </>
        );
      } else {
        // If branch does not match submit3pl, render "-"
        return "-";
      }
    }
  },
  {
    field: "silverwater",
    headerName: "Silverwater",
    width: 200,
    renderCell: (cellValues) => {
      if (cellValues.row.branch === "silverwater") {
        // If branch matches submit3pl, render the download button
        return (
          <>
            <Button href={cellValues.row.fileLink} target="_blank">
              <Download />
              {/* {extractCSVname(cellValues.row.file)} */}
            </Button>
          </>
        );
      } else {
        // If branch does not match submit3pl, render "-"
        return "-";
      }
    }
  },

  // {
  //   field: "acrFileName",
  //   headerName: "ACR File Name",
  //   minWidth: 350,
  //   renderCell: (cellValues) => (
  //     <>
  //       <Button href={cellValues.row.acrFileLink}>
  //         <Download /> {extractCSVname(cellValues.value)}
  //       </Button>
  //     </>
  //   ),
  // },
  // {
  //   field: "silverwaterFileName",
  //   headerName: "Sliverwater File Name",
  //   minWidth: 350,
  //   renderCell: (cellValues) => (
  //     <>
  //       <Button href={cellValues.row.silverwaterFileLink}>
  //         <Download /> {extractCSVname(cellValues.value)}
  //       </Button>
  //     </>
  //   ),
  // },
  // {
  //   field: "skuLogisticFileName",
  //   headerName: "SKU Logistic File Name",
  //   minWidth: 350,

  //   renderCell: (cellValues) => (
  //     <>
  //       <Button href={cellValues.row.skuLogisticFileName}>
  //         <Download /> {extractCSVname(cellValues.value)}
  //       </Button>
  //     </>
  //   ),
  // },
  // {
  //   field: "summitFileName",
  //   headerName: "Summit File Name",
  //   minWidth: 350,

  //   renderCell: (cellValues) => (
  //     <>
  //       <Button href={cellValues.row.summitFileLink}>
  //         <Download /> {extractCSVname(cellValues.value)}
  //       </Button>
  //     </>
  //   ),
  // },

  // {
  //   field: "newingtonSummitFileLink",
  // },
  // {
  //   field: "egertonSilverwaterFileLink",
  // },
  // {
  //   field: "erskineParkAcrFileLink",
  // },
  // {
  //   field: "yennoraSkuLogisticFileLink",
  // },
  // {
  //   // field: "soh_history_action",
  //   headerName: "SOH ACTION",
  //   type: "actions",
  //   width: 170,

  //   renderCell: (cellValues) => (
  //     <>
  //       <Grid
  //         width={"100%"}
  //         sx={{
  //           flex: 1,
  //           justifyContent: "center",
  //           alignItems: "center",
  //           textAlign: "center",
  //         }}
  //       >
  //         <Button
  //           variant="contained"
  //           sx={{
  //             margin: 0,
  //             marginLeft: "16px",
  //             // padding: 0,
  //           }}
  //           onClick={() => console.log("CLICKED")}
  //         >
  //           PUSH TO SOH
  //         </Button>
  //       </Grid>
  //     </>
  //   ),
  // },
];

const fieldHeaderPairs1 = [
  {
    field: "createdAt",
    headerName: "CSV Generated Date",
    width: 260,
    valueFormatter: (params) => dateTimeFormatter(params),
  },
  { field: "ID" },
  {
    field: "fileName",
    headerName: "File",
    width: 120,
    renderCell: (cellValues) => {
      
      // if (cellValues.row.branch === "sku_logistics") {
        // If branch matches submit3pl, render the download button
        return (
          <>
            <Button href={cellValues.row.fileLink}>
              <Download />
              {/* {extractCSVname(cellValues.row.fileName)} */}
            </Button>
          </>
        );
      // } else {
      //   // If branch does not match submit3pl, render "-"
      //   return "-";
      // }
    }
  },
]

const filterColumn = [
  //   {
  // field: "STORE_CODE",
  // label: "Branch",
  // component: "TextField",
  // component: "AutocompleteField",
  // url: "/replenishment/pipeline",
  // overrideDataField: "branchList",
  //   },

  {
    field: "startDate",
    label: "From",
    component: "TextField",
    type: "date",
  },
  {
    field: "endDate",
    label: "To",
    component: "TextField",
    type: "date",
  },
  // {
  //   field: "soldToa",
  //   // label: "Sold To",
  //   component: "AutocompleteField",
  //   url: "/current/company-account/all",
  // },
];

const TWOrderTestingNetSuite = () => {
  return (
    <TWOrderTestingTable
      headerTitle="TW Despatch Report"
      exportFeature={true}
      exportFileLink1="/temple-webster/netsuite-get-so-csv-files"
      exportFileLink2="/temple-webster/netsuite-so-files-import"
      exportFileLink="/temple-webster/netsuite-generate-tracking-csv-file"
      exportFileLink0="/temple-webster/netsuite-generate-3pldespatch-report?export=true"
      // customIDKey="ID"
      columns={fieldHeaderPairs}
      columns1={fieldHeaderPairs1}
      filterColumn={filterColumn}
      pageUrl="/temple-webster/netsuite-dispatch-report"
      pageUrl1="/temple-webster/netsuite-tracking-file-report"
      columnsVisibility={{
        ID: false,
        newingtonSummitFileLink: false,
        egertonSilverwaterFileLink: false,
        erskineParkAcrFileLink: false,
        yennoraSkuLogisticFileLink: false,
      }}
    />
  );
};

export default TWOrderTestingNetSuite;

