import { default as ColorPalette } from "./colors";

/*Look for making flex:1 without using function in shared TableComponent. Using css might be easy to change design. Visit again*/

const MuiDataGridOverrides = {
  styleOverrides: {
    root: {
      flex: 1,
      fontSize: 14,
      cell: {
        "&:last-child": {
          borderBottom: 0,
        },
      },
    },

    columnHeaders: {
      backgroundColor: ColorPalette.primary.main,
      color: ColorPalette.primary.light,
    },

    //Sort button hover
    iconButtonContainer: {
      button: {
        color: ColorPalette.primary.light,
        "&:hover": {
          backgroundColor: ColorPalette.DataGridTheme.hoverBackground,
          color: ColorPalette.primary.light,
        },
      },
    },

    row: {
      "&:nth-of-type(even)": {
        backgroundColor: ColorPalette.DataGridTheme.evenRowColor,
      },
      "&:hover": {
        backgroundColor: ColorPalette.DataGridTheme.rowHoverColor,
      },
    },
    menuIconButton: {
      color: ColorPalette.primary.light,
      "&:hover": {
        backgroundColor: ColorPalette.DataGridTheme.hoverBackground,
      },
      "&:active, &:hover": {
        color: ColorPalette.primary.light,
      },
    },

    iconSeparator: {
      opacity: 0,
    },

    footerContainer: {
      borderBottom: 1,

      borderTop: 0,
    },
  },
};

export default MuiDataGridOverrides;
